export class LayerGroup implements ILayerGroup {
    id: string;
    title: string;
    icon: string;
    iconClass: string;
    children: LayerGroup[];
    visible: boolean;
    uiVisible: boolean;
    sequenceMode: 'choice' | 'checkbox';
    uiGroupExpandable: boolean;
    uiGroupHeaderVisible: boolean;
    uiGroupAlwaysOpen: boolean;

    private readonly _parent: LayerGroup;

    constructor(group?: ILayerGroup, parent?: LayerGroup) {
        if (parent != undefined)
            this._parent = parent;

        this.visible = true;
        this.uiVisible = true;
        if (group) {
            if (group.id != undefined)
                this.id = group.id;

            if (group.title != undefined)
                this.title = group.title;

            if (group.icon != undefined)
                this.icon = group.icon;


            if (group.iconClass != undefined)
                this.iconClass = group.iconClass;

            if (group.children != undefined) {
                this.children = [];
                group.children.forEach(c => this.children.push(new LayerGroup(c, this)));
            }

            if (group.visible != undefined)
                this.visible = group.visible;

            if (group.uiVisible != undefined)
                this.uiVisible = group.uiVisible;
            
            if (group.sequenceMode != undefined)
                this.sequenceMode = group.sequenceMode;

            if (group.uiGroupExpandable != undefined)
                this.uiGroupExpandable = group.uiGroupExpandable;

            if (group.uiGroupHeaderVisible != undefined)
                this.uiGroupHeaderVisible = group.uiGroupHeaderVisible;

            if (group.uiGroupAlwaysOpen != undefined)
                this.uiGroupAlwaysOpen = group.uiGroupAlwaysOpen;
        }
    }

    getParent(): LayerGroup { return this._parent; }
}

export interface ILayerGroup {
    id: string;
    title: string;
    icon: string;
    iconClass: string;
    children: LayerGroup[];
    visible: boolean;
    uiVisible: boolean;
    sequenceMode: 'choice' | 'checkbox';
    uiGroupExpandable: boolean;
    uiGroupHeaderVisible: boolean;
    uiGroupAlwaysOpen: boolean;
}
