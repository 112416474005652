import { Projection } from '../model/RM2Projection';

export class GeolocationOptions implements IGeolocationOptions {
    projection?: Projection;
    pan?: boolean;
    rotate?: boolean;
    panIfNotInView?: boolean;
    animate?: boolean;
    isAccurate?: boolean
    showAccuracy?: boolean
    showHeading?: boolean
    minResolution?: number;
    autoZoom?: boolean;

    markerScale?: number;

    relCenterX?: number;
    relCenterY?: number;

    constructor(opts?: IGeolocationOptions) {
        this.pan = false;
        this.rotate = false;
        this.panIfNotInView = false;
        this.animate = false;
        this.isAccurate = true;
        this.showAccuracy = true;
        this.showHeading = true;
        this.minResolution = 1;
        this.autoZoom = true;
        this.markerScale = 1;

        if (opts) {
            if (opts.projection != undefined)
                this.projection = opts.projection;
            
            if (opts.pan != undefined)
                this.pan = opts.pan;

            if (opts.rotate != undefined)
                this.rotate = opts.rotate;

            if (opts.panIfNotInView != undefined)
                this.panIfNotInView = opts.panIfNotInView;

            if (opts.animate != undefined)
                this.animate = opts.animate;

            if (opts.isAccurate != undefined)
                this.isAccurate = opts.isAccurate;

            if (opts.showAccuracy != undefined)
                this.showAccuracy = opts.showAccuracy;

            if (opts.showHeading != undefined)
                this.showHeading = opts.showHeading;

            if (opts.minResolution != undefined)
                this.minResolution = opts.minResolution;

            if (opts.autoZoom != undefined)
                this.autoZoom = opts.autoZoom;

            if (opts.relCenterX != undefined && !isNaN(opts.relCenterX) && opts.relCenterX >= 0.00 && opts.relCenterX <= 1.00)
                this.relCenterX = opts.relCenterX;

            if (opts.relCenterY != undefined && !isNaN(opts.relCenterY) && opts.relCenterY >= 0.00 && opts.relCenterY <= 1.00)
                this.relCenterY = opts.relCenterY;

            if (opts.markerScale != undefined)
                this.markerScale = opts.markerScale;
        }
    }

    getPrimaryColor(): string { return this.isAccurate == true ? '#0F3C8F' : '#F0143C'; }
    getSecondaryColor(): string { return '#FFFFFF'; }
    getHeadingIconName(): string { return this.isAccurate == true ? 'geolocation-heading-accurate_B-48' : 'geolocation-heading-inaccurate_B-48'; }
}

export interface IGeolocationOptions {
    projection?: Projection;
    pan?: boolean;
    rotate?: boolean;
    panIfNotInView?: boolean;
    animate?: boolean;
    isAccurate?: boolean;
    showAccuracy?: boolean;
    showHeading?: boolean;
    minResolution?: number;
    autoZoom?: boolean;

    markerScale?: number;

    relCenterX?: number;
    relCenterY?: number;
}
