export interface IService {
    readonly type: ServiceType;
    readonly name: string;
    readonly options: any;
    readonly enabled: boolean;
}

export enum ServiceType {
    Routing = 'routing',
    Nominatim = 'nominatim',
    Localization = 'localization'
}
