import * as maplibregl from 'maplibre-gl';

import { IPanOptions, PanOptions } from '../model/RM2CameraChangeOptions';
import { IRM2Event, RM2Event } from '../model/RM2Event';
import { IPopupOptions } from './RM2PopupOptions';
import { FeatureCollection, Feature } from '../model/RM2Feature';
import { CoordinateLike } from '../model/RM2Geometry';
import { Map } from '../map/RM2Map';
import { PopupCarouselElementId } from '../templates/popup-templates';
import { ServiceType } from '../services/RM2Service';
import { LocalizationService } from '../services/RM2LocalizationService';
import { Layer } from '../model/RM2Layer';

declare const $: any;

export class Popup {
    private _map: Map;

    public mbPopup: maplibregl.Popup;

    /**
     * Returns position of the popup. Returned value is a numeric array of two ordinates in map's UI projection.
     */
    public get position(): CoordinateLike { return this.mbPopup.getLngLat().toArray() as CoordinateLike; }

    /**
     * Sets position of the popup.
     */
    public set position(coordinate: CoordinateLike) {
        this.mbPopup.setLngLat(coordinate);
        if (this._options.panOnPositionChange === true) {
            const opts = this._options && this._options.cameraOptions ? this._options.cameraOptions : { animate: true };
            this.panTo(opts);
        }
    }

    protected _content: HTMLElement;
    public get content(): HTMLElement { return this._content; }

    protected _options: IPopupOptions;
    protected _featureCollection: FeatureCollection;
    protected _selectedFeatureId: number;

    public get closesOnClick(): boolean { return this._options.closesOnClick; }

    /** Called on popup open */
    public get onOpen(): IRM2Event<void> { return this._onOpen.expose(); }
    protected readonly _onOpen = new RM2Event<void>();

    /** Called on popup close */
    public get onClose(): IRM2Event<void> { return this._onClose.expose(); }
    protected readonly _onClose = new RM2Event<void>();

    /** Called on carousel slide if this popup has a FeatureCollection */
    public get onSlide() { return this._onSlide.expose(); }
    protected readonly _onSlide = new RM2Event<number>();

    public get featureCollection(): FeatureCollection { return this._featureCollection; }
    public get selectedFeature(): Feature { return this._selectedFeatureId != undefined && this._featureCollection != undefined ? this._featureCollection.features[this._selectedFeatureId] : undefined; }

    /** Popup metadata */
    public get metadata(): any { return this._metadata; }
    private readonly _metadata: any;

    constructor(map: Map, content: HTMLElement, options: IPopupOptions, fc?: FeatureCollection, metadata?: any) {
        this._map = map;
        this._content = content;
        this._options = options;
        this._featureCollection = fc;
        this._metadata = metadata;

        const ls = this._map.getService(ServiceType.Localization) as LocalizationService;
        ls.refresh(content);

        if (fc && fc.length > 0)
            this._selectedFeatureId = 0;

        this._map = map;

        const maxWidth = this.getMaxWidthForPage(this._selectedFeatureId);
        const isCarousel = fc != undefined && fc.length > 1;
        this.mbPopup = new maplibregl.Popup({
            closeOnClick: this._options.closesOnClick,
            closeButton: this._options.hasCloseButton,
            anchor: this._options.anchor,
            offset: this._options.offset,
            maxWidth: 'calc(' + maxWidth + ' + 10px)'
        }).setDOMContent(content);

        if (this._options.addContainer == false) {
            if (content.parentElement) {
                content.parentElement.style.padding = '0';
                content.parentElement.style.background = 'none';
            }
            else
                console.warn('Failed to remove Mapbox popup container.');
        }

        // kje unsubscribe?
        this.mbPopup.on('close', () => {
            this._onClose.trigger();
            if (isCarousel) {
                const carouselEl = $(`[id^='${PopupCarouselElementId}-']`);
                if (carouselEl)
                    carouselEl.off('slide.bs.carousel', this._handleSlide);
            }
        });

        this.mbPopup.on('open', () => {
            this._onOpen.trigger();
            if (isCarousel) {
                const carouselEl = $(`[id^='${PopupCarouselElementId}-']`);
                if (carouselEl)
                    carouselEl.on('slide.bs.carousel', this._handleSlide);
            }
        });
    }

    close() {
        if (this.mbPopup)
            this.mbPopup.remove();
    }

    protected setMaxWidth(width: string): void {
        this.mbPopup.setMaxWidth(width);
    }

    panTo(options?: IPanOptions) {
        const metadata = this._map.metadata;
        if (metadata.panPopup) {
            const opts = new PanOptions(options);
            // if (metadata.zoomPopup)
            //     opts.zoom = this._map.getView().zoom < 12 ? 12 : null;
    
            // this._map.pan(this.position, opts);

            if (opts.zoom)
                this._map.pan(this.position, opts);
            else {
                // to je ok samo, če je mapa obrnjena proti severu
                const el = this.mbPopup.getElement();
                if (el) {
                    const h = el.offsetHeight;
                    const w = el.offsetWidth;
                    const point = this._map.project(this.position);
        
                    const topLeftX = point[0] - w / 2;
                    const topLeftY = point[1] - h;
                    const topRightX = point[0] + w / 2;
                    const topRightY = point[1] - h;
                    const bottomLeftX = point[0] - w / 2;
                    const bottomLeftY = point[1] + 32; // 32 padding
                    const bottomRightX = point[0] + w / 2;
                    const bottomRightY = point[1] + 32; // 32 padding

                    // console.log(`h=${h}, w=${w}, top-left=(${topLeftX}, ${topLeftY}), top-right=(${topRightX}, ${topRightY}), bottom-left=(${bottomLeftX}, ${bottomLeftY}), bottom-right=(${bottomRightX}, ${bottomRightY})`)
    
                    const bounds = this._map.getBounds();
                    const mapSW = bounds.getSouthWest();
                    const mapSWPixel = this._map.project([mapSW.lng, mapSW.lat]);
                    const mapNE = bounds.getNorthEast();
                    const mapNEPixel = this._map.project([mapNE.lng, mapNE.lat]);
        
                    // console.log(`mapSWPixel=${JSON.stringify(mapSWPixel)}, mapNEPixel=${JSON.stringify(mapNEPixel)}`);

                    //// preko koordinate
                    // const topLeftCoord = this._map.unproject([topLeftX, topLeftY]);
                    // const topRightCoord = this._map.unproject([topRightX, topRightY]);
                    // const bottomLeftCoord = this._map.unproject([bottomLeftX, bottomLeftY]);
                    // const bottomRightCoord = this._map.unproject([bottomRightX, bottomRightY]);
        
                    // // deltaX
                    // let deltaX = topLeftCoord.x - mapSW.lng;
                    // if (deltaX > 0)
                    //     deltaX = bottomLeftCoord.x - mapSW.lng;
                    
                    // if (deltaX > 0) {
                    //     deltaX = topRightCoord.x - mapNE.lng;
                    //     if (deltaX < 0) {
                    //         deltaX = bottomRightCoord.x - mapNE.lng;
                    //         if (deltaX < 0)
                    //             deltaX = 0;
                    //     }
                    // }
        
                    // // delta Y
                    // let deltaY = topLeftCoord.y - mapSW.lat;
                    // if (deltaY > 0)
                    //     deltaY = bottomLeftCoord.y - mapSW.lat;
                    
                    // if (deltaY > 0) {
                    //     deltaY = topRightCoord.y - mapNE.lat;
                    //     if (deltaY < 0) {
                    //         deltaY = bottomRightCoord.y - mapNE.lat;
                    //         if (deltaY < 0)
                    //             deltaY = 0;
                    //     }
                    // }
    
                    //// preko pikslov
                    // deltaX
                    let deltaX = topLeftX - mapSWPixel[0];
                    if (deltaX > 0)
                        deltaX = bottomLeftX - mapSWPixel[0];
                    
                    if (deltaX > 0) {
                        deltaX = topRightX - mapNEPixel[0];
                        if (deltaX < 0) {
                            deltaX = bottomRightX - mapNEPixel[0];
                            if (deltaX < 0)
                                deltaX = 0;
                        }
                    }
        
                    

                    // IA 2022-11-07
                    let deltaY = 0;
                    if (topRightY < mapNEPixel[1])  deltaY = topRightY - mapNEPixel[1];
                    else if (bottomLeftY > mapSWPixel[1]) deltaY = bottomLeftY - mapSWPixel[1];
                    /* // Prej je bilo spodnje
                    let deltaY = topLeftY - mapSWPixel[1];
                    if (deltaY > 0)
                        deltaY = bottomLeftY - mapSWPixel[1];
                    
                    if (deltaY < 0) {
                        deltaY = topRightY - mapNEPixel[1];
                        if (deltaY > 0) {
                            deltaY = bottomRightY - mapNEPixel[1];
                            if (deltaY > 0)
                                deltaY = 0;
                        }
                    }
                    */
                    // console.log(`dX=${deltaX}, dY=${deltaY}`);
        
                    if (deltaX !== 0 || deltaY !== 0) {
                        if (deltaY < 0)
                            deltaY -= 50; // padding
                        
                        const newSW = this._map.unproject([mapSWPixel[0] + deltaX, mapSWPixel[1] + deltaY]);
                        const newNE = this._map.unproject([mapNEPixel[0] + deltaX, mapNEPixel[1] + deltaY]);
    
                        // const newBounds: CoordinateLike[] = [[mapSW.lng + deltaX, mapSW.lat + deltaY], [mapNE.lng + deltaX, mapNE.lat + deltaY]];
                        // this._map.fit(newBounds, { animate: true });
    
                        // this._map.fit([newSW, newNE], { animate: true });

                        const newCenter: CoordinateLike = [(newNE.x + newSW.x) / 2, (newNE.y + newSW.y) / 2];
                        this._map.pan(newCenter, { animate: true });
                    }
                }
            }
        }
    }

    slideTo(page: number) {
        if (this._featureCollection && page >= 0 && page <= this._featureCollection.length - 1) {
            $('.carousel').carousel(page);
            this._handleSlide({ to: page });
        }
    }

    protected getMaxWidthForPage(page: number): string {
        const width = page != undefined && this._options.maxWidth.length - 1 >= page ? this._options.maxWidth[page] : this._options.maxWidth[0];
        if (width != '-1')
            return width;
        return null;
    }

    protected _handleSlide = (e: any) => {
        this._selectedFeatureId = e.to;
        const pos = this.selectedFeature.geometry.getCoordinate();
        this.position = [pos.x, pos.y];

        this.setMaxWidth(this.getMaxWidthForPage(e.to));
        this._onSlide.trigger(e.to);
    };
}
