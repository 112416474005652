/*
notes
- Feature je klasičen GIS feature z geometry in fields. Implementacija ovije OL3 feature pri čemer je OL3 interna geometrija shranjena
    v fieldu __geom, referenca na this feature pa v fieldu __feature. Ta dva fielda se smatrata kot interna.
- FeatureCollection je storage za features pri čemer se features interno hranijo v arrayu (_features), dodtno pa je vzpostavljen index po
    id-ju (_idIndex) ter prostorski index (_spatialIndex)

todo
- testiranje
- JSDdoc API dokumentacija
- Feature - razmisliti in implementirati kako globoko iti v featureSchema. Ustrezno dopolnit FieldInfo (maxLength, ...)
- Feature - sinhronizacija med feature in olFeature geometrijo
- FeatureCollection - metode za dodajanje, odstranjevanje (add, remove, clean, ...)
- FeatureCollection - trenutno se za spatialIndex uporablja RBush. Opcija bi bila tudi JSTS-jev Quadtree a so prvi testi pokazali, da je slabši.
*/

import RBush from 'rbush';

import { v4 } from "uuid";

import { Geometry, Envelope, Coordinate } from './RM2Geometry';

/**
 * FieldInfo interface.
 */
export interface FieldInfo {
  name: string;
  type: string;
}

/**
 * Feature object.
 */
export class Feature<T = any> {
  private _id: string;

  // public get id(): string { return this._id; }

  type: 'Feature' = 'Feature';
  geometry: Geometry;
  properties: T;

  // TODO: properties.id v id
  constructor(properties?: T, geometry?: Geometry) {
    this._id = v4();
    
    this.setProperties(properties ? properties : Object.create({}));
    if (geometry)
      this.geometry = geometry;
  }

  getKeys() {
    const keys = [];
    for (let key in this.properties)
      keys.push(key);
    
    return keys;
  }

  // TODO: podpri jsonpath
  getField(key: string): any {
    if (key in (this.properties as any))
      return this.properties[key];
    else
      return undefined;
  }

  setField(key: string, value: any) {
    if (this.properties == null)
      this.properties = Object.create({});
    
    this.properties[key] = value;
  }

  hasProperty(key: string): boolean {
    return this.properties[key] != undefined;
  }

  getProperties(): T {
    return this.properties;
  }

  setProperties(properties: T) {
    this.properties = properties;
  }

  getInternalId() {
    return this._id;
  }

  setInternalId(id: string) {
    this._id = id;
  }

  public clone(): Feature {
    const ft = new Feature();
    ft.properties = Object.assign({}, this.properties); // TODO: clone funkcija za properties
    ft.geometry = this.geometry.clone();
    return ft;
  }

  /**
   * Static helper function for parsing GeoJSON
   */
  public static fromGeoJson(json: object | string): Feature {
    const geoJson: any = typeof json == 'string' ? JSON.parse(json as string) : json;
    const ft = new Feature();
    if (geoJson.properties)
      ft.properties = geoJson.properties;
    
    if (geoJson.geometry)
      ft.geometry = Geometry.fromGeoJson(geoJson.geometry);

    return ft;
  }

  public static toGeoJson(instance: Feature): any {
    const ft = {
      type: instance.type,
      properties: Object.assign({}, instance.properties),
      geometry: instance.geometry ? Geometry.toGeoJson(instance.geometry) : {}
    };

    return ft;
  }

  /**
   * Helper function for converting to GeoJSON
   */
  public toGeoJson(): any {
    const ft = {
      type: this.type,
      properties: this.getProperties(),
      geometry: this.geometry ? Geometry.toGeoJson(this.geometry) : {}
    };

    delete ft.properties['geometry'];
    return ft;

    // const gj: any = {
    //   type: this._type,
    //   properties: this._properties
    // };

    // if (this.geometry)
    //   gj.geometry = Geometry.toGeoJson(this._geometry);
    
    // return gj;




    // if (this._olFeature) {
    //   const gjf = Feature._formatGeoJSON.writeFeatureObject(this._olFeature) as any;
    //   if (this.geometry && !gjf.geometry) {
    //     gjf.geometry = Geometry.toGeoJson(this.geometry);
    //     // ce layer ni na karti od ol ne dobi geometrija!
    //   }
    //   return gjf;
    // }
    // return undefined;
  }

  /**
   * Helper function for stringifying GeoJSON
   */
  public toGeoJsonString(): string {
    return JSON.stringify(this.toGeoJson());
  }

  public static fromMb(mbFt: GeoJSON.Feature): Feature {
    const ft = new Feature(mbFt.id ? mbFt.id.toString() : null);
    ft.setProperties(mbFt.properties as any);
    ft.geometry = Geometry.fromGeoJson(mbFt.geometry);

    return ft;
  }
}

/**
 * FeatureCollection object.
 */
export class FeatureCollection<T = any, M = any> {
  type: 'FeatureCollection' = 'FeatureCollection';
  crs?: Crs;
  features: Array<Feature<T>>;
  properties?: M;
  bbox?: [number, number, number, number];

  private _idIndex: { [code: string]: Feature<T>; } = {};
  private _spatialIndex: any;

  /**
   * Creates an instance of FeatureCollection.
   */
  public constructor(features?: Feature<T>[], properties?: any) {
    if (features)
      this.features = features.slice();
    else
      this.features = [];
    
    if (properties)
      this.properties = Object.assign({}, properties);
    else
      this.properties = {} as M;

    this.features.forEach(x => this._idIndex[x.getInternalId()] = x);
    this._spatialIndex = new RBush();
    
    const items = new Array(this.features.length);
    this.features.forEach((x, i) => {
      if (x.geometry) {
        const envelope = x.geometry.getEnvelopeInternal();
        items[i] = {
          minX: envelope.minx,
          minY: envelope.miny,
          maxX: envelope.maxx,
          maxY: envelope.maxy,
          value: x
        };
      }
      else {
        items[i] = {
          minX: +Infinity,
          minY: +Infinity,
          maxX: -Infinity,
          maxY: -Infinity,
          value: x
        }
      }
    });
    this._spatialIndex.load(items);  
  }

  public forEach(callbackfn: (value: Feature<T>, index: number, array: Feature<T>[]) => void, thisArg?: any): void {
    if (this.features)
      this.features.forEach(callbackfn, thisArg);
  }

  /**
   * Returns number of features in the collection.
   */
  public get length(): number {
    return this.features.length;
  }

  /**
   * Returns all features in the collection.
   */
  public getAll(): Feature[] {
    return this.features.slice();
  }

  /**
   * Gets feature by its id.
   */
  public getById(id: string): Feature {
    return this._idIndex[id];
  }

  /**
   * Gets feature by its value.
   */
  public getByProperty(property: string, value: any): Feature {
    return this.features.find(ft => ft.getField(property) == value);
  }

  /**
   * Gets the first feature in the collection.
   */
  public get first(): Feature {
    if (this.features && this.features.length > 0)
      return this.features[0];
    return undefined;
  }

  /**
   * Gets the last feature in the collection.
   */
  public get last(): Feature<T> {
    if (this.features && this.features.length > 0)
      return this.features[this.features.length - 1];
    return undefined;
  }

  /**
   * Sets the CRS code of this collection
   */
  public setCrsCode(code: string) {
    if (this.getCrsCode() == undefined)
      this.crs = new Crs(code);
    else
      this.crs.properties.name = code;
  }

  /**
   * Gets the CRS code of this collection
   */
  public getCrsCode(): string {
    if (this.crs && this.crs.properties && this.crs.properties.name)
      return this.crs.properties.name;
    return undefined;
  }

  /**
   * Gets features by geograpic area defined by given envelope.
   */
  public getByEnvelope(envelope: Envelope): Feature[] {
    const items = this._spatialIndex.search({
      minX: envelope.minx,
      minY: envelope.miny,
      maxX: envelope.maxx,
      maxY: envelope.maxy
    });
    return items.map(x => x.value);
  }

  /**
   * Gets features by geograpic location defined by given cooridnate.
   */
  public getByCoordinate(coordinate: Coordinate): Feature[] {
    return this.getByEnvelope(Envelope.fromCoordinate(coordinate));
  }

  public static fromMb(mbFc: GeoJSON.FeatureCollection): FeatureCollection {
    return new FeatureCollection(mbFc.features.map(ft => Feature.fromMb(ft)));
  }

  /**
   * Static helper function for parsing GeoJSON
   */
  public static fromGeoJson(json: object | string): FeatureCollection {
    return FeatureCollection.fromGeoJsonTyped<any, any>(json);
  }

  public static fromGeoJsonTyped<T, M>(json: object | string): FeatureCollection<T, M> {
    const geoJson: any = typeof json == 'string' ? JSON.parse(json as string) : json;
    const ftColl = new FeatureCollection<T, M>();

    if (geoJson.crs)
      ftColl.crs = geoJson.crs;

    if (geoJson.properties)
      ftColl.properties = geoJson.properties;
    
    if (geoJson.bbox)
      ftColl.bbox = geoJson.bbox;

    if (geoJson.features && Array.isArray(geoJson.features))
      geoJson.features.forEach(f => ftColl.features.push(Feature.fromGeoJson(f)));

    return ftColl;
  }

  /**
   * Helper function for converting to GeoJSON
   */
  public toGeoJson(): any {
    let copy: any = {};
    Object.assign(copy, this);
    delete copy._spatialIndex;
    delete copy._idIndex;
    copy.features = [];
    this.features.forEach(ft => copy.features.push(ft.toGeoJson()));

    return copy;
  }

  /**
   * Helper function for stringifying GeoJSON
   */
  public toGeoJsonString(): string {
    return JSON.stringify(this.toGeoJson());
  }

  /**
   * Sets the specified key's property
   */
  public setProperty(key: string, value: any) {
    if (this.properties == undefined)
      this.properties = {} as any;
    this.properties[key] = value;
  }
}

export class Crs {
  type: string;
  properties: CrsProperties;

  constructor(crsCode: string) {
    this.type = 'name';
    this.properties = new CrsProperties(crsCode);
  }
}

export class CrsProperties {
  name: string;

  constructor(name: string) {
    this.name = name;
  }
}
