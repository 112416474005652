import * as maplibregl from 'maplibre-gl';
import * as maplibreglCompare from '@maplibre/maplibre-gl-compare';
import { LayerSpecification, SourceSpecification, StyleSpecification, FilterSpecification, SymbolLayerSpecification, LegacyFilterSpecification } from '@maplibre/maplibre-gl-style-spec';
import Mustache from 'mustache';
import { MapEventArgs, RM2Event } from '../model/RM2Event';
import { Projection } from '../model/RM2Projection';
import { Coordinate, Geometry, Popup } from '../';
import { IPolygonOptions, ILineOptions, IFeatureHighlightOptions, ICircleOptions, IHighlightOptions } from '../highlights/RM2HighlightOptions';
import { IFitOptions, ICameraChangeOptions, IPanOptions, PanOptions, FitOptions, CameraChangeOptions } from '../model/RM2CameraChangeOptions';
import { IGeolocationOptions } from '../geolocation/RM2GeolocationOptions';
import { IPopupOptions, ICarouselPopupOptions, CarouselPopupOptions, PopupOptions } from '../popup/RM2PopupOptions';
import { Feature, FeatureCollection } from '../model/RM2Feature';
import { CoordinateLike, Envelope, Point } from '../model/RM2Geometry';
import { MapControlType, ControlData } from '../map-controls/RM2MapControls';
import { IService, ServiceType } from '../services/RM2Service';
import { NominatimService, QueryOptions, ReverseGeocodingOptions, INominatimServiceOptions } from '../services/RM2NominatimService';
import { RoutingService, Route, IRouteOptions, IRoutingServiceOptions, RouteSimple, IRouteHighlightOptions, RouteHighlightOptions, RouteLegacy } from '../services/RM2RoutingService';
import { LocalizationService, ILocalizationServiceOptions } from '../services/RM2LocalizationService';
import { Highlights } from '../highlights/RM2Highlights';
import { MapPickerControl } from '../map-controls/map-picker-control/RM2MapPickerControl';
import { Geolocation, RM2GeolocationPosition } from '../geolocation/RM2Geolocation';
import { LegendOptions, LegendControl } from '../map-controls/legend-control/RM2LegendControl';
import { RoutingControl, RoutingGeocodingFeatureProps } from '../map-controls/routing-control/RM2RoutingControl';
import { PrometSiControl } from '../map-controls/promet-si-control/RM2PrometSiControl';
import { MapPickerControlOptions } from '../map-controls/map-picker-control/RM2MapPickerControlOptions';
import { RM2ViewData } from '../model/RM2ViewData';
import { LayerGroup } from '../model/style-metadata/RM2LayerGroup';
import { StyleMetadata } from '../model/style-metadata/RM2StyleMetadata';
import { Layer } from '../model/RM2Layer';
import { MapTheme } from '../model/RM2Theme';
import { MapboxClusterProperties } from '../model/MapboxClusterProperties';
import { PopupCarouselTemplate } from '../templates/popup-templates';
import { v4 } from 'uuid';
import { NAPControl } from '../map-controls/nap-control/RM2NAPControl';
import { IStyleMetadata } from '../model/style-metadata/RM2StyleMetadata';

export type RMap2TransformRequestFunction = (url: string, resourceType: string) => { url: string };

export interface RM2MapOptions {
  transformRequest?: RMap2TransformRequestFunction;
  view?: RM2ViewData;
  label?: string;
  theme?: MapTheme;
  id?: string;
}

export interface RM2MapCompareState {
  sliderPosition?: number;
}

export interface PersistedVisibility {
  type: 'group' | 'layer';
  id: string;
  visible: boolean;
}

export class Map {

  public static readonly routeSourceName = 'mb-r-route-source';

  private _mbMap: maplibregl.Map;
  private _mbMapCompare: maplibreglCompare;
  private _mbMapCompareMap: Map;

  private _style: StyleSpecification;
  public get style(): StyleSpecification { return this._style; }

  public get metadata(): StyleMetadata { return this._style && this.style.metadata ? new StyleMetadata(this._style.metadata) : null; }

  // private _legendControl: LegendControlMb;
  private _geolocatorControl: maplibregl.GeolocateControl;
  private _geolocation: Geolocation;
  private _highlights: Highlights;

  /**
   * Events
   */
  /** Exposes map's onClick RM2Event<T> object, that can be subscribed for custom eventhandling */
  public get onClick() { return this._onClick.expose(); }
  private readonly _onClick = new RM2Event<MapEventArgs>();

  /** Exposes map's onClick RM2Event<T> object, that can be subscribed for custom eventhandling */
  public get onLongClick() { return this._onLongClick.expose(); }
  private readonly _onLongClick = new RM2Event<MapEventArgs>();

  /** Exposes map's onDblClick RM2Event<T> object, that can be subscribed for custom eventhandling */
  public get onDblClick() { return this._onDblClick.expose(); }
  private readonly _onDblClick = new RM2Event<MapEventArgs>();

  /** Exposes map's onMouseMove RM2Event<T> object, that can be subscribed for custom eventhandling */
  public get onMouseMove() { return this._onMouseMove.expose(); }
  private readonly _onMouseMove = new RM2Event<MapEventArgs>();

  /** Exposes map's onLoad RM2Event<T> object, that can be subscribed for custom eventhandling */
  public get onLoad() { return this._onLoad.expose(); }
  private readonly _onLoad = new RM2Event<MapEventArgs>();

  /** Exposes map's onStyleLoad RM2Event<T> object, that can be subscribed for custom eventhandling */
  public get onStyleLoad() { return this._onStyleLoad.expose(); }
  private readonly _onStyleLoad = new RM2Event<MapEventArgs>();

  /** Exposes map's onRestyleLayer RM2Event<T> object, that can be subscribed for custom eventhandling */
  public get onRestyleLayer() { return this._onRestyleLayer.expose(); }
  private readonly _onRestyleLayer = new RM2Event<MapEventArgs>();

  /** Exposes map's onUserAction RM2Event<T> object. Event fires when user performs interaction with map (touchastart, mousedown, wheel) */
  public get onUserAction() { return this._onUserAction.expose(); }
  private readonly _onUserAction = new RM2Event<MapEventArgs>();
  private readonly _userActionEvents = ['touchstart', 'mousedown', 'wheel'];

  public get onSourceChange() { return this._onSourceChange.expose(); }
  private readonly _onSourceChange = new RM2Event<string>();

  /** Exposes map's onViewChange RM2Event<T> object. Event fires when the map's view changes */
  public get onViewChange() { return this._onViewChange.expose(); }
  private readonly _onViewChange = new RM2Event<RM2ViewData>();

  /** Exposes map's onZoom RM2Event<T> object. Event fires when the map's zoom changes */
  public get onZoom() { return this._onZoom.expose(); }
  private readonly _onZoom = new RM2Event<number>();

  /** Exposes map's onLayerVisibilityChanged RM2Event<T> object. Event fires when a layer's visibility changes */
  public get onGroupVisibilityChanged() { return this._onGroupVisibilityChanged.expose(); }
  private readonly _onGroupVisibilityChanged = new RM2Event<LayerGroup>();

  /** Exposes map's onLayerVisibilityChanged RM2Event<T> object. Event fires when a layer's visibility changes */
  public get onLayerVisibilityChanged() { return this._onLayerVisibilityChanged.expose(); }
  private readonly _onLayerVisibilityChanged = new RM2Event<Layer>();

  /** Exposes map's onRouteConstructed R_M2Event<T> object. Event fires when a route has been constructed */
  public get onRouteConstructed() { return this._onRouteConstructed.expose(); }
  private readonly _onRouteConstructed = new RM2Event<Route>();

  /** Exposes map's onPopupOpened R_M2Event<T> object. Event fires when a popup has been opened */
  public get onPopupOpened() { return this._onPopupOpened.expose(); }
  private readonly _onPopupOpened = new RM2Event<Popup>();

  /** Exposes map's onPopupClicked R_M2Event<T> object. Event fires when a popup has been clicked */
  public get onFeaturePopupClicked() { return this._onFeaturePopupClicked.expose(); }
  private readonly _onFeaturePopupClicked = new RM2Event<Feature>();

  /** Exposes map's onProcessingStatusChanged R_M2Event<T> object. */
  public get onProcessingStatusChanged() { return this._onProcessingStatusChanged.expose(); }
  private readonly _onProcessingStatusChanged = new RM2Event<boolean>();

  /** Exposes map's onFeatureAdded RM2Event<T> object. Event fires when a feature is added to the map */
  public get onFeaturesAdded() { return this._onFeaturesAdded.expose(); }
  private readonly _onFeaturesAdded = new RM2Event<Feature[]>();

  /** Exposes map's onFeatureRemoved RM2Event<T> object. Event fires when a feature is removed from the map */
  public get onFeaturesRemoved() { return this._onFeaturesRemoved.expose(); }
  private readonly _onFeaturesRemoved = new RM2Event<Feature[]>();

  /**
   * Settings
   */

  // public get selectedLayer(): Layer { return this._selectedLayer; }
  // public set selectedLayer(layer: Layer) { this._selectedLayer = layer; } // TODO ali pripada layer tej mapi?
  // private _selectedLayer: Layer;

  public get id(): string { return this._id; }
  private _id: string;

  public get label(): string { return this._label; }
  private _label: string;

  public get theme(): MapTheme { return this._theme; }
  private _theme: MapTheme = MapTheme.Light;

  public get loaded(): boolean { return this._loaded; }
  private _loaded: boolean = false;

  public get popups(): Popup[] { return this._popups.slice(); }
  private _popups: Popup[] = [];
  private _mobilePopups: HTMLElement[] = []
  private _popupMobileMarker: Feature;

  public get projection(): Projection { return this._projection; }
  private _projection: Projection = Projection.create('EPSG:4326');

  /**
   * Other properties
   */
  private _controls: { [type: string]: any } = [];
  private _services: IService[] = [];

  private _clusterLayers: LayerSpecification[] = [];
  private _sources: { [id: string]: FeatureCollection } = {};
  private _sourceRequests: { [id: string]: { time: Date, req: XMLHttpRequest } } = {};

  private persistViewKey: string = "RM2Map.View";
  private persistVisibilityKey: string = "RM2Map.PersistVisibility";
  private _forceReloadSourceIntervals: NodeJS.Timeout[] = [];

  constructor(styleUrl: string, container: string | HTMLElement, options?: RM2MapOptions) {
    // HACK: tukaj se registrira EPSG:3912 s polno definicijo, drugače vrže error pri transformacijah
    Projection.create('EPSG:3912;+title=y/x:D48/GK +proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=-5000000 +ellps=bessel +towgs84=426.9,142.6,460.1,4.91,4.49,-12.42,17.1 +units=m +no_defs');
    Projection.create('EPSG:2170;+title=y/x:D48/GK +proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=-5000000 +ellps=bessel +towgs84=426.9,142.6,460.1,4.91,4.49,-12.42,17.1 +units=m +no_defs');

    this._id = options && options.id ? options.id : v4();

    const mbOptions: maplibregl.MapOptions = {
      container: container,
      style: styleUrl,
      renderWorldCopies: false,
      // attributionControl: attributionControlData && attributionControlData.enabled !== false
      transformRequest: options && options.transformRequest ? options.transformRequest : null
    };

    this._mbMap = new maplibregl.Map(mbOptions);

    if (options) {
      this._label = options.label;
      if (options.theme != undefined)
        this._theme = options.theme;
    }

    // Error
    this._mbMap.on('error', (e: any) => console.error('Mapbox error.', e));

    // Style load
    this._mbMap.once('styledata', (e) => {
      this._style = this._mbMap.getStyle();
      const metadata = this.metadata;
      const persistedView = metadata && metadata.persistView && this.getPersistedView();
      if (metadata) {
        // Min/max zoom
        this._mbMap.setMinZoom(metadata.minZoom || 5);
        this._mbMap.setMaxZoom(metadata.maxZoom || 18);

        // Min/max pitch
        this._mbMap.setMinPitch(metadata.minPitch || 0);
        this._mbMap.setMaxPitch(metadata.maxPitch || 60);

        // Extent
        if (metadata.extent)
          this._mbMap.setMaxBounds(new maplibregl.LngLatBounds(metadata.extent[0], metadata.extent[1]));

        // Initial view
        // let view: RM2ViewData;

        if (persistedView) {
          this._mbMap.jumpTo(persistedView);
        }
        else if (options && options.view) {
          this._mbMap.jumpTo(options.view);
        }
        else if (metadata.startExtent) {
          this._mbMap.fitBounds(metadata.startExtent, { animate: false });
        }
        else {
          const view = {
            center: metadata.center || [14.8176, 46.12239],
            zoom: metadata.zoom || 8,
            pitch: metadata.tilt || 0,
            bearing: metadata.heading || 0
          };
          this._mbMap.jumpTo(view);
        }

        // Map theme
        this.setMapTheme(this._theme);

        // Clustering
        for (let srcId in this._style.sources) {
          const src = this._style.sources[srcId] as maplibregl.GeoJSONSourceOptions;
          if (src && src.cluster === true)
            this.getLayers(l => l.source === srcId).forEach(l => this.generateClusterLayers(l));
        }

        // 2022-06-02 IA HACK Remove hidden vector/hybrid layers TODO to naj bo pod nekim setting
        if (this.metadata.removeHiddenVectorHybridLayers !== false && this._style && this._style.layers) {
          for (let i = 0; i < this._style.layers.length; i++) {
            const mglLayer = this._style.layers[i];
            if (mglLayer && mglLayer.metadata && (mglLayer.metadata["gid"] === "vector" || mglLayer.metadata["gid"] === "hybrid" || mglLayer.metadata["gid"] === "vector.mb-style" || mglLayer.metadata["gid"] === "hybrid.mb-style") && mglLayer.layout && mglLayer.layout.visibility === "none") {
              if (mglLayer.id) {
                this._mbMap.removeLayer(mglLayer.id);
                this._style.layers.splice(i, 1);
                i--;
              }
            }
          }
        }

        if (this.metadata.forceReloadSources) {
          for (const id in this.metadata.forceReloadSources) {
            const frs = this.metadata.forceReloadSources[id];
            if (frs && frs.sourceId && frs.intervalMS !== undefined && frs.intervalMS != null && frs.intervalMS > 0) {
              if (frs.sourceId in this._mbMap.style.sourceCaches) {
                this._forceReloadSourceIntervals.push(setInterval(() => {
                  try {
                    // console.info(`FORCE REFRESH ${frs.sourceId}`);
                    this._mbMap.style.sourceCaches[frs.sourceId].clearTiles();
                    this._mbMap.style.sourceCaches[frs.sourceId].update(this._mbMap.transform, this._mbMap.terrain);
                  }
                  catch (e) {
                    console.error(e);
                  }
                }, frs.intervalMS));
              }
            }
          }
        }

        // Initial layer visibility
        const persisted = this.getPersistedVisibility();
        this._style.layers.forEach((mglLayer: LayerSpecification) => {
          if (mglLayer.metadata == undefined) {
            mglLayer.metadata = {};
          }

          const l = new Layer(mglLayer);
          const found = persisted.find(x => x.type === 'layer' && x.id === l.id);
          if (found) {
            const vis = found.visible;
            l.metadata.visible = vis;
            this._mbMap.setLayoutProperty(l.id, 'visibility', l.metadata.visible ? 'visible' : 'none');
          }
          else {
            if (l.metadata.visible == undefined) {
              const vis = l.layout == undefined || l.layout.visibility !== 'none';
              l.metadata.visible = vis;
            }
            else
              this._mbMap.setLayoutProperty(l.id, 'visibility', l.metadata.visible ? 'visible' : 'none');
          }
        });

        for (let i = 0; i < metadata.groups.length; i++) {
          const g = metadata.groups[i];
          if (g.uiVisible !== false) {
            const found = persisted.find(x => x.type === 'group' && x.id === g.id);
            if (found) {
              metadata.groups[i].visible = found.visible;
              (this.style.metadata as any).groups[i].visible = found.visible;
            }

            this.refreshSingleGroupVisibility(g);
          }
        }

        // setTimeout(() => console.log(this._mbMap.getStyle().layers), 1000);

        // if (metadata.groups) {
        //   metadata.groups.filter((g: LayerGroup) => g.visible == false).forEach((g: LayerGroup) => {
        //     this._style.layers.filter(l => l.metadata && l.metadata.gid === g.id).forEach(l => {
        //       if (l.layout && l.layout.visibility !== 'none') {
        //         this.layerVisibility[l.id] = true;
        //         this._mbMap.setLayoutProperty(l.id, 'visibility', 'none');
        //       }
        //     });
        //   });
        // }

        // Notice
        if (metadata.notice) {
          let notice = document.createElement("div");
          if (metadata.noticeStyle)
            notice.setAttribute('style', metadata.noticeStyle);
          else
            notice.setAttribute('style', "position: absolute; bottom:0; right:0; background-color: rgba(255, 255, 255, 0.4); font-size: 0.6em; padding: 1px;");

          const now = new Date();
          const yearPlaceholder = '{{year}}';
          let text = metadata.notice;
          while (text.includes(yearPlaceholder))
            text = text.replace(yearPlaceholder, now.getFullYear().toString());

          notice.innerHTML = text;
          this._mbMap.getContainer().appendChild(notice);

          // const attributionControlData = this.getControlData(MapControlType.Attribution);
          // attributionControl: attributionControlData && attributionControlData.enabled !== false,
        }

        // side scroller
        if (metadata.enableSideScroller === true) {
          const getSideScroller = (position: 'left' | 'right') => {
            const scrollerLeft = document.createElement('div');

            scrollerLeft.className = 'd-flex align-items-center justify-content-center d-md-none';
            scrollerLeft.style.position = 'absolute';
            if (position === 'left')
              scrollerLeft.style.left = '0';
            else
              scrollerLeft.style.right = '0';

            scrollerLeft.style.width = '30px';
            scrollerLeft.style.height = '100%';
            scrollerLeft.style.backgroundColor = '#FFFFFF88';

            const scrollerLeftIcon = document.createElement('div');
            scrollerLeftIcon.className = 'rm2-scroller-icon';

            scrollerLeft.appendChild(scrollerLeftIcon);
            return scrollerLeft;
          };

          const ctrlsContainer = this._mbMap.getContainer().querySelector('.maplibregl-control-container');
          const container = ctrlsContainer.parentNode;
          container.insertBefore(getSideScroller('left'), ctrlsContainer);
          container.insertBefore(getSideScroller('right'), ctrlsContainer);
        }

        // Gestures
        if (metadata.enableRotate === false) {
          this._mbMap.dragRotate.disable();
          this._mbMap.touchZoomRotate.disableRotation();
          this._mbMap.touchPitch.disable();
        }

        // Services
        let routingServiceOpts: IRoutingServiceOptions;
        let routingServiceName: string;
        let nominatimServiceOpts: INominatimServiceOptions;
        let nominatimServiceName: string;
        let localizationServiceOpts: ILocalizationServiceOptions;
        let addLocalizationService: boolean = true;
        if (metadata.services) {
          metadata.services.forEach((service: IService) => {
            if (service.type === ServiceType.Routing) {
              routingServiceName = service.name;
              routingServiceOpts = service.options;
            }
            else if (service.type === ServiceType.Nominatim) {
              nominatimServiceName = service.name;
              nominatimServiceOpts = service.options;
            }
            else if (service.type === ServiceType.Localization) {
              localizationServiceOpts = service.options;
              addLocalizationService = service.enabled !== false;
            }
          });
        }

        this._services.push(new RoutingService(routingServiceName, routingServiceOpts));
        this._services.push(new NominatimService(nominatimServiceName, nominatimServiceOpts));
        if (addLocalizationService)
          this._services.push(new LocalizationService(this as any, localizationServiceOpts));
      }

      this._onStyleLoad.trigger();
    });

    // Map load
    this._mbMap.on('load', () => {
      this._loaded = true;
      this.getTarget().addEventListener('contextmenu', e => e.preventDefault());

      this._highlights = new Highlights(this);
      this._highlights.featuresAdded.subscribe((fts: Feature[]) => this._onFeaturesAdded.trigger(fts));
      this._highlights.featuresRemoved.subscribe((fts: Feature[]) => this._onFeaturesRemoved.trigger(fts));
      this._geolocation = new Geolocation(this);
      this.addControls();
      this.addMobileClickOverlay();

      this.startRefreshingGeoJSONUrlSources();

      this._onLoad.trigger();
    });

    // trenutno ne moreš fetchat podatkov direktno iz source-a (querySourceFeatures vrne samo s trenutnega viewporta), se na novo naredi HTTP request (browser naj bi jih cachiral)
    // TODO: fix, da potegne features direktno iz source-a, pri transformacijah bi bilo optimalen nek middleware medtem ko še nalaga podatke za source
    this._mbMap.on('sourcedata', async (e: maplibregl.MapSourceDataEvent) => {
      if (e.dataType === 'source' && e.source && e.source.type === 'geojson') {
        const data = e.source['data'];
        if (typeof data === 'string') {
          const req = this._sourceRequests[data];
          if (req == null || ((new Date().getTime() - req.time.getTime()) > 60 * 1000)) {
            if (req && req.req)
              req.req.abort();

            try {
              const xhr = new XMLHttpRequest();
              this._sourceRequests[data] = { time: new Date(), req: xhr };
              xhr.timeout = 5000;
              xhr.onload = () => {
                if (xhr.responseText) {
                  const fc = FeatureCollection.fromGeoJson(xhr.responseText);
                  const fcCrs = fc.getCrsCode();
                  if (fcCrs != undefined && fcCrs.toLowerCase() !== this.projection.crsCode.toLowerCase()) { // transformacije
                    for (let i = 0; i < fc.features.length; i++)
                      fc.features[i].geometry = Geometry.transform(fc.features[i].geometry, Projection.create(fcCrs), this.projection);

                    this.setSourceData(e.sourceId, fc);
                  }

                  this._sources[e.sourceId] = fc;
                  // TODO: to se bo prožilo vsakič, ko se naložijo novi tile-i (ko pridejo feature-si v viewport) in je minila od prejšnjega proženja minuta
                  // fix, da se proži le, ko se source spremeni
                  this._onSourceChange.trigger(e.sourceId);
                }
              };

              // xhr.ontimeout = () => rej('Timeout');
              // xhr.onerror = () => rej('Error getting location');
              // xhr.onabort = () => rej('HTTP request aborted.');
              xhr.onloadend = () => this._sourceRequests[data].req = null;
              xhr.open('GET', data, true);
              xhr.send(null);
            }
            catch (err) {
              delete this._sourceRequests[data];
            }
          }
        }
      }
    });

    this.initEvents();
  }

  /**
   * Methods
   */

  /** Sources */

  public getSource(id: string): maplibregl.Source {
    return this._mbMap.getSource(id);
  }

  public addSource(id: string, source: SourceSpecification) {
    this._mbMap.addSource(id, source);
  }

  public setSourceData(id: string, data: FeatureCollection) {
    const src = this._mbMap.getSource(id) as maplibregl.GeoJSONSource;
    if (src) {
      const newData = data.toGeoJson();
      src.setData(newData);
      this._sources[id] = data;
      this._onSourceChange.trigger(id);
    }
    else
      throw new Error(`Could not find GeoJSON source '${id}'.`);
  }

  /** Layers */

  public addLayer(layer: LayerSpecification | maplibregl.CustomLayerInterface, before?: string) {
    this._mbMap.addLayer(layer, before);
  }

  /**
   * Returns array of layers that meet the criteria in given condition expression.
   */
  public getLayers(condition: (x: Layer) => boolean = () => true): Layer[] {
    return this.getStyle().layers.filter(l => condition(new Layer(l))).map(l => new Layer(l));
  }

  /**
   * Returns first layer that meets the criteria in given condition expression. If none, returns undefined.
   */
  public getLayer(condition: string | ((x: Layer) => boolean)): Layer {
    let found: LayerSpecification;
    if (typeof condition == 'string')
      found = this._mbMap.getLayer(condition) as LayerSpecification;
    else
      found = this.getStyle().layers.find(l => condition(new Layer(l)));

    return found ? new Layer(found) : null;
  }

  public getGroups(): LayerGroup[] {
    return this.metadata.groups.slice();
  }

  public getGroup(id: string): LayerGroup {
    const metadata = this.metadata;
    if (metadata && metadata.groups) {
      const g = metadata.groups.find(x => x.id === id);
      if (g) {
        return new LayerGroup(g);
      }
    }

    return null;
  }

  public getLayerFeatures(id: string): Feature[] {
    const l = this.getLayer(l => l.id === id);
    if (typeof l.source === 'string' && l.metadata.includeInIdentify) {
      const src = this._sources[l.source];
      if (src) {
        return src.features;
      }
    }

    return [];
  }

  public getSourceFeatures(id: string): Feature[] {
    if (id in this._sources) {
      return this._sources[id].features;
    }
    // const mbFtrs = this._mbMap.querySourceFeatures(id);
    // if (mbFtrs) {
    //   return mbFtrs.map(mbf => Feature.fromMb(mbf));
    // }
    return [];
  }

  /**
   * Gets the layer's visibility value
   */
  public getLayerVisibility(id: string): boolean {
    const vis = this._mbMap.getLayoutProperty(id, 'visibility');
    return vis !== 'none';
  }

  // private layerVisibility: { [id: string]: boolean } = {};
  /**
   * Sets the layer's visibility value
   */
  public setLayerVisibility(id: string, visible: boolean) {
    let layers: LayerSpecification[] = [];
    const found = this._mbMap.getLayer(id) as LayerSpecification;
    if (found)
      layers.push(found);
    else {
      const ls = this._mbMap.getStyle().layers.filter(l => l.id.endsWith(id));
      // if (ls.length == 1)
      layers = ls;
      // // else error multiple matches
    }

    layers.forEach(layer => {
      const l = new Layer(layer);
      if (l.metadata == undefined)
        l.metadata = {};

      l.metadata.visible = visible;
      if (visible && l.metadata.gid) {
        // refresh group visibility
        const g = l.metadata.gid;
        this.setGroupVisibility(g, true);
      }
      else {
        this.setMbLayerVisibility(l.id, visible);
        this._onLayerVisibilityChanged.trigger(l);
      }
    });
  }

  public setLayoutProperty(layer: string, name: string, value: any) {
    this._mbMap.setLayoutProperty(layer, name, value);
  }

  public setPaintProperty(layer: string, name: string, value: any, options?: maplibregl.StyleSetterOptions) {
    this._mbMap.setPaintProperty(layer, name, value, options);
  }

  public getPaintProperty(layer: string, name: string) {
    return this._mbMap.getPaintProperty(layer, name);
  }

  public setFilter(layer: string, filter?: FilterSpecification) {
    this._mbMap.setFilter(layer, filter);
  }

  public getFilter(layer: string): void | FilterSpecification {
    return this._mbMap.getFilter(layer);
  }

  public getGroupVisibility(id: string): boolean {
    const g = this.getGroup(id);
    if (g.visible === false)
      return false;

    const parent = g.getParent();
    if (parent == undefined)
      return g.visible === true;
    return this.getGroupVisibility(parent.id);
  }

  public setGroupVisibility(id: string, visible: boolean) {
    const metadata = this.metadata;
    const g = metadata.findGroup(id);
    if (g != null) {
      if (g.visible !== visible) {
        g.visible = visible;
        if (g.visible === true) { // show parents if not visible
          let root: LayerGroup = null;
          const updateUpwards = (group: LayerGroup) => {
            group.visible = true;
            root = group;
            const parent = group.getParent();
            if (parent)
              updateUpwards(parent);
          };

          updateUpwards(g);
          this._style.metadata = metadata;
          this.refreshSingleGroupVisibility(root);
        }
        else if (g.visible === false) // hide children
        {
          this._style.metadata = metadata;
          this.refreshSingleGroupVisibility(g);
        }

        this._onGroupVisibilityChanged.trigger(new LayerGroup(g, g.getParent()));
      }
      else
        this.refreshSingleGroupVisibility(g);
    }
    else
      throw new Error(`Cannot find group with ID '${id}'.`);
  }

  /** Transformations */

  /**
   * Returns ordinate array transformed from given projection to map's projection.
   */
  public transformToProjection(coordinate: CoordinateLike, fromProjection: Projection): CoordinateLike {
    return Projection.transform(coordinate, fromProjection, this._projection) as CoordinateLike;
  }

  /**
   * Returns ordinates array transformed from given projection to map's projection.
   */
  public transformToProjectionMulti(coordinate: CoordinateLike[], fromProjection: Projection): CoordinateLike[] {
    const newCoordinates: CoordinateLike[] = [];
    coordinate.forEach(coord => newCoordinates.push(this.transformToProjection(coord, fromProjection)));
    return newCoordinates;
  }

  /**
   * Transforms a GeoJSON to projection
   */
  public transformGeometryToProjection(geometry: Geometry, fromProjection: Projection) {
    if (this._projection.crsCode !== fromProjection.crsCode)
      return Geometry.transform(geometry, fromProjection, this._projection);

    return geometry;
  }

  /** Camera */

  public pan(coordinate: Coordinate | CoordinateLike, options?: IPanOptions): void {
    const defaultPanOptions = new PanOptions();
    let location: CoordinateLike = Array.isArray(coordinate) ? coordinate : [coordinate.x, coordinate.y];
    if (options && options.projection && options.projection.crsCode !== this._projection.crsCode)
      location = this.transformToProjection(Array.isArray(coordinate) ? coordinate : Coordinate.toOrdinates(coordinate), options.projection);

    const camOptions: maplibregl.FlyToOptions = {
      center: new maplibregl.LngLat(location[0], location[1]),
      zoom: options && options.zoom ? options.zoom : this._mbMap.getZoom(),
      animate: options && options.animate ? options.animate : defaultPanOptions.animate !== false,
      duration: options && options.animate !== false ? (options.duration || defaultPanOptions.duration) : 0,
      bearing: options && options.bearing ? options.bearing : this._mbMap.getBearing(),
      pitch: options && options.pitch ? options.pitch : this._mbMap.getPitch()
    };

    if (options && (options.relCenterX || options.relCenterY)) {
      const size = this.getSize();
      const xOffset = ((options.relCenterX ? options.relCenterX : 0.5) - 0.5) * size[0];
      const yOffset = ((options.relCenterY ? options.relCenterY : 0.5) - 0.5) * size[0];
      camOptions.offset = [xOffset, yOffset];
    }

    this._mbMap.easeTo(camOptions);
  }

  public tilt(degrees: number, options?: ICameraChangeOptions): void {
    this._mbMap.setPitch(degrees);
  }

  public setCenter(center: Coordinate | CoordinateLike, projection?: Projection) {
    this._mbMap.setCenter(Array.isArray(center) ? center : new maplibregl.LngLat(center.x, center.y));
  }

  public fit(bounds: Coordinate[] | CoordinateLike[], options?: IFitOptions) {
    try {
      let boundsArray: CoordinateLike[] = [];
      if (Array.isArray(bounds) && Array.isArray(bounds[0]))
        boundsArray = (bounds as CoordinateLike[]).slice();
      else
        boundsArray = (bounds as Coordinate[]).map(coord => Coordinate.toOrdinates(coord as Coordinate));

      if (options && options.projection)
        boundsArray = this.transformToProjectionMulti(boundsArray, options.projection);

      const coordinates = boundsArray.map(coord => new maplibregl.LngLat(coord[0], coord[1]));
      if (coordinates.length > 1) {
        const b = coordinates.reduce(function (bounds, coord) {
          return bounds.extend(coord);
        }, new maplibregl.LngLatBounds(coordinates[0], coordinates[0]));

        const opts: maplibregl.FitBoundsOptions = new FitOptions({
          padding: options && options.padding ? options.padding : null,
          animate: options && options.animate !== false,
          duration: options && options.animate !== false ? (options && options.duration ? options.duration : null) : 0,
          pitch: options && options.pitch ? options.pitch : null,
          bearing: options && options.bearing ? options.bearing : null
        });

        this._mbMap.fitBounds(b, opts);
      } else {
        console.warn('Cannot fit to the specified bounding box:');
        console.warn(coordinates);
      }
    } catch (e) {
      console.error(e);
    }
  }

  public rotate(heading: number, options?: ICameraChangeOptions) {
    const defaultCameraChangeOptions = new CameraChangeOptions();
    this._mbMap.rotateTo(heading, {
      animate: options && options.animate ? options.animate : defaultCameraChangeOptions.animate,
      duration: options && options.duration ? options.duration : defaultCameraChangeOptions.duration
    });
  }

  public zoom(zoom: number, options?: ICameraChangeOptions) {
    options = new CameraChangeOptions(options);
    this._mbMap.zoomTo(zoom, {
      animate: options.animate,
      duration: options.duration
    })
  }

  public setView(view: RM2ViewData) {
    this._mbMap.jumpTo(view);
  }

  public getView(): RM2ViewData {
    // const ext = this._mbMap.getBounds().toArray();
    // const canvas = this._mbMap.getCanvas();

    return {
      center: this._mbMap.getCenter().toArray() as CoordinateLike,
      zoom: this._mbMap.getZoom(),
      bearing: this._mbMap.getBearing(),
      pitch: this._mbMap.getPitch(),
      // extent: [ext[0][0], ext[0][1], ext[1][0], ext[1][1]],
      // size: [canvas.clientWidth, canvas.clientHeight],
      // resolution: window.devicePixelRatio * 96
    };
  }

  public fitCameraToSources(sourceIds: string[], options?: ICameraChangeOptions): void {
    const ftrs = [];
    for (let i = 0; i < sourceIds.length; i++) {
      const features = this.getSourceFeatures(sourceIds[i]);
      if (features) {
        for (let j = 0; j < features.length; j++) {
          ftrs.push(features[j]);
        }
      }
    }
    const fc = new FeatureCollection(ftrs);
    this.fitCameraToFeatures(fc, options);
  }

  public fitCameraToFeatures(ftColl: FeatureCollection, options?: ICameraChangeOptions): void {
    if (ftColl.features.length > 0) {
      const geometries = ftColl.features.map((x: Feature) => x.geometry);
      // TODO: brez transformacij med projekcijami, če ni v options
      let projection = options && options.projection ? options.projection : this._projection;
      if (ftColl && ftColl.getCrsCode) {
        const crsCode = ftColl.getCrsCode();
        if (crsCode) {
          projection = Projection.create(crsCode);
        }
      }

      if (geometries.length === 1 && geometries[0].type.toLowerCase() === 'point') {
        const pan = options as IPanOptions;
        const opts: IPanOptions = {};
        for (let panProp in pan)
          opts[panProp] = pan[panProp];

        opts.projection = projection;
        this.pan(geometries[0].getCoordinate(), new PanOptions(opts));
      }
      else {
        const opts = new FitOptions(options);
        opts.projection = projection;
        let coords: Coordinate[] = [];
        geometries.forEach(g => coords = coords.concat(g.getCoordinates())); // todo: neka getExtent funkcija iz knjižnice
        this.fit(coords, opts);
      }
    }
  }

  public fitCameraToCurrentRoute(options?: IFitOptions) {
    if (this._highlights && this._highlights.route)
      this.fitCameraToFeatures(this._highlights.route);
  }

  /** Highlights */

  public drawMarker(geometry: Geometry, options?: IHighlightOptions) {
    if (this._highlights)
      this._highlights.showMarker(geometry, options);
  }

  public clearMarkers() {
    if (this._highlights)
      this._highlights.clearMarkers();
  }

  public drawPolygon(coords: Geometry, options?: IPolygonOptions) {
    if (this._highlights)
      this._highlights.showPolygon(coords, options);
  }

  public clearPolygons() {
    if (this._highlights)
      this._highlights.clearPolygons();
  }

  public drawLine(coords: Geometry, options?: ILineOptions) {
    if (this._highlights)
      this._highlights.showLine(coords, options);
  }

  public clearLines() {
    if (this._highlights)
      this._highlights.clearLines();
  }

  public drawCircle(coords: Geometry, options?: ICircleOptions) {
    if (this._highlights)
      this._highlights.showCircle(coords, options);
  }

  public clearCircles() {
    if (this._highlights)
      this._highlights.clearCircles();
  }

  public drawRoutes(routes: Route[], options?: IRouteHighlightOptions) {
    if (this._highlights) {
      const src = this.getSource(Map.routeSourceName);
      if (src == null)
        throw new Error(`No source '${Map.routeSourceName}' found for displaying route.`);

      this._highlights.showRoutes(routes, options);
    }
  }

  public drawRoute(route: Route, options?: IRouteHighlightOptions) {
    if (options == undefined) {
      const ctrl = this.getControl(MapControlType.MapPicker);
      if (ctrl)
        options = ctrl._options;
    }

    if (this._highlights) {
      const src = this.getSource(Map.routeSourceName);
      if (src == null)
        throw new Error(`No source '${Map.routeSourceName}' found for displaying route.`);

      this._highlights.showRoute(route, options);
    }

    const mapPicker = this.getControl(MapControlType.MapPicker) as MapPickerControl;
    if (mapPicker)
      mapPicker.inject(route);
  }

  public clearRoute(clearMapPicker: boolean = false) {
    if (this._highlights)
      this._highlights.clearRoute();

    if (clearMapPicker !== false) {
      const mapPicker = this.getControl(MapControlType.MapPicker) as MapPickerControl;
      if (mapPicker)
        mapPicker.clear();
    }
  }

  public drawRouteLegacy(route: RouteLegacy, options?: IRouteHighlightOptions) {
    if (options == undefined) {
      const ctrl = this.getControl(MapControlType.MapPicker);
      if (ctrl)
        options = ctrl._options;
    }

    if (this._highlights) {
      const src = this.getSource(Map.routeSourceName);
      if (src == null)
        throw new Error(`No source '${Map.routeSourceName}' found for displaying route.`);

      this._highlights.showRouteLegacy(route, options);
    }
  }

  public addFeature(ft: Feature, options?: IFeatureHighlightOptions): Feature {
    if (this._highlights)
      return this._highlights.showFeature(ft, options);
    return null;
  }

  public addFeatures(fts: Feature[] | FeatureCollection, options?: IFeatureHighlightOptions): FeatureCollection {
    if (this._highlights)
      return this._highlights.showFeatures(fts, options);
    return null;
  }

  public removeFeature(id: string) {
    if (this._highlights)
      this._highlights.clearFeature(id);
  }

  public removeFeatures(ids: string[]) {
    this.clearFeatures(ids);
  }

  public setFeatureValue(ids: string | string[], key: string, value: any) {
    if (this._highlights)
      this._highlights.setFeatureValue(ids, key, value);
  }

  public clearFeatures(ids?: string[]) {
    if (this._highlights)
      this._highlights.clearFeatures(ids);
  }

  public getAllFeatures(): FeatureCollection {
    if (this._highlights)
      return this._highlights.getAllFeatures();
    return new FeatureCollection();
  }

  public clearAllHighlights() {
    if (this._highlights)
      this._highlights.clearAll();
  }

  public setFeaturesIncludeInIdentify(includeInIdentify: boolean) {
    if (this._highlights) {
      this._highlights.setIncludeInIdentify(includeInIdentify);
    }
  }

  /** Geolocation */

  public showGeolocation(position: RM2GeolocationPosition, options: IGeolocationOptions) {
    if (this._geolocation)
      this._geolocation.showGeolocation(position, options);
  }

  public clearGeolocation() {
    if (this._geolocation)
      this._geolocation.clearGeolocation();
  }

  /** Popups */

  private sortPopupClickListeners(content: HTMLElement, contentMobile: HTMLElement) {
    const clicks: HTMLElement[] = [];
    const loop = (el: HTMLElement) => {
      if (el.onclick)
        clicks.push(el);
      for (let i = 0; i < el.children.length; i++)
        loop(el.children.item(i) as HTMLElement);
    };
    loop(content);

    for (let i = 0; i < clicks.length; i++) {
      // const mobileEl: HTMLElement = contentMobile.querySelector(`#${clicks[i].getAttribute('id')}`);
      const mobileEl: HTMLElement = contentMobile.querySelector(`#${clicks[i].getAttribute('data-id')}`);
      if (mobileEl)
        mobileEl.onclick = clicks[i].onclick;
    }

    const popupClose = contentMobile.querySelectorAll('.rm2-popup-close');
    for (let i = 0; i < popupClose.length; i++) {
      const item = popupClose.item(i) as HTMLDivElement;
      item.onclick = () => this.closeAllPopups();
    }
  }

  public openPopup(coordinate: Coordinate | CoordinateLike, content: HTMLElement, options?: IPopupOptions, metadata?: any): Popup {
    const contentMobile = document.createElement('div');
    contentMobile.innerHTML = content.innerHTML;
    this.sortPopupClickListeners(content, contentMobile);

    return this.getPopup(coordinate, content, contentMobile, [], options, metadata);
  }

  public openPopupForFeature(feature: Feature, layer: Layer, content: HTMLElement, options?: ICarouselPopupOptions, metadata?: any): Popup {
    const contentMobile = document.createElement('div');
    contentMobile.innerHTML = content.innerHTML;
    this.sortPopupClickListeners(content, contentMobile);

    const fc = new FeatureCollection();
    fc.features.push(feature);
    return this.getPopup(feature.geometry.getCoordinate(), content, contentMobile, [{ el: content, ft: feature }, { el: contentMobile, ft: feature }], options, fc, { layer: layer });
  }

  public openPopupForFeatures(coordinate: Coordinate | CoordinateLike, features: Feature[] | FeatureCollection, template: (ft: Feature, i: number) => HTMLElement, options?: ICarouselPopupOptions, metadata?: any): Popup {
    if (Array.isArray(features))
      features = new FeatureCollection(features);

    let clickDetectors: { el: HTMLElement, ft: Feature }[] = [];
    options = new CarouselPopupOptions(options);
    const content = document.createElement('div');
    const contentMobile = document.createElement('div');
    if (features.features.length > 1) {
      const transformedFts = features.features.map((ft, i) => {
        return {
          index: i,
          ft: ft
        };
      });

      const copyFtsToDiv = (id: number, c: HTMLDivElement) => {
        c.innerHTML = Mustache.render(PopupCarouselTemplate(id), { features: transformedFts });
        const inner = c.getElementsByClassName('carousel-inner')[0];
        for (let i = 0; i < inner.children.length; i++) {
          const ft = transformedFts[i];
          const rendered = template(ft.ft, ft.index);
          clickDetectors.push({ el: rendered, ft: ft.ft });
          inner.children[i].appendChild(rendered);
        }
      };

      copyFtsToDiv(0, content);
      copyFtsToDiv(1, contentMobile);
    }
    else {
      const ft = features.first;
      const render1 = template(ft, 0);
      content.appendChild(render1);
      const render2 = template(ft, 0);
      contentMobile.appendChild(render2);

      clickDetectors = [{ el: render1, ft: ft }, { el: render2, ft: ft }];
    }

    return this.getPopup(coordinate, content, contentMobile, clickDetectors, options, features, metadata);
  }

  public closeAllPopups() {
    this._popups.forEach(popup => popup.close());
  }

  /** Utility */

  public refreshSourceWithUrl(url: string) {
    const getSrcsWithUrl = (url: string): string[] => {
      const sources = this._mbMap.getStyle().sources;
      const filtered: string[] = [];
      for (let s in sources) {
        const src = sources[s];
        if (src.type === 'geojson') {
          const u = src.data;
          if (typeof u === 'string' && u.startsWith(url))
            filtered.push(s);
        }
      }

      return filtered;
    };

    const srcs = getSrcsWithUrl(url);
    const style = this._mbMap.getStyle();
    srcs.forEach(src => this.refreshSource(src));
  }

  public refreshSource(id: string) {
    // HACK
    const source = this._mbMap.getSource(id);
    if (source.type === 'geojson') {
      const s = source as maplibregl.GeoJSONSource;
      const previous = s['_data'];
      if (typeof previous === 'string') {
        s.setData(null);
        delete this._sourceRequests[previous];
        s.setData(previous);
      }
    }
    else if (source.type === 'vector') {
      // maplibregl.clearStorage((err) => console.log(err));
      if (this._loaded === true) {
        const src = this._mbMap.getSource(id) as maplibregl.VectorTileSource;
        if (src) {
          const mbLayers = this.getLayers();
          let layers: { before: string, layer: LayerSpecification }[] = [];
          for (let i = 0; i < mbLayers.length; i++) {
            const l = mbLayers[i];
            if (l.source === id) {
              layers.push({
                before: i < (mbLayers.length - 1) ? mbLayers[i + 1].id : undefined,
                layer: l as LayerSpecification
              })
              this._mbMap.removeLayer(l.id);
            }
          }

          this._mbMap.removeSource(id);
          let newSrc: any = { type: src.type };
          if (src.type === 'vector') {
            newSrc.tiles = src.tiles;
            newSrc.maxzoom = src.maxzoom;
            newSrc.minzoom = src.minzoom;
          }
          this._mbMap.addSource(id, newSrc);

          layers = layers.reverse();
          for (let i = 0; i < layers.length; i++) {
            const l = layers[i];
            this.addLayer(l.layer, l.before);
          }
        }
      }
    }
  }

  /**
   * Should be called to redraw map after map's target DOM element resizes.
   */
  public updateSize() {
    this._mbMap.resize();
  }

  public getSize(): number[] {
    const canvas = this._mbMap.getCanvas();
    return [canvas.clientWidth, canvas.clientHeight];
  }

  /** Gets the instance of a map control */
  public getControl(type: MapControlType): any {
    return this._controls[type];
  }

  /** Gets the instance of a map service */
  public getService(type: ServiceType): IService {
    return this._services.find(service => service.type === type);
  }

  public getTarget(): HTMLElement {
    return this._mbMap.getCanvasContainer().parentElement;
  }

  public setTarget(target: HTMLElement) {
    if (this._mbMap.getContainer() != target)
      throw new Error('Setting new target is not supported with Mapbox!');
  }

  public isStyleLoaded(): boolean {
    return this._mbMap.isStyleLoaded() === true;
  }

  public getStyle(): StyleSpecification {
    return this._mbMap.getStyle();
  }

  public checkIfCssLoaded(printWarning?: boolean): boolean {
    const loadedCss = document.styleSheets;
    const searchFor = '/mapbox-gl.css';
    let loaded: boolean = false;
    for (let i = 0; i < loadedCss.length; i++) {
      const css = loadedCss.item(i);
      if (css && css.href && css.href.endsWith(searchFor)) {
        loaded = true;
        break;
      }
    }

    if (loaded == false && printWarning == true)
      console.warn(`Mapbox styleshets not found. Searching for '${searchFor}'.`);

    return loaded;
  }

  public query(query: string, options?: QueryOptions): Promise<QueryResult[]> {
    return new Promise<QueryResult[]>(async (res, rej) => {
      const service = this.getService(ServiceType.Nominatim) as NominatimService;
      const nominatimRes = await service.query(query, options);

      // HACK za TIC
      const response = [nominatimRes];
      this.style.layers.forEach(layer => {
        const l = new Layer(layer);
        if (l.metadata.uiVisible && l.source != null) {
          const fc = this.getLayerFeatures(l.id);
          const fts = fc.filter(ft => {
            const title = ft.getField('title') || ft.getField('Title');
            const description = ft.getField('description') || ft.getField('Description');

            if ((title && typeof title === "string" && title.toLowerCase().includes(query.toLowerCase())) ||
              (description && typeof description === "string" && description.toLowerCase().includes(query.toLowerCase())))
              return true;
            return false;
          });

          response.push({
            service: l.metadata.gid || l.metadata.title || l.id,
            status: 'OK',
            items: fts.map(ft => {
              const geom = ft.geometry;
              let loc = undefined;
              if (geom)
                loc = geom.getCentroid().getCoordinate();

              return {
                title: ft.getField('title') || ft.getField('Title') || ft.getField('description') || ft.getField('Description'),
                type: 'location', // ??
                dateTime: new Date(),
                projection: this._projection,
                location: loc ? [loc.x, loc.y] : undefined,
                envelope: geom ? geom.getEnvelopeInternal() : undefined,
                geometry: geom
              };
            })
          });
        }
      });

      res(response);
    });
  }

  public route(locations: CoordinateLike[], options?: IRouteOptions): Promise<Route<RouteSimple>> {
    const service = this.getService(ServiceType.Routing) as RoutingService;
    return service.route(locations, options);
  }

  public locate(coordinate: Coordinate, options?: ReverseGeocodingOptions): Promise<FeatureCollection> {
    const service = this.getService(ServiceType.Nominatim) as NominatimService;
    return service.reverse([coordinate.x, coordinate.y], options);
  }

  public setMapTheme(theme: MapTheme) {
    this._theme = theme;
    this._style.layers.filter(l => l.id.toLowerCase().endsWith(this._theme)).forEach(newThemeLayer => {
      this._mbMap.setLayoutProperty(newThemeLayer.id, 'visibility', 'visible');

      var layerId = newThemeLayer.id.substring(0, newThemeLayer.id.length - this._theme.length - 1);
      this._style.layers.filter(l => l.id.startsWith(layerId) && l.id == newThemeLayer.id == false).forEach(otherLayer => this._mbMap.setLayoutProperty(otherLayer.id, 'visibility', 'none'));
    });
  }

  public setLanguage(lang: string) {
    const service = this.getService(ServiceType.Localization) as LocalizationService;
    service.setLanguage(lang);
  }

  public getBounds() {
    return this._mbMap.getBounds();
  }

  public compareWith(map: Map, options?: RM2MapCompareState) {
    const compareContainer = this._mbMap.getContainer().parentElement;
    this._mbMapCompare = new maplibreglCompare(this._mbMap, map._mbMap, compareContainer);
    this._mbMapCompareMap = map;
    if (options) {
      if (options.sliderPosition != null)
        this._mbMapCompare.setSlider(options.sliderPosition);
    }
  }

  public getCurrentCompareMap(): Map {
    return this._mbMapCompareMap;
  }

  public getCurrentCompareState(): RM2MapCompareState {
    if (this._mbMapCompare) {
      return {
        sliderPosition: this._mbMapCompare.currentPosition
      };
    }
    return null;
  }

  public stopCompare() {
    if (this._mbMapCompare)
      this._mbMapCompare.remove();
  }

  public dispose() {
    if (this._forceReloadSourceIntervals) {
      for (let i = 0; i < this._forceReloadSourceIntervals.length; i++) {
        clearInterval(this._forceReloadSourceIntervals[i]);
      }
      this._forceReloadSourceIntervals = [];
    }
    this._mbMap.remove();
    this._mbMap = undefined;
  }

  public project(coord: Coordinate | CoordinateLike): [number, number] {
    const arr = coord instanceof Coordinate ? [coord.x, coord.y] : coord;
    const p = this._mbMap.project({ lng: arr[0], lat: arr[1] });
    return [p.x, p.y];
  }

  public unproject(point: [number, number]): Coordinate {
    const unp = this._mbMap.unproject(point);
    return new Coordinate(unp.lng, unp.lat);
  }

  public persistVisibility(visibilities: PersistedVisibility[]) {
    const persisted = this.getPersistedVisibility();
    for (let i = 0; i < visibilities.length; i++) {
      const vis = visibilities[i];
      const found = persisted.findIndex(x => x.type === vis.type && x.id === vis.id);
      if (found !== -1)
        persisted[found].visible = vis.visible;
      else {
        persisted.push({
          type: vis.type,
          id: vis.id,
          visible: vis.visible
        });
      }
    }

    if (localStorage) {
      localStorage.setItem(this.persistVisibilityKey, JSON.stringify(persisted));
    }
  }

  public getPersistedVisibility(): PersistedVisibility[] {
    if (localStorage) {
      const str = localStorage.getItem(this.persistVisibilityKey);
      if (str) {
        const array: PersistedVisibility[] = JSON.parse(str);
        return array;
      }
    }

    return [];
  }

  /*
    END OF INTERFACE IMPLEMENTATION
  */









  public addControl(data: ControlData, instance: maplibregl.IControl | maplibregl.IControl) {
    this._controls[data.type] = instance;
    this._mbMap.addControl(instance, data.position);
  }

  private mobileMarkerId = '47b1d48c-91b4-446f-8bd3-be1ad5b28d30';
  private showMobileMarker(coordinate: Coordinate | CoordinateLike) {
    this.removeMobileMarker();
    this._popupMobileMarker = this.addFeature(new Feature({
      id: this.mobileMarkerId,
      icon: 'marker_E2001A-32',
      offset: [0, -16],
      includeInIdentify: false
    }, Point.fromCoordinate(coordinate)), { pan: false });
  }

  private removeMobileMarker() {
    if (this._popupMobileMarker)
      this.removeFeature(this.mobileMarkerId);
  }

  private getPopup(coordinate: Coordinate | CoordinateLike, content: HTMLElement, contentMobile: HTMLElement, clickDetectors: { el: HTMLElement, ft: Feature }[], options?: IPopupOptions, features?: FeatureCollection, metadata?: any): Popup {
    options = new PopupOptions(options);

    const popup = new Popup(this, content, options, features, metadata);
    popup.mbPopup.setLngLat(Array.isArray(coordinate) ? coordinate : Coordinate.toOrdinates(coordinate));
    setTimeout(() => {
      // zamik, da se ne zapre takoj zaradi klika
      popup.mbPopup.addTo(this._mbMap);

      popup.mbPopup['_content'].parentElement.classList.add('d-none', 'd-sm-flex');
      clickDetectors.forEach(d => d.el.onclick = () => this._onFeaturePopupClicked.trigger(d.ft));

      // scroll page if map not in viewport
      const canvas = this._mbMap.getCanvas();
      const rect = canvas.getBoundingClientRect();
      const isInViewport =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth);
      if (isInViewport === false)
        canvas.scrollIntoView({ block: 'center', inline: 'center' });

      // zamik, da se najprej naloži HTML popupa
      if (options && options.panOnOpen)
        popup.panTo(options.cameraOptions);

      if (options.showMarkerOnMobile === true)
        this.showMobileMarker(coordinate);

      if (options.showMarkerOnMobile) {
        popup.onSlide.subscribe(e => {
          if (popup.selectedFeature && popup.selectedFeature.geometry)
            this.showMobileMarker(popup.selectedFeature.geometry.getCoordinate());
        });
      }

      this._onPopupOpened.trigger(popup);
    }, 10);

    // add mobile popup
    const mobilePopup = document.createElement('div');
    mobilePopup.className = 'rm2-popup-mobile mx-2 mb-2 bg-white d-sm-none';
    mobilePopup.appendChild(contentMobile);
    this.getTarget().appendChild(mobilePopup);

    this._popups.push(popup);
    this._mobilePopups.push(mobilePopup);

    popup.onClose.subscribe(() => {
      this.removeMobileMarker();
      const i = this._popups.indexOf(popup);
      if (i != -1) {
        this._popups.splice(i, 1);

        const m = this._mobilePopups.splice(i, 1)[0];
        this.getTarget().removeChild(m);
      }
      // popup.onClose.unsubscribe(); // kje unsubscribe?
    });

    return popup;
  }

  // private getLayerImages(iconImage: any[]): string[] {
  //   if (typeof iconImage == 'string')
  //     return [iconImage];
  //   else {
  //     let imagesToLoad = [iconImage[iconImage.length - 1]];
  //     for (let i = 2; i < iconImage.length; i += 2)
  //       if (imagesToLoad.indexOf(iconImage[i]) == -1)
  //         imagesToLoad.push(iconImage[i]);

  //     return imagesToLoad;
  //   }
  // }

  private ftsAtPoint(point: { x: number, y: number }): GeoJSON.Feature[] {
    if (this.metadata) {
      const buffer = this.metadata.hitBuffer;
      const opts = { layers: this.getLayers(l => l.metadata && l.metadata.includeInIdentify).map(l => l.id).concat(this._clusterLayers.map(l => l.id)) };
      const bbox = [[point.x - buffer, point.y - buffer], [point.x + buffer, point.y + buffer]] as [CoordinateLike, CoordinateLike];
      const fts = this._mbMap.queryRenderedFeatures(bbox, opts);
      return fts.filter(ft => ft.properties.includeInIdentify !== false);
    }
    return [];
  }

  private clearLongClickTimeout() {
    if (this.longClickTimeout)
      clearTimeout(this.longClickTimeout);
  }

  private longClickCallback = (event) => {
    this.movedSinceLastMouseButtonDown = true;
    this._onLongClick.trigger(this._handleMapLongClick(event));
  };

  private longClickTimeout;
  private movedSinceLastMouseButtonDown: boolean;
  private initEvents() {
    this._mbMap.on('mousedown', (event: any) => {
      this.movedSinceLastMouseButtonDown = false;
      this._handleUserAction(event);

      this.clearLongClickTimeout();
      this.longClickTimeout = setTimeout(() => this.longClickCallback(event), 500);
    });

    this._mbMap.on('mouseup', (event: any) => {
      this.clearLongClickTimeout();

      if (this.movedSinceLastMouseButtonDown == false && this.loaded) {
        const e = this._handleMapClick(event);
        if (e)
          this._onClick.trigger(e);
      }
    });

    this._mbMap.on('mousemove', (event: any) => {
      this.clearLongClickTimeout();
      this._handleMousemove(event);

      if (this.loaded) {
        const fts = this.ftsAtPoint(event.point);
        if (fts.length > 0)
          this._mbMap.getCanvas().style.cursor = 'pointer';
        else
          this._mbMap.getCanvas().style.cursor = '';
      }
    });

    this._mbMap.on('touchstart', (event: any) => {
      this._handleUserAction(event);

      this.clearLongClickTimeout();
      this.longClickTimeout = setTimeout(() => this.longClickCallback(event), 500);
    });

    this._mbMap.on('touchend', (event: any) => this.clearLongClickTimeout());
    this._mbMap.on('touchcancel', (event: any) => this.clearLongClickTimeout());
    this._mbMap.on('touchmove', (event: any) => this.clearLongClickTimeout());
    this._mbMap.on('wheel', (event: any) => this._handleUserAction(event));
    this._mbMap.on('moveend', (event: any) => this._onViewChange.trigger(this._handleViewChange(event)));
    this._mbMap.on('drag', (event: any) => { this.clearLongClickTimeout(); this.movedSinceLastMouseButtonDown = true });
    this._mbMap.on('zoom', (event: any) => this._onZoom.trigger(this._mbMap.getZoom()));
    this._mbMap.on('rotate', (event: any) => { this.clearLongClickTimeout(); this.movedSinceLastMouseButtonDown = true });
    this._mbMap.on('pitch', (event: any) => { this.clearLongClickTimeout(); this.movedSinceLastMouseButtonDown = true });
  }

  private _handleMapLongClick = (event: any): MapEventArgs => {
    const coords: CoordinateLike = [event['lngLat']['lng'], event['lngLat']['lat']];
    return {
      map: this,
      coordinate: coords,
      nativeEvent: event.originalEvent,
      features: [],
      featureLayers: []
    };
  };

  private _handleMapClick = (event: any): MapEventArgs => {
    const featuresData: { feature: Feature, featureLayer: Layer }[] = [];
    const fts = this.ftsAtPoint(event.point);
    fts.forEach(f => {
      const parsed = this.decodeQueriedProperties(f.properties);
      const ftr = new Feature();
      const ftrprops = {};

      Object.assign(ftrprops, parsed);
      ftr.setProperties(ftrprops);

      const geometry = Geometry.fromGeoJson(f.geometry);
      ftr.geometry = geometry;

      if (ftr && (ftr.properties.id == undefined || featuresData.find(d => d.feature.properties.id === ftr.properties.id) == null)) {
        featuresData.push({
          feature: ftr,
          featureLayer: new Layer((f as any).layer)
        });
      }
    });

    const legendControl = this.getControl(MapControlType.Legend) as LegendControl;
    if (legendControl)
      legendControl.close();

    const coords: CoordinateLike = [event['lngLat']['lng'], event['lngLat']['lat']];
    const clickPoint = Point.fromCoordinate(Coordinate.fromOrdinates(coords));
    const notClusters = featuresData.filter(x => this._clusterLayers.find(c => c.id === x.featureLayer.id) == undefined);
    if (notClusters.length > 0) {
      // features inside list

      // sort by distance from click
      const coords: CoordinateLike = [event['lngLat']['lng'], event['lngLat']['lat']];
      const clickPoint = Point.fromCoordinate(Coordinate.fromOrdinates(coords));
      let featuresDataSorted = featuresData.filter(x => notClusters.indexOf(x) != -1).sort((a, b) => a.feature.geometry.distance(clickPoint) - b.feature.geometry.distance(clickPoint));

      // hit tolerance
      const metadata = this.metadata;
      if (metadata.hitTolerance)
        featuresDataSorted = featuresDataSorted.slice(0, metadata.hitTolerance);

      // grupiranje po layerjih
      // featuresDataSorted = featuresDataSorted.sort((a, b) => {
      //   const i1 = this.style.layers.findIndex(l => l.id === a.featureLayer.id);
      //   const i2 = this.style.layers.findIndex(l => l.id === b.featureLayer.id);
      //   return i1 - i2;
      // });

      return {
        map: this,
        coordinate: coords,
        nativeEvent: event.originalEvent,
        features: featuresDataSorted.map(x => x.feature),
        featureLayers: featuresDataSorted.map(x => x.featureLayer)
      };
    }
    else {
      if (featuresData.length > 0) {
        // only clusters
        const cluster = featuresData[0];
        const props: MapboxClusterProperties = cluster.feature.properties;
        const src = (typeof cluster.featureLayer.source == 'string' ? this._mbMap.getSource(cluster.featureLayer.source) : cluster.featureLayer.source) as maplibregl.GeoJSONSource;
        if (src) {
          src.getClusterExpansionZoom(props.cluster_id, (err, zoom) => {
            if (err)
              return;

            const coord = cluster.feature.geometry.getCoordinate();
            this._mbMap.easeTo({
              center: [coord.x, coord.y],
              zoom: zoom
            });
          });
        }

        return null;
      }
      else {
        return {
          map: this,
          coordinate: coords,
          nativeEvent: event.originalEvent,
          features: [],
          featureLayers: []
        };
      }
    }
  };

  private _handleViewChange = (event: any): RM2ViewData => {
    const metadata = this.metadata;
    if (metadata && metadata.persistView !== false)
      this.persistView();

    return this.getView();
  };

  private _handleUserAction = (event: Event) => {
    this._onUserAction.trigger({
      map: this,
      coordinate: undefined,
      nativeEvent: event
    });
  };


  private _handleMousemove = (event: any) => {
    try {
      const featuresData: { feature: Feature, featureLayer: Layer }[] = [];

      const fts = this.ftsAtPoint(event.point);
      fts.forEach(f => {
        const parsed = this.decodeQueriedProperties(f.properties);
        const ftr = new Feature();
        const ftrprops = {};

        Object.assign(ftrprops, parsed);
        ftr.setProperties(ftrprops);

        const geometry = Geometry.fromGeoJson(f.geometry);
        ftr.geometry = geometry;

        if (ftr && (ftr.properties.id == undefined || featuresData.find(d => d.feature.properties.id === ftr.properties.id) == null)) {
          featuresData.push({
            feature: ftr,
            featureLayer: new Layer((f as any).layer)
          });
        }

      });

      const notClusters = featuresData.filter(x => this._clusterLayers.find(c => c.id === x.featureLayer.id) == undefined);

      // features inside list

      // sort by distance from click
      const coords: CoordinateLike = [event['lngLat']['lng'], event['lngLat']['lat']];
      const clickPoint = Point.fromCoordinate(Coordinate.fromOrdinates(coords));
      let featuresDataSorted = featuresData.filter(x => notClusters.indexOf(x) != -1).sort((a, b) => a.feature.geometry.distance(clickPoint) - b.feature.geometry.distance(clickPoint));

      // hit tolerance
      const metadata = this.metadata;
      if (metadata && metadata.hitTolerance)
        featuresDataSorted = featuresDataSorted.slice(0, metadata.hitTolerance);

      // grupiranje po layerjih
      // featuresDataSorted = featuresDataSorted.sort((a, b) => {
      //   const i1 = this.style.layers.findIndex(l => l.id === a.featureLayer.id);
      //   const i2 = this.style.layers.findIndex(l => l.id === b.featureLayer.id);
      //   return i1 - i2;
      // });
      this._onMouseMove.trigger({
        map: this,
        coordinate: coords,
        nativeEvent: event.originalEvent,
        features: featuresDataSorted.map(x => x.feature),
        featureLayers: featuresDataSorted.map(x => x.featureLayer)
      });
    }
    catch (ex) {
      console.error(ex);
    }
  }

  private decodeQueriedProperties(properties: any): any {
    if (typeof properties == 'number' || typeof properties == 'string')
      return properties;

    for (let key in properties) {
      const value = properties[key];
      if (typeof value == 'string') {
        if (value.length > 1 && (value[0] == '{' || value[0] == '[') &&
          /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').
            replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
            replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
          properties[key] = this.decodeQueriedProperties(JSON.parse(value));
        }
        else
          properties[key] = value;
      }
      else
        properties[key] = this.decodeQueriedProperties(value);
    }

    return properties;
  }

  private addControls() {
    // // add & hide geolocator control
    //   this._geolocatorControl = new maplibregl.GeolocateControl({
    //     positionOptions: { enableHighAccuracy: true },
    //     trackUserLocation: true
    //   });
    //   this._mbMap.addControl(this._geolocatorControl);

    // const geolocators = document.getElementsByClassName('maplibregl-ctrl-icon maplibregl-ctrl-geolocate');
    // for (let i = 0; i < geolocators.length; i++) {
    //   if (geolocators[i] instanceof HTMLElement)
    //     (geolocators[i] as HTMLElement).style.display = 'none';
    // }

    // const topRight = document.getElementsByClassName('maplibregl-ctrl-top-right');
    // if (topRight.length == 1) {
    //   for (let i = 0; i < topRight.length; i++) {
    //     if (topRight[i] instanceof HTMLElement)
    //       (topRight[i] as HTMLElement).style.display = 'none';
    //   }
    // }

    const metadata = this.metadata;
    if (metadata && metadata.controls) {
      const controls = metadata.controls;
      for (let i = 0; i < controls.length; i++) {
        const ctrl: ControlData = controls[i];
        if (ctrl && ctrl.enabled !== false) {
          if (ctrl.type == MapControlType.Legend) {
            const addLegendControl = (options?: LegendOptions) => {
              this.addControl(ctrl, new LegendControl(this, options));
            };

            // if (this._options.legendOptionsPath) {
            //   const legendSettingsFile = new XMLHttpRequest();
            //   legendSettingsFile.open('GET', this._options.legendOptionsPath, false);
            //   legendSettingsFile.onreadystatechange = () => {
            //     if (legendSettingsFile.readyState === 4) {
            //       if (legendSettingsFile.status === 200 || legendSettingsFile.status == 0) {
            //         const legendSettings = JSON.parse(legendSettingsFile.responseText);
            //         addLegendControl(legendSettings);
            //       }
            //     }
            //   };

            //   legendSettingsFile.send(null);
            // }
            // else
            addLegendControl();
          }
          else if (ctrl.type == MapControlType.FullScreen)
            this.addControl(ctrl, new maplibregl.FullscreenControl(null));
          else if (ctrl.type == MapControlType.Zoom) {
            this.addControl(ctrl,
              new maplibregl.NavigationControl({
                showCompass: metadata.enableRotate === true,
                showZoom: true
              })
            );
          }
          // else if (ctrl.type == MapControlType.Basemaps)
          //   this._mbMap.addControl(new BasemapsControlMb(this), ctrl.position);
          else if (ctrl.type == MapControlType.Geolocation) {
          }
          else if (ctrl.type == MapControlType.Routing)
            this.addControl(ctrl, new RoutingControl(this, ctrl.options));
          else if (ctrl.type == MapControlType.MapPicker) {
            const opts = ctrl.options ? MapPickerControlOptions.fromGeoJson(ctrl.options) : new RouteHighlightOptions();
            const mp = new MapPickerControl(this, opts);
            mp.onRouteConstructed.subscribe((data) => this._onRouteConstructed.trigger(data));
            mp.onProcessingStatusChange.subscribe((data) => this._onProcessingStatusChanged.trigger(data));
            this.addControl(ctrl, mp);
          }
          else if (ctrl.type == MapControlType.PrometSi) {
            const ps = new PrometSiControl(this, ctrl.options);
            ps.onRouteSaved.subscribe(r => this._onRouteConstructed.trigger(r));
            this.addControl(ctrl, ps);
          }
          else if (ctrl.type == MapControlType.NAP) {
            const nap = new NAPControl(this, ctrl.options);
            this.addControl(ctrl, nap);
          }
        }
      }
    }
  }

  private triggerGeolocator() {
    this._geolocatorControl.trigger();
  }

  private startRefreshingGeoJSONUrlSources() {
    setInterval(() => {
      // ŽL: to je nek hack, ker Mapbox ne refresha geojson vsebin avtomatično
      if (this._mbMap) {
        const srcs = this._mbMap.getStyle().sources;
        for (let key in srcs) {
          const src = srcs[key];
          if (src.type === 'geojson' && typeof src.data === 'string') {
            const gjSrc = this._mbMap.getSource(key) as maplibregl.GeoJSONSource;
            const oldUrl = src.data;
            gjSrc.setData(null);
            gjSrc.setData(oldUrl);
          }
        }
      }
    }, 60000);
  }

  private persistView() {
    const view = this.getView();
    if (view) {
      if (localStorage) {
        localStorage.setItem(this.persistViewKey, JSON.stringify(view));
      }
    }
  }

  private getPersistedView(): RM2ViewData {
    if (localStorage) {
      const viewString = localStorage.getItem(this.persistViewKey);
      if (!viewString || viewString.length === 0 || !viewString.trim())
        return null;
      else
        return JSON.parse(viewString) as RM2ViewData;
    }
    else {
      console.error('Error trying to load view from local storage.');
      return null;
    }
  }

  // private selectLayers(rootLayer: Layer, condition: (x: Layer) => boolean): Layer[] {
  //   let layers = new Array<Layer>();
  //   if (rootLayer instanceof GroupLayer) {
  //     if (condition(rootLayer))
  //       layers.push(rootLayer);

  //     const lg = rootLayer as GroupLayer;
  //     for (let i = 0; i < lg.layers.length; i++)
  //       layers = layers.concat(this.selectLayers(lg.layers[i], condition));
  //   }
  //   else {
  //     if (condition(rootLayer))
  //       layers.push(rootLayer);
  //   }
  //   return layers;
  // }

  // // todo method options to controll which IQueryService shuld be used. Default is all this._queryServices and all VectorLayer
  // /**
  //  * Executes query over supported services (map's queriable services) for given querystring and sends QueryServiceResult object to given response function.
  //  *
  //  * Response is QueryServiceResult objects array.
  //  */
  // public query(queryString: string, response: (results: QueryServiceResult[]) => void, options?: QueryServiceOptions) {
  //   options = options || { maxItems: 3 };
  //   const queryServices = this._services.filter(x => (x as any).__is_IQueryService).map(x => (x as any) as IQueryService)
  //     .concat(this.selectLayers(this._layers, x => (x as any).__is_IQueryService).map(x => (x as any) as IQueryService));
  //   const results = new Array<QueryServiceResult>(queryServices.length);
  //   queryServices.forEach((x, i) => {
  //     x.query(queryString, (r) => { results[i] = r; response(results); }, options);
  //   });
  // }

  private setMbLayerVisibility(id: string, visible: boolean) {
    const mbVis = visible ? 'visible' : 'none';
    this._mbMap.setLayoutProperty(id, 'visibility', mbVis);
    this._clusterLayers.filter(cl => cl.id.startsWith(id)).forEach(cl => this._mbMap.setLayoutProperty(cl.id, 'visibility', mbVis));
  }

  private refreshSingleGroupVisibility(group: LayerGroup) {
    // const group = this.metadata.findGroup(id);
    // if (group) {
    const elementalLayers = this.getLayers((l: Layer) => l.metadata && l.metadata.gid === group.id);
    const canDisplay = this.canDisplayGroup(group);
    elementalLayers.forEach(child => {
      if (group.visible === false)
        this.setMbLayerVisibility(child.id, false);
      else if (group.visible === true && canDisplay === true) {
        const vis = child.metadata.visible;
        this.setMbLayerVisibility(child.id, vis);
        if ((this._mbMap.getLayoutProperty(child.id, 'visibility') !== 'none') !== vis)
          this._onLayerVisibilityChanged.trigger(new Layer(this._mbMap.getLayer(child.id) as LayerSpecification));
      }
    });

    if (group.children)
      group.children.forEach(c => this.refreshSingleGroupVisibility(c));
    // }
    // else
    //   throw new Error(`Cannot find group with ID '${id}'.`);
  }

  private canDisplayGroup(group: LayerGroup): boolean {
    if (group.visible === false)
      return false;

    const parent = group.getParent();
    if (parent != null)
      return this.canDisplayGroup(parent)
    return true;
  }

  private generateClusterLayers(layer: Layer): void {
    // const layer = new Layer(l);
    const clusterFilter: FilterSpecification = ['has', 'point_count'];
    const pointCount: FilterSpecification = ['get', 'point_count'];
    const notClusterFilter: FilterSpecification = ['!', clusterFilter];
    const filter = this._mbMap.getFilter(layer.id);
    this._mbMap.setFilter(layer.id, filter ? (['all', filter, notClusterFilter]) as FilterSpecification : notClusterFilter);
    const metadata = this.metadata;

    const iconSize = layer.metadata.clusterSymbolIconSize ? layer.metadata.clusterSymbolIconSize : metadata.clusterIcons.iconSize;
    const textSize = layer.metadata.clusterSymbolTextSize ? layer.metadata.clusterSymbolTextSize : metadata.clusterIcons.textSize;
    const textOffset = layer.metadata.clusterSymbolTextOffset ? layer.metadata.clusterSymbolTextOffset : metadata.clusterIcons.textOffset;
    const textColor = layer.metadata.clusterSymbolTextColor ? layer.metadata.clusterSymbolTextColor : metadata.clusterIcons.textColor;

    const symbolLayer: SymbolLayerSpecification = {
      id: `${layer.id}_cluster-symbol`,
      type: 'symbol',
      source: layer.source,
      filter: clusterFilter,
      metadata: { gid: layer.metadata.gid },
      layout: {
        'icon-image': layer.metadata.clusterSymbol,
        'icon-allow-overlap': true,
        'icon-size': iconSize,
        'text-field': [
          'case',
          ['<', pointCount, ['literal', 10]], pointCount,
          ['literal', '+']
        ],
        'text-font': ['Noto Sans Regular'],
        'text-size': textSize,
        'text-allow-overlap': true,
        'text-offset': textOffset as [number, number]
      },
      paint: {
        'text-color': textColor
      }
    };

    if (typeof layer.source === 'string') {
      const src = this._mbMap.getSource(layer.source);
      if (src.type === "vector")
        symbolLayer['source-layer'] = layer['source-layer'];
    }

    this._mbMap.addLayer(symbolLayer, layer.id);
    this._clusterLayers.push(symbolLayer);

    // if (layer.metadata.clusterType === 'circle') {
    //   const style = layer.metadata.clusterStyle as CircleClusterStyle;
    //   const circleLayer: maplibregl.Layer = {
    //     id: `${layer.id}_cluster-circle`,
    //     type: 'circle',
    //     source: layer.source,
    //     filter: clusterFilter,
    //     paint: {
    //       'circle-color': style.circleColor,
    //       'circle-stroke-color': style.circleOutlineColor,
    //       'circle-stroke-width': style.circleOutlineWidth,
    //       'circle-opacity': style.circleOpacity,
    //       'circle-radius': style.circleRadius
    //     }
    //   };
    //   this._mbMap.addLayer(circleLayer, layer.id);
    //   this._clusterLayers.push(circleLayer);
    // }
    // else if (layer.metadata.clusterType === 'symbol') {
    //   const style = layer.metadata.clusterStyle as SymbolClusterStyle;
    //   const symbolLayer: maplibregl.Layer = {
    //     id: `${layer.id}_cluster-symbol`,
    //     type: 'symbol',
    //     source: layer.source,
    //     filter: clusterFilter,
    //     layout: {
    //       'icon-allow-overlap': true,
    //       'icon-image': style.iconImage,
    //       'icon-anchor': style.iconAnchor,
    //       'icon-size': style.iconSize
    //     }
    //   };

    //   this._mbMap.addLayer(symbolLayer, layer.id);
    //   this._clusterLayers.push(symbolLayer);
    // }

    // if (layer.metadata.clusterStyle.showText !== false) {
    //   const labelLayer: maplibregl.Layer = {
    //     id: `${layer.id}_cluster-label`,
    //     type: 'symbol',
    //     source: layer.source,
    //     filter: clusterFilter,
    //     layout: {
    //       'text-field': '{point_count_abbreviated}',
    //       'text-font': ["Noto Sans Regular"],
    //       'text-size': 12,
    //       'text-allow-overlap': true
    //     },
    //     paint: {
    //       'text-color': layer.metadata.clusterStyle.textColor
    //     }
    //   };

    //   this._mbMap.addLayer(labelLayer, layer.id);
    //   this._clusterLayers.push(labelLayer);
    // }
  }

  // private refreshGroupVisibility(id: string) {
  //   const group = this.metadata.findGroup(id);
  //   // TODO: check for children & parents
  //   if (group) {
  //     const elementalLayers = this._mbMap.getStyle().layers.filter(l => l.metadata && l.metadata.gid === id);
  //     elementalLayers.forEach(child => {
  //       const prevVis = this._mbMap.getLayoutProperty(child.id, 'visibility') !== 'none';
  //       if (group.visible === false) {
  //         this.layerVisibility[child.id] = prevVis;
  //         this._mbMap.setLayoutProperty(child.id, 'visibility', 'none');
  //       }
  //       else {
  //         const vis = this.layerVisibility[child.id];
  //         if (vis != undefined) {
  //           this._mbMap.setLayoutProperty(child.id, 'visibility', vis ? 'visible' : 'none');
  //           if (prevVis !== vis)
  //             this._onLayerVisibilityChanged.trigger(this._mbMap.getLayer(child.id));
  //         }
  //       }
  //     });
  //   }
  //   else
  //     throw new Error(`Cannot find group with ID '${id}'.`);
  // }

  // /**
  //  * Executes location query over supported services (map's location-queriable services) for given coordinate and sends LocationServiceOptions object to given response function.
  //  *
  //  * Response is LocationServiceOptions objects array.
  //  */
  // public locate(coordinate: Coordinate, response: (results: LocationServiceResult[]) => void, options?: LocationServiceOptions) {
  //   options = options || { projection: this._uiProjection };
  //   options.projection = options.projection || this._uiProjection;
  //   const locationServices = this._services.filter(x => (x as any).__is_ILocationService).map(x => (x as any) as ILocationService)
  //     .concat(this.selectLayers(this._layers, x => (x as any).__is_ILocationService).map(x => (x as any) as ILocationService));
  //   const results = new Array<LocationServiceResult>();
  //   locationServices.forEach((x, i) => {
  //     x.locate(coordinate, (r) => { results[i] = r; response(results); }, options);
  //   });
  // }

  // /**
  //  * Executes routing query over routing service for given coordinates and sends RoutingServiceResult object to given response function.
  //  *
  //  * Response is RoutingServiceOptions object.
  //  */
  // public route(coordinates: Coordinate[], response: (result: RoutingServiceResult) => void, options?: RoutingServiceOptions) {
  //   options = options || { projection: this._uiProjection };
  //   options.projection = options.projection || this._uiProjection;
  //   this._routingService.route(coordinates, response, options);
  // }

  // TODO: brez OL funkcij in objektov
  // public getFitCenterAndResolution(geometryOrExtent: ol.Extent | ol.geom.Geometry, options: olx.view.FitGeometryOptions) {
  //     const view = this.olMap.getView();
  //     const viewa = view as any;

  //     let size = options.size;
  //     if (!size)
  //         size = viewa.getSizeFromViewport_();

  //     const rotation = view.getRotation();
  //     let geometry;
  //     if (!(geometryOrExtent instanceof ol.geom.SimpleGeometry))
  //         geometry = ol.geom.Polygon.fromExtent(geometryOrExtent as ol.Extent);
  //     else if (geometryOrExtent.getType() === 'Circle') {
  //         geometryOrExtent = geometryOrExtent.getExtent();
  //         geometry = ol.geom.Polygon.fromExtent(geometryOrExtent);
  //         geometry.rotate(rotation, ol.extent.getCenter(geometryOrExtent));
  //     }
  //     else
  //         geometry = geometryOrExtent;

  //     const padding = options.padding !== undefined ? options.padding : [0, 0, 0, 0];
  //     const constrainResolution = options.constrainResolution !== undefined ? options.constrainResolution : true;
  //     const nearest = options.nearest !== undefined ? options.nearest : false;
  //     let minResolution;
  //     if (options.minResolution !== undefined)
  //         minResolution = options.minResolution;
  //     else if (options.maxZoom !== undefined) {
  //         minResolution = this.olMap.getView().constrainResolution(
  //         viewa.maxResolution_, options.maxZoom - viewa.minZoom_, 0);
  //     }
  //     else 
  //         minResolution = 0;

  //     const coords = geometry.getFlatCoordinates();
  //     // calculate rotated extent
  //     const cosAngle = Math.cos(-rotation);
  //     let sinAngle = Math.sin(-rotation);
  //     let minRotX = +Infinity;
  //     let minRotY = +Infinity;
  //     let maxRotX = -Infinity;
  //     let maxRotY = -Infinity;
  //     const stride = geometry.getStride();
  //     for (let i = 0, ii = coords.length; i < ii; i += stride) {
  //         const rotX = coords[i] * cosAngle - coords[i + 1] * sinAngle;
  //         const rotY = coords[i] * sinAngle + coords[i + 1] * cosAngle;
  //         minRotX = Math.min(minRotX, rotX);
  //         minRotY = Math.min(minRotY, rotY);
  //         maxRotX = Math.max(maxRotX, rotX);
  //         maxRotY = Math.max(maxRotY, rotY);
  //     }

  //     // calculate resolution
  //     let resolution = viewa.getResolutionForExtent([minRotX, minRotY, maxRotX, maxRotY], [size[0] - padding[1] - padding[3], size[1] - padding[0] - padding[2]]);
  //     resolution = isNaN(resolution) ? minResolution : Math.max(resolution, minResolution);
  //     if (constrainResolution) {
  //         let constrainedResolution = view.constrainResolution(resolution, 0, 0);
  //         if (!nearest && constrainedResolution < resolution)
  //             constrainedResolution = view.constrainResolution(constrainedResolution, -1, 0);
  //         resolution = constrainedResolution;
  //     }

  //     // calculate center
  //     sinAngle = -sinAngle; // go back to original rotation
  //     let centerRotX = (minRotX + maxRotX) / 2;
  //     let centerRotY = (minRotY + maxRotY) / 2;
  //     centerRotX += (padding[1] - padding[3]) / 2 * resolution;
  //     centerRotY += (padding[0] - padding[2]) / 2 * resolution;
  //     const centerX = centerRotX * cosAngle - centerRotY * sinAngle;
  //     const centerY = centerRotY * cosAngle + centerRotX * sinAngle;
  //     const center = [centerX, centerY];

  //     return { center: center, resolution: resolution };
  // }

  // private extentGetCenter(extent: ol.Extent) {
  //   return [(extent[0] + extent[2]) / 2, (extent[1] + extent[3]) / 2];
  // }


  public fullscreenGet = (): boolean => {
    var fsc = this.getControl(MapControlType.FullScreen) as maplibregl.FullscreenControl;
    if (fsc) {
      return fsc._isFullscreen() === true;
    }
    return false;
  }
  public fullscreenEnter = (): boolean => {
    var fsc = this.getControl(MapControlType.FullScreen) as maplibregl.FullscreenControl;
    if (fsc) {
      fsc._requestFullscreen();
      return true;
    }
    return false;
  }
  public fullscreenExit = (): boolean => {
    var fsc = this.getControl(MapControlType.FullScreen) as maplibregl.FullscreenControl;
    if (fsc) {
      fsc._exitFullscreen();
      return true;
    }
    return false;
  }

  // mobile click overlay -> fullscreen
  private addMobileClickOverlay = () => {
    if (this.style && this.style.metadata && (this.style.metadata as IStyleMetadata).mobileOverlayEnable === true) {
      var fsc = this.getControl(MapControlType.FullScreen) as maplibregl.FullscreenControl;
      if (fsc) {

        const locService = this.getService(ServiceType.Localization) as LocalizationService;

        const overlayDiv = document.createElement('div');
        overlayDiv.className = "rm2-mobile-overlay d-md-none align-items-center justify-content-center";
        overlayDiv.style.position = 'absolute';
        overlayDiv.style.left = '0';
        overlayDiv.style.top = '0';
        overlayDiv.style.width = '100%';
        overlayDiv.style.height = '100%';
        overlayDiv.style.zIndex = "1000";
        overlayDiv.style.cursor = "pointer";

        let title = (this.style.metadata as IStyleMetadata).mobileOverlayText || "rmap.general.mobileOverlay.title";
        if (locService) {
          title = locService.localize(title);
        }
        var span = document.createElement("span");
        overlayDiv.appendChild(span);
        span.classList.add("rm2-mobile-overlay-text")
        span.innerText = title;

        overlayDiv.onclick = (ev: MouseEvent) => {
          ev.stopPropagation();
          ev.preventDefault();
          this.fullscreenEnter();
        };

        const closeFullscreenContainer = document.createElement('div');
        closeFullscreenContainer.className = "rm2-mobile-overlay-close-container align-items-center justify-content-center";// maplibregl-ctrl-bottom-right";
        closeFullscreenContainer.style.width = 'calc(100% - 140px)';
        closeFullscreenContainer.style.marginLeft = '70px';
        closeFullscreenContainer.style.marginRight = '70px';
        closeFullscreenContainer.style.marginBottom = '10px';
        closeFullscreenContainer.style.pointerEvents = 'none';
        closeFullscreenContainer.style.position = 'absolute';
        closeFullscreenContainer.style.zIndex = '2';
        closeFullscreenContainer.style.right = '0';
        closeFullscreenContainer.style.bottom = 'var(--mapControlsMargin)';

        const closeFullscreenContainer2 = document.createElement('div');
        closeFullscreenContainer.appendChild(closeFullscreenContainer2);
        closeFullscreenContainer2.className = "rm2-mobile-overlay-close-div maplibregl-ctrl maplibregl-ctrl-group";
        closeFullscreenContainer2.style.marginRight = "0";

        const closeFullscreenButton = document.createElement('button');
        closeFullscreenContainer2.appendChild(closeFullscreenButton);
        closeFullscreenButton.className = "rm2-mobile-overlay-close-button"
        closeFullscreenButton.style.width = "inherit";

        let closeText = (this.style.metadata as IStyleMetadata).mobileOverlayCloseText || "rmap.general.mobileOverlay.close";
        if (locService) {
          closeText = locService.localize(closeText);
        }
        closeFullscreenButton.innerText = closeText;
        closeFullscreenButton.onclick = (ev: MouseEvent) => {
          ev.stopPropagation();
          ev.preventDefault();
          this.fullscreenExit();
        }





        let isFullscreen = false;
        const updateMobileOverlay = () => {
          // console.log(`updateMobileOverlay width isFullscreen ${isFullscreen}`);
          if (!isFullscreen) {
            overlayDiv.classList.add("d-flex");
            overlayDiv.style.display = undefined;
            closeFullscreenContainer.style.display = "none";
          }
          else {
            overlayDiv.classList.remove("d-flex");
            overlayDiv.style.display = "none";
            closeFullscreenContainer.style.display = "flex";
          }
        };




        fsc.on("fullscreenstart", () => {
          isFullscreen = true;
          updateMobileOverlay();
        });
        fsc.on("fullscreenend", () => {
          isFullscreen = false;
          updateMobileOverlay();
        });
        updateMobileOverlay();

        const ctrlsContainer = this._mbMap.getContainer().querySelector('.maplibregl-control-container');
        const container = ctrlsContainer && ctrlsContainer.parentNode;
        container && container.insertBefore(overlayDiv, ctrlsContainer);
        // container && container.insertBefore(closeFullscreenContainer, ctrlsContainer);
        ctrlsContainer.appendChild(closeFullscreenContainer);
      }
    }
  }

}

export class QueryResult {
  service: string;
  status: string;
  items: QueryResultItem[];
}

export interface QueryResultItem {
  title: string;
  type: string;
  dateTime: Date;
  projection: Projection;
  location: number[];
  envelope: Envelope;
  geometry: Geometry;
  relevance?: number;
  distance?: number;
}
