export const ContainerTemplate = `
    <div class="d-inline-flex align-items-center w-100 h-100">
        <!-- menu button -->
        <div id="menu-button" class="px-2 cursor-pointer">
            <svg height="20px" width="40px" viewBox="0 -53 384 384" xmlns="http://www.w3.org/2000/svg">
                <path fill="#80828b" d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/>
                <path fill="#80828b" d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/>
                <path fill="#80828b" d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/>
            </svg>
        </div>

        <!-- search entry -->
        <div class="form-group m-0 flex-grow-1">
            <input id="search-input" class="form-control" data-i18n="[placeholder]rmap.rm.routing.search-placeholder" aria-label="Search" style="font-size: 16px;">
            <label for="search-input" class="d-none">Search</label>
        </div>

        <!-- routing button -->
        <div id="routing-button" class="px-2 cursor-pointer">
            <svg version="1.1" width="32px" height="32px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                <path fill="#0F3C8F" d="M502.6,233.3L278.7,9.4c-12.5-12.5-32.8-12.5-45.4,0L9.4,233.3c-12.5,12.5-12.5,32.8,0,45.4l223.9,223.9c12.5,12.5,32.8,12.5,45.4,0l223.9-223.9C515.1,266.1,515.1,245.8,502.6,233.3L502.6,233.3z M401.6,245.9l-84.2,77.7c-5.1,4.7-13.4,1.1-13.4-5.9V264h-96v64c0,4.4-3.6,8-8,8h-32c-4.4,0-8-3.6-8-8v-80c0-17.7,14.3-32,32-32h112v-53.7c0-7,8.3-10.6,13.4-5.9l84.2,77.7C405.1,237.3,405.1,242.7,401.6,245.9L401.6,245.9z" />
            </svg>
        </div>
    </div>
`;
