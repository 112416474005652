export const LegendTemplate = `
    <div class="d-flex flex-column h-100">
        <div class="bg-primary px-2 pb-2 d-flex align-items-end promet-si-legend-header">
            <button id="hide-legend-button" style="background: transparent;" type="button" class="d-flex align-items-center border-0 w-100 ps-5 mb-3">
                <div class="d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="70px" height="24px" fill="white" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                        <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                    </svg>

                    <span class="ms-3 text-white text-lowercase fs-28px" data-i18n="promet-si.legend.title"></span>
                </div>
            </button>
        </div>

        <div id="legend-body" class="flex-grow-1" style="overflow-y: auto;">
            {{#groups}}
                <div class="py-4">
                    {{#layers}}
                        {{#isCheckbox}}
                            <!-- checkbox layerji -->
                            <div class="px-4 promet-si-legend-item">
                                <div class="form-check py-2">
                                    <input class="form-check-input" type="checkbox" id="checkbox-{{id}}" name="{{id}}">
                                    <label class="form-check-label text-lowercase" for="checkbox-{{id}}">
                                        <img src="{{icon}}" class="promet-si-legend-icon" alt="layer icon" width="25px" height="25px">
                                        <span data-i18n="{{title}}"></span>
                                    </label>
                                </div>
                            </div>
                        {{/isCheckbox}}
                        {{^isCheckbox}}
                            <!-- radio button layerji -->
                            {{#children}}
                                <div class="px-4 promet-si-legend-item">
                                    <div class="form-check py-2 d-flex align-items-center">
                                        <input class="form-check-input" type="radio" name="{{_parent.id}}" id="radio-{{id}}" value="{{id}}">
                                        <label class="form-check-label text-lowercase" for="radio-{{id}}">
                                            <div class="d-flex align-items-center">
                                                <div class="border rounded mx-2">
                                                    <img src="{{icon}}" alt="layer icon" width="60px" height="40px">
                                                </div>
                                                <span data-i18n="{{title}}"></span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            {{/children}}
                        {{/isCheckbox}}
                    {{/layers}}
                </div>
            {{/groups}}
        </div>
    </div>
`;
