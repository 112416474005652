// BOOTSTRAP 4
export const FilterTemplate = `
    <div class="bg-white h-100 position-relative">
        <!-- close -->
        <div id="rm2-nap-filter-close" class="position-absolute p-1 close" style="right: 16px; top: 10px; cursor: pointer;"></div>

        <!-- naslov -->
        <div class="title border-bottom p-4 d-flex align-items-center">
            <div class="rm2-nap-filter-title-img mr-2"></div>
            <h6 class="text-black mb-0">filter</h6>
        </div>

        <!-- tabs -->
        <ul class="nav nav-tabs shadow-sm" role="tablist">
            <li class="nav-item d-flex align-items-center w-50" role="presentation">
                <button class="nav-link w-100 active" id="rm2-nap-filter-tabs-promet-tab" data-toggle="tab" data-target="#rm2-nap-filter-tabs-promet" type="button" role="tab" aria-controls="rm2-nap-filter-tabs-promet" aria-selected="true" data-i18n="ncup.filter.traffic.title"></button>
            </li>
            <li class="nav-item d-flex align-items-center w-50" role="presentation">
                <button class="nav-link w-100" id="rm2-nap-filter-tabs-polnilnice-tab" data-toggle="tab" data-target="#rm2-nap-filter-tabs-polnilnice" type="button" role="tab" aria-controls="rm2-nap-filter-tabs-polnilnice" aria-selected="false" data-i18n="ncup.filter.chargingStations"></button>
            </li>
        </ul>
        <div class="tab-content" style="overflow-y: auto;">
            <!-- promet -->
            <div class="tab-pane active" id="rm2-nap-filter-tabs-promet" role="tabpanel" aria-labelledby="rm2-nap-filter-tabs-promet-tab">
                <!-- sloji -->
                <div class="p-4 border-bottom">
                    <div class="font-weight-bold" data-i18n="ncup.filter.traffic.layers"></div>
                    <div class="mt-3 nap-filter-sloji">
                        {{#prometGroups}}
                            {{#layers}}
                                {{#isCheckbox}}
                                    <!-- checkbox layerji -->
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="nap-filter-sloji-{{id}}" name="{{id}}">
                                        <label class="form-check-label w-100" for="nap-filter-sloji-{{id}}">
                                            <div class="d-flex align-items-center">
                                                <img src="{{icon}}" alt="layer icon" class="mr-2" width="25px" height="25px" />
                                                <div data-i18n="{{title}}" class="text-lowercase"></div>
                                            </div>
                                        </label>
                                    </div>
                                {{/isCheckbox}}
                            {{/layers}}
                        {{/prometGroups}}
                    </div>
                </div>

                <!-- prikaz karte -->
                <div class="p-4">
                    <div class="font-weight-bold" data-i18n="ncup.filter.traffic.basemaps"></div>
                    <div class="mt-3 nap-filter-prikaz">
                        {{#prometGroups}}
                            {{#layers}}
                                {{^isCheckbox}}
                                    <!-- radio button layerji -->
                                    {{#children}}
                                        <div class="form-check align-items-center mb-2">
                                            <input class="form-check-input" type="radio" name="{{_parent.id}}" id="nap-filter-prikaz-{{id}}" value="{{id}}">
                                            <label class="form-check-label w-100" for="nap-filter-prikaz-{{id}}">
                                                <div class="d-flex align-items-center">
                                                    <div class="border rounded mr-2">
                                                        <img src="{{icon}}" alt="layer icon" width="60px" height="40px">
                                                    </div>
                                                    <div data-i18n="{{title}}" class="text-lowercase"></div>
                                                </div>
                                            </label>
                                        </div>
                                    {{/children}}
                                {{/isCheckbox}}
                            {{/layers}}
                        {{/prometGroups}}
                    </div>
                </div>
            </div>
            
            <!-- polnilnice -->
            <div class="tab-pane" id="rm2-nap-filter-tabs-polnilnice" role="tabpanel" aria-labelledby="rm2-nap-filter-tabs-polnilnice-tab">
                <!-- obvestilo popup -->
                <div class="d-flex w-100 align-items-center justify-content-center position-relative" style="z-index: 100;">
                    <div class="alert alert-warning alert-dismissible show position-absolute" role="alert" style="left: 15px; top: 15px; right: 15px; padding: 10px; background-color: white; border-width: 2px; border-color: var(--napYellow);">
                        <div class="d-flex flex-column align-items-end">
                            <button type="button" style="padding: 0 !important; border: none;" data-dismiss="alert" aria-label="Close">
                                <div class="nap-close nap-close-dark"></div>
                            </button>
                        </div>

                        <!-- title -->
                        <strong style="color: var(--napYellow); margin-bottom: 5px;" class="mr-auto text-uppercase" data-i18n="ncup.filter.warning.title"></strong>
                        
                        <!-- text -->
                        <div class="mt-1" style="color: var(--bodyText);" data-i18n="ncup.filter.warning.text"></div>
                    </div>
                </div>

                <!-- zasedenost -->
                <div class="p-4 border-bottom">
                    <div class="font-weight-bold" data-i18n="ncup.filter.availability.title"></div>
                    <div class="mt-3">
                        <div class="row">
                            <!-- prosto -->
                            <div class="col-6 py-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-zasedenost-prosto">
                                    <label class="form-check-label" for="nap-filter-zasedenost-prosto" data-i18n="ncup.filter.availability.free"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- moč polnjenja -->
                <div class="p-4 border-bottom">
                    <div class="font-weight-bold" data-i18n="ncup.filter.power"></div>
                    <div class="mt-3">
                        <div id="nap-filter-moc-polnjenja"></div>
                        <div class="d-flex mt-2">
                            <div id="nap-filter-moc-bottom">0 kW</div>
                            <div id="nap-filter-moc-top" class="ml-auto">120 kW</div>
                        </div>
                    </div>
                </div>

                <!-- priključki -->
                <div class="p-4 border-bottom rm2-">
                    <div class="font-weight-bold" data-i18n="ncup.filter.connectors.title"></div>
                    <div class="mt-3">
                        <div class="row g-3">
                            <!-- 3-polni -->
                            <div class="col-6 col-lg-3 py-1 nap-filter-prikljucki">
                                <div class="form-check d-flex align-items-center">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-prikljucki-3-polni">
                                    <label class="form-check-label" for="nap-filter-prikljucki-3-polni">
                                        <div class="d-flex flex-column align-items-center">
                                            <div class="prikljucek prikljucek-3polni"></div>
                                            <small class="text-center" data-i18n="ncup.filter.connectors.3-polni"></small>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <!-- 5-polni -->
                            <div class="col-6 col-lg-3 py-1 nap-filter-prikljucki">
                                <div class="form-check d-flex align-items-center">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-prikljucki-5-polni">
                                    <label class="form-check-label" for="nap-filter-prikljucki-5-polni">
                                        <div class="d-flex flex-column align-items-center">
                                            <div class="prikljucek prikljucek-5polni"></div>
                                            <small class="text-center" data-i18n="ncup.filter.connectors.5-polni"></small>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <!-- schuko -->
                            <div class="col-6 col-lg-3 py-1 nap-filter-prikljucki">
                                <div class="form-check d-flex align-items-center">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-prikljucki-schuko">
                                    <label class="form-check-label" for="nap-filter-prikljucki-schuko">
                                        <div class="d-flex flex-column align-items-center">
                                            <div class="prikljucek prikljucek-schuko"></div>
                                            <small class="text-center" data-i18n="ncup.filter.connectors.schuko"></small>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <!-- type 1 -->
                            <div class="col-6 col-lg-3 py-1 nap-filter-prikljucki">
                                <div class="form-check d-flex align-items-center">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-prikljucki-type-1">
                                    <label class="form-check-label" for="nap-filter-prikljucki-type-1">
                                        <div class="d-flex flex-column align-items-center">
                                            <div class="prikljucek prikljucek-type1"></div>
                                            <small class="text-center" data-i18n="ncup.filter.connectors.type-1"></small>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <!-- type 2 -->
                            <div class="col-6 col-lg-3 py-1 nap-filter-prikljucki">
                                <div class="form-check d-flex align-items-center">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-prikljucki-type-2">
                                    <label class="form-check-label" for="nap-filter-prikljucki-type-2">
                                        <div class="d-flex flex-column align-items-center">
                                            <div class="prikljucek prikljucek-type2"></div>
                                            <small class="text-center" data-i18n="ncup.filter.connectors.type-2"></small>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <!-- CHAdeMO -->
                            <div class="col-6 col-lg-3 py-1 nap-filter-prikljucki">
                                <div class="form-check d-flex align-items-center">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-prikljucki-CHAdeMO">
                                    <label class="form-check-label" for="nap-filter-prikljucki-CHAdeMO">
                                        <div class="d-flex flex-column align-items-center">
                                            <div class="prikljucek prikljucek-chademo"></div>
                                            <small class="text-center" data-i18n="ncup.filter.connectors.CHAdeMO"></small>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <!-- CCS 1 SAE -->
                            <div class="col-6 col-lg-3 py-1 nap-filter-prikljucki">
                                <div class="form-check d-flex align-items-center">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-prikljucki-ccs-1-sae">
                                    <label class="form-check-label" for="nap-filter-prikljucki-ccs-1-sae">
                                        <div class="d-flex flex-column align-items-center">
                                        <div class="prikljucek prikljucek-ccs-1-sae"></div>
                                            <small class="text-center" data-i18n="ncup.filter.connectors.ccs-1-sae"></small>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <!-- CCS 2 SAE -->
                            <div class="col-6 col-lg-3 py-1 nap-filter-prikljucki">
                                <div class="form-check d-flex align-items-center">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-prikljucki-ccs-2-sae">
                                    <label class="form-check-label" for="nap-filter-prikljucki-ccs-2-sae">
                                        <div class="d-flex flex-column align-items-center">
                                        <div class="prikljucek prikljucek-ccs-2-sae"></div>
                                            <small class="text-center" data-i18n="ncup.filter.connectors.ccs-2-sae"></small>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <!-- roadster -->
                            <div class="col-6 col-lg-3 py-1 nap-filter-prikljucki">
                                <div class="form-check d-flex align-items-center">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-prikljucki-roadster">
                                    <label class="form-check-label" for="nap-filter-prikljucki-roadster">
                                        <div class="d-flex flex-column align-items-center">
                                        <div class="prikljucek prikljucek-roadster"></div>
                                            <small class="text-center" data-i18n="ncup.filter.connectors.roadster"></small>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <!-- supercharge -->
                            <div class="col-6 col-lg-3 py-1 nap-filter-prikljucki">
                                <div class="form-check d-flex align-items-center">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-prikljucki-supercharge">
                                    <label class="form-check-label" for="nap-filter-prikljucki-supercharge">
                                        <div class="d-flex flex-column align-items-center">
                                            <div class="prikljucek prikljucek-supercharge"></div>
                                            <small class="text-center" data-i18n="ncup.filter.connectors.supercharge"></small>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- način aktivacije polnjenja -->
                <div class="p-4 border-bottom">
                    <div class="font-weight-bold" data-i18n="ncup.filter.activation.title"></div>
                    <div class="mt-3">
                        <div class="row">
                            <!-- kartica -->
                            <div class="col-6 py-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-nacin-aktivacije-kartica">
                                    <label class="form-check-label" for="nap-filter-nacin-aktivacije-kartica" data-i18n="ncup.filter.activation.card"></label>
                                </div>
                            </div>

                            <!-- aplikacija -->
                            <div class="col-6 py-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-nacin-aktivacije-app">
                                    <label class="form-check-label" for="nap-filter-nacin-aktivacije-app" data-i18n="ncup.filter.activation.app"></label>
                                </div>
                            </div>

                            <!-- SMS -->
                            <div class="col-6 py-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-nacin-aktivacije-sms">
                                    <label class="form-check-label" for="nap-filter-nacin-aktivacije-sms" data-i18n="ncup.filter.activation.sms"></label>
                                </div>
                            </div>

                            <!-- plug & charge -->
                            <div class="col-6 py-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-nacin-aktivacije-pnc">
                                    <label class="form-check-label" for="nap-filter-nacin-aktivacije-pnc" data-i18n="ncup.filter.activation.pnc"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- cena -->
                <div class="p-4 border-bottom">
                    <div class="font-weight-bold" data-i18n="ncup.filter.price.title"></div>
                    <div class="mt-3">
                        <div class="row">
                            <!-- brezplačno -->
                            <div class="col-6 py-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-cena-brezplacno">
                                    <label class="form-check-label" for="nap-filter-cena-brezplacno" data-i18n="ncup.filter.price.free"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- obratovalni čas -->
                <div class="p-4 border-bottom">
                    <div class="font-weight-bold" data-i18n="ncup.filter.open.title"></div>
                    <div class="mt-3">
                        <div class="row">
                            <!-- 24 ur -->
                            <div class="col-6 py-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-obr-cas-24ur">
                                    <label class="form-check-label" for="nap-filter-obr-cas-24ur" data-i18n="ncup.filter.open.24h"></label>
                                </div>
                            </div>

                            <!-- trenutno odprto -->
                            <div class="col-6 py-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-obr-cas-trenutno">
                                    <label class="form-check-label" for="nap-filter-obr-cas-trenutno" data-i18n="ncup.filter.open.currently"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- vrsta vozila -->
                <div class="p-4">
                    <div class="font-weight-bold" data-i18n="ncup.filter.vehicle.title"></div>
                    <div class="mt-3">
                        <div class="row">
                            <!-- avto -->
                            <div class="col-6 py-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-vrsta-avto">
                                    <label class="form-check-label" for="nap-filter-vrsta-avto" data-i18n="ncup.filter.vehicle.car"></label>
                                </div>
                            </div>

                            <!-- kolo, motor, skiro -->
                            <div class="col-6 py-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-vrsta-kolo-motor-skiro">
                                    <label class="form-check-label" for="nap-filter-vrsta-kolo-motor-skiro" data-i18n="ncup.filter.vehicle.kolo-motor-skiro"></label>
                                </div>
                            </div>

                            <!-- avtodom -->
                            <div class="col-6 py-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-vrsta-avtodom">
                                    <label class="form-check-label" for="nap-filter-vrsta-avtodom" data-i18n="ncup.filter.vehicle.avtodom"></label>
                                </div>
                            </div>

                            <!-- tovorno vozilo -->
                            <div class="col-6 py-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="nap-filter-vrsta-tovorno-vozilo">
                                    <label class="form-check-label" for="nap-filter-vrsta-tovorno-vozilo" data-i18n="ncup.filter.vehicle.tovorno"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
`;
