import * as maplibregl from 'maplibre-gl';
import { LayerSpecification } from '@maplibre/maplibre-gl-style-spec';

import { IGeolocationOptions, GeolocationOptions } from './RM2GeolocationOptions';
import { IPanOptions } from '../model/RM2CameraChangeOptions';
import { Coordinate, Point } from '../model/RM2Geometry';
import { Map } from '../map/RM2Map';
import { FeatureCollection, Feature } from '..';
import { appendFileSync } from 'fs';

export class Geolocation {
  private _map: Map;

  private get PrimaryColorPropertyName(): string { return 'primaryColor'; }
  private get SecondaryColorPropertyName(): string { return 'secondaryColor'; }

  private get LocationMarkerScalePropertyName(): string { return 'locationMarkerScale'; }
  private get LocationMarkerOutlineWidthPropertyName(): string { return 'locationMarkerOutlineWidth'; }

  private get HeadingIconImagePropertyName(): string { return 'headingIconImage'; }
  private get HeadingIconScalePropertyName(): string { return 'headingIconScale'; }
  private get HeadingIconRotatePropertyName(): string { return 'headingIconRotate'; }

  private get AccuracyOutlineWidthPropertyName(): string { return 'accuracyOutlineWidth'; }
  private get AccuracyOutlineColorProperyName(): string { return 'accuracyOutlineColor'; }
  private get AccuracyOpacityPropertyName(): string { return 'accuracyOpacity'; }

  constructor(map: Map) {
    this._map = map;

    this.initSources();
    this.initLayers();
  }

  public showGeolocation(pos: RM2GeolocationPosition, opts: IGeolocationOptions) {
    if (pos == undefined || pos == null)
      return;

    const apos = pos as any;
    if (apos && apos.coords ) {
      if (apos.longitude === undefined) apos.longitude = apos.coords.longitude;
      if (apos.latitude === undefined) apos.latitude = apos.coords.latitude;
      if (apos.accuracy === undefined) apos.accuracy = apos.coords.accuracy;
      if (apos.heading === undefined) apos.heading = apos.coords.heading;
    }

    opts = new GeolocationOptions(opts);
    const src = this._map.getSource(this._geolocationSourceName) as maplibregl.GeoJSONSource;
    if (src) {
      this._map.setLayoutProperty(this._headingLayerName, 'visibility', opts.showHeading == true ? 'visible' : 'none');
      this._map.setLayoutProperty(this._accuracyLayerName, 'visibility', opts.showAccuracy == true ? 'visible' : 'none');

      src.setData(new FeatureCollection([this.createGeolocationFeature(pos, opts as GeolocationOptions)]).toGeoJson());
      this.updateAccuracyLayerRadius(pos);
      this.handleCamera(pos, opts);
    }
    else
      throw new Error('Could not find geolocation source when updating.');
  }

  public clearGeolocation() {
    const src = this._map.getSource(this._geolocationSourceName) as maplibregl.GeoJSONSource;
    if (src)
      src.setData(new FeatureCollection().toGeoJson());
  }

  protected isPositionInsideMap(pos: RM2GeolocationPosition) {
    const bounds: maplibregl.LngLatBounds = this._map.getBounds();
    const lng = pos.longitude;
    const lat = pos.latitude;

    return lng < bounds.getEast() && lat < bounds.getNorth() && lng > bounds.getWest() && lat > bounds.getSouth();
  }

  private createGeolocationFeature(pos: RM2GeolocationPosition, opts?: GeolocationOptions): Feature {
    const ft = new Feature();
    ft.properties = { };
    if (pos == null || pos == undefined)
      return ft;
    
    ft.geometry = Point.fromCoordinate(Coordinate.fromOrdinates([pos.longitude, pos.latitude]));
    if (opts == null || opts == undefined)
      opts = new GeolocationOptions();

    ft.properties[this.PrimaryColorPropertyName] = opts.getPrimaryColor();
    ft.properties[this.SecondaryColorPropertyName] = opts.getSecondaryColor();

    ft.properties[this.LocationMarkerScalePropertyName] = opts.markerScale;
    ft.properties[this.LocationMarkerOutlineWidthPropertyName] = 1;

    ft.properties[this.HeadingIconImagePropertyName] = opts.getHeadingIconName();
    ft.properties[this.HeadingIconScalePropertyName] = opts.markerScale;
    ft.properties[this.HeadingIconRotatePropertyName] = pos.heading;

    ft.properties[this.AccuracyOutlineWidthPropertyName] = 1;
    ft.properties[this.AccuracyOutlineColorProperyName] = opts.getSecondaryColor();
    ft.properties[this.AccuracyOpacityPropertyName] = 0.15;

    return ft;
  }

  private updateAccuracyLayerRadius(pos: RM2GeolocationPosition) {
    const accuracyLayer = this._map.getLayer(this._accuracyLayerName) as LayerSpecification;
    if (accuracyLayer) {
      this._map.setPaintProperty(this._accuracyLayerName, 'circle-radius', {
        stops: [
          [0, 0],
          [20, this.metersToPixelsAtMaxZoom(pos.accuracy, pos.latitude)]
        ],
        base: 2
      });
    }
  }

  private metersToPixelsAtMaxZoom = (meters: number, latitude: number): number => meters / 0.075 / Math.cos(latitude * Math.PI / 180);

  protected handleCamera(pos: RM2GeolocationPosition, opts: IGeolocationOptions) {
    let rotateTo = this._map.getView().bearing;
    if (opts.rotate && pos.heading !== undefined && pos.heading != null) rotateTo = pos.heading;

    const panOptions: IPanOptions = {
      zoom: null,
      projection: opts.projection,
      animate: opts.animate,
      relCenterX: opts.relCenterX,
      relCenterY: opts.relCenterY,
      bearing: rotateTo
    };

    const panCoord = Coordinate.fromOrdinates([pos.longitude, pos.latitude]);
    if (opts.pan)
      this._map.pan(panCoord, panOptions);
    else {
      if (opts.panIfNotInView && !this.isPositionInsideMap(pos))
        this._map.pan(panCoord, panOptions);
      else
        this._map.rotate(rotateTo);
    }
  }

  private readonly _geolocationSourceName: string = 'mb-r-geolocation-source';
  private readonly _accuracyLayerName: string = 'mb-r-geolocation-accuracy';
  private readonly _markerInnerCircleLayerName: string = 'mb-r-geolocation-marker-inner';
  private readonly _markerOuterCircleLayerName: string = 'mb-r-geolocation-marker-outer';
  private readonly _headingLayerName: string = 'mb-r-geolocation-heading';
  private initSources() {
      if (this._map.getSource(this._geolocationSourceName) == undefined)
          this._map.addSource(this._geolocationSourceName, { type: 'geojson', data: new FeatureCollection().toGeoJson() });
  }

  private initLayers() {
    if (this._map.getLayer(this._accuracyLayerName) == undefined) {
      const mbLayer: LayerSpecification = {
        'id': this._accuracyLayerName,
        'type': 'circle',
        'source': this._geolocationSourceName,
        'paint': {
          'circle-color': ['get', this.PrimaryColorPropertyName],
          'circle-opacity': ['get', this.AccuracyOpacityPropertyName],
          'circle-stroke-width': ['get', this.AccuracyOutlineWidthPropertyName],
          'circle-stroke-color': ['get', this.AccuracyOutlineColorProperyName],
          'circle-pitch-scale': 'map',
          'circle-pitch-alignment': 'map'
        }
      };

      this._map.addLayer(mbLayer);
    }

    if (this._map.getLayer(this._markerOuterCircleLayerName) == undefined) {
      const mbLayer: LayerSpecification = {
        'id': this._markerOuterCircleLayerName,
        'type': 'circle',
        'source': this._geolocationSourceName,
        'paint': {
          'circle-color': ['get', this.SecondaryColorPropertyName],
          'circle-radius': ['*', ['literal', 10], ['get', this.LocationMarkerScalePropertyName]],
          'circle-stroke-width': 1,
          'circle-stroke-color': ['get', this.PrimaryColorPropertyName],
          'circle-pitch-scale': 'map',
          'circle-pitch-alignment': 'map'
        }
      };

      this._map.addLayer(mbLayer);
    }

    if (this._map.getLayer(this._markerInnerCircleLayerName) == undefined) {
      const mbLayer: LayerSpecification = {
        'id': this._markerInnerCircleLayerName,
        'type': 'circle',
        'source': this._geolocationSourceName,
        'paint': {
          'circle-color': ['get', this.PrimaryColorPropertyName],
          'circle-radius': ['*', ['literal', 7], ['get', this.LocationMarkerScalePropertyName]],
          'circle-pitch-scale': 'map',
          'circle-pitch-alignment': 'map'
        }
      };

      this._map.addLayer(mbLayer);
    }

    if (this._map.getLayer(this._headingLayerName) == undefined) {
      const mbLayer: LayerSpecification = {
        'id': this._headingLayerName,
        'type': 'symbol',
        'source': this._geolocationSourceName,
        'layout': {
          'icon-image': ['get', this.HeadingIconImagePropertyName],
          'icon-size': ['*', ['literal', 0.5], ['get', this.HeadingIconScalePropertyName]],
          'icon-rotate': ['get', this.HeadingIconRotatePropertyName],
          'icon-anchor': 'center',
          'icon-allow-overlap': true,
          'icon-pitch-alignment': 'map',
          'icon-rotation-alignment': 'map'
        }
      };

      this._map.addLayer(mbLayer);
    }
  }
}

export class RM2GeolocationPosition {
  longitude: number;
  latitude: number;
  accuracy: number;
  heading: number;
}
