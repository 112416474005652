export const RouteTabTemplate = `
    <div id="route-tab-container-{{Id}}" class="position-relative bg-white" style="cursor: pointer">
        <div class="w-100 p-4 mx-0 mb-2">
            <!-- podatki o poti -->
            <div class="d-flex">
                <!-- ikona -->
                <div>
                    <img alt="pot" src="assets/icons/car.svg" class="icon-32">
                </div>

                <!-- title, razdalja, tip poti (najhitrejša, najkrajša) -->
                <div class="px-3">
                    <!--<div class="fs-22px text-primary fw-bold mb-1">prek Roške ceste</div>-->
                    <div>
                        <span class="fs-18px">{{Length}}</span>
                        <span class="fs-18px text-muted fst-italic ms-3" data-i18n="rmap.rm.routing.modes.{{Profile}}"><span class="text-red">{{DetourText}}</span></span>
                    </div>

                    {{#Selected}}
                        <!-- prikaži detajle -->
                        <div class="mt-3">
                            <a class="btn btn-sm btn-primary mt-2" type="button" data-bs-toggle="collapse" href="#details-{{Id}}" aria-expanded="false" aria-controls="details-{{Id}}" data-i18n="general.details"></a>
                        </div>
                    {{/Selected}}
                </div>

                <!-- potovalni čas -->
                <div class="ms-auto">
                    <div id="route-tab-tt-badge-{{Id}}" class="badge {{TTClass}}">
                        <img alt="duration" src="assets/icons/potovalni_cas_white.svg" class="icon-32">
                        <span>{{Duration}}</span>
                    </div>

                    <div id="route-tab-tt-spinner-{{Id}}" class="badge badge-primary {{TTSpinnerClass}}">
                        <div class="spinner-border spinner-border-sm text-white align-self-center">
                            <span class="sr-only">
                                Loading ...
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- detajli -->
            <div class="row mt-2 mx-0">
                <div class="col-12">
                    <div class="collapse" id="details-{{Id}}">
                        <hr class="my-5">

                        <div>
                            <!-- start -->
                            <div class="d-inline-flex align-items-center w-100" style="margin-bottom: 20px;">
                                <span class="me-3">
                                    <img alt="start" src="assets/icons/routing_marker_start.svg" class="icon-32">
                                </span>

                                <div>
                                    <div class="fs-24px text-primary fw-bold">
                                        {{Start.properties.Title}}
                                    </div>
                                    <!-- <div class="fs-16px text-muted">{TODO}</div> --> <!-- 1000 Ljubljana -->
                                </div>
                            </div>

                            <!-- vmesne točke -->
                            {{#Instructions}}
                                {{#properties.IsVia}}
                                    <!-- via točka -->
                                    <div data-promet-routing-instruction-coord="{{properties.Coordinate}}" class="d-inline-flex align-items-center w-100" style="margin-bottom: 10px; margin-top: 10px;">
                                        <span class="me-3">
                                            <img alt="via" src="assets/icons/routing_marker_via.svg" class="icon-32">
                                        </span>

                                        <div>
                                            <div class="fs-24px text-primary fw-bold">
                                                {{properties.TitleString}}
                                            </div>
                                            <!-- <div class="fs-16px text-muted">{TODO}</div> --> <!-- 1000 Ljubljana -->
                                        </div>
                                    </div>
                                {{/properties.IsVia}}
                                {{^properties.IsVia}}
                                    <!-- ni via točka -->
                                    <div data-promet-routing-instruction-coord="{{properties.Coordinate}}" class="d-inline-flex ms-3 align-items-center w-100" style="margin-bottom: 10px; margin-top: 0;">
                                        <span class="me-3">
                                            <img alt="point" src="assets/icons/{{properties.Icon}}.svg" width="32px" height="32px">
                                        </span>

                                        <div>
                                            <div style="margin-bottom: -4px;">
                                                <div class="text-muted" style="font-size: 14px;">{{properties.CumulativeLengthString}}, {{properties.CumulativeDurationString}}</div>
                                            </div>
                                            <div style="margin-top: 2px; font-size: 16px;">{{properties.TitleString}}</div>
                                        </div>
                                    </div>
                                {{/properties.IsVia}}
                            {{/Instructions}}

                            <!-- konec -->
                            <div class="d-inline-flex align-items-center w-100" style="margin-top: 15px;">
                                <span class="me-3">
                                    <img alt="end" src="assets/icons/routing_marker_end.svg" class="icon-32">
                                </span>

                                <div>
                                    <div class="fs-24px text-primary fw-bold">
                                        {{Destination.properties.Title}}
                                    </div>
                                    <!-- <div class="fs-16px text-muted">{TODO}</div> --> <!-- 1000 Ljubljana -->
                                </div>
                            </div>
                        </div>

                        <hr class="my-5">

                        <!-- links -->
                        <div class="d-flex flex-wrap align-items-center">
                            <a data-i18n="[href]rmap.rm.routing.links.darsgo.href" target="_blank" rel="noopener noreferrer" style="margin-right: 15px">
                                <span data-i18n="rmap.rm.routing.links.darsgo.title">
                                </span>
                            </a>

                            <a data-i18n="[href]rmap.rm.routing.links.evignettes.href" target="_blank" rel="noopener noreferrer">
                                <span data-i18n="rmap.rm.routing.links.evignettes.title">
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- selected marker -->
        {{#Selected}}
            <div style="width: 6px; top: 0; left: 0; background-color: #B4C7E6 !important;" class="h-100 position-absolute"></div>
        {{/Selected}}
    </div>
`;
