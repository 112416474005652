export class NAPControlOptions implements INAPControlOptions {
    resourcesPath?: string;
    napDcUrl?: string;

    constructor(opts?: INAPControlOptions) {
        this.resourcesPath = opts ? opts.resourcesPath : null;
        this.napDcUrl = opts ? opts.napDcUrl : 'https://www.ncup.si/dc';
    }
}

export interface INAPControlOptions {
    resourcesPath?: string;
    napDcUrl?: string;
}
