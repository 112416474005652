import proj4 from 'proj4';

/**
 * Projection object.
 */
export class Projection {
    private static _projections: { [code: string]: Projection; } = {};

    // private _olProjection: ol.proj.Projection;
    private _definition: string;

    private _crsCode: string;

    /**
     * Creates an instance of Projection.
     */
    public static create(definition: string): Projection {
        try {
            const cp = definition.split(';');
            const definedProjection = Projection._projections[cp[0]];
            if (definedProjection) {
                if (cp.length > 1 && definedProjection._definition !== definition)
                    throw new Error('Projection with same crsCode was already defined: ' + definition);
                return definedProjection;
            }

            if (cp.length > 1)
                proj4.defs(cp[0], cp[1]);

            // // TODO: remove olProjection
            // (ol.proj as any).setProj4(proj4);
            // const olProjection = ol.proj.get(cp[0]);
            // if (cp.length === 3)
            //     olProjection.setExtent(JSON.parse(cp[2]));
            
            const projection = new Projection(definition);
            Projection._projections[cp[0]] = projection;
            return projection;
        }
        catch (ex) {
            throw new Error('Error creating projection: [' + ex.message + ']');
        }
    }

    /**
     * Transforms given coordinate from source projection to target projection.
     */
    public static transform(coordinate: number[], sourceProjection: Projection, targetProjection: Projection): number[] {
        // return (ol.proj as any).transform(coordinate, sourceProjection._olProjection, targetProjection._olProjection);
        return proj4(sourceProjection.crsCode, targetProjection.crsCode, coordinate);
    }

    private constructor(definition: string) {
        // this._olProjection = ol;
        this._definition = definition;
        this._crsCode = definition.split(';')[0];
    }

    /**
     * Returns associated definition.
     */
    public get definition(): string {
        return this._definition;
    }

    /**
     * Returns CRS code of associated projection.
     */
    public get crsCode(): string {
        return this._crsCode;
    }

    // /**
    //  * Returns extent of associated projection. Extent is returned as array of numeric ordinates [minX, minY, maxX, maxY];
    //  */
    // public get extent(): number[] {
    //     return this._olProjection.getExtent();
    // }

    // /**
    //  * Returns meters per unit numeric value for associated projection.
    //  */
    // public get metersPerUnit(): number {
    //     return this._olProjection.getMetersPerUnit();
    // }

    // /**
    //  * Returns name of units for associated projection.
    //  */
    // public get units(): string {
    //     return this._olProjection.getUnits() as string;
    // }

    // /**
    //  * Returns associated projection.
    //  */
    // public get olProjection(): ol.proj.Projection {
    //     return this._olProjection;
    // }
}
