import * as maplibregl from 'maplibre-gl';
import { ExpressionSpecification, LayerSpecification, GeoJSONSourceSpecification } from '@maplibre/maplibre-gl-style-spec';
import proj4 from 'proj4';

import { Projection } from '../model/RM2Projection';
import { Geometry, LineString, Point } from '../model/RM2Geometry';
import { IMarkerOptions, MarkerOptions, IPolygonOptions, PolygonOptions, ILineOptions, LineOptions, IFeatureHighlightOptions, ICircleOptions, CircleOptions, IHighlightOptions, HighlightOptions, FeatureHighlightOptions, HighlightType, LineType } from './RM2HighlightOptions';
import { Observable, Subject } from 'rxjs';
import { Feature, FeatureCollection } from '../model/RM2Feature';
import { Route, RouteFeatureCollection, IRouteHighlightOptions, RouteHighlightOptions, RouteLegacy } from '../services/RM2RoutingService';
import { Map } from '../map/RM2Map';
import { Layer } from '../model/RM2Layer';
import { ILayerMetadata } from '../model/style-metadata/RM2LayerMetadata';

export class Highlights {
  private _map: Map;

  private readonly _highlightsSourceName = 'mb-r-highlights-source';
  private readonly _markerLayerName = 'mb-r-marker-layer';
  private readonly _polygonLayerName = 'mb-r-polygon-layer';
  private readonly _lineSolidLayerName = 'mb-r-line-solid-layer';
  private readonly _lineDottedLayerName = 'mb-r-line-dotted-layer';
  private readonly _lineDashedLayerName = 'mb-r-line-dashed-layer';
  private readonly _circleLayerName = 'mb-r-circle-layer';
  private readonly _layerIds = [
    this._highlightsSourceName,
    this._markerLayerName,
    this._polygonLayerName, 
    this._lineSolidLayerName,
    this._lineDottedLayerName,
    this._lineDashedLayerName,
    this._circleLayerName,
  ]
  private readonly _layerSpecifications: LayerSpecification[] = []

  protected readonly rmFeatureLabel = 'rm_isFeature';

  private _includeInIdentify: boolean = true;
  

  public get featuresAdded(): Observable<Feature[]> { return this._featuresAdded as Observable<Feature[]>; }
  protected _featuresAdded = new Subject<Feature[]>();

  public get featuresRemoved(): Observable<Feature[]> { return this._featuresRemoved as Observable<Feature[]>; }
  protected _featuresRemoved = new Subject<Feature[]>();

  private routeFc: FeatureCollection;
  public get route(): FeatureCollection { return this.routeFc; }

  constructor(map: Map) {
    this._map = map;

    const src: GeoJSONSourceSpecification = { type: 'geojson', data: new FeatureCollection().toGeoJson() };
    this._map.addSource(this._highlightsSourceName, src);
    this.initLayers();
  }

  showMarker(geometry: Geometry, options?: IMarkerOptions) {
    const type = geometry.type.toLowerCase();
    if (type === 'point' || type == 'multipoint') {
      options = new MarkerOptions(options);
      const ft = new Feature(this.getFeaturePropertiesFromHighlightOptions(options as MarkerOptions), geometry);

      this.processData(ft, options);
    }
  }

  clearMarkers() {
    this.clear(item => item && item.properties && item.properties[HighlightOptions.HighlightTypePropertyName].toLowerCase() == HighlightOptions.highlightTypeToString(HighlightType.Marker) && this.isRmFeature(item) === false);
  }

  showPolygon(geometry: Geometry, options?: IPolygonOptions) {
    const type = geometry.type.toLowerCase();
    if (type === 'polygon' || type == 'multipolygon') {
      options = new PolygonOptions(options);
      const ft = new Feature(this.getFeaturePropertiesFromHighlightOptions(options as PolygonOptions), geometry);

      this.processData(ft, options);
    }
  }

  clearPolygons() {
    this.clear(item => item && item.properties && item.properties[HighlightOptions.HighlightTypePropertyName].toLowerCase() == HighlightOptions.highlightTypeToString(HighlightType.Polygon) && this.isRmFeature(item) === false);
  }

  showLine(geometry: Geometry, options?: ILineOptions) {
    const type = geometry.type.toLowerCase();
    if (type === 'linestring' || type === 'multilinestring') {
      options = new LineOptions(options);
      const ft = new Feature(this.getFeaturePropertiesFromHighlightOptions(options as LineOptions), geometry);

      this.processData(ft, options);
    }
  }

  clearLines() {
    this.clear(item => item && item.properties && item.properties[HighlightOptions.HighlightTypePropertyName].toLowerCase() == HighlightOptions.highlightTypeToString(HighlightType.Line) && this.isRmFeature(item) === false);
  }

  showCircle(geometry: Geometry, options?: ICircleOptions) {
    const type = geometry.type.toLowerCase();
    if (type === 'point' || type == 'multipoint') {
      options = new CircleOptions(options);
      const ft = new Feature(this.getFeaturePropertiesFromHighlightOptions(options as CircleOptions), geometry);

      this.processData(ft, options);
    }
  }

  clearCircles() {
    this.clear(item => item && item.properties && item.properties[HighlightOptions.HighlightTypePropertyName].toLowerCase() == HighlightOptions.highlightTypeToString(HighlightType.Circle) && this.isRmFeature(item) === false);
  }

  clearFeature(id: string) {
    const source = this.getSource();
    const data = source.data;
    let found: any;
    let filtered = data.features.filter((item) => {
      if (item.properties.id == id)
        found = item;

      return item.properties.id != id
    });

    data.features = filtered;
    const fc = FeatureCollection.fromMb(data);
    this._map.setSourceData(this._highlightsSourceName, fc);

    if (found)
      this._featuresRemoved.next(found);
  }

  setFeatureValue(ids: string | string[], key: string, value: any) {
    if (Array.isArray(ids)) {
      const source = this.getSource();
      const data = source.data;
      const indices = [];
      data.features.forEach((ft, i) => {
        if (ids.includes(ft.properties.id))
          indices.push(i);
      });

      indices.forEach(index => {
        data.features[index].properties[key] = value;
        // TODO: fire onFeatureChanged event
      });

      const fc = FeatureCollection.fromMb(data);
      this._map.setSourceData(this._highlightsSourceName, fc);
    }
    else {
      const id = ids as string;
      const source = this.getSource();
      const data = source.data;
      const i = data.features.findIndex((item) => item.properties.id == id);
      if (i != -1) {
        data.features[i].properties[key] = value;
        const fc = FeatureCollection.fromMb(data);
        this._map.setSourceData(this._highlightsSourceName, fc);
        // TODO: fire onFeatureChanged event
      }
    }
  }

  clearFeatures(ids?: string[]) {
    if (ids)
      this.clear(item => this.isRmFeature(item) && ids.includes(item.properties.id));
    else
      this.clear(item => this.isRmFeature(item));
  }

  getAllFeatures(): FeatureCollection {
    const source = this.getSource();
    const fts = source.data.features.filter(item => this.isRmFeature(Feature.fromMb(item))).map(item => Feature.fromGeoJson(item));
    return new FeatureCollection(fts);
  }

  transform(fc: FeatureCollection, source: Projection, target: Projection): FeatureCollection {
    // mogoče problem pri veliko Featureih?
    for (let i = 0; i < fc.features.length; i++) {
      const feat = fc.features[i];
      feat.geometry = Geometry.transform(feat.geometry, source, target);
    }

    fc.setCrsCode(target.crsCode);
    return fc;
  }

  addFeaturesToSource(ftColl: FeatureCollection) {
    const source = this.getSource();
    const data = source.data;
    data.features = data.features.concat(ftColl.toGeoJson().features as any);

    const fc = FeatureCollection.fromMb(data);
    this._map.setSourceData(this._highlightsSourceName, fc);
  }

  showRoutes(routes: Route<RouteFeatureCollection>[], options?: IRouteHighlightOptions) {
    options = new RouteHighlightOptions(options);
    this.clearRoute();

    // const data = route.route;
    // let fts: Feature[] = [];
    // const ftStyleFunc = (source: Feature[]) => {
    //   source.forEach((ft, i) => {
    //     let arr: HighlightOptions[];
    //     if (i === 0)
    //       arr = options.startPointStyle.slice();
    //     else if (i === source.length - 1)
    //       arr = options.endPointStyle.slice();
    //     else
    //       arr = options.viaPointStyle.slice();

    //     arr.forEach(s => fts.push(new Feature(s, ft.geometry)));
    //   });
    // };

    // if (data) {
    //   fts = data.features.slice();
    //   // fts.forEach(ft => ft.setProperties(options.routeStyle[0]));

    //   // to prikaže prvo in zadnjo točno na route-i
    //   const instructions = data.properties.Instructions;
    //   const points = instructions.features.filter(ft => ft.properties.Type === 'instruction-start' || ft.properties.Type === 'instruction-via' || ft.properties.Type === 'instruction-end').map(x => new Feature(x.properties, x.geometry));
    //   ftStyleFunc(points);
    // }

    // // če ni točk na poti (če je postavljena samo 1 točka) to prikaže točno tiste točke, prek katerih se je route-a query-ala
    // if (fts.length === 0) {
    //   if (route.points)
    //     ftStyleFunc(route.points.map(x => new Feature(null, Point.fromCoordinate(x))));
    // }





    var lines: Feature[] = [];
    var wayPoints: Feature[] = [];
    // var labels = new FeatureCollection<IRMapViewLabelFeatureProperties, object>();
    // var events = new FeatureCollection<object, object>();
    for (var i = 0; i < routes.length; i++)
    {
        var route = routes[i];
        // var label = route.Label;
        // if (i == selected)
        // {
        //     wayPoints = routes[selected].WayPoints;
        //     events.features = routes.SelectMany(r => r.Events.features).ToList();
        // }

        // if (label.properties != null && label.geometry != null)
        // {
        //     label.properties.SortKey = i;
        //     labels.features.Add(label);
        // }

        const instructions = route.route.properties.Instructions.features.filter(ft => ft.properties.Type === 'instruction-start' || ft.properties.Type === 'instruction-via' || ft.properties.Type === 'instruction-end');
        instructions.forEach((x, i) => {
          let arr: HighlightOptions[];
          if (i === 0)
            arr = options.startPointStyle.slice();
          else if (i === instructions.length - 1)
            arr = options.endPointStyle.slice();
          else
            arr = options.viaPointStyle.slice();
  
          arr.forEach(s => wayPoints.push(new Feature(s, x.geometry)));
        });

        lines.push(...(route.routeSegments || route.route).features.slice());
    }

    // SetSourceData(RouteLineStringSource, lines);
    // SetSourceData(RoutePointSource, wayPoints);
    // SetSourceData(RouteLabelsSource, JsonConvert.SerializeObject(labels)); // TODO: brez serializacije

    // if (events != null && events.features.Count > 0)
    // {
    //     IoC.Get<ILayersService>().SetGroupsOpacity(MapStyle.Metadata.Groups.Select(x => x.Id), 0.5f);
    //     SetSourceData(RouteEventSource, events);
    // }

    const src = new FeatureCollection<object, object>(lines.concat(wayPoints));
    this._map.setSourceData(Map.routeSourceName, src);

    if (options.pan)
      this.processPan(src, new FeatureHighlightOptions({ cameraOptions: options.cameraOptions }));





    // const fc = new FeatureCollection(fts);
    // this._map.setSourceData(this.routeSourceName, fc);
    // this.processPan(fc, new FeatureHighlightOptions({ cameraOptions: options.cameraOptions }));
  }

  showRoute(route: Route<RouteFeatureCollection>, options?: IRouteHighlightOptions) {
    options = new RouteHighlightOptions(options);
    this.clearRoute();

    const data = route.route;
    let fts: Feature[] = [];
    const ftStyleFunc = (source: Feature[]) => {
      source.forEach((ft, i) => {
        let arr: HighlightOptions[];
        if (i === 0)
          arr = options.startPointStyle.slice();
        else if (i === source.length - 1)
          arr = options.endPointStyle.slice();
        else
          arr = options.viaPointStyle.slice();

        arr.forEach(s => fts.push(new Feature(s, ft.geometry)));
      });
    };

    if (data) {
      fts = data.features.slice();
      // fts.forEach(ft => ft.setProperties(options.routeStyle[0]));

      // to prikaže prvo in zadnjo točno na route-i
      const instructions = data.properties.Instructions;
      const points = instructions.features.filter(ft => ft.properties.Type === 'instruction-start' || ft.properties.Type === 'instruction-via' || ft.properties.Type === 'instruction-end').map(x => new Feature(x.properties, x.geometry));
      ftStyleFunc(points);
    }

    // če ni točk na poti (če je postavljena samo 1 točka) to prikaže točno tiste točke, prek katerih se je route-a query-ala
    if (fts.length === 0) {
      if (route.points)
        ftStyleFunc(route.points.map(x => new Feature(null, Point.fromCoordinate(x))));
    }

    const fc = new FeatureCollection(fts);
    this._map.setSourceData(Map.routeSourceName, fc);

    if (options.pan)
      this.processPan(fc, new FeatureHighlightOptions({ cameraOptions: options.cameraOptions }));
    // fts.forEach(ft => ft.setField('includeInIdentify', false));
    // this.routeFc = this.showFeatures(new FeatureCollection(fts), { cameraOptions: options.cameraOptions });
  }

  showRouteLegacy(route: RouteLegacy, options?: IRouteHighlightOptions) {
    options = new RouteHighlightOptions(options);
    this.clearRoute();

    let fts: Feature[] = [];
    
    // waypoints
    const points = route.Data.features.map(x => new Feature(x.properties, Geometry.fromGeoJson(x.geometry)));
    points.forEach((ft, i) => {
      let arr: HighlightOptions[];
      if (i === 0)
        arr = options.startPointStyle.slice();
      else if (i === route.Data.length - 1)
        arr = options.endPointStyle.slice();
      else
        arr = options.viaPointStyle.slice();

      arr.forEach(s => {
        const item = new Feature(s, ft.geometry);
        item.setField('includeInIdentify', false);
        const coords = item.geometry.getCoordinate();
        const coordsTransformed = proj4('EPSG:3912', this._map.projection.crsCode, coords);
        item.geometry.coordinates = [coordsTransformed.x, coordsTransformed.y];
        fts.push(item);
      });
    });

    // linestrings
    const geom = LineString.fromWkt('LINESTRING(' + route.Routing.LineStrings.join(',') + ')') as LineString;
    const coords = geom.coordinates.map(x => proj4('EPSG:3912', this._map.projection.crsCode, x));
    geom.coordinates = coords;
    fts.push(new Feature({
      Color: '#0F3C8F',
      includeInIdentify: false
    }, geom));

    const fc = new FeatureCollection();
    fc.features = fts;
    this._map.setSourceData(Map.routeSourceName, fc);

    if (options.pan)
      this.processPan(fc, new FeatureHighlightOptions({ cameraOptions: options.cameraOptions }));
  }

  clearRoute() {
    const s = this._map.getSource(Map.routeSourceName);
    if (s) {
      this._map.setSourceData(Map.routeSourceName, new FeatureCollection());
    }
  }

  showFeature(ft: Feature, opts?: IFeatureHighlightOptions): Feature {
    const options = this.processFeature(ft, opts);
    if (options) {
      this.processData(ft, options);
      this._featuresAdded.next([ft]);
      return ft;
    }
    return null;
  }

  showFeatures(fts: Feature[] | FeatureCollection, opts?: IFeatureHighlightOptions): FeatureCollection {
    if (Array.isArray(fts))
      fts = new FeatureCollection(fts);

    opts = new FeatureHighlightOptions(opts);
    for (let i = 0; i < fts.features.length; i++)
      this.processFeature(fts.features[i], opts);

    this.processData(fts, opts);
    this._featuresAdded.next(fts.features.slice());
    return fts;
  }

  clearAll() {
    this.clearMarkers();
    this.clearPolygons();
    this.clearLines();
    this.clearCircles();
    this.clearFeatures();
  }

  protected getFeaturePropertiesFromHighlightOptions(options: HighlightOptions, ft?: Feature): any {
    if (options instanceof MarkerOptions)
      return new MarkerOptions(options as IMarkerOptions).applyOptionsToFeatureProperties(ft);
    else if (options instanceof PolygonOptions)
      return new PolygonOptions(options as IPolygonOptions).applyOptionsToFeatureProperties(ft);
    else if (options instanceof LineOptions)
      return new LineOptions(options as ILineOptions).applyOptionsToFeatureProperties(ft);
    else if (options instanceof CircleOptions)
      return new CircleOptions(options as ICircleOptions).applyOptionsToFeatureProperties(ft);
  }

  protected processData(ft: Feature | FeatureCollection, options: IHighlightOptions) {
    if (ft instanceof Feature)
      ft = new FeatureCollection([ft]);

    if (options.projection) {
      if (options.projection.crsCode !== this._map.projection.crsCode)
        this.transform(ft, options.projection, this._map.projection);
    }
    else {
      const fcCrs = ft.getCrsCode();
      if (fcCrs && fcCrs !== this._map.projection.crsCode)
        this.transform(ft, Projection.create(fcCrs), this._map.projection);
    }

    this.processPan(ft, options);
    this.addFeaturesToSource(ft);
  }

  protected setAsRmFeature(ft: Feature) {
    ft.setField(this.rmFeatureLabel, true);
  }

  protected isRmFeature(ft: Feature): boolean {
    if (ft && ft.properties)
      return ft.getField(this.rmFeatureLabel) === true;
    return true;
  }

  private processFeature(ft: Feature, opts?: IFeatureHighlightOptions): IHighlightOptions {
    const type = ft.geometry.type.toLowerCase();
    this.setAsRmFeature(ft);
    if (type == 'point' || type == 'multipoint') {
      if (ft.getField(CircleOptions.CircleRadiusPropertyName) != undefined) { // TODO: na lepši način
        const circleOptions = new CircleOptions();
        circleOptions.applyFeatureHighlightOptions(opts);

        ft.properties = this.getFeaturePropertiesFromHighlightOptions(circleOptions, ft);
        return circleOptions;
      }
      else {
        const markerOptions = new MarkerOptions();
        markerOptions.applyFeatureHighlightOptions(opts);

        ft.properties = this.getFeaturePropertiesFromHighlightOptions(markerOptions, ft);
        return markerOptions;
      }
    }
    else if (type == 'polygon' || type == 'multipolygon') {
      const polygonOptions = new PolygonOptions();
      polygonOptions.applyFeatureHighlightOptions(opts);

      ft.properties = this.getFeaturePropertiesFromHighlightOptions(polygonOptions, ft);
      return polygonOptions;
    }
    else if (type == 'linestring' || type == 'multilinestring') {
      const lineOptions = new LineOptions();
      lineOptions.applyFeatureHighlightOptions(opts);

      ft.properties = this.getFeaturePropertiesFromHighlightOptions(lineOptions, ft);
      return lineOptions;
    }

    return null;
  }

  private clear(predicate: (item: any) => boolean = null) {
    const source = this.getSource();
    if (predicate == null)
      predicate = (item) => this.isRmFeature(item) === false;

    const filtered = source.data.features.filter((item) => predicate(Feature.fromMb(item)) === false);
    source.data.features = filtered;
    const fc = FeatureCollection.fromMb(source.data);
    this._map.setSourceData(this._highlightsSourceName, fc);
  }

  private getSource(): { src: maplibregl.GeoJSONSource, data: GeoJSON.FeatureCollection } {
    const src = this._map.getSource(this._highlightsSourceName);
    return { src: src as maplibregl.GeoJSONSource, data: src['_data'] };
  }

  private initLayers() {
    const zIndex = 0;
    // const style = this._map.getStyle();
    const metadata = this._map.metadata;
    let topLayer: Layer;
    // find the first layer that has a higher z-index
    if (metadata && metadata.groups) {
      topLayer = this._map.getLayer(l => {
        if (l.metadata) {
          const groupName = l.metadata.gid;
          if (groupName && groupName in metadata.groups && (metadata.groups[groupName].zIndex === -1 || metadata.groups[groupName].zIndex < zIndex))
            return true;
        }
        return false;
      });
    }

    const defaultPolygon = new PolygonOptions();
    const polygonMbLayer: LayerSpecification = {
      'id': this._polygonLayerName,
      'type': 'fill',
      'source': this._highlightsSourceName,
      'metadata': { includeInIdentify: this._includeInIdentify },
      'layout': {
        // 'text-field': ['get', 'text'],
        // 'text-field': 'test',
        // 'text-font': ['Noto Sans Italic']
        // 'fill-sort-key': ['get', 'zindex']
      },
      'paint': {
        'fill-color': this.getProperty(PolygonOptions.FillColorPropertyName, defaultPolygon.fillColor),
        'fill-opacity': this.getProperty(PolygonOptions.FillOpacityPropertyName, defaultPolygon.fillOpacity),
        'fill-outline-color': this.getProperty(PolygonOptions.OutlineColorPropertyName, defaultPolygon.outlineColor)
      },
      'filter': ['==', HighlightOptions.HighlightTypePropertyName, HighlightOptions.highlightTypeToString(HighlightType.Polygon)]
    };
    this._map.addLayer(polygonMbLayer, topLayer ? topLayer.id : null);
    this._layerSpecifications.push(polygonMbLayer);

    const defaultSolidLine = new LineOptions();
    const solidLineMbLayer: LayerSpecification = {
      'id': this._lineSolidLayerName,
      'type': 'line',
      'source': this._highlightsSourceName,
      'metadata': { includeInIdentify: this._includeInIdentify },
      'layout': {
        // 'line-join': ['get', 'lineJoin'],
        // 'line-cap': ['get', 'lineCap']
        // 'text-field': ['get', 'text'],
        // 'text-field': 'test',
        // 'text-font': ['Noto Sans Italic'],
        // 'line-sort-key': ['get', 'zindex'],
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-color': this.getProperty(LineOptions.LineColorPropertyName, defaultSolidLine.lineColor),
        'line-opacity': this.getProperty(LineOptions.LineOpacityPropertyName, defaultSolidLine.lineOpacity),
        'line-width': this.getProperty(LineOptions.LineWidthPropertyName, defaultSolidLine.lineWidth)
      },
      'filter': [
        'all',
        ['==', HighlightOptions.HighlightTypePropertyName, HighlightOptions.highlightTypeToString(HighlightType.Line)],
        ['==', LineOptions.LineTypePropertyName, LineOptions.lineTypeToString(LineType.Solid)]
      ]
    };
    this._map.addLayer(solidLineMbLayer, topLayer ? topLayer.id : null);
    this._layerSpecifications.push(solidLineMbLayer);

    const defaultDottedLine = new LineOptions();
    const dottedLineMbLayer: LayerSpecification = {
      'id': this._lineDottedLayerName,
      'type': 'line',
      'source': this._highlightsSourceName,
      'metadata': { includeInIdentify: this._includeInIdentify },
      'layout': {
        // 'line-join': ['get', 'lineJoin'],
        // 'line-cap': ['get', 'lineCap']
        // 'text-field': ['get', 'text'],
        // 'text-field': 'test',
        // 'text-font': ['Noto Sans Italic'],
        'line-join': 'round',
        'line-cap': 'round',
        'line-round-limit': 0
      },
      'paint': {
        'line-color': this.getProperty(LineOptions.LineColorPropertyName, defaultDottedLine.lineColor),
        'line-opacity': this.getProperty(LineOptions.LineOpacityPropertyName, defaultDottedLine.lineOpacity),
        'line-width': 8,
        // 'line-dasharray': [0.01, 2]
        'line-dasharray': [0, 2]
      },
      'filter': [
        'all',
        ['==', HighlightOptions.HighlightTypePropertyName, HighlightOptions.highlightTypeToString(HighlightType.Line)],
        ['==', LineOptions.LineTypePropertyName, LineOptions.lineTypeToString(LineType.Dotted)]
      ]
    };
    this._map.addLayer(dottedLineMbLayer, topLayer ? topLayer.id : null);
    this._layerSpecifications.push(dottedLineMbLayer);

    const defaultDashedLine = new LineOptions();
    const dashedLineMbLayer: LayerSpecification = {
      'id': this._lineDashedLayerName,
      'type': 'line',
      'source': this._highlightsSourceName,
      'metadata': { includeInIdentify: this._includeInIdentify },
      'layout': {
        // 'line-join': ['get', 'lineJoin'],
        // 'line-cap': ['get', 'lineCap']
        // 'text-field': ['get', 'text'],
        // 'text-field': 'test',
        // 'text-font': ['Noto Sans Italic'],
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-color': this.getProperty(LineOptions.LineColorPropertyName, defaultDashedLine.lineColor),
        'line-opacity': this.getProperty(LineOptions.LineOpacityPropertyName, defaultDashedLine.lineOpacity),
        'line-width': this.getProperty(LineOptions.LineWidthPropertyName, defaultDashedLine.lineWidth),
        'line-dasharray': [2, 2]
      },
      'filter': [
        'all',
        ['==', HighlightOptions.HighlightTypePropertyName, HighlightOptions.highlightTypeToString(HighlightType.Line)],
        ['==', LineOptions.LineTypePropertyName, LineOptions.lineTypeToString(LineType.Dashed)]
      ]
    };
    this._map.addLayer(dashedLineMbLayer, topLayer ? topLayer.id : null);
    this._layerSpecifications.push(dashedLineMbLayer);

    const defaultCircle = new CircleOptions();
    const circleMbLayer: LayerSpecification = {
      'id': this._circleLayerName,
      'type': 'circle',
      'source': this._highlightsSourceName,
      'metadata': { includeInIdentify: this._includeInIdentify },
      'paint': {
        'circle-color': this.getProperty(CircleOptions.CircleColorPropertyName, defaultCircle.color),
        'circle-opacity': this.getProperty(CircleOptions.CircleOpacityPropertyName, defaultCircle.opacity),
        'circle-radius': this.getProperty(CircleOptions.CircleRadiusPropertyName, defaultCircle.radius),
        'circle-stroke-color': this.getProperty(CircleOptions.OutlineColorPropertyName, defaultCircle.outlineColor),
        'circle-stroke-width': this.getProperty(CircleOptions.OutlineWidthPropertyName, defaultCircle.outlineWidth)
      },
      'filter': ['==', HighlightOptions.HighlightTypePropertyName, HighlightOptions.highlightTypeToString(HighlightType.Circle)]
    }
    this._map.addLayer(circleMbLayer, topLayer ? topLayer.id : null);
    this._layerSpecifications.push(circleMbLayer);

    const defaultMarker = new MarkerOptions();
    const markerMbLayer: LayerSpecification = {
      'id': this._markerLayerName,
      'type': 'symbol',
      'source': this._highlightsSourceName,
      'metadata': { includeInIdentify: this._includeInIdentify },
      'layout': {
        'icon-image': this.getProperty(MarkerOptions.IconPropertyName, defaultMarker.icon),
        'icon-anchor': this.getProperty(MarkerOptions.AnchorPropertyName, defaultMarker.anchor),
        // 'icon-offset': this.getProperty(MarkerOptions.OffsetPropertyName, defaultMarker.offset), // nekaj narobe z offset expressioni
        'icon-offset': this.getProperty(MarkerOptions.OffsetPropertyName, defaultMarker.offset, true),
        'icon-size': this.getProperty(MarkerOptions.SizePropertyName, defaultMarker.size),
        'icon-allow-overlap': true,
        'symbol-sort-key': this.getProperty(MarkerOptions.ZIndexKeyPropertyName, defaultMarker.zindex)
      },
      'filter': ['==', HighlightOptions.HighlightTypePropertyName, HighlightOptions.highlightTypeToString(HighlightType.Marker)]
    };
    this._map.addLayer(markerMbLayer, topLayer ? topLayer.id : null);
    this._layerSpecifications.push(markerMbLayer);
  }

  private processPan(ft: FeatureCollection, options: IHighlightOptions) {
    if (options) {
      if (options.pan === true) {
        options.cameraOptions.projection = null;
        this._map.fitCameraToFeatures(ft, options.cameraOptions);
      }

      if ('centroid' in options)
        this.showMarker(options['centroid'] as Geometry, new MarkerOptions({ pan: false }));
    }
  }

  private getProperty(property: string, def: any, addLiteral?: boolean): ExpressionSpecification {
    const defExpr = addLiteral ? ['literal', def] : def;
    return [
      'case',
      ['all', ['has', property], ['!=', ['get', property], null]], ['get', property],
      defExpr
    ];
  }

  public setIncludeInIdentify (includeInIdentify: boolean) {
    this._includeInIdentify = includeInIdentify;
    this._layerSpecifications.forEach(ls => {
      if (ls.metadata)
        (ls.metadata as ILayerMetadata).includeInIdentify = includeInIdentify;
    })
  }
}
