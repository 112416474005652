export class LayerMetadata implements ILayerMetadata {
    gid: string;
    title: string;
    icon: string;
    visible: boolean;
    uiVisible: boolean;
    lockMode: string[];
    includeInIdentify: boolean;

    visibleRefreshButton: boolean;

    infoWindowEnableOpenButton: boolean;
    infoWindowTitleTemplate: string;
    infoWindowDescriptionTemplate: string;

    listViewDefaultExpand: boolean;
    listViewEnableOpenButton: boolean;
    listViewTitleTemplate: string;
    listViewDescriptionTemplate: string;
    infoWindowDescriptionRefreshInterval?: number;

    infoWindowMaxWidth: string;

    // Clustering properties
    clusterSymbol: string;
    clusterSymbolIconSize: number;
    clusterSymbolTextSize: number;
    clusterSymbolTextOffset: number[];
    clusterSymbolTextColor: string;
    // clusterType: 'circle' | 'symbol';
    // clusterStyle: CircleClusterStyle | SymbolClusterStyle;

    constructor(metadata: ILayerMetadata) {
        this.gid = metadata.gid;
        this.title = metadata.title;
        this.icon = metadata.icon;
        this.visible = metadata.visible !== false;
        this.uiVisible = metadata.uiVisible;
        this.lockMode = metadata.lockMode || [];
        this.includeInIdentify = metadata.includeInIdentify === true;

        this.visibleRefreshButton = metadata.visibleRefreshButton !== false;
        this.listViewDefaultExpand = metadata.listViewDefaultExpand === true;

        this.listViewEnableOpenButton = metadata.listViewEnableOpenButton === true ? true : false;
        this.listViewTitleTemplate = metadata.listViewTitleTemplate || `
            {{#Title}}
                {{#translateKey}}$.Title{{/translateKey}}
            {{/Title}}

            {{^Title}}
                {{#title}}
                    {{#translateKey}}$.title{{/translateKey}}
                {{/title}}
            {{/Title}}
        `;

        this.listViewDescriptionTemplate = metadata.listViewDescriptionTemplate || `
            {{#Description}}
                {{#translateKey}}$.Description{{/translateKey}}
            {{/Description}}

            {{^Description}}
                {{#description}}
                    {{#translateKey}}$.description{{/translateKey}}
                {{/description}}
            {{/Description}}
        `;

        this.infoWindowEnableOpenButton = metadata.infoWindowEnableOpenButton === true ? true : this.listViewEnableOpenButton;
        this.infoWindowTitleTemplate = metadata.infoWindowTitleTemplate || `
            <div class='d-flex-inline align-items-center w-100'>
                <!--<img class='rm2-popup-content-img mr-1 my-0' src='{{Icon}}' alt='{{Title}}'>-->
                <span class='mb-0 font-weight-bold'>{{Title}}</span>
            </div>
            <hr class='mt-0 my-2' style='height: 1px'>
        `;

        this.infoWindowDescriptionTemplate = metadata.infoWindowDescriptionTemplate || `
            <p class='mb-0'>
                {{Description}}
            </p>
        `;

        this.infoWindowMaxWidth = metadata.infoWindowMaxWidth || '320px';
        this.infoWindowDescriptionRefreshInterval = metadata.infoWindowDescriptionRefreshInterval;

        this.clusterSymbol = metadata.clusterSymbol || 'dogodek_cluster-32';
        this.clusterSymbolIconSize = metadata.clusterSymbolIconSize;
        this.clusterSymbolTextSize = metadata.clusterSymbolTextSize;
        this.clusterSymbolTextOffset = metadata.clusterSymbolTextOffset;
        this.clusterSymbolTextColor = metadata.clusterSymbolTextColor;
        // this.clusterType = metadata.clusterType || 'circle';
        // if (this.clusterType === 'circle')
        //     this.clusterStyle = new CircleClusterStyle(metadata.clusterStyle as CircleClusterStyle);
        // else if (this.clusterType === 'symbol')
        //     this.clusterStyle = new SymbolClusterStyle(metadata.clusterStyle as SymbolClusterStyle);
    }
}

export interface ILayerMetadata {
    gid?: string;
    title?: string;
    icon?: string;
    visible?: boolean;
    uiVisible?: boolean;
    lockMode?: string[];
    includeInIdentify?: boolean;

    visibleRefreshButton?: boolean;

    infoWindowEnableOpenButton?: boolean;
    infoWindowTitleTemplate?: string;
    infoWindowDescriptionTemplate?: string;

    listViewDefaultExpand?: boolean;
    listViewEnableOpenButton?: boolean;
    listViewTitleTemplate?: string;
    listViewDescriptionTemplate?: string;
    infoWindowDescriptionRefreshInterval?: number;

    infoWindowMaxWidth?: string;

    // Clustering properties
    clusterSymbol?: string;
    clusterSymbolIconSize?: number;
    clusterSymbolTextSize?: number;
    clusterSymbolTextOffset?: number[];
    clusterSymbolTextColor?: string;
    // clusterType: 'circle' | 'symbol';
    // clusterStyle: CircleClusterStyle | SymbolClusterStyle;
}

export abstract class ClusterStyle {
    showText: boolean;
    textColor: string;

    constructor(style?: ClusterStyle) {
        this.textColor = '#222222';
        this.showText = true;

        if (style) {
            Object.keys(this).forEach(k => {
                if (style[k] != undefined)
                    this[k] = style[k];
            });
        }
    }
}

export class CircleClusterStyle extends ClusterStyle {
    circleColor: string;
    circleOutlineColor: string;
    circleOutlineWidth: number;
    circleOpacity: number;
    circleRadius: number;

    constructor(style?: CircleClusterStyle) {
        super(style);

        this.circleColor = '#FFFFFF';
        this.circleOutlineColor = '#000000';
        this.circleOutlineWidth = 2;
        this.circleOpacity = 1;
        this.circleRadius = 12;

        if (style) {
            Object.keys(this).forEach(k => {
                if (style[k] != undefined)
                    this[k] = style[k];
            });
        }
    }
}

export class SymbolClusterStyle extends ClusterStyle {
    iconImage: string;
    iconAnchor: 'left' | 'top' | 'right' | 'bottom' | 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left' | 'center';
    iconSize: number;

    constructor(style?: SymbolClusterStyle) {
        super(style);

        this.iconImage = 'zaprta-140';
        this.iconAnchor = 'center';
        this.iconSize = 0.2;

        if (style) {
            Object.keys(this).forEach(k => {
                if (style[k] != undefined)
                    this[k] = style[k];
            });
        }
    }
}
