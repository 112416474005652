import { IService, ServiceType } from './RM2Service';
import { CoordinateLike, Geometry, Envelope, Coordinate } from '../model/RM2Geometry';
import { FeatureCollection } from '../model/RM2Feature';
import { QueryResult, QueryResultItem, Projection } from '..';

export class NominatimServiceOptions implements INominatimServiceOptions {
    queryUrl?: string;
    query_v2Url?: string;
    query_v3Url?: string; // nov geocoding za Promet+
    reverseGeocodingUrl?: string;
    GeocodeLocQueryUrl?: string;
    GeocodeLocReverseUrl?: string;
    projection?: string;
    requestsTimeoutMs?: number;

    constructor(opts?: INominatimServiceOptions) {
        this.projection = 'EPSG:4326';
        this.requestsTimeoutMs = 5000;

        if (opts) {
            if (opts.queryUrl != undefined)
                this.queryUrl = opts.queryUrl;
            
            if (opts.query_v2Url != undefined)
                this.query_v2Url = opts.query_v2Url;
        
            if (opts.query_v3Url != undefined)
                this.query_v3Url = opts.query_v3Url;

            if (opts.reverseGeocodingUrl != undefined)
                this.reverseGeocodingUrl = opts.reverseGeocodingUrl;

            if (opts.GeocodeLocQueryUrl != undefined)
                this.GeocodeLocQueryUrl = opts.GeocodeLocQueryUrl;

            if (opts.GeocodeLocReverseUrl != undefined)
                this.GeocodeLocReverseUrl = opts.GeocodeLocReverseUrl;
            
            if (opts.projection != undefined)
                this.projection = opts.projection;
            
            if (opts.requestsTimeoutMs != undefined)
                this.requestsTimeoutMs = opts.requestsTimeoutMs;
        }
    }
}

export interface INominatimServiceOptions {
    queryUrl?: string;
    query_v2Url?: string;
    query_v3Url?: string; // nov geocoding za Promet+
    reverseGeocodingUrl?: string;
    GeocodeLocQueryUrl?: string;
    GeocodeLocReverseUrl?: string;
    projection?: string;
    requestsTimeoutMs?: number;
}

export class QueryOptions implements IQueryOptions {
    pageSize?: number;
    page?: number;
    lang?: string;

    constructor(opts?: IQueryOptions) {
        this.pageSize = 5;
        this.page = 0;

        if (opts) {
            if (opts.pageSize != undefined)
                this.pageSize = opts.pageSize;
            
            if (opts.page != undefined)
                this.page = opts.page;
            
            if (opts.lang != undefined)
                this.lang = opts.lang;
        }
    }
}

export interface IQueryOptions {
    pageSize?: number;
    page?: number;
    lang?: string;
}

export class ReverseGeocodingOptions implements IReverseGeocodingOptions {
    pageSize?: number;

    constructor(opts?: IQueryOptions) {
        this.pageSize = 5;

        if (opts) {
            if (opts.pageSize != undefined)
                this.pageSize = opts.pageSize;
        }
    }
}

export interface IReverseGeocodingOptions {
    pageSize?: number;
}

export class NominatimService implements IService {

    public readonly type = ServiceType.Nominatim;
    public readonly name: string;
    public readonly options: INominatimServiceOptions;
    public readonly enabled: boolean = true;

    public constructor(name: string, options: INominatimServiceOptions) {
        this.name = name;
        this.options = new NominatimServiceOptions(options);
    }

    public reverse(coordinate: CoordinateLike, options?: IReverseGeocodingOptions): Promise<FeatureCollection<ReverseGeocodingFeatureProperties, any>> {
        return new Promise<FeatureCollection>((res, rej) => {
            if (this.options.reverseGeocodingUrl) {
                options = new ReverseGeocodingOptions(options);
                const url = `${this.options.reverseGeocodingUrl}?x=${coordinate[0]}&y=${coordinate[1]}&pagesize=${options.pageSize}`;
                
                const xhr = new XMLHttpRequest();
                xhr.timeout = this.options.requestsTimeoutMs;
                xhr.onload = () => {
                    if (xhr.responseText)
                        res(FeatureCollection.fromGeoJson(xhr.responseText));
                    else
                        res(null);
                };
    
                xhr.ontimeout = () => rej('Timeout');
                xhr.onerror = () => rej('Error getting location');
                xhr.onabort = () => rej('HTTP request aborted.');
                // xhr.onloadend = () => {
                //     this.currentHttpRequest = null;
                //     this.setLoadingStatus(false);
                // };
        
                // this.currentHttpRequest = xhr;
                xhr.open('GET', url, true);
                xhr.send(null);
            }
            else
                rej('No reverse geocoding URL provided in map picker options(\'reverseGeocodingUrl\').');
        });
    }

    public query(query: string, options?: IQueryOptions): Promise<QueryResult> {
        return new Promise<QueryResult>((res, rej) => {
            options = new QueryOptions(options);
            const xhr = new XMLHttpRequest();
            xhr.timeout = this.options.requestsTimeoutMs;
            xhr.onload = () => {
                if (!xhr.status || xhr.status >= 200 && xhr.status < 300) {
                    const r = JSON.parse(xhr.responseText);
                    let items: QueryResultItem[] = [];
                    r.forEach((ft: any) => {
                        items.push({
                            title: ft.display_Name || ft.display_name,
                            type: (ft.class || ft.category) + "-" + ft.type,
                            dateTime: new Date(),
                            projection: Projection.create(this.options.projection),
                            location: [ft.lon, ft.lat],
                            envelope: Envelope.fromNumArray(ft.boundingBox || ft.boundingbox),
                            geometry: Geometry.fromWkt(ft.geoText || ft.geotext),
                            relevance: ft.importance
                        });
                    });
                    
                    // TODO: nek default transformer
                    res({
                        service: this.name,
                        status: 'OK',
                        items: items
                    });
                }
            };
    
            xhr.ontimeout = () => rej('Timeout');
            xhr.onerror = () => rej('Error loading location data for \'' + query + '\'.');
            xhr.open('GET', `${this.options.queryUrl}/search?&polygon_text=1&limit=${options.pageSize}&q=${query}`, true);
            xhr.send(null);
        });
    }

    public query_v2(query: string, options?: IQueryOptions): Promise<FeatureCollection<Query_v2ResponseFeatureProperties, Query_v2ResponseFeatureCollectionProperties>> {
        return new Promise<FeatureCollection>((res, rej) => {
            options = new QueryOptions(options);
            const xhr = new XMLHttpRequest();
            xhr.timeout = this.options.requestsTimeoutMs;
            xhr.onload = () => {
                if (!xhr.status || xhr.status >= 200 && xhr.status < 300) {
                    const fc = FeatureCollection.fromGeoJson(xhr.responseText);
                    res(fc);
                }
            };
    
            xhr.ontimeout = () => rej('Timeout');
            xhr.onerror = () => rej('Error loading location data for \'' + query + '\'.');
            xhr.open('GET', `${this.options.queryUrl}?condition=${query}&pagesize=${options.pageSize}&page=${options.page}`, true);
            xhr.send(null);
        });
    }

    public query_v3(query: string): Promise<FeatureCollection<Query_v3ResponseFeatureProperties, any>> {
        return new Promise<FeatureCollection>((res, rej) => {
            const xhr = new XMLHttpRequest();
            xhr.timeout = this.options.requestsTimeoutMs;
            xhr.onload = () => {
                if (!xhr.status || xhr.status >= 200 && xhr.status < 300) {
                    const fc = FeatureCollection.fromGeoJson(xhr.responseText);
                    res(fc);
                }
            };

            const body = new Query_v3Request({
                query: query
            } as IQuery_v3Request);
    
            xhr.ontimeout = () => rej('Timeout');
            xhr.onerror = () => rej('Error loading location data for \'' + query + '\'.');
            xhr.open('POST', `${this.options.query_v3Url}`, true);
            xhr.send(JSON.stringify(body));
        });
    }

    public reverse_v3(coord: CoordinateLike): Promise<FeatureCollection<Query_v3ResponseFeatureProperties, any>> {
        return new Promise<FeatureCollection>((res, rej) => {
            const xhr = new XMLHttpRequest();
            xhr.timeout = this.options.requestsTimeoutMs;
            xhr.onload = () => {
                if (!xhr.status || xhr.status >= 200 && xhr.status < 300) {
                    const fc = FeatureCollection.fromGeoJson(xhr.responseText);
                    res(fc);
                }
            };

            const body = new Query_v3Request({
                pageSize: 1,
                x: coord[0],
                y: coord[1],
            } as IQuery_v3Request);

            xhr.ontimeout = () => rej('Timeout');
            xhr.onerror = () => rej('Error fetching reverse geocoding for \'' + JSON.stringify(coord) + '\'.');
            xhr.open('POST', `${this.options.query_v3Url}`, true);
            xhr.send(JSON.stringify(body));
        });
    }

    public GeocodeLoc(query: string, options?: IQueryOptions): Promise<FeatureCollection<GeocodeLocFeatureProperties, GeocodeLocFeatureCollectionProperties>> {
        return new Promise<FeatureCollection<GeocodeLocFeatureProperties, GeocodeLocFeatureCollectionProperties>>((res, rej) => {
            if (!this.options.GeocodeLocQueryUrl)
                rej('No GeocodeLocQueryUrl provided');
            
            options = new QueryOptions(options);
            const xhr = new XMLHttpRequest();
            xhr.timeout = this.options.requestsTimeoutMs;
            xhr.onload = () => {
                if (!xhr.status || xhr.status >= 200 && xhr.status < 300) {
                    const r = JSON.parse(xhr.responseText);

                    const fc = FeatureCollection.fromGeoJson(r);
                    res(fc);
                }
            };
    
            xhr.ontimeout = () => rej('Timeout');
            xhr.onerror = () => rej(`Error loading location data for '${query}'.`);
            xhr.open('GET', `${this.options.GeocodeLocQueryUrl}?query=${encodeURIComponent(query)}&take=${options.pageSize}&skip=${options.pageSize * options.page}`, true);
            xhr.send(null);
        });
    }

    public GeocodeLocReverse(coordinate: Coordinate | CoordinateLike, options?: IReverseGeocodingOptions): Promise<FeatureCollection<GeocodeLocFeatureProperties, GeocodeLocFeatureCollectionProperties>> {
        return new Promise<FeatureCollection<GeocodeLocFeatureProperties, GeocodeLocFeatureCollectionProperties>>((res, rej) => {
            if (!this.options.GeocodeLocReverseUrl)
                rej('No GeocodeLocReverseUrl provided');
            
            if (coordinate instanceof Coordinate)
                coordinate = [coordinate.x, coordinate.y];
            
            options = new ReverseGeocodingOptions(options);
            const xhr = new XMLHttpRequest();
            xhr.timeout = this.options.requestsTimeoutMs;
            xhr.onload = () => {
                if (!xhr.status || xhr.status >= 200 && xhr.status < 300) {
                    const r = JSON.parse(xhr.responseText);

                    const fc = FeatureCollection.fromGeoJson(r);
                    res(fc);
                }
            };
    
            const x = coordinate[0];
            const y = coordinate[1];
            xhr.ontimeout = () => rej('Timeout');
            xhr.onerror = () => rej(`Error loading location data for '${x}, ${y}'.`);
            xhr.open('GET', `${this.options.GeocodeLocReverseUrl}?x=${x}&y=${y}`, true);
            xhr.send(null);
        });
    }
}

export interface Query_v2ResponseFeatureProperties {
    Id: number;
    Title: string;
    Type: Query_v2LocationType;
    Distance: number;
}

export interface Query_v2ResponseFeatureCollectionProperties {
    Name: number;
    Page: number;
    NextPage: boolean;
}

export type Query_v2LocationType = 'Naselje' | 'Ulica' | 'Poi' | 'Postaja';

export interface IQuery_v3Request {
    query: string;
    pageSize: number;
    page: number;
    dataSource: string;
    ModelVersion: number;
    dist?: number;
    x?: number;
    y?: number;
    InCrs: string;
    OutCrs: string;
}

export class Query_v3Request implements IQuery_v3Request {
    query: string;
    pageSize: number;
    page: number;
    dataSource: string;
    ModelVersion: number;
    dist?: number;
    x?: number;
    y?: number;
    InCrs: string;
    OutCrs: string;

    constructor(req?: IQuery_v3Request) {
        this.query = req && req.query ? req.query : undefined;
        this.pageSize = req && req.pageSize ? req.pageSize : 10;
        this.page = req && req.page != undefined ? req.page : 0;
        this.dataSource = req && req.dataSource ? req.dataSource : undefined;
        this.ModelVersion = req && req.ModelVersion != undefined ? req.ModelVersion :1;

        this.dist = req && req.dist != undefined ? req.dist : undefined;
        this.x = req && req.x != undefined ? req.x : undefined;
        this.y = req && req.y != undefined ? req.y : undefined;

        this.InCrs = req && req.InCrs ? req.InCrs : "EPSG:4326";
        this.OutCrs = req && req.OutCrs ? req.OutCrs : "EPSG:4326";
    }
}

export interface Query_v3ResponseFeatureProperties {
    Title: string;
    Description: string;
    Icon: string;
    Id: string;
    ContentName: string;
    IconAnchor: number[];
    IsFromXY?: boolean;
    Odsek: string;
    Stacionaza?: number;
    Cesta: Query_v3ResponseFeaturePropertiesCesta;
    Ad: Query_v3ResponseFeaturePropertiesAd;
}

export interface Query_v3ResponseFeaturePropertiesCesta
{
    ftype: string;
    descr: string;
    bvx?: number;
    bvy?: number;
    bvr?: number;
    dist?: number;
    ds_field: string;
    ds_at?: number;
    odsek: string;
    cesta: string;
    kategorija: string;
}

export interface Query_v3ResponseFeaturePropertiesAd
{
    ftype: string;
    descr: string;
    bvx?: number;
    bvy?: number;
    bvr?: number;
    dist?: number;
}

export interface ReverseGeocodingFeatureProperties {
    Id?: number;
    Title?: string;
    Type?: string;
}

export interface ReverseGeocodingProperties {
    Name: number;
    Page: number;
    NextPage: boolean;
}

export interface GeocodeLocFeatureProperties {
    Sid: number;
    Title: string;
    Description: string;
    Type: GeocodeLocLocationType;
    TypedId: string;
    gc_cesta: Query_v3ResponseFeaturePropertiesCesta;
}

export type GeocodeLocLocationType = 'kucni_brojevi' | 'jls' | 'dars_mp' | 'dars_parking' | 'dars_predori' | 'dars_prk' | 'poi' | 'rpe_na' | 'rpe_ul' | 'stavbe_naslovi';

export interface GeocodeLocFeatureCollectionProperties {
    Take: number;
    Skip: number;
    HasNext: boolean;
    OutCrs: string;
    Types: any;
}
