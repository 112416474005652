import * as maplibregl from 'maplibre-gl';
import { Map } from '../../..';
import { LocalizationService } from '../../../services/RM2LocalizationService';
import { ServiceType } from '../../../services/RM2Service';

export class PrometSiExpandMapControl implements maplibregl.IControl {

    private _localizationService: LocalizationService;
    private _a: HTMLAnchorElement;

    constructor(map: Map) {
        this._localizationService = map.getService(ServiceType.Localization) as LocalizationService;
    }
    
    onAdd(map: maplibregl.Map): HTMLElement {
        const wrapper = document.createElement('div');
        wrapper.className = 'maplibregl-ctrl maplibregl-ctrl-group';

        this._a = document.createElement('a');
        this._a.href = `/${this._localizationService.getLanguage()}/map`;
        
        const btn = document.createElement('button');
        btn.type = 'button';
        const icon = document.createElement('span');
        icon.className = 'promet-si-expand-map-icon maplibregl-ctrl-icon';

        this._a.appendChild(btn);
        btn.appendChild(icon);
        wrapper.appendChild(this._a);

        return wrapper;
    }

    onRemove(map: maplibregl.Map): void {
        this._a.parentNode.removeChild(this._a);
    }

    getDefaultPosition() : maplibregl.ControlPosition {
        return 'bottom-right';
    }
};
