import { Projection } from '../model/RM2Projection';
import { Coordinate, CoordinateLike, Geometry } from '../model/RM2Geometry';
import { IService } from './RM2Service';
import { ServiceType } from './RM2Service';
import { Feature, FeatureCollection } from '../model/RM2Feature';
import { LineOptions, CircleOptions, HighlightOptions } from '../highlights/RM2HighlightOptions';
import { RouteStatusRequestDTO, RouteStatusResponseDTO } from '../model/RouteStatus';
import { IFitOptions } from '../model/RM2CameraChangeOptions';
import { v4 } from 'uuid';
import { Query_v3ResponseFeatureProperties } from './RM2NominatimService';
import { Position } from '@turf/helpers';
import proj4 from 'proj4';

export type RouteFormat = '' | 'v1';

export class RouteOptions implements IRouteOptions {
    projection?: Projection;
    format?: RouteFormat;
    mode?: PathMode;

    constructor(opts?: IRouteOptions) {
        this.projection = opts && opts.projection != undefined ? opts.projection : Projection.create('EPSG:4326');
        this.format = opts && opts.format != undefined ? opts.format : '';
        this.mode = opts && opts.mode != undefined ? opts.mode : PathMode.Fastest;
    }
}

export interface IRouteOptions {
    projection?: Projection;
    format?: RouteFormat;
    mode?: PathMode;
}

export class RouteHighlightOptions implements IRouteHighlightOptions {
    routeStyle?: HighlightOptions[];
    startPointStyle?: HighlightOptions[];
    viaPointStyle?: HighlightOptions[];
    endPointStyle?: HighlightOptions[];
    stationStyle?: HighlightOptions[];

    pan?: boolean;
    cameraOptions?: IFitOptions;

    constructor(opts?: IRouteHighlightOptions) {
        this.routeStyle = opts && opts.routeStyle != undefined ? opts.routeStyle : [new LineOptions({
            lineColor: '#59A0DD',
            lineOpacity: 0.8
        })];

        this.startPointStyle = opts && opts.startPointStyle != undefined ? opts.startPointStyle : [new CircleOptions({
            color: '#FFFFFF',
            radius: 5,
            outlineColor: '#000000',
            outlineWidth: 2
        })];

        this.viaPointStyle = opts && opts.viaPointStyle != undefined ? opts.viaPointStyle : this.startPointStyle;

        this.endPointStyle = opts && opts.endPointStyle != undefined ? opts.endPointStyle : [
            new CircleOptions({
                color: '#FFFFFF',
                radius: 5,
                outlineColor: '#000000',
                outlineWidth: 2
            }),
            new CircleOptions({
                color: '#000000',
                outlineWidth: 0,
                radius: 3
            })
        ];

        this.stationStyle = opts && opts.stationStyle != undefined ? opts.stationStyle : [new CircleOptions({
            color: '#59A0DD',
            radius: 5,
            outlineWidth: 2,
            outlineColor: '#FFFFFF'
        })];

        this.pan = opts && opts.pan != undefined ? opts.pan : false;
        this.cameraOptions = opts && opts.cameraOptions != undefined ? opts.cameraOptions : undefined;
    }

    public static fromGeoJson(geoJson: any): RouteHighlightOptions {
        const routeStyles: HighlightOptions[] = [];
        if (geoJson.routeStyle)
            geoJson.routeStyle.forEach(r => routeStyles.push(HighlightOptions.fromGeoJson(r)));

        const startPointStyles: HighlightOptions[] = [];
        if (geoJson.startPointStyle)
            geoJson.startPointStyle.forEach(r => startPointStyles.push(HighlightOptions.fromGeoJson(r)));

        const viaPointStyles: HighlightOptions[] = [];
        if (geoJson.viaPointStyle)
            geoJson.viaPointStyle.forEach(r => viaPointStyles.push(HighlightOptions.fromGeoJson(r)));

        const endPointStyles: HighlightOptions[] = [];
        if (geoJson.endPointStyle)
            geoJson.endPointStyle.forEach(r => endPointStyles.push(HighlightOptions.fromGeoJson(r)));

        const stationStyles: HighlightOptions[] = [];
        if (geoJson.stationStyle)
            geoJson.stationStyle.forEach(r => stationStyles.push(HighlightOptions.fromGeoJson(r)));

        return new RouteHighlightOptions({
            routeStyle: routeStyles.length > 0 ? routeStyles : null,
            startPointStyle: startPointStyles.length > 0 ? startPointStyles : null,
            viaPointStyle: viaPointStyles.length > 0 ? viaPointStyles : null,
            endPointStyle: endPointStyles.length > 0 ? endPointStyles : null,
            stationStyle: stationStyles.length > 0 ? stationStyles : null,
            cameraOptions: geoJson.cameraOptions
        });
    }
}

export interface IRouteHighlightOptions {
    routeStyle?: HighlightOptions[];
    startPointStyle?: HighlightOptions[];
    viaPointStyle?: HighlightOptions[];
    endPointStyle?: HighlightOptions[];
    stationStyle?: HighlightOptions[];

    pan?: boolean;
    cameraOptions?: IFitOptions;
}

export class RoutingServiceOptions implements IRoutingServiceOptions {
    url: string;
    urlv1: string;
    urlMultiple: string;
    urlRouteStatus: string;
    projection?: string;
    requestsTimeoutMs?: number;

    constructor(opts?: IRoutingServiceOptions) {
        this.url = opts && opts.url != undefined ? opts.url : undefined;
        this.urlv1 = opts && opts.urlv1 != undefined ? opts.urlv1 : undefined;
        this.urlMultiple = opts && opts.urlMultiple != undefined ? opts.urlMultiple : undefined;
        this.urlRouteStatus = opts && opts.urlRouteStatus != undefined ? opts.urlRouteStatus : undefined;
        this.projection = opts && opts.projection != undefined ? opts.projection : 'EPSG:3912;+title=y/x:D48/GK +proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=-5000000 +ellps=bessel +towgs84=426.9,142.6,460.1,4.91,4.49,-12.42,17.1 +units=m +no_defs';
        this.requestsTimeoutMs = opts && opts.requestsTimeoutMs != undefined ? opts.requestsTimeoutMs : 15000;
    }
}

export interface IRoutingServiceOptions {
    url: string;
    urlv1: string;
    urlMultiple: string;
    urlRouteStatus: string;
    projection?: string;
    requestsTimeoutMs?: number;
}

export interface Route<T = any> {
    points: CoordinateLike[]; // EPSG:4326
    pointsFt?: Feature<Query_v3ResponseFeatureProperties>[]; // EPSG:3912
    route: T; // originalna pot s servisa
    routeSegments?: FeatureCollection; // spremenjena pot na klientu na nivo segmentov za risanje na zemljevidu
}

export class RouteFeatureCollection extends FeatureCollection<RouteFeatureProperties, RouteMetaProperties> { }

// Routing v1
export interface RouteFeatureProperties {
    Odsek: string;
    StacFrom: number;
    StacTo: number;
    RoadName: string;
    FunctionRoadClass: RFunctionalRoadClass;
    FormOfWay: RFormOfWay;
    EdgeId: number;
    EdgeFractionFrom: number;
    EdgeFractionTo: number;
    SourceDataItemFractionFrom: number;
    SourceDataItemFractionTo: number;
    SegmentIds: number[];
    FirstSegmentFractionFrom: number;
    LastSegmentFractionTo: number;
    RouteFractionFrom: number;
    RouteFractionTo: number;
    OptimalTTMs: number;
    SpeedLimitTTMs: number;
    JamThresholdTTMs: number;
    RealTTMs: number;
    SegmentLengthMM: number;
}

export class RouteInstructionsFeatureCollection extends FeatureCollection<RouteInstructionsFeatureProperties, RouteInstructionsMetaProperties> { }

export interface RouteMetaProperties {
    Id: string;
    MapId: number;
    Version: string;
    Mode: PathMode;
    Length: number;
    OptimalTTMs: number;
    SpeedLimitTTMs: number;
    RealTTMs: number;
    IsDetour: boolean;
    Instructions: RouteInstructionsFeatureCollection;
}

export interface RouteInstructionsFeatureProperties {
    Type: string;
    Odsek: string;
    Stacionaza: number;
    RouteFraction: number;
    Title: string;
    TitleTranslations: any;
    Length: number;
    Duration: number;
    CumulativeLength: number;
    CumulativeDuration: number;
}

export interface RouteInstructionsMetaProperties { }

export enum PathMode {
    Dynamic = -1,
    Shortest = 0,
    Fastest = 1
}

export enum RFunctionalRoadClass {
    /// <summary>
    /// Main road
    /// </summary>
    Frc0 = 0,
    /// <summary>
    /// First class road
    /// </summary>
    Frc1 = 1,
    /// <summary>
    /// Second class road
    /// </summary>
    Frc2 = 2,
    /// <summary>
    /// Third class road
    /// </summary>
    Frc3 = 3,
    /// <summary>
    /// Fourth class road
    /// </summary>
    Frc4 = 4,
    /// <summary>
    /// Fifth class road
    /// </summary>
    Frc5 = 5,
    /// <summary>
    /// Sixth class road
    /// </summary>
    Frc6 = 6,
    /// <summary>
    /// Other class road
    /// </summary>
    Frc7 = 7
}

export enum RFormOfWay {
    /// <summary>
    /// The physical road type is unknown.
    /// </summary>
    Undefined = 0,
    /// <summary>
    /// A Motorway is defined as a road permitted for motorized vehicles only in combination with a prescribed minimum speed. It has two or more physically separated carriageways and no single level-crossings.
    /// </summary>
    Motorway = 1,
    /// <summary>
    /// A multiple carriageway is defined as a road with physically separated carriageways regardless of the number of lanes. If a road is also a motorway, it should be coded as such and not as a multiple carriageway.
    /// </summary>
    MultipleCarriageWay = 2,
    /// <summary>
    /// All roads without separate carriageways are considered as roads with a single carriageway.
    /// </summary>
    SingleCarriageWay = 3,
    /// <summary>
    /// A Roundabout is a road which forms a ring on which traffic traveling in only one direction is allowed.
    /// </summary>
    Roundabout = 4,
    /// <summary>
    /// A Traffic Square is an open area (partly) enclosed by roads which is used for non-traffic purposes and which is not a Roundabout.
    /// </summary>
    TrafficSquare = 5,
    /// <summary>
    /// A Slip Road is a road especially designed to enter or leave a line.
    /// </summary>
    SlipRoad = 6,
    /// <summary>
    /// Other.
    /// </summary>
    Other = 7
}

/// Routing v0
export interface RouteSimple {
    version: string;
    mode: string;
    translations: string;
    length: number;
    duration: number;
    crsCode: string;
    geometry: string;
    segments: RouteSimpleSegment;
    stations: RouteSimpleStation[];
    properties: RouteSimpleProperties;
}

export class RouteSimpleStation {
    type: string;
    title: string;
    location: CoordinateLike;
    fraction: number;
    length: number;
    duration: number;
    cumulativeLength: number;
    cumulativeDuration: number;
    properties: any;
}

export class RouteSimpleSegment {
    sourceId: number;
    roadName: string;
    functionalRoadClass: number;
    formOfWay: number;
    key: string;
    fromStation: number;
    toStation: number;
    fromFraction: number;
    toFraction: number;
    fromLength: number;
    toLength: number;
    fromDuration: number;
    toDuration: number;
    startAzimuth: number;
    endAzimuth: number;
    geometry: string; // WKT
    roadDescription: string;
    roadCategory: string;
    road: string;
}

export class RouteSegmentProperties {
    SegmentId: number;
    OptimalTTMs: number;
    SpeedLimitTTMs: number;
    JamTresholdTTMs: number;
    FractionFrom: number;
    FractionTo: number;
    LengthMM: number;
}

export interface RouteSimpleProperties {
    sloToll: number[];
}

export interface RouteLegacy {
    ContentName: string;
    Data: FeatureCollection<Query_v3ResponseFeatureProperties, any>;
    HumanContentName: string;
    Icon: string;
    Id: string;
    LastModified: string;
    Notifications: any[];
    Routing: RouteLegacyRouting;
    Title: string;
}

export interface RouteLegacyRouting {
    ContentName: string;
    Items: any[];
    Language: string;
    LineStrings: string[];
    PathDescription: any[];
    PathGeometryDescription: any[];
    RoadCategories: any[];
    RouteLength: number;
    RouteLengths: number[];
    RoutingVersion: number;
    Timestamp: string;
    TotalCost: number[];
    TravelTime: number;
    TravelTimeStr: string;
    TravelTimes: number[];
}

export class RouteMultipleResponse {
    Routes: Route<RouteFeatureCollection>[];

    public static fromResponse(json: string, locations: CoordinateLike[]): RouteMultipleResponse {
        const geoJson = JSON.parse(json);
        const routes: Route<RouteFeatureCollection>[] = [];

        if (Array.isArray(geoJson.Routes)) {
            for (let i = 0; i < geoJson.Routes.length; i++) {
                const route: RouteFeatureCollection = RouteFeatureCollection.fromGeoJson(geoJson.Routes[i]);
                route.properties.Instructions = RouteInstructionsFeatureCollection.fromGeoJson(route.properties.Instructions);
                route.properties.Id = v4();
                routes.push({
                    points: locations.slice(),
                    route: route
                });
            }
        }

        const res = new RouteMultipleResponse();
        res.Routes = routes;
        return res;
    }
}

export class RoutingService implements IService {

    public readonly type = ServiceType.Routing;
    public readonly name: string;

    public get options(): RoutingServiceOptions { return this._options; }
    private _options: RoutingServiceOptions;

    public readonly enabled: boolean = true;

    public constructor(name: string, options: IRoutingServiceOptions) {
        this.name = name;
        this._options = new RoutingServiceOptions(options);
    }

    public setOptions(options: IRoutingServiceOptions) {
        if (options)
            this._options = new RoutingServiceOptions(options);
    }

    public async route(locations: CoordinateLike[], options?: IRouteOptions): Promise<Route<RouteSimple>> {
        if (!this._options.url)
            throw new Error('No \'url\' parameter specified in routing options.');

        const locs = locations.map(loc => `${loc[1]},${loc[0]}`);
        // const url = `${this.options.url}&format=${options.format}&loc=${locs.join(encodeURIComponent(';'))}`;
        const url = `${this._options.url}&format=simple&loc=${locs.join(encodeURIComponent(';'))}`;

        return this.routingRequest(url, (res) => {
            // TODO: transformiraj geometrijo iz route
            let response: RouteSimple = JSON.parse(res);

            const serviceProjection = Projection.create(this._options.projection);
            const points = locations.slice();
            // const points = locations.map(loc => {
            //     const coord = Geometry.transform(Point.fromCoordinate(loc), options.projection, serviceProjection).getCoordinate();
            //     return [coord.x, coord.y] as CoordinateLike;
            // });

            return {
                points: points,
                route: response
            };
        }, options);
    }

    // transforms route to legacy promet route (za PrometSiControl)
    public async transformRoute(route: Route<RouteFeatureCollection>, title?: string): Promise<Route<RouteLegacy>> {
        if (!this._options.url)
            throw new Error('No \'url\' parameter specified in routing options.');

        let profile = PathMode[route.route.properties.Mode].toString().toLowerCase();
        if (profile !== 'shortest' && profile != 'fastest')
            profile = 'fastest';

        // const url = `${this._options.url}&format=simple&loc=${locs.join(encodeURIComponent(';'))}&profile=${profile}`;
        const url = 'https://www.promet.si/dc/routing'; // TODO: v config
        return new Promise<Route<RouteLegacy>>(async (res, rej) => {
            try {
                // const points = route.pointsFt.map(x => {
                //     const ft = x.clone();
                //     const coords = ft.geometry.coordinates as Position;
                //     const coordsTransformed = proj4('EPSG:4326', 'EPSG:3912', coords);
                //     ft.geometry.coordinates = coordsTransformed;
                //     return ft;
                // });
                
                const body = {
                    typeOfRoute: profile,
                    Points: route.route.properties.Instructions.features.filter(ft => {
                        const type = ft.properties.Type;
                        return type === 'instruction-start' || type === 'instruction-via' || type === 'instruction-end';
                    }).map(ft => {
                        return {
                            Odsek: ft.properties.Odsek,
                            Stacionaza: ft.properties.Stacionaza
                        }
                    }) // tukaj dodati Id property?
                };
                const routeStr = await this.postRequest(url, body);

                if (routeStr) {
                    const r: RouteLegacy = JSON.parse(routeStr);
                    r.Id = route.route.properties.Id || v4();

                    const first = route.pointsFt[0];
                    const last = route.pointsFt[route.pointsFt.length - 1];
                    r.Title = title || `${first.properties.Title} - ${last.properties.Title}`;

                    res({
                        points: route.points.map(x => x.map(y => y)),
                        pointsFt: route.pointsFt.map(x => x.clone()),
                        route: r
                    } as Route<RouteLegacy>);
                }
                else
                    rej('404');
            }
            catch (e) {
                rej(e);
            }
        })
    }

    public async routev1(locations: CoordinateLike[], options?: IRouteOptions): Promise<Route<RouteFeatureCollection>> {
        if (!this._options.urlv1)
            throw new Error('No \'urlv1\' parameter specified in routing options.');

        options = new RouteOptions(options);
        const locs = locations.map(loc => `${loc[1]},${loc[0]}`);
        const char = this._options.urlv1.includes('?');
        const url = `${this._options.urlv1}${char ? '&' : '?'}loc=${locs.join(encodeURIComponent(';'))}&profile=${PathMode[options.mode].toLowerCase()}`;

        return this.routingRequest(url, (res) => {
            // TODO: transformiraj geometrijo iz route
            let response: RouteFeatureCollection = RouteFeatureCollection.fromGeoJson(res);
            response.properties.Instructions = RouteInstructionsFeatureCollection.fromGeoJson(response.properties.Instructions);
            response.properties.Mode = options.mode;
            response.properties.Id = v4();

            const serviceProjection = Projection.create(this._options.projection);
            const points = locations.slice();
            // const points = locations.map(loc => {
            //     const coord = Geometry.transform(Point.fromCoordinate(loc), options.projection, serviceProjection).getCoordinate();
            //     return [coord.x, coord.y] as CoordinateLike;
            // });

            return {
                points: points,
                route: response
            };
        }, options);
    }

    public async routeStatus(route: RouteFeatureCollection): Promise<RouteStatusResponseDTO> {
        return new Promise<RouteStatusResponseDTO>((res, rej) => {
            const req: RouteStatusRequestDTO = {
                Cache: false,
                CurrentSegmentIndex: 0,
                Route: route
            };

            const url = `${this._options.urlRouteStatus}`;
            this.postRequest(url, req)
                .then(r => {
                    if (r) {
                        const status = RouteStatusResponseDTO.fromJSON(r);
                        res(status);
                    }
                    else
                        res(null);
                })
                .catch(e => res(null));
        });
    }

    public async routeMultiple(locations: CoordinateLike[]): Promise<RouteMultipleResponse> {
        return new Promise<RouteMultipleResponse>((res, rej) => {
            const xhr = new XMLHttpRequest();
            xhr.timeout = this._options.requestsTimeoutMs;
            xhr.onload = () => {
                if (xhr.responseText)
                    res(RouteMultipleResponse.fromResponse(xhr.responseText, locations));
                else
                    res(null);
            };

            const url = `${this._options.urlMultiple}?loc=${locations.map(x => [x[1], x[0]]).join(encodeURIComponent(';'))}`;
            xhr.ontimeout = () => res(null);
            xhr.onerror = () => res(null);
            xhr.onabort = () => res(null);
            xhr.open('GET', url, true);
            xhr.send();
        });
    }

    private routingRequest<T>(url: string, response: (responseText: string) => Route<T>, options: IRouteOptions): Promise<Route<T>> {
        return new Promise<Route<T>>((res, rej) => {
            if (!url)
                throw new Error('No URL parameter specified in routing options.');

            options = new RouteOptions(options);
            const xhr = new XMLHttpRequest();
            xhr.timeout = this._options.requestsTimeoutMs;
            xhr.onload = () => {
                if (xhr.responseText)
                    res(response(xhr.responseText));
                else
                    res(null);
            };

            xhr.ontimeout = () => res(null);
            xhr.onerror = () => res(null);
            xhr.onabort = () => res(null);
            xhr.open('GET', url, true);
            xhr.send(null);
        });
    }

    private postRequest(url: string, body: any): Promise<string> {
        return new Promise<any>((res, rej) => {
            const xhr = new XMLHttpRequest();
            xhr.timeout = this._options.requestsTimeoutMs;
            xhr.onload = () => {
                if (xhr.responseText)
                    res(xhr.responseText);
                else
                    res(null);
            };

            const json = JSON.stringify(body);
            xhr.ontimeout = () => res(null);
            xhr.onerror = () => res(null);
            xhr.onabort = () => res(null);
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.send(json);
        });
    }
}
