import * as maplibregl from 'maplibre-gl';

import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FeatureCollection, Point } from '../..';
import { PaddingOptions, PanOptions } from '../../model/RM2CameraChangeOptions';
import { Coordinate, CoordinateLike } from '../../model/RM2Geometry';
import { IRoutingControlOptions, RoutingControlOptions, RoutingInput, ToolbarItem, RoutingInputActionButton, RoutingInputActionButtonType } from './RM2RoutingControlOptions';
import { MapControlType } from '../RM2MapControls';
import { MapPickerControl } from '../map-picker-control/RM2MapPickerControl';
import { ServiceType } from '../../services/RM2Service';
import { Utils } from '../../services/RM2StaticService';
import { GeocodeLocFeatureCollectionProperties, GeocodeLocFeatureProperties, GeocodeLocLocationType, IQueryOptions, NominatimService, Query_v2LocationType, Query_v2ResponseFeatureCollectionProperties, Query_v2ResponseFeatureProperties, ReverseGeocodingFeatureProperties, ReverseGeocodingProperties } from '../../services/RM2NominatimService';
import { RoutingService, RouteFeatureCollection, RouteInstructionsFeatureCollection } from '../../services/RM2RoutingService';
import { LocalizationService } from '../../services/RM2LocalizationService';
import { Map } from '../../map/RM2Map';
import { Feature } from '../../model/RM2Feature';

declare var $: any;

export class RoutingControl implements maplibregl.IControl {
    protected _map: Map;
    
    private _options: RoutingControlOptions;
    private _routingService: RoutingService;
    private _nominatimService: NominatimService;
    private _localizationService: LocalizationService;
    private _mapPicker: MapPickerControl;

    protected _button: HTMLButtonElement;
    protected _panel: HTMLDivElement;
    protected _panelTop: HTMLDivElement;
    protected _panelBottom: HTMLDivElement;
    protected _spinner: HTMLElement;
    protected _message: HTMLDivElement;
    protected _reverseLocations: HTMLElement;
    protected _disclaimer: HTMLElement;
    protected _searchResults: HTMLUListElement;
    protected _toolbarContent: HTMLDivElement;
    protected _searchInputs: RoutingInput[] = [
        new RoutingInput('input-1', '[placeholder]rmap.rm.routing.enter-start', '[placeholder]rmap.rm.routing.enter-start-alt'),
        new RoutingInput('input-2', '[placeholder]rmap.rm.routing.enter-finish', '[placeholder]rmap.rm.routing.enter-finish-alt')
    ];

    private get searchInputIdWaitingForLocation(): number {
        if (this.getPanelStatus() === false)
            return undefined;
        
        for (let i = 0; i < this._searchInputs.length; i++) {
            const input = this._searchInputs[i];
            if (input.getLocation() == undefined)
                return i;
        }

        return undefined;
    }

    private lastFocusedInput: string;
    private focusedTransportationMode: ToolbarItem;
    private locationHighlight: Feature;
    private routeStationHighlight: Feature;

    private inputChangeDebouncing: Subject<HTMLInputElement> = new Subject<HTMLInputElement>();

    private searchResultsHideTimeout;
    private focusedSearchResult: number;
    // private hoveringSearchResults: boolean = false;
    private searchInputPlaceholder = '[placeholder]rmap.rm.routing.search-placeholder';
    private searchToolbarItem: ToolbarItem = {
        id: 'SEARCH',
        icon: 'fa fa-2x fa-search',
        showDisclaimer: false
    };

    private transportationModes: ToolbarItem[] = [
        // { id: 'PUBLIC', icon: 'fa fa-2x fa-bus', enabled: false },
        // { id: 'BICYCLE_RENT', icon: 'fa fa-2x fa-bicycle', enabled: false },
        // { id: 'WALK', icon: 'fa fa-2x fa-walking', enabled: false },
        { id: 'CAR', icon: 'fa fa-2x fa-car' }
    ];

    constructor(map: Map, opts?: IRoutingControlOptions) {
        this._map = map;
        this._options = new RoutingControlOptions(opts);

        this._routingService = this._map.getService(ServiceType.Routing) as RoutingService;
        this._nominatimService = this._map.getService(ServiceType.Nominatim) as NominatimService;
        this._localizationService = this._map.getService(ServiceType.Localization) as LocalizationService;

        this._mapPicker = this._map.getControl(MapControlType.MapPicker);
        if (this._mapPicker == undefined)
            throw new Error('Routing control requires the map picker control to be added to the map (\'' + MapControlType.MapPicker + '\').');

        this._mapPicker.drawRoutes = false;
        this._mapPicker.constructRoutes = false;
        this._mapPicker.onLocationSelected.subscribe(async (loc) => {
            if (this.getLoadingStatus() === false) {
                const geocode = async (): Promise<FeatureCollection<ReverseGeocodingFeatureProperties, any>> => {
                    let l = null;
                    try {
                        l = await this.reverse(loc.coord, { pageSize: 1 });
                    }
                    catch {
                        console.warn('Reverse geocoding failed - forwarding clicked coordinate.');
                    }

                    if (l == null || l.length === 0)
                        l = new FeatureCollection<ReverseGeocodingFeatureProperties, any>([new Feature<ReverseGeocodingFeatureProperties>({ Title: `${loc.coord[0]}, ${loc.coord[1]}` })]);
                    
                    l.features[0].geometry = clickCoord;
                    return l;
                };

                const clickCoord = Point.fromCoordinate(loc.coord);
                if (loc.mouseButton === 1 && this.getPanelStatus() === true && this.searchInputIdWaitingForLocation != undefined) { // LMB
                    this.setLoadingStatus(true);
                    
                    const l = (await geocode()).first;
                    this.setLoadingStatus(false);
                    const found = this._searchInputs[this.searchInputIdWaitingForLocation];
                    this.setSearchInputValue(found, l, true, false);

                    if (this.searchInputIdWaitingForLocation == undefined)
                        this.selectTransportationModeTab(0);
                }
                else if (loc.mouseButton === 2) { // RMB
                    this.setLoadingStatus(true);
                    if (this.getPanelStatus() === false)
                        this.setPanelStatus(true);
                    
                    this.selectTransportationModeTab(0);
                    const l = (await geocode()).first;
                    this.setLoadingStatus(false);

                    if (loc.index > this._searchInputs.length - 1) // TODO: ko bo več inputov, tukaj fix
                        loc.index = this._searchInputs.length - 1;
                    
                    const found = this._searchInputs[loc.index];
                    this.setSearchInputValue(found, l, true, false);
                }
            }
        });

        this._button = this.createButton();
        this._panel = this.createPanel();

        this.inputChangeDebouncing.pipe(debounceTime(200)).subscribe(async (e) => this.processInputChange(e));
        this.onSearchTabSelected();
    }

    onAdd(map: maplibregl.Map): HTMLElement {
        const wrapper = document.createElement('div');
        wrapper.className = 'maplibregl-ctrl maplibregl-ctrl-group';
        wrapper.appendChild(this._button);

        return wrapper;
    }

    onRemove() {
        this._button.parentNode.removeChild(this._button);
        this._panel.parentNode.removeChild(this._panel);
        this._map = undefined;
    }

    getDefaultPosition(): maplibregl.ControlPosition {
        return 'top-left';
    }

    private createButton(): HTMLButtonElement {
        const button = document.createElement('button');
        button.type = 'button';
        button.className = 'rm2-routing-control-main-btn';
        const i = document.createElement('i');
        i.className = 'fa fa-route';
        button.appendChild(i);
        button.onclick = () => {
            this.setPanelStatus(true);
            if (this.focusedTransportationMode && this.focusedTransportationMode.data)
                this.showModeRoute(this.focusedTransportationMode);
            else {
                if (this.locationHighlight)
                    this.highlightLocation(this.locationHighlight.geometry.getCoordinate(), true)
            }
        };

        return button;
    }

    // TODO: check, če se je višina sploh spremenila? base class za vse kontrole, ki se morajo spremeniti ob resizu windowa?
    private resizePanel = () => {
        const height = this._map.getSize()[1];
        if (this.isMobile())
            this._panel.style.maxHeight = `${height}px`;
        else
            this._panel.style.maxHeight = `CALC(${height}px - 1em)`;
    };

    private createPanel(): HTMLDivElement {
        const panel = document.createElement('div');
        panel.className = 'rm2-routing-control-panel rm2-map-controls-panel flex-column';
        // const height = this._rMap.getSize()[1];
        // panel.style.maxHeight = `${height}px`;

        this._panelTop = this.createPanelTop();
        panel.appendChild(this._panelTop);

        panel.appendChild(this.createSearchResults());

        this._panelBottom = this.createPanelBottom();
        panel.appendChild(this._panelBottom);

        return panel;
    }

    private createPanelTop(): HTMLDivElement {
        const panelTop = document.createElement('div');
        panelTop.className = 'panel-top border';

        /* Transportation modes TABS  */
        panelTop.appendChild(this.createToolbar());

        const container = document.createElement('div');
        container.className = 'panel-top-container';
        /* Location search inputs */
        const inputsContainer = document.createElement('div');
        inputsContainer.className = 'panel-top-inputs';
        this._searchInputs.forEach((routingInput, i) => {
            const container = document.createElement('div');
            container.className = 'rm2-routing-search-input-container input-group mb-2';
            const input = this.createInput(routingInput);
            const inputAppend = document.createElement('div');
            inputAppend.className = 'input-group-append';
            const myLocBtn = document.createElement('button');
            myLocBtn.className = 'btn rm2-routing-search-input-btn';
            myLocBtn.type = 'button';
            const myLocBtnIcon = document.createElement('i');
            myLocBtnIcon.className = 'fa fa-crosshairs';
            myLocBtn.appendChild(myLocBtnIcon);
            myLocBtn.onclick = () => {
                this.setLoadingStatus(true);
                navigator.geolocation.getCurrentPosition(async (e) => {
                    const locs = await this.reverse([e.coords.longitude, e.coords.latitude], { pageSize: 1 });
                    if (locs && locs.length > 0) {
                        this.setSearchInputValue(routingInput, locs.first);

                        // TODO: check accuracy - notify if bad?
                        const ts = e.timestamp;
                        if ((new Date().getTime() - ts) > 10 * 60 * 1000)
                            this.setMessage({ title: 'rmap.rm.routing.warnings.old-location.title', content: 'rmap.rm.routing.warnings.old-location.content', i18nOptions: { mins: 10 } }, 'warning');
                        else
                            this.hideMessage();
                    }
                    else
                        this.setMessage({ title: 'general.errors.generic.title', content: 'rmap.general.errors.cannot-get-user-location' }, 'danger');
                    
                    this.setLoadingStatus(false);
                },
                () => {
                    this.setMessage({ title: 'general.errors.generic.title', content: 'rmap.general.errors.cannot-get-user-location' }, 'danger');
                    this.setLoadingStatus(false);
                });
            };
            routingInput.addActionButton(new RoutingInputActionButton(RoutingInputActionButtonType.MyLocation, myLocBtn));
            inputAppend.appendChild(myLocBtn);

            const panToLocBtn = document.createElement('button');
            panToLocBtn.className = 'btn rm2-routing-search-input-btn';
            panToLocBtn.type = 'button';
            const locBtnIcon = document.createElement('i');
            locBtnIcon.className = 'fa fa-map-marker-alt';
            panToLocBtn.appendChild(locBtnIcon);
            panToLocBtn.onclick = () => {
                const loc = routingInput.getLocation();
                if (loc)
                    this.highlightLocation(loc.geometry.getCoordinate(), true);
            };
            routingInput.addActionButton(new RoutingInputActionButton(RoutingInputActionButtonType.PanToLocation, panToLocBtn, false));
            inputAppend.appendChild(panToLocBtn);

            const clearBtn = document.createElement('button');
            clearBtn.className = 'btn rm2-routing-search-input-btn';
            clearBtn.type = 'button';
            const clearBtnIcon = document.createElement('i');
            clearBtnIcon.className = 'fa fa-times';
            clearBtn.appendChild(clearBtnIcon);
            clearBtn.onclick = () => {
                input.value = '';
                this.processInputChange(input);
            };
            routingInput.addActionButton(new RoutingInputActionButton(RoutingInputActionButtonType.Clear, clearBtn));
            inputAppend.appendChild(clearBtn);

            container.appendChild(input);
            container.appendChild(inputAppend);

            inputsContainer.appendChild(container);
        });
        container.appendChild(inputsContainer);

        /* Reverse locations button */
        const reverseLocations = document.createElement('div');
        reverseLocations.className = 'panel-top-reverse-locations';
        const reverseLocationsBtn = document.createElement('button');
        reverseLocationsBtn.type = 'button'; // da se page ne refresha
        reverseLocationsBtn.className = 'rm2-routing-control-btn';
        const i = document.createElement('i');
        i.className = 'fa fa-2x fa-exchange-alt fa-rotate-90';
        reverseLocations.appendChild(reverseLocationsBtn);
        reverseLocationsBtn.appendChild(i);

        // TODO: debouncing?
        reverseLocationsBtn.onclick = () => {
            if (this._searchInputs.length === 2) {
                const first = this._searchInputs.shift();
                const firstLoc = first.getLocation();
                const second = this._searchInputs.shift();
                const secondLoc = second.getLocation();

                first.saveLocation(null);
                second.saveLocation(null);
                this._searchInputs.push(first);
                this._searchInputs.push(second);

                this.setSearchInputValue(second, firstLoc, false, false);
                this.setSearchInputValue(first, secondLoc, false, false);

                this.locationHighlight = null;
            }
        };
        
        this._reverseLocations = reverseLocations;
        container.appendChild(reverseLocations);
        panelTop.appendChild(container);

        // disclaimer
        if (this._map.metadata.enableRoutingDisclaimer) {
            this._disclaimer = document.createElement('span');
            this._localizationService.localizeElement(this._disclaimer, 'rmap.rm.routing.disclaimer');
            panelTop.appendChild(this._disclaimer);

            this.refreshDisclaimerVisibility();
        }

        return panelTop;
    }

    private createSearchResults(): HTMLDivElement {
        const searchResults = document.createElement('ul');
        searchResults.className = 'list-group rm2-routing-control-search-results border rounded-bottom shadow';

        const size = this._map.getSize();
        searchResults.style.maxHeight = `${size[1] / 2}px`;
        // searchResults.onmouseenter = () => this.hoveringSearchResults = true;
        // searchResults.onmouseleave = () => this.hoveringSearchResults = false;
        searchResults.addEventListener('focus', () => this.stopSearchResultsHideTimeout(), true);
        searchResults.addEventListener('blur', () => this.startSearchResultsHideTimeout(), true);

        this._searchResults = searchResults;

        const container = document.createElement('div');
        container.className = 'rm2-routing-control-search-results-container';
        container.appendChild(searchResults);
        
        return container;
    }

    private createPanelBottom(): HTMLDivElement {
        const panelBottom = document.createElement('div');
        panelBottom.className = 'panel-bottom border';

        /* Spinner */
        this._spinner = document.createElement('div');
        this._spinner.className = 'text-center p-2 d-none';
        const spinnerContent = document.createElement('div');
        spinnerContent.className = 'spinner-border';
        spinnerContent.setAttribute('role', 'status');
        const spinnerSpan = document.createElement('span');
        spinnerSpan.className = 'sr-only';
        spinnerSpan.innerText = 'Loading ...';
        spinnerContent.appendChild(spinnerSpan);
        this._spinner.append(spinnerContent);
        panelBottom.appendChild(this._spinner);

        this._toolbarContent = this.createToolbarContent();
        panelBottom.appendChild(this._toolbarContent);

        return panelBottom;
    }

    private createInput(routingInput: RoutingInput): HTMLInputElement {
        const input = document.createElement('input');
        input.type = 'text';
        input.id = routingInput.id;
        this._localizationService.localizeElement(input, routingInput.placeholderText);
        input.className = 'rm2-routing-search-input form-control';

        input.onkeyup = this.onSearchKeyUp;
        input.addEventListener('focus', () => {
            this.lastFocusedInput = input.id;
            if (input.value.length > 0)
                this.processInputChange(input);
            
            // if (this._searchResults.children.length > 0)
            //     this._searchResults.classList.add('active');
            this.stopSearchResultsHideTimeout();
        }, true);

        input.addEventListener('blur', this.startSearchResultsHideTimeout, true);

        routingInput.setTarget(input);
        return input;
    }

    private createToolbar(): HTMLDivElement {
        const div = document.createElement('div');
        div.className = 'rm2-routing-toolbar mb-3';
        const ul = document.createElement('ul');
        ul.className = 'nav nav-pills rm2-routing-transportation-modes-tabs';
        ul.id = 'pills-tab';
        ul.setAttribute('role', 'tablist');

        const search = this.createToolbarItem(this.searchToolbarItem.id, this.searchToolbarItem.icon, true, true);
        search.onclick = () => {
            if (this.focusedTransportationMode == undefined) {
                const loc = this._searchInputs[0].getLocation();
                if (loc)
                    this.highlightLocation(loc.geometry.getCoordinate(), true);
            }
        };

        ul.appendChild(search);

        for (let i = 0; i < this.transportationModes.length; i++) {
            const mode = this.transportationModes[i].id;
            const modeIcon = this.transportationModes[i].icon;
            const enabled = this.transportationModes[i].enabled !== false;

            const li = this.createToolbarItem(mode, modeIcon, enabled, false);
            li.onclick = () => {
                if (this.focusedTransportationMode && this.focusedTransportationMode.id === mode)
                    this.showModeRoute(this.transportationModes[i]);
            };

            ul.appendChild(li);
        }
        
        div.appendChild(ul);

        /* Hide button */
        const hideI = document.createElement('i');
        hideI.className = 'fa fa-arrow-left rm2-routing-control-hide p-3';
        hideI.onclick = () => {
            this.setPanelStatus(false);
            this.clearRoute();
            this.clearHighlight();
        };
        div.appendChild(hideI);

        /* Clear button */
        const clear = document.createElement('i');
        clear.className = 'fa fa-times rm2-routing-control-clear p-3';
        clear.onclick = () => this.clearSearch();
        div.appendChild(clear);

        return div;
    }

    private createToolbarItem(label: string, icon: string, enabled: boolean, active: boolean): HTMLLIElement {
        const li = document.createElement('li');
        li.className = 'nav-item rm2-routing-transportation-modes-tab-item';
        const a = document.createElement('a');
        const id = 'pills-' + label + '-tab';
        a.className = 'nav-link' + (active === true ? ' active' : '') + (enabled === false ? ' disabled': '');
        a.id = id;
        a.setAttribute('data-toggle', 'pill');
        a.setAttribute('mode', label);
        a.href = '#pills-' + label;
        a.setAttribute('role', 'tab');
        a.setAttribute('aria-controls', 'pills-' + label);
        a.setAttribute('aria-selected', 'true');
        const i = document.createElement('i');
        i.className = icon;
        a.appendChild(i);
        li.appendChild(a);

        return li;
    }

    private createToolbarContent(): HTMLDivElement {
        const content = document.createElement('div');
        content.className = 'tab-content rm2-routing-toolbar-content';
        content.id = 'pills-tabContent';

        const search = this.createToolbarContentItem(this.searchToolbarItem.id, true);
        content.appendChild(search);

        for (let i = 0; i < this.transportationModes.length; i++) {
            const mode = this.transportationModes[i].id;
            const div = this.createToolbarContentItem(mode, false);
            content.appendChild(div);
            this.transportationModes[i].element = div;
        }
        
        return content;
    }

    private createToolbarContentItem(label: string, active: boolean) {
        const div = document.createElement('div');
        div.className = 'tab-pane fade' + (active === true ? ' show active' : '');
        div.id = 'pills-' + label;
        div.setAttribute('role', 'tabpanel');
        div.setAttribute('aria-labelledby', 'pills-' + label + '-tab');
        // div.innerHTML = mode;
        return div;
    }

    private createRouteOverviewContent(route: RouteFeatureCollection): HTMLDivElement {
        const detail = document.createElement('div');
        detail.className = 'pt-3 pr-3 pl-3';

        // const row1 = document.createElement('div');
        // row1.className = 'row';
        // /* Start time */
        // const start = document.createElement('div');
        // start.className = 'col-sm-6';
        // const startIcon = document.createElement('i');
        // startIcon.className = 'fa fa-map-marker-alt rm2-routing-control-route-overview-table-icon';
        // const startText = document.createElement('span');
        // startText.innerText = this.formatDate(new Date(route.plan.itineraries[0].startTime));
        // start.appendChild(startIcon);
        // start.appendChild(startText);

        // /* Arrival time */
        // const arrival = document.createElement('div');
        // arrival.className = 'col-sm-6';
        // const arrivalIcon = document.createElement('i');
        // arrivalIcon.className = 'fa fa-flag-checkered rm2-routing-control-route-overview-table-icon';
        // const arrivalText = document.createElement('span');
        // arrivalText.innerText = this.formatDate(new Date(route.plan.itineraries[0].endTime));
        // arrival.appendChild(arrivalIcon);
        // arrival.appendChild(arrivalText);

        // row1.appendChild(start);
        // row1.appendChild(arrival);

        const row2 = document.createElement('div');
        row2.style.marginLeft = '0';
        row2.style.marginRight = '0';
        row2.className = 'row';
        /* Distance */
        const distance = document.createElement('div');
        distance.className = 'col-sm-6';
        const distanceIcon = document.createElement('i');
        distanceIcon.className = 'fa fa-ruler-horizontal rm2-routing-control-route-overview-table-icon';
        const distanceText = document.createElement('span');
        distanceText.innerText = Utils.formatDistance(route.properties.Length);
        distance.append(distanceIcon);
        distance.append(distanceText);

        /* Time */
        const time = document.createElement('div');
        time.className = 'col-sm-6';
        const timeIcon = document.createElement('i');
        timeIcon.className = 'fa fa-stopwatch rm2-routing-control-route-overview-table-icon';
        const timeText = document.createElement('span');
        timeText.innerText = Utils.formatDuration(route.properties.RealTTMs / 1000);
        time.appendChild(timeIcon);
        time.appendChild(timeText);

        row2.appendChild(distance);
        row2.appendChild(time);

        // div.appendChild(row1);
        detail.appendChild(row2);

        if (route.properties.Instructions.features.find(station => station.properties.Type === 'station-toll')) {
            const tollWarningDiv = document.createElement('div');
            tollWarningDiv.className = 'text-warning';
            const tollIcon = document.createElement('i');
            tollIcon.className = 'fa fa-exclamation-triangle rm2-routing-control-route-overview-table-icon mt-2'
            tollWarningDiv.appendChild(tollIcon);
            const tollText = document.createElement('span');
            this._localizationService.localizeElement(tollText, 'rmap.rm.routing.includes-tolls');
            tollWarningDiv.appendChild(tollText);
            detail.appendChild(tollWarningDiv);
        }

        detail.appendChild(document.createElement('hr'));

        const div = document.createElement('div');
        div.appendChild(detail);
        div.appendChild(this.createRouteOverviewStations(route.properties.Instructions));
        return div;
    }

    private createRouteOverviewStations(stations: RouteInstructionsFeatureCollection): HTMLUListElement {
        const ul = document.createElement('ul');
        ul.className = 'list-group';

        const getIcon = (stationInstructionType: string) => {
            if (stationInstructionType === 'instruction-start')
                return 'fa fa-crosshairs';
            else if (stationInstructionType === 'instruction-turn-left')
                return 'fa fa-long-arrow-alt-left';
            else if (stationInstructionType === 'instruction-turn-right')
                return 'fa fa-long-arrow-alt-right';
            else if (stationInstructionType === 'instruction-turn-straight')
                return 'fa fa-long-arrow-alt-up';
            // else if (stationInstructionType === 'station-toll')
            //     return 'fa fa-exclamation-triangle text-warning';
            else if (stationInstructionType === 'instruction-turn-sharpleft')
                return 'fa fa-long-arrow-alt-left';
            else if (stationInstructionType === 'instruction-turn-sharpright')
                return 'fa fa-long-arrow-alt-right';
            else if (stationInstructionType === 'instruction-turn-uturn')
                return 'fa fa-redo fa-rotate-90';
            else if (stationInstructionType === 'instruction-turn-end' || stationInstructionType === 'instruction-end')
                return 'fa fa-flag-checkered';
            return 'fa fa-car';
        };

        stations.features.forEach(station => {
            if (station.properties.Type !== 'station-toll') {
                const li = document.createElement('li');
                li.className = 'list-group-item border-0 rm2-routing-control-route-overview-item py-2';
    
                const stationContainer = document.createElement('div');
                stationContainer.className = 'row';
                // Icon (desktop resolution)
                const iconDiv = document.createElement('div');
                iconDiv.className = 'col-sm-2 text-center d-none d-sm-block';
                const icon = document.createElement('i');
                icon.className = getIcon(station.properties.Type); // get icon from station instruction
                iconDiv.appendChild(icon);
    
                const descriptionDiv = document.createElement('div');
                descriptionDiv.className = 'col-sm-10';
                const textDivContainer = document.createElement('div');
                textDivContainer.className = 'row d-inline-flex w-100 justify-content-center justify-content-sm-start align-items-center';

                // Icon (mobile resolution)
                const iconMobile = document.createElement('i');
                iconMobile.className = getIcon(station.properties.Type);
                iconMobile.classList.add('d-sm-none', 'px-2');
                textDivContainer.appendChild(iconMobile);

                const textDiv = document.createElement('div');
                // textDiv.className = 'row';
                // textDiv.className = '';
                // const prop = 'title:' + this._localizationService.getLanguage();
                const title = station.properties.TitleTranslations.find(t => t.Item1 === this._localizationService.getLanguage());
                if (title)
                    textDiv.innerText = title.Item2;
                textDivContainer.appendChild(textDiv);
                descriptionDiv.appendChild(textDivContainer);
                
                if (station.properties.Length > 0) {
                    const getLine = (): HTMLDivElement => {
                        const line = document.createElement('div');
                        line.className = 'rm2-routing-control-route-overview-line flex-grow-1';
                        return line;
                    };

                    const locationDiv = document.createElement('div');
                    locationDiv.className = 'row d-flex';
                    const line1 = getLine();
                    line1.classList.add('d-sm-none');
                    locationDiv.appendChild(line1);
                    const distance = document.createElement('span');
                    distance.className = 'text-muted';
                    distance.innerText = Utils.formatDistance(station.properties.Length);
                    locationDiv.appendChild(distance);
                    const line2 = getLine();
                    locationDiv.appendChild(line2);
                    descriptionDiv.appendChild(locationDiv);
                }
                else if (station.properties.Type === 'station-toll') {
                    const tollWarningDiv = document.createElement('div');
                    tollWarningDiv.className = 'row text-warning';
                    tollWarningDiv.innerText = 'Cestnina'
                    descriptionDiv.appendChild(tollWarningDiv);
                }
    
                stationContainer.appendChild(iconDiv);
                stationContainer.appendChild(descriptionDiv);
    
                li.appendChild(stationContainer);
                li.onclick = () => {
                    const view = this._map.getView();
                    // HACK za zdaj dokler se ne zamenja routing servicea
                    // const proj = Projection.create(this._options.projection);
                    // const proj = Projection.create('EPSG:3912;+title=y/x:D48/GK +proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=-5000000 +ellps=bessel +towgs84=426.9,142.6,460.1,4.91,4.49,-12.42,17.1 +units=m +no_defs')

                    if (this.routeStationHighlight)
                        this._map.removeFeature(this.routeStationHighlight.properties.id);
                    
                    this.routeStationHighlight = this._map.addFeature(new Feature({
                        color: '#59A0DD',
                        radius: 5,
                        outlineWidth: 2,
                        outlineColor: '#FFFFFF'
                    }, station.geometry), {
                        // projection: proj,
                        pan: true,
                        cameraOptions: new PanOptions({
                            zoom: view.zoom > 14 ? view.zoom : 14,
                            relCenterX: this.getRelCenterX()
                        })
                    });

                    // this._rMap.pan(station.location, {
                    //     // projection: proj,
                    //     // HACK za zdaj dokler se ne zamenja routing servicea
                    //     projection: Projection.create('EPSG:3912;+title=y/x:D48/GK +proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=-5000000 +ellps=bessel +towgs84=426.9,142.6,460.1,4.91,4.49,-12.42,17.1 +units=m +no_defs'),
                    //     zoom: view.zoom > 14 ? view.zoom : 14,
                    //     relCenterX: this.getRelCenterX()
                    // });
                };
    
                ul.appendChild(li);
            }
        });

        return ul;
    }

    private createSearchResultItem(title: string, highlight?: string): HTMLSpanElement {
        const container = document.createElement('span');
        if (title && highlight) {
            while (title.toLowerCase().includes(highlight.toLowerCase())) {
                const titleL = title.toLowerCase();
                const highlightL = highlight.toLowerCase();
                const i = titleL.indexOf(highlightL);
                const span1 = document.createElement('span');
                span1.innerText = title.substr(0, i);
                container.appendChild(span1);
                const span2 = document.createElement('span');
                span2.className = 'font-weight-bold';
                span2.innerText = title.substr(i, highlight.length);
                container.appendChild(span2);

                title = title.substr(i + highlight.length);
            }

            const endSpan = document.createElement('span');
            endSpan.innerText = title;
            container.appendChild(endSpan);
        }
        else
            container.innerText = title;
        return container;
    }

    private clearSearch() {
        let isCleared: boolean = true;
        for (let i = 0; i < this._searchInputs.length; i++) {
            if (this._searchInputs[i].getLocation()) {
                isCleared = false;
                break;
            }
        }

        if (isCleared)
            this.setPanelStatus(false);
        else {
            this._searchInputs.forEach((input, i) => {
                const target = input.getTarget();
                if (target) {
                    if (i > 0 && i < this._searchInputs.length - 1) {
                        target.parentNode.removeChild(target);
                        input.saveLocation(null);
                    }
                    else
                        this.setSearchInputValue(input, null, false, false, false);
                }
            })
            
            if (this._searchInputs.length > 2)
                this._searchInputs.splice(1, this._searchInputs.length - 2);
            
            while (this._searchResults.firstChild)
                this._searchResults.removeChild(this._searchResults.firstChild);
            
            this.clearTransportationModesContent();
            this.clearHighlight();
            this.locationHighlight = null;
            this.clearRoute();
        }
    }

    private onSearchTabSelected = (e?: Event) => {
        this.focusedTransportationMode = null;
        this.clearRoute();
        this.clearHighlight();

        const loc = this._searchInputs[0].getLocation();
        if (loc)
            this.highlightLocation(loc.geometry.getCoordinate(), false);
        
        this._reverseLocations.style.display = 'none';
        for (let i = 1; i < this._searchInputs.length; i++)
            this._searchInputs[i].getTarget().parentElement.style.display = 'none';
        
        const searchTarget = this._searchInputs[0].getTarget();
        this._localizationService.localizeElement(searchTarget, this.searchInputPlaceholder);
        this.refreshDisclaimerVisibility();
    };

    private selectTransportationModeTab(index: number) {
        const mode = this.transportationModes[index];
        $('#pills-' + mode.id + '-tab').tab('show');
    }

    private onTransportationModeTabSelected = (e: Event) => {
        const modeId = (e.target as HTMLElement).getAttribute('mode');
        const mode = this.transportationModes.find(m => m.id === modeId);
        this._reverseLocations.style.display = 'flex';
        for (let i = 0; i < this._searchInputs.length; i++) {
            const input = this._searchInputs[i];
            const target = input.getTarget();
            target.parentElement.style.display = 'flex';
            // target.placeholder = input.placeholderText;
            this._localizationService.localizeElement(target, input.placeholderText);
        }

        this.focusedTransportationMode = mode;
        this.showModeRoute(mode);

        const waitingId = this.searchInputIdWaitingForLocation;
        if (waitingId != undefined) {
            const waitingInput = this._searchInputs[waitingId];
            // waitingInput.getTarget().placeholder = waitingInput.placeholderTextAlt;
            this._localizationService.localizeElement(waitingInput.getTarget(), waitingInput.placeholderTextAlt);
        }
        this.refreshDisclaimerVisibility();
    };

    private setSearchResults(items: FeatureCollection<RoutingGeocodingFeatureProps, RoutingGeocodingProps>, query?: string) {
        while (this._searchResults.firstChild) {
            this._searchResults.firstChild.removeEventListener('keyup', this.onSearchKeyUp);
            this._searchResults.removeChild(this._searchResults.firstChild);
        }
        
        if (items.length > 0) {
            const appendToSearchResults = (item: Feature<RoutingGeocodingFeatureProps>) => {
                const a = document.createElement('a');
                a.className = 'list-group-item list-group-item-action rm2-routing-control-search-results-item';
                a.href = '#';
                const i = document.createElement('i');
                i.className = `fa ${item.properties.FaIcon} rm2-routing-control-search-results-item-icon`;
                const span = this.createSearchResultItem(item.properties.Title, query);
                // const span = document.createElement('span');
                // span.innerText = item.name;
                a.appendChild(i);
                a.appendChild(span);
    
                // TODO: unsubscribe on destroy
                a.onclick = () => {
                    if (this.lastFocusedInput) {
                        const found = this._searchInputs.find(input => input.getTarget().id === this.lastFocusedInput);
                        this.setSearchResultsStatus(false);
                        if (found)
                            this.setSearchInputValue(found, item);
                    }
                };

                a.addEventListener('keyup', this.onSearchKeyUp);
                if (this._searchResults.children.length > 0 && this._searchResults.children[this._searchResults.children.length - 1].id === 'rm2-search-results-more-btn')
                    this._searchResults.insertBefore(a, this._searchResults.children[this._searchResults.children.length - 1]);
                else
                    this._searchResults.appendChild(a);
            };

            items.features.forEach(item => appendToSearchResults(item));
            if (items.properties.HasNextPage === true) {
                let page = items.properties.Page;
                const more = document.createElement('button');
                more.className = 'btn btn-light btn-block';
                more.type = 'button';
                more.id = 'rm2-search-results-more-btn';
                this._localizationService.localizeElement(more, 'rmap.rm.routing.more-results');
                more.onclick = async () => {
                    this.stopSearchResultsHideTimeout();
                    (this._searchResults.lastChild as HTMLElement).focus();
                    const moreLocs = await this.query(query, { page: ++page });
                    this.lastProcessedInputText = query;
                    moreLocs.forEach(item => appendToSearchResults(item));
                    if (moreLocs.properties.HasNextPage === false) {
                        more.parentElement.removeChild(more);
                        (this._searchResults.lastChild as HTMLAnchorElement).focus();
                    }
                };

                this._searchResults.appendChild(more);
            }

            if (this.getSearchResultsStatus() === false)
                this.setSearchResultsStatus(true);
        }
    }

    private setSearchInputValue(input: RoutingInput, value: Feature<RoutingGeocodingFeatureProps>, highlight: boolean = true, pan: boolean = true, recalculateInputsRoute: boolean = true) {
        const target = input.getTarget();
        input.saveLocation(value);

        if (recalculateInputsRoute === true)
            this.onLocationSelected(highlight, pan);
        
        const waitingId = this.searchInputIdWaitingForLocation;
        for (let i = 0; i < this._searchInputs.length; i++) {
            const input = this._searchInputs[i];
            const target = input.getTarget();
            if (this.focusedTransportationMode != undefined)
                // target.placeholder = i === waitingId ? input.placeholderTextAlt : input.placeholderText;
                this._localizationService.localizeElement(target, i === waitingId ? input.placeholderTextAlt : input.placeholderText);
            else
                // target.placeholder = this.searchInputPlaceholder;
                this._localizationService.localizeElement(target, this.searchInputPlaceholder);
        }

        if (value && this.focusedTransportationMode) {
            const i = this._searchInputs.indexOf(input);
            if (i < this._searchInputs.length - 1)
                this._searchInputs[i + 1].getTarget().focus();
        }

        const panBtn = input.getActionButton(RoutingInputActionButtonType.PanToLocation);
        const myLocBtn = input.getActionButton(RoutingInputActionButtonType.MyLocation);
        target.value = value ? value.properties.Title : '';
        panBtn.visible = value != undefined;
        myLocBtn.visible = !panBtn.visible;
    }

    private onLocationSelected(highlight: boolean = true, pan: boolean = true) {
        if (this.focusedTransportationMode)
            this.processSearchInputs(highlight, pan);
        else {
            if (highlight === true) {
                const searchInput = this._searchInputs[0];
                const loc = searchInput.getLocation();
                if (loc)
                    this.highlightLocation(loc.geometry.getCoordinate(), pan);
                else
                    this.clearHighlight();
            }
        }
    }

    async processSearchInputs(highlight: boolean = true, pan: boolean = true) {
        this.clearRoute();
        this.clearHighlight();
        this.clearTransportationModesContent();
        this.hideMessage();
        this.locationHighlight = null;

        const locations = new FeatureCollection();
        for (let i = 0; i < this._searchInputs.length; i++) {
            const value = this._searchInputs[i].getLocation();
            if (value)
                locations.features.push(value);
        }

        if (locations.length > 1) {
            this.setLoadingStatus(true);
            try {
                const route = await this._routingService.routev1(this._searchInputs.map(input => {
                    const coord = input.getLocation().geometry.getCoordinate();
                    return [coord.x, coord.y] as CoordinateLike;
                }));
                
                if (route && route.route) {
                    this.transportationModes.forEach(mode => {
                        // if (mode.ena bled !== false) {
                            // TODO: ko pride več-modalni routing, tukaj check
                            // const found = route.extraTrips.find(trip => trip.tripMode === mode.id);
                            let div: HTMLDivElement;
                            // TODO: check, če je pot sploh veljavna?
                            // if (found && found.plan && found.plan.itineraries) {
                            //     mode.data = found;
                            //     div = this.createRouteOverviewContent(found);
                            //     // this.showModeRoute(mode);
                            // }
                            // else {
                            //     mode.data = null;
                            //     div = document.createElement('div');
                            //     div.innerText = 'Za ta način transporta žal ni bilo najdene ustrezne poti.';
                            // }
    
                            mode.data = { points: locations.features.map(ft => {
                                const c = ft.geometry.getCoordinate();
                                return [c.x, c.y] as CoordinateLike;
                            }), route: route.route };
                            div = this.createRouteOverviewContent(route.route);
                            mode.element.appendChild(div);
                        // }
                    });
        
                    this.showModeRoute(this.focusedTransportationMode);
                }
                else {
                    this.setMessage({ title: 'general.errors.generic.title', content: 'rmap.rm.routing.errors.cannot-get-route' }, 'danger');
                    this.clearRoute();
                }
            }
            catch (e) {
                console.error('Error when fetching route\n', e);
                this.setMessage({ title: 'general.errors.generic.title', content: 'rmap.rm.routing.errors.cannot-get-route' }, 'danger');
                this.clearRoute();
            }
            finally {
                this.setLoadingStatus(false);
            }
        }
        else if (locations.length === 1 && highlight)
            this.highlightLocation(locations.features[0].geometry.getCoordinate(), pan);
    }

    private clearTransportationModesContent() {
        this.transportationModes.forEach(mode => {
            mode.data = null;
            while (mode.element.firstChild)
                mode.element.removeChild(mode.element.firstChild);
        });

        this._toolbarContent.style.display = 'none';
        this._toolbarContent.style.display = 'block';
    }

    private highlightLocation(coord: Coordinate | CoordinateLike, pan: boolean) {
        if (this.locationHighlight)
            this._map.removeFeature(this.locationHighlight.properties.id);
        
        this.locationHighlight = this._map.addFeature(new Feature({
            icon: 'marker_red-128'
        }, Point.fromCoordinate(coord)), { pan: false });

        if (pan) {
            const zoom = this._map.getView().zoom;
            const defaultZoom = 14;
            this._map.pan(coord, {
                zoom: zoom > defaultZoom ? zoom : defaultZoom,
                relCenterX: this.getRelCenterX()
            });
        }
    }

    private clearHighlight() {
        if (this.locationHighlight)
            this._map.removeFeature(this.locationHighlight.properties.id);
    }

    private clearRoute() {
        // if (this.displayedRouteFtColl) {
        //     const ids = this.displayedRouteFtColl.features.map(ft => ft.properties.id);
        //     this._rMap.removeFeatures(ids);
        //     this.displayedRouteFtColl = null;
        // }

        this._map.clearRoute(false);
        if (this.routeStationHighlight)
            this._map.removeFeature(this.routeStationHighlight.properties.id);
    }
    
    private attachToolbarEventListeners() {
        const attach = (item: ToolbarItem, handler: (e: Event) => void) => {
            const format = '#pills-[id]-tab';
            $(format.replace('[id]', item.id)).on('shown.bs.tab', handler);
        };

        attach(this.searchToolbarItem, this.onSearchTabSelected);
        this.transportationModes.forEach(mode => attach(mode, this.onTransportationModeTabSelected));
    }
    
    private removeToolbarEventListeners() {
        const remove = (item: ToolbarItem, handler: (e: Event) => void) => {
            const format = '#pills-[id]-tab';
            $(format.replace('[id]', item.id)).off('shown.bs.tab', handler);
        };

        remove(this.searchToolbarItem, this.onSearchTabSelected);
        this.transportationModes.forEach(mode => remove(mode, this.onTransportationModeTabSelected));
    }
    
    private getPanelStatus(): boolean {
        return this._panel.parentNode != null;
    }

    private setPanelStatus(status: boolean) {
        if (status === true) {
            if (this.getPanelStatus() === false) {
                if (this._button && this._button.parentNode && this._button.parentNode.parentNode)
                    this._button.parentNode.parentNode.appendChild(this._panel);
                
                this._localizationService.refresh();
                this.resizePanel();
                window.addEventListener('resize', this.resizePanel);
                this.attachToolbarEventListeners();
            }
            else
                throw new Error('Routing panel already shown.');
        }
        else if (status === false) {
            window.removeEventListener('resize', this.resizePanel);
            this.removeToolbarEventListeners();

            if (this._panel && this._panel.parentNode)
                this._panel.parentNode.removeChild(this._panel);
        }
    }

    private getRelCenterX(): number {
        if (this.isMobile())
            return 0.5;
        else {
            const size = this._map.getSize();
            const panelBottomOpen = this._panelBottom && this._panelBottom.clientHeight > 0;
            return panelBottomOpen ? (0.5 + ((this._panel.clientWidth / size[0]) / 2)) : 0.5;
        }
    }

    private getRouteFitPadding(): PaddingOptions {
        if (this.isMobile()) {
            return {
                top: this._panelTop.clientHeight + 50,
                // bottom: this._panelBottom.clientHeight + 50, // TODO: to pofiksati, pri reverse route ne dela vedno OK
                bottom: 200 + 50, // TODO: to pofiksati, pri reverse route ne dela vedno OK
                left: 50,
                right: 50
            };
        }
        else {
            return {
                top: 50,
                bottom: 50,
                left: this._panel.clientWidth + 50,
                right: 50
            }
        }
    }

    private getSearchResultsStatus(): boolean {
        return this._searchResults.classList.contains('active');
    }

    private setSearchResultsStatus(visible: boolean) {
        this.focusedSearchResult = undefined;
        if (visible)
            this._searchResults.classList.add('active');
        else
            this._searchResults.classList.remove('active');
    }

    private getLoadingStatus(): boolean {
        return this._spinner.classList.contains('d-none') === false;
    }

    private setLoadingStatus(loading: boolean) {
        if (loading === true) {
            this._spinner.classList.remove('d-none');
            this._searchInputs.forEach(input => input.disabled = true);
            for (let i = 0; i < this._reverseLocations.children.length; i++) {
                const child = this._reverseLocations.children.item(i);
                if (child instanceof HTMLButtonElement)
                    child.disabled = true;
            }

            // TODO:
            // this.mapPickerControl.hide();
        }
        else {
            this._spinner.classList.add('d-none');
            this._searchInputs.forEach(input => input.disabled = false);
            for (let i = 0; i < this._reverseLocations.children.length; i++) {
                const child = this._reverseLocations.children.item(i);
                if (child instanceof HTMLButtonElement)
                    child.disabled = false;
            }
        }
    }

    private setMessage(options: { title: string, content: string, i18nOptions?: Object }, type: string) {
        this.hideMessage();

        this._message = document.createElement('div');
        this._panelTop.appendChild(this._message);

        // this._message.className = `alert alert-${type} alert-dismissible fade show m-0`;
        this._message.className = `alert alert-${type} alert-dismissible show m-0`;
        const titleDiv = document.createElement('strong');
        // titleDiv.innerText = options.title;
        this._localizationService.mark(titleDiv, options.title);
        this._message.appendChild(titleDiv);

        this._message.appendChild(document.createElement('br'));

        const contentDiv = document.createElement('span');
        // contentDiv.innerText = content;
        this._localizationService.mark(contentDiv, options.content, options.i18nOptions);
        this._message.appendChild(contentDiv);

        const closeBtn = document.createElement('button');
        closeBtn.type = 'button';
        closeBtn.className = 'close p-2';
        closeBtn.setAttribute('data-dismiss', 'alert');
        closeBtn.setAttribute('aria-label', 'close');
        const closeBtnSpan = document.createElement('span');
        closeBtnSpan.className = 'fa fa-xs fa-times';
        closeBtnSpan.setAttribute('aria-hidden', 'true');
        closeBtn.appendChild(closeBtnSpan);

        this._localizationService.refresh(this._message);
        this._message.appendChild(closeBtn);
        this._message.style.display = 'block';
    }

    private hideMessage() {
        if (this._message && this._message.parentElement)
            this._message.parentElement.removeChild(this._message);
    }

    // private fetchingRoute: XMLHttpRequest;
    // private getRoute(locations: FeatureCollection): Promise<any> {
    //     return new Promise<any>((res, rej) => {
    //         if (this.fetchingRoute)
    //             this.fetchingRoute.abort();
            
    //         const xhr = new XMLHttpRequest();
    //         xhr.timeout = this.requestsTimeoutMs;
    //         xhr.onload = () => {
    //             if (xhr.responseText)
    //                 res(JSON.parse(xhr.responseText));
    //             else
    //                 res(null);
    //         };

    //         xhr.ontimeout = () => {
    //             this.setMessage('Napaka!', this.requestsTimeoutMessage, 'danger');
    //             rej('Timeout');
    //         };

    //         xhr.onerror = () => rej('Error getting route');
    //         xhr.onabort = () => rej('HTTP request aborted.');
    //         xhr.onloadend = () => this.fetchingRoute = null;
    
    //         // TODO: dodaj vmesne točke
    //         const loc1 = locations.features[0].geometry.getCoordinate();
    //         const loc2 = locations.features[1].geometry.getCoordinate();
    //         const url = `${this._options.routingUrl}&loc=${loc1.y},${loc1.x}%3B${loc2.y},${loc2.x}`;
    //         this.fetchingRoute = xhr;

    //         xhr.open('GET', url, true);
    //         xhr.send(null);
    //     });
    // }

    // private getLocations(query: string | CoordinateLike, pageSize: number = 5, page: number = 0): Promise<FeatureCollection<RoutingLocation, RoutingLocationResponseData>> {
    //     return new Promise<FeatureCollection>((res, rej) => {
    //         if (this.fetchingLocations)
    //             this.fetchingLocations.abort();
    
    //         const xhr = new XMLHttpRequest();
    //         xhr.timeout = this.requestsTimeoutMs;
    //         xhr.onload = () => {
    //             if (!xhr.status || xhr.status >= 200 && xhr.status < 300) {
    //                 const data = FeatureCollection.fromGeoJson(JSON.parse(xhr.responseText));
    //                 res(data);
    //             }
    //         };
    
    //         xhr.ontimeout = () => {
    //             this.setMessage('Napaka!', this.requestsTimeoutMessage, 'danger');
    //             rej('Timeout');
    //         };

    //         xhr.onerror = () => rej('Error loading location data for \'' + query + '\'.');
    //         // xhr.onabort = () => console.error('Locations HTTP request aborted.');
    //         xhr.onloadend = () => this.fetchingLocations = null;
    
    //         let url: string;
    //         if (typeof query === 'string') {
    //             url = `${this._options.locationsUrl}?condition=${query}&pagesize=${pageSize}&page=${page}`;
    //             this.lastProcessedInputText = query;
    //         }
    //         else {
    //             if (query.length === 2)
    //                 url = `${this._options.reverseGeocodingUrl}?x=${query[0]}&y=${query[1]}&pagesize=1`;
    //             else
    //                 throw new Error('Invalid number of coordinates for reverse geocoding - expected 2, got ' + query.length + '.');
    //         }

    //         if (url) {
    //             this.fetchingLocations = xhr;
    //             xhr.open('GET', url, true);
    //             xhr.send(null);
    //         }
    //         else
    //             throw new Error('Could not determine locations URL with passed query - ' + query);
    //     });
    // }

    // TODO: če klikneš na isti mode, se route zbriše in ponovno nariše -> mogoče samo fit namesto addFeatures
    private showModeRoute(mode: ToolbarItem) {
        const data = mode.data;
        this.clearRoute();
        if (data) {
            const opts = Object.assign({}, this._options);
            opts.pan = true;
            opts.cameraOptions = { padding: this.getRouteFitPadding() };
            this._map.drawRoute(data, opts);
        }
        else
            this.processSearchInputs();
    }

    private lastProcessedInputText: string;
    private async processInputChange(target: HTMLInputElement) {
        const id = target.id;
        const found = this._searchInputs.find(input => input.id === id);
        if (target.value && target.value.length > 0) {
            if (this.lastProcessedInputText !== target.value) {
                const locs = await this.query(target.value);
                this.lastProcessedInputText = target.value;
                this.setSearchResults(locs, target.value);
            }
            else
                this.setSearchResultsStatus(true);
        }
        else {
            if (this.focusedTransportationMode == undefined)
                this.clearTransportationModesContent();

            this.setSearchInputValue(found, null, true, false);
            this.setSearchResultsStatus(false);
        }
    }

    private onSearchKeyUp = (e: KeyboardEvent) => {
        if (e.code == undefined || e.code == '')
            return;
        
        const c = Number.parseInt(e.code);
        if (c === 38) // arrow up
            this.navigateSearchResults(true);
        else if (c === 40) // arrow down
            this.navigateSearchResults(false);
        else if (c === 27) // escape
            this.setSearchResultsStatus(false);
        else if (c === 13) { // enter
            // const found = this._searchInputs.find(input => input.element.id === this.focusedInput);
            // this.setSearchResultsStatus(false);
            // if (found)
            //     this.setSearchInputValue(found, found.location);
        }
        else
            this.inputChangeDebouncing.next(e.target as HTMLInputElement);
    }

    private navigateSearchResults(ascend?: boolean) {
        const searchResults: HTMLAnchorElement[] = [];
        for (let i = 0; i < this._searchResults.children.length; i++) {
            const child = this._searchResults.children[i];
            if (child.tagName.toLowerCase() === 'a')
                searchResults.push(child as HTMLAnchorElement);
        }

        if (searchResults.length > 0) {
            if (ascend === true) {
                if (this.focusedSearchResult >= 1)
                    this.focusedSearchResult--;
                else
                    this.focusedSearchResult = searchResults.length - 1;
            }
            else if (ascend === false) {
                if (this.focusedSearchResult < searchResults.length - 1)
                    this.focusedSearchResult++;
                else
                    this.focusedSearchResult = 0;
            }

            if (this.focusedSearchResult == undefined)
                this.focusedSearchResult = 0;
    
            const a = searchResults[this.focusedSearchResult];
            a.focus();
        }
    }

    private query(query: string, options?: IQueryOptions): Promise<FeatureCollection<RoutingGeocodingFeatureProps, RoutingGeocodingProps>> {
        return new Promise<FeatureCollection<RoutingGeocodingFeatureProps, RoutingGeocodingProps>>(async (res, rej) => {
            try {
                const opts = this._nominatimService.options;
                const fc = new FeatureCollection();
                if (opts.GeocodeLocQueryUrl) {
                    const locs = await this._nominatimService.GeocodeLoc(query);
                    fc.features = locs.features.map(x => {
                        const ft = new Feature<RoutingGeocodingFeatureProps>();
                        ft.properties = RoutingGeocodingFeatureProps.fromGeocodeLocResponse(x.properties);
                        ft.geometry = x.geometry;
                        return ft;
                    });
                    fc.properties = RoutingGeocodingProps.fromGeocodeLocResponse(locs.properties);
                }
                else {
                    const locs = await this._nominatimService.query_v2(query, options);
                    fc.features = locs.features.map(x => {
                        const ft = new Feature<RoutingGeocodingFeatureProps>();
                        ft.properties = RoutingGeocodingFeatureProps.fromQueryv2Response(x.properties);
                        ft.geometry = x.geometry;
                        return ft;
                    });
                    fc.properties = RoutingGeocodingProps.fromQueryv2Response(locs.properties);
                }
                res(fc);
            }
            catch (e) {
                rej(e);
            }
        });
    }

    private reverse(coords: CoordinateLike, options?: IQueryOptions): Promise<FeatureCollection<RoutingGeocodingFeatureProps, RoutingGeocodingProps>> {
        return new Promise<FeatureCollection<RoutingGeocodingFeatureProps, RoutingGeocodingProps>>(async (res, rej) => {
            try {
                const opts = this._nominatimService.options;
                const fc = new FeatureCollection();
                if (opts.GeocodeLocReverseUrl) {
                    // const locs = await this._nominatimService.GeocodeLocReverse([e.coords.longitude, e.coords.latitude], { pageSize: 1 });
                    const locs = await this._nominatimService.GeocodeLocReverse(coords, options);
                    fc.features = locs.features.map(x => {
                        const ft = new Feature<RoutingGeocodingFeatureProps>();
                        ft.properties = RoutingGeocodingFeatureProps.fromGeocodeLocResponse(x.properties);
                        ft.geometry = x.geometry;
                        return ft;
                    });
                    fc.properties = RoutingGeocodingProps.fromGeocodeLocResponse(locs.properties);
                }
                else {
                    const locs = await this._nominatimService.reverse(coords, options);
                    fc.features = locs.features.map(x => {
                        const ft = new Feature<RoutingGeocodingFeatureProps>();
                        ft.properties = RoutingGeocodingFeatureProps.fromReverseGeocodingResponse(x.properties);
                        ft.geometry = x.geometry;
                        return ft;
                    });
                    fc.properties = RoutingGeocodingProps.fromReverseGeocodingResponse(locs.properties);
                }
                res(fc);
            }
            catch (e) {
                rej(e);
            }
        });
    }

    private stopSearchResultsHideTimeout = () => {
        if (this.searchResultsHideTimeout)
            clearTimeout(this.searchResultsHideTimeout);
    };

    private startSearchResultsHideTimeout = () => {
        this.stopSearchResultsHideTimeout();
        this.searchResultsHideTimeout = setTimeout(() => this.setSearchResultsStatus(false), 100);
    };

    private formatDate(date: Date): string {
        let day = date.getDay().toString();
        // if (day.length === 1)
        //     day = '0' + day;
        
        let month = date.getMonth().toString();
        // if (month.length === 1)
        //     month = '0' + month;
    
        let year = date.getFullYear().toString();

        let h = date.getHours().toString();
        if (h.length === 1)
            h = '0' + h;
        
        let min = date.getMinutes().toString();
        if (min.length === 1)
            min = '0' + min;
        
        let sec = date.getSeconds().toString();
        if (sec.length === 1)
            sec = '0' + sec;

        return `${day}.${month}.${year} ${h}:${min}`;
    }

    private refreshDisclaimerVisibility() {
        if (this._disclaimer && this._disclaimer.style) {
            this._disclaimer.style.display = this._map.metadata.enableRoutingDisclaimer && this.focusedTransportationMode != null && this.focusedTransportationMode.showDisclaimer !== false ? 'block' : 'none';
        }
    }

    private isMobile(): boolean {
        return window.innerWidth < 768;
        // return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    }
}

export class RoutingGeocodingFeatureProps {

    Title: string;
    FaIcon: string;
    
    public static fromQueryv2Response(res: Query_v2ResponseFeatureProperties): RoutingGeocodingFeatureProps {
        const prop = new RoutingGeocodingFeatureProps();
        prop.Title = res.Title;
        prop.FaIcon = this.getQueryv2Icon(res.Type);
        return prop;
    }

    public static fromReverseGeocodingResponse(res: ReverseGeocodingFeatureProperties): RoutingGeocodingFeatureProps {
        const prop = new RoutingGeocodingFeatureProps();
        prop.Title = res.Title;
        prop.FaIcon = this.getQueryv2Icon(res.Type);
        return prop;
    }

    public static fromGeocodeLocResponse(res: GeocodeLocFeatureProperties): RoutingGeocodingFeatureProps {
        const prop = new RoutingGeocodingFeatureProps();
        prop.Title = res.Title;
        prop.FaIcon = this.getGeocodeLocIcon(res.Type);
        return prop;
    }

    private static getQueryv2Icon(type: string): string {
        if (type === 'Naselje')
            return 'fa fa-city';
        else if (type === 'Ulica')
            return 'fa fa-road';
        else if (type === 'Postaja')
            return 'fa fa-bus';
        return 'fa-map-marker-alt';
    }

    private static getGeocodeLocIcon(type: GeocodeLocLocationType): string {
        if (type === 'rpe_ul') // ulice
            return 'fa fa-road';
        else if (type === 'kucni_brojevi' || type === 'stavbe_naslovi') // točen naslov - hišna številka
            return 'fa fa-building';
        else if (type === 'jls' || type === 'rpe_na') // naselje
            return 'fa fa-city';
        else if (type === 'dars_mp') // mejni prehodi
            return 'fa fa-passport';
        else if (type === 'dars_parking') // parkirišče
            return 'fa fa-parking';
        // else if (type === 'dars_predori') // predor
        //     return '';
        else if (type === 'dars_prk') // priključek
            return 'fa fa-road';
        else if (type === 'poi') // poi
            return 'fa fa-star';
        else
            return 'fa-map-marker-alt';
    }
}

export class RoutingGeocodingProps {
    HasNextPage: boolean;
    Page: number;
    
    public static fromQueryv2Response(res: Query_v2ResponseFeatureCollectionProperties): RoutingGeocodingProps {
        const prop = new RoutingGeocodingProps();
        prop.HasNextPage = res.NextPage;
        prop.Page = res.Page;
        return prop;
    }

    public static fromReverseGeocodingResponse(res: ReverseGeocodingProperties): RoutingGeocodingProps {
        const prop = new RoutingGeocodingProps();
        prop.HasNextPage = res.NextPage;
        prop.Page = res.Page;
        return prop;
    }

    public static fromGeocodeLocResponse(res: GeocodeLocFeatureCollectionProperties): RoutingGeocodingProps {
        const prop = new RoutingGeocodingProps();
        prop.HasNextPage = res.HasNext;
        prop.Page = Math.floor(res.Skip / res.Take); // tukaj ok, če je floor? načeloma mora biti Skip deljiv s Take
        return prop;
    }
}
