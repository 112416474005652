import { Feature } from '../../model/RM2Feature';
import { RouteFeatureCollection, IRouteHighlightOptions, RouteHighlightOptions, Route } from '../../services/RM2RoutingService';

export class RoutingControlOptions extends RouteHighlightOptions implements IRouteHighlightOptions {
    constructor(opts?: IRoutingControlOptions) {
        super(opts);
    }
}

export interface IRoutingControlOptions extends IRouteHighlightOptions {
}

export class RoutingInput {
    id: string;
    placeholderText: string;
    placeholderTextAlt: string;

    public set disabled(value: boolean) {
        this.getTarget().disabled = value;
        this._actionButtons.forEach(btn => btn.target.disabled = value);
    }

    private _element: HTMLInputElement;
    private _location: Feature;
    private _actionButtons: RoutingInputActionButton[] = [];

    constructor(id: string, placeholderText: string, placeholderTextAlt: string) {
        this.id = id;
        this.placeholderText = placeholderText;
        this.placeholderTextAlt = placeholderTextAlt;
    }

    public getTarget(): HTMLInputElement { return this._element; }
    public setTarget(target: HTMLInputElement) { this._element = target; }

    public getLocation(): Feature { return this._location; }
    public saveLocation(location: Feature) { this._location = location; }

    public addActionButton(button: RoutingInputActionButton) {
        const found = this.getActionButton(button.type);
        if (found)
            throw new Error('An action button of type \'' + RoutingInputActionButtonType[button.type] + '\' already exists in the routing input.');
        else
            this._actionButtons.push(button);
    }

    public getActionButton(type: RoutingInputActionButtonType) {  return this._actionButtons.find(btn => btn.type === type); }
}

export class RoutingInputActionButton {
    private _type: RoutingInputActionButtonType;
    public get type(): RoutingInputActionButtonType { return this._type; }

    private _target: HTMLButtonElement;
    public get target(): HTMLButtonElement { return this._target; }

    public get visible(): boolean { return this.target && this.target.style.display !== 'none'; };
    public set visible(visible: boolean) {
        if (this._target)
            this._target.style.display = visible !== false ? 'inline-block' : 'none';
    }

    constructor(type: RoutingInputActionButtonType, target: HTMLButtonElement, visible?: boolean) {
        this._type = type;
        this._target = target;

        if (visible != undefined)
            this.visible = visible;
    }
}

export enum RoutingInputActionButtonType {
    MyLocation = 0,
    PanToLocation = 1,
    Clear = 2
}

export class ToolbarItem {
    id: string;
    icon: string;
    showDisclaimer?: boolean = true;
    enabled?: boolean;
    element?: HTMLDivElement;
    data?: Route<any>;
}
