// BOOTSTRAP 4
export const DetailsTemplate = `
    <div class="bg-white h-100 position-relative">
        <!-- close -->
        <div id="rm2-nap-details-close" class="position-absolute p-1 close" style="right: 16px; top: 10px; cursor: pointer;"></div>

        <!-- header -->
        <div class="border-bottom">
            <div class="image" style="background-image: url({{resourcesPath}}/polnilnica.jpg)"></div>
            <div class="title p-4">
                <h4 style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden">{{Name}}</h4>
                <div class="text-muted">{{Zip}} {{City}}</div>
            </div>
        </div>

        <!-- tabs -->
        <ul class="nav nav-tabs shadow-sm" role="tablist">
            <li class="nav-item d-flex align-items-center w-50" role="presentation">
                <button class="nav-link green w-100 active" id="rm2-nap-details-tabs-mesta-tab" data-toggle="tab" data-target="#rm2-nap-details-tabs-mesta" type="button" role="tab" aria-controls="rm2-nap-details-tabs-mesta" aria-selected="true" data-i18n="ncup.chargingStations.detail.stations"></button>
            </li>
            <li class="nav-item d-flex align-items-center w-50" role="presentation">
                <button class="nav-link w-100" id="rm2-nap-details-tabs-podrobnosti-tab" data-toggle="tab" data-target="#rm2-nap-details-tabs-podrobnosti" type="button" role="tab" aria-controls="rm2-nap-details-tabs-podrobnosti" aria-selected="false" data-i18n="ncup.chargingStations.detail.details"></button>
            </li>
        </ul>
        <div class="tab-content" style="overflow-y: auto;">
            <!-- polnilna mesta -->
            <div class="tab-pane active" id="rm2-nap-details-tabs-mesta" role="tabpanel" aria-labelledby="rm2-nap-details-tabs-mesta-tab">
                {{#RefuelPoints}}
                    <div class="p-4 border-bottom nap-details-mesta">
                        <div class="d-flex mb-2">
                            <div class="d-flex align-items-center">
                                <img src="{{resourcesPath}}/polnilnica_aktivacija.svg" alt="{{StationName}}" style="width: 24px; height: 24px;" />
                            </div>
                            <div class="ml-4">
                                <div>{{StationName}}</div>
                                <div class="text-muted">{{Voltage}} V | {{MaximumCurrent}} A | {{ChargingPower}} kW</div>
                            </div>
                        </div>
                        <div class="d-flex mb-2">
                            <div class="badge badge-{{AvailableColor}} my-auto text-white" data-i18n="ncup.filter.availability.{{AvailableText}}"></div>
                            <div class="ml-2 my-auto">·</div>
                            {{#UsageTypes}}
                                <div class="ml-2 d-flex">
                                    <img src="{{resourcesPath}}/{{.}}.svg" alt="{{.}}" class="my-auto" style="width: 24px; height: 24px;"/>
                                </div>
                            {{/UsageTypes}}
                        </div>
                        {{#Connectors}}
                            <div class="d-flex">
                                <div>
                                    <img src="{{resourcesPath}}/{{Icon}}.svg" alt="{{Title}}" class="prikljucek-big" style="width: 24px; height: 24px;"/>
                                </div>
                                <div class="ml-4 d-flex flex-column justify-content-center">
                                    <div data-i18n="{{Title}}"></div>
                                    <!--<div class="text-muted">{{V}} V | {{A}} A | {{kW}} kW</div>-->
                                    <div class="text-muted">{{MaxPowerAtSocket}} kW</div>
                                </div>
                            </div>
                        {{/Connectors}}
                        {{#ElectricAdHocPrice}}
                            <div class="d-flex mb-2">
	                            <div class="d-flex align-items-center">
		                            <img src="/ncup_resources/ncup-map/assets/icons/polnilnica_placljivost.svg" alt="" class="rm2-nap-details-icon" style="width:24px; height:18px;">
	                            </div>
	                            <div class="ml-4">
		                            <div>{{ElectricAdHocPrice}} EUR</div>
	                            </div>
                            </div>
                        {{/ElectricAdHocPrice}}

                    </div>
                {{/RefuelPoints}}
            </div>

            <!-- podrobnosti -->
            <div class="tab-pane nap-details-podrobnosti" id="rm2-nap-details-tabs-podrobnosti" role="tabpanel" aria-labelledby="rm2-nap-details-tabs-podrobnosti-tab">
                <!-- podatki -->
                <div class="p-4 border-bottom">

                    <!-- trenutno odprto -->
                    <a class="rm2-nap-details-odprto d-flex" data-toggle="collapse" href="#rm2-nap-details-odprto-collapse" role="button" aria-expanded="false" aria-controls="rm2-nap-details-odprto-collapse">
                        <div>
                            <img src="{{resourcesPath}}/polnilnica_odpiralni.svg" alt="" class="rm2-nap-details-icon" />
                        </div>
                        <div class="ml-4 flex-grow-1">
                            <div class="d-flex align-items-start">
                                <span data-i18n="ncup.chargingStations.open.currently"></span>&nbsp;

                                <span data-i18n="{{TodayOpenStatus}}" class="font-weight-bold"></span>
                                <span class="ml-1 nap-caret nap-caret-up">&#9650;</span>

                                <div class="time ml-1 d-flex align-items-center">
                                    <div>
                                        {{#TodayOpenTimes}}
                                            <div>
                                                - {{.}}
                                            </div>
                                        {{/TodayOpenTimes}}
                                    </div>

                                    <span class="ml-1 nap-caret">&#9660;</span>
                                </div>
                            </div>
                            
                            <!-- dnevi -->
                            <div class="collapse mt-1" id="rm2-nap-details-odprto-collapse">
                                {{#Days}}
                                    <div class="d-flex align-items-start {{CSSClasses}}">
                                        <div>{{Title}}</div>
                                        <div class="ml-auto d-flex flex-column align-items-end">
                                            {{#OpenTimes}}
                                                <div>
                                                    {{.}}
                                                </div>
                                            {{/OpenTimes}}
                                        </div>
                                    </div>
                                {{/Days}}
                            </div>
                        </div>
                    </a>
                    
                    <!-- polnjenje plačljivo -->
                    <div class="mt-2 d-flex">
                        <div>
                            <img src="{{resourcesPath}}/polnilnica_placljivost.svg" alt="" class="rm2-nap-details-icon" />
                        </div>
                        <div class="ml-4">
                            {{#ElectricAdHocPrice}}
                                <div>
                                    <span data-i18n="ncup.chargingStations.price.type"></span>&nbsp;<span data-i18n="ncup.chargingStations.price.pay"></span>
                                </div>
                                <div class="fs-10px">
                                    <span data-i18n="ncup.chargingStations.price.adhoc"></span>: {{.}} € / kWh
                                </div>
                                <div class="fs-10px">{{PaymentTypesText}}</div>
                            {{/ElectricAdHocPrice}}
                            {{^ElectricAdHocPrice}}
                                {{#hasAdHocPrice}}
                                    <div>
                                        <span data-i18n="ncup.chargingStations.price.type"></span>&nbsp;<span data-i18n="ncup.chargingStations.price.free"></span>
                                    </div>
                                {{/hasAdHocPrice}}
                                {{^hasAdHocPrice}}
                                    <div>
                                    {{#hasPerChargingPointPrice}}
                                        <span data-i18n="ncup.chargingStations.price.perChargingPoint"></span>
                                    {{/hasPerChargingPointPrice}}
                                    {{^hasPerChargingPointPrice}}
                                        <span data-i18n="ncup.chargingStations.price.unknown"></span>
                                    {{/hasPerChargingPointPrice}}
                                    </div>
                                {{/hasAdHocPrice}}
                            {{/ElectricAdHocPrice}}

                            <!--<div class="fs-10px">
                                <span data-i18n="ncup.chargingStations.price.payment-types"></span>:&nbsp
                                {{#PaymentTypes}}
                                    <span>{{.}}</span>
                                {{/PaymentTypes}}
                            </div>-->
                        </div>
                    </div>

                    <!-- načini plačila -->
                    <div class="mt-2 d-flex">
                        <div>
                            <img src="{{resourcesPath}}/polnilnica_aktivacija.svg" alt="" class="rm2-nap-details-icon" />
                        </div>
                        <div class="ml-4">
                            {{#Authentications}}
                                <div class="line" data-i18n="ncup.chargingStations.authenticationTypes.{{.}}"></div>
                            {{/Authentications}}
                        </div>
                    </div>

                    <!-- vrste polnjenja -->
                    <!--<div class="mt-2">
                        <span data-i18n="ncup.chargingStations.types.title"></span>: {{ChargingTypes}}
                    </div>-->
                </div>

                <!-- ponudnik in upravljalec -->
                <div class="p-4 border-bottom">
                    <div class="font-weight-bold" data-i18n="ncup.chargingStations.provider"></div>

                    <div class="mt-4">
                        <div class="d-flex">
                            <!-- ime -->
                            <div>
                                <img src="{{resourcesPath}}/polnilnica_upravljalec_ime.svg" alt="" class="rm2-nap-details-icon" />
                            </div>
                            <div class="ml-4 line">{{UpravljalecName}}</div>
                        </div>

                        <!-- telefon -->
                        <div class="mt-2 d-flex">
                            <div>
                                <img src="{{resourcesPath}}/polnilnica_upravljalec_telefon.svg" alt="" class="rm2-nap-details-icon" />
                            </div>
                            <div class="ml-4 line">{{UpravljalecTel}}</div>
                        </div>

                        <!-- web -->
                        <div class="mt-2 d-flex">
                            <div>
                                <img src="{{resourcesPath}}/polnilnica_upravljalec_link.svg" alt="" class="rm2-nap-details-icon" />
                            </div>
                            <div class="ml-4 line"><a href="{{UpravljalecWeb}}" target="_blank" rel="noopener">{{UpravljalecWeb}}</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
`;
