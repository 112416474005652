import * as maplibregl from 'maplibre-gl';
import { FilterSpecification } from '@maplibre/maplibre-gl-style-spec';


import Mustache from 'mustache';
import { FilterTemplate } from './templates/filter-template';
import { Map, PersistedVisibility } from '../../map/RM2Map';
import { NAPControlOptions, INAPControlOptions } from './RM2NAPControlOptions';
import { DetailsTemplate } from './templates/details-template';
import { LayerGroup } from '../../model/style-metadata/RM2LayerGroup';
import { LocalizationService } from '../../services/RM2LocalizationService';
import { ServiceType } from '../../services/RM2Service';
import { Feature } from '../../model/RM2Feature';
import { Layer } from '../../model/RM2Layer';
import { Point } from '../../model/RM2Geometry';

declare let $: any;

export class NAPControl implements maplibregl.IControl {
    protected _map: Map;

    private _options: INAPControlOptions;

    private _localizationService: LocalizationService;

    private _button: HTMLButtonElement;
    private _filter: HTMLDivElement;
    private _details: HTMLDivElement;

    private get responsiveBreakpoint(): number { return 503; }

    private get filterWidth(): string { return '280px'; }
    private get detailsWidth(): string { return '300px'; }

    private get chargingLayerId(): string { return 'ncup.charging'; }

    constructor(map: Map, opts?: INAPControlOptions) {
        this._map = map;
        this._options = new NAPControlOptions(opts);

        this._map.onFeaturePopupClicked.subscribe(p => {
            if (p.properties.contentName === 'ncup.charging') {
                this.getPolnilnice([p])
                    .then(polnilnice => {
                        this.setFilterStatus(false);
                        this.setDetailsStatus(polnilnice[0]);
                        this._map.closeAllPopups();
                    })
                    .catch(ex => console.error(ex));
            }
        });

        this._connectorsDict['chademo'] = { icon: 'prikljucek_CHAdeMO_green', title: 'ncup.filter.connectors.CHAdeMO' };
        this._connectorsDict['cee3'] = { icon: 'prikljucek_3_polni_green', title: 'ncup.filter.connectors.3-polni' };
        this._connectorsDict['cee5'] = { icon: 'prikljucek_5_polni_green', title: 'ncup.filter.connectors.5-polni' };
        this._connectorsDict['domestic'] = { icon: 'prikljucek_schuko_green', title: 'ncup.filter.connectors.schuko' };
        this._connectorsDict['iec62196T1'] = { icon: 'prikljucek_type1_green', title: 'ncup.filter.connectors.type-1' };
        this._connectorsDict['iec62196T1COMBO'] = { icon: 'ccs_1_sae_green', title: 'ncup.filter.connectors.ccs-1-sae' };
        this._connectorsDict['iec62196T2'] = { icon: 'prikljucek_type2_green', title: 'ncup.filter.connectors.type-2' };
        this._connectorsDict['iec62196T2COMBO'] = { icon: 'ccs_2_sae_green', title: 'ncup.filter.connectors.ccs-2-sae' };
        this._connectorsDict['teslaR'] = { icon: 'prikljucek_roadster_green', title: 'ncup.filter.connectors.roadster' };
        this._connectorsDict['teslaS'] = { icon: 'prikljucek_supercharge_green', title: 'ncup.filter.connectors.supercharge' };

        // this._vehiclesDict['electricalDevices'] = { icon: , title: 'ncup.filter.vehicle.devices' };
        // this._vehiclesDict['electricBikeOrMotorcycle'] = { icon: , title: 'ncup.filter.vehicle.kolo-motor-skiro' };
        // this._vehiclesDict['electricVehicle'] = { icon: , title: 'ncup.filter.vehicle.car' };
        // this._vehiclesDict['lorryPowerConsumption'] = { icon: , title: 'ncup.filter.vehicle.tovorno' };
        // this._vehiclesDict['motorhomeOrCaravanSupply'] = { icon: , title: 'ncup.filter.vehicle.avtodom' };
        // this._vehiclesDict['other'] = { icon: , title: 'ncup.filter.vehicle.other' };

        this._localizationService = this._map.getService(ServiceType.Localization) as LocalizationService;

        this._button = this.create();

        window.addEventListener('resize', this.resizeElements);
        this.resizeElements();
    }

    private create(): HTMLButtonElement {
        // open filter btn
        const button = document.createElement('button');
        button.setAttribute('type', 'button');
        const icon = document.createElement('div');
        icon.className = 'rm2-nap-filter-icon';
        button.appendChild(icon);
        button.onclick = () => this.setFilterStatus(true);
        // button.onclick = () => this.setDetailsStatus(true);

        // filter panel
        this.createFilter();

        // details panel
        this.createDetails();

        return button;
    }

    private createFilter() {
        const filter = document.createElement('div');
        filter.className = 'rm2-nap-filter position-absolute animate-left';

        const prometGroups = this.getPrometGroups();
        filter.innerHTML = Mustache.render(FilterTemplate, {
            resourcesPath: this._options.resourcesPath,
            prometGroups: prometGroups
        });

        // moč polnjenja slider
        $(filter).find("#nap-filter-moc-polnjenja").slider({
            range: true,
            min: 0,
            max: 120,
            values: [0, 120],
            change: (e, ui) => {
                const vals = ui.values;
                this.onFilterChange();
                filter.querySelector('#nap-filter-moc-bottom').innerHTML = `${vals[0]} kW`;
                filter.querySelector('#nap-filter-moc-top').innerHTML = `${vals[1]} kW`;
            }
        });

        // attach filter change listeners
        filter.querySelectorAll('input[type="checkbox"]').forEach((l: HTMLInputElement) => l.onchange = () => this.onFilterChange());

        this._localizationService.refresh(filter);
        this._filter = filter;
        this.attachSlojiToggleEventsListeners(prometGroups);

        const close: HTMLElement = filter.querySelector('#rm2-nap-filter-close');
        close.onclick = () => this.setFilterStatus(false);

        this.refreshFilterSize();
        this.setFilterStatus(false);
        // this._button.parentNode.parentNode.appendChild(filter);
    }

    private createDetails() {
        const details = document.createElement('div');
        details.className = 'rm2-nap-details position-absolute animate-left';
        this._details = details;

        this.setDetailsStatus(null);
    }

    private setFilterStatus(visible: boolean) {
        if (this._filter == undefined)
            return;

        const l = visible ? '0px' : `-${this.getFilterWidth()}`;
        this._filter.style.left = l;
    }

    private setDetailsStatus(data: Feature<EnergySiteWebDTO>) {
        if (data) {
            const now = new Date();
            let dayNow = now.getDay();
            if (dayNow === 0)
                dayNow = 7;

            let todayOpenTimes: string[] = [];
            let isOpenToday: boolean;
            const days = this.getDaysArray().map((x, i) => {
                let today = new Date().getDay();
                if (today === 0)
                    today = 7;

                const title = this._localizationService.localize(`ncup.days.day-${i + 1}`);
                const isToday = i + 1 === today;
                const cssClasses = isToday ? 'font-weight-bold' : '';
                const xWorkingHours = data.properties.WorkingHours.find(y => y.DayInWeek === x);

                if (xWorkingHours) {
                    const openTimes = xWorkingHours.OpenPeriods.map(h => h.stringify());
                    if (isToday) {
                        const currOpen = xWorkingHours.isCurrentlyOpen();
                        if (currOpen)
                            isOpenToday = true;

                        todayOpenTimes = openTimes.slice();
                    }

                    return {
                        Title: title,
                        OpenTimes: openTimes,
                        CSSClasses: cssClasses
                    };
                }
                else {
                    return {
                        Title: title,
                        OpenTimes: ['--'],
                        CSSClasses: cssClasses
                    };
                }
            });

            this._details.innerHTML = Mustache.render(DetailsTemplate, Object.assign(data.properties, {
                resourcesPath: this._options.resourcesPath,
                RefuelPoints: data.properties.RefuelPoints.map(p => {
                    return Object.assign(p, {
                        Connectors: p.Connectors.map(c => {
                            const data = this.getConnectorData(c.ChargingInterface);
                            return {
                                Title: data.title,
                                Icon: data.icon,
                                MaxPowerAtSocket: c.MaxPowerAtSocket
                            }
                        })
                    });
                }),
                Days: days,
                TodayOpenTimes: todayOpenTimes,
                TodayOpenStatus: isOpenToday ? 'ncup.chargingStations.open.open' : 'ncup.chargingStations.open.closed',
                PaymentTypesText: data.properties.PaymentTypes && data.properties.PaymentTypes.length > 0 ? `${this._localizationService.localize('ncup.chargingStations.price.payment-types.title')}: ${data.properties.PaymentTypes.map(x => this._localizationService.localize(`ncup.chargingStations.price.payment-types.${x}`)).join(', ')}` : null
                // ChargingTypes: 'AC, DC, CCS'
            }));
            this._localizationService.refresh(this._details);

            const close: HTMLElement = this._details.querySelector('#rm2-nap-details-close');
            close.onclick = () => this.setDetailsStatus(null);
            this.refreshDetailsSize();
        }

        const l = data ? '0px' : `-${this.getDetailsWidth()}`;
        this._details.style.left = l;
    }

    private resizeElements = () => {
        this.refreshFilterSize();
        this.refreshDetailsSize();
    };

    private refreshFilterSize() {
        if (this._filter == undefined)
            return;

        const size = this._map.getSize();
        const h = size[1];

        this._filter.style.width = this.getFilterWidth();
        this._filter.style.height = `${h}px`;
        const currLeft = this._filter.style.left;
        if (currLeft.startsWith('-'))
            this._filter.style.left = `-${this.getFilterWidth()}`;

        // refresh tab content height
        const tabs: HTMLElement = this._filter.querySelector('.tab-content');
        tabs.style.height = `${h - 120}px`;
    }

    private refreshDetailsSize() {
        if (this._details == undefined)
            return;

        const size = this._map.getSize();
        const h = size[1];

        this._details.style.width = this.getDetailsWidth();
        this._details.style.height = `${h}px`;
        const currLeft = this._details.style.left;
        if (currLeft.startsWith('-'))
            this._details.style.left = `-${this.getDetailsWidth()}`;

        // refresh tab content height
        const tabs: HTMLElement = this._details.querySelector('.tab-content');
        if (tabs)
            tabs.style.height = `${h - 220}px`;
    }

    // TODO: to poenotiti s PrometSiControl (podvojeno)
    private attachSlojiToggleEventsListeners(prometGroups: { layers: LayerGroup[], isCheckbox: boolean }[]) {
        // legend checkboxes
        const checkboxes = this._filter.querySelectorAll(".nap-filter-sloji input[type='checkbox']");
        checkboxes.forEach((c: HTMLInputElement) => {
            c.checked = (prometGroups.filter(l => l.isCheckbox === true).map(l => l.layers) as any).flat().find((g: LayerGroup) => g.id === c.name).visible !== false; // set initial UI
            c.addEventListener('change', e => {
                this._map.setGroupVisibility(c.name, c.checked);
                this._map.persistVisibility([{ type: 'group', id: c.name, visible: c.checked }]);
            }); // set visibility
        });

        // legend radios
        const radios = this._filter.querySelectorAll(".nap-filter-prikaz input[type='radio']");
        radios.forEach((c: HTMLInputElement) => {
            const parent: LayerGroup = (prometGroups.filter(l => l.isCheckbox === false).map(l => l.layers) as any).flat().find((g: LayerGroup) => g.id === c.name);
            c.checked = parent.children.find(l => l.id === c.value).visible !== false; // set initial UI
            c.addEventListener('change', e => {
                const filtered = parent.children.filter(child => child.id !== c.value);
                filtered.forEach(other => this._map.setGroupVisibility(other.id, false));
                this._map.setGroupVisibility(c.value, true); // set visibility

                const persist: PersistedVisibility[] = filtered.map(x => {
                    return {
                        type: 'group',
                        id: x.id,
                        visible: false
                    };
                });

                persist.push({
                    type: 'group',
                    id: c.id,
                    visible: true
                });

                this._map.persistVisibility(persist);
            });
        })

        // group visibility update
        this._map.onGroupVisibilityChanged.subscribe(g => {
            const parent = g.getParent();
            if (parent && parent.sequenceMode === 'choice') {
                // update radios
                const radios = this._filter.querySelectorAll(`[name="${parent.id}"]`);
                // radios.forEach((radio: HTMLInputElement) => updateLegendItemUI(radio, radio.value === g.id));
            }
            else {
                // update checkbox
                const checkbox: HTMLInputElement = this._filter.querySelector(`[id^='nap-filter-prikaz-${g.id}']`);
                // checkbox.checked = g.visible !== false;
                // updateLegendItemUI(checkbox, g.visible !== false);
            }
        });
    }

    private getPrometGroups(): { layers: LayerGroup[], isCheckbox: boolean }[] {
        const metadata = this._map.metadata;
        if (metadata && metadata.groups) {
            const groups = metadata.groups.filter(g => g.id && g.uiVisible !== false);
            const radio = groups.filter(g => g.sequenceMode === 'choice');
            const checkbox = groups.filter(g => g.sequenceMode !== 'choice');

            return [
                {
                    layers: checkbox,
                    isCheckbox: true
                },
                {
                    layers: radio,
                    isCheckbox: false
                }
            ]
        }

        return [];
    }

    private filterReq: XMLHttpRequest;
    private filterDebounce: any;
    private onFilterChange() {
        if (this.filterDebounce) {
            clearTimeout(this.filterDebounce);
            this.filterDebounce = null;
        }

        if (this.filterReq) {
            this.filterReq.abort();
            this.filterReq = null;
        }

        this.filterDebounce = setTimeout(() => {
            const zasedenost_prosto: HTMLInputElement = this._filter.querySelector('#nap-filter-zasedenost-prosto');

            const mocPolnjenja = $(this._filter).find("#nap-filter-moc-polnjenja").slider('values');

            const prikljucek_3polni: HTMLInputElement = this._filter.querySelector('#nap-filter-prikljucki-3-polni');
            const prikljucek_5polni: HTMLInputElement = this._filter.querySelector('#nap-filter-prikljucki-5-polni');
            const prikljucek_schuko: HTMLInputElement = this._filter.querySelector('#nap-filter-prikljucki-schuko');
            const prikljucek_type1: HTMLInputElement = this._filter.querySelector('#nap-filter-prikljucki-type-1');
            const prikljucek_type2: HTMLInputElement = this._filter.querySelector('#nap-filter-prikljucki-type-2');
            const prikljucek_chademo: HTMLInputElement = this._filter.querySelector('#nap-filter-prikljucki-CHAdeMO');
            const prikljucek_ccs1sae: HTMLInputElement = this._filter.querySelector('#nap-filter-prikljucki-ccs-1-sae');
            const prikljucek_ccs2sae: HTMLInputElement = this._filter.querySelector('#nap-filter-prikljucki-ccs-2-sae');
            const prikljucek_roadster: HTMLInputElement = this._filter.querySelector('#nap-filter-prikljucki-roadster');
            const prikljucek_supercharge: HTMLInputElement = this._filter.querySelector('#nap-filter-prikljucki-supercharge');

            const aktivacija_kartica: HTMLInputElement = this._filter.querySelector('#nap-filter-nacin-aktivacije-kartica');
            const aktivacija_app: HTMLInputElement = this._filter.querySelector('#nap-filter-nacin-aktivacije-app');
            const aktivacija_sms: HTMLInputElement = this._filter.querySelector('#nap-filter-nacin-aktivacije-sms');
            const aktivacija_pnc: HTMLInputElement = this._filter.querySelector('#nap-filter-nacin-aktivacije-pnc');

            const cena_brezplacno: HTMLInputElement = this._filter.querySelector('#nap-filter-cena-brezplacno');

            const obrCas_24ur: HTMLInputElement = this._filter.querySelector('#nap-filter-obr-cas-24ur');
            const obrCas_trenutnoOdprto: HTMLInputElement = this._filter.querySelector('#nap-filter-obr-cas-trenutno');

            const vrsta_avto: HTMLInputElement = this._filter.querySelector('#nap-filter-vrsta-avto');
            const vrsta_koloMotorSkiro: HTMLInputElement = this._filter.querySelector('#nap-filter-vrsta-kolo-motor-skiro');
            const vrsta_avtodom: HTMLInputElement = this._filter.querySelector('#nap-filter-vrsta-avtodom');
            const vrsta_tovorno: HTMLInputElement = this._filter.querySelector('#nap-filter-vrsta-tovorno-vozilo');

            const dto: IPolnilniceFilterRequest = {
                MocPolnjenjaMin: mocPolnjenja[0],
                MocPolnjenjaMax: mocPolnjenja[1]
            };

            if (zasedenost_prosto.checked) dto.ZasedenostProsto = true;

            if (prikljucek_3polni.checked) dto.Prikljucki3Polni = true;
            if (prikljucek_5polni.checked) dto.Prikljucki5Polni = true;
            if (prikljucek_schuko.checked) dto.PrikljuckiSchuko = true;
            if (prikljucek_type1.checked) dto.PrikljuckiType1 = true;
            if (prikljucek_type2.checked) dto.PrikljuckiType2 = true;
            if (prikljucek_chademo.checked) dto.PrikljuckiCHAdeMO = true;
            if (prikljucek_ccs1sae.checked) dto.PrikljuckiCCS1SAE = true;
            if (prikljucek_ccs2sae.checked) dto.PrikljuckiCCS2SAE = true;
            if (prikljucek_roadster.checked) dto.PrikljuckiRoadster = true;
            if (prikljucek_supercharge.checked) dto.PrikljuckiSupercharge = true;

            if (aktivacija_kartica.checked) dto.NacinAktivacijeKartica = true;
            if (aktivacija_app.checked) dto.NacinAktivacijeAplikacija = true;
            if (aktivacija_sms.checked) dto.NacinAktivacijeSMS = true;
            if (aktivacija_pnc.checked) dto.NacinAktivacijePlugAndCharge = true;

            if (cena_brezplacno.checked) dto.CenaBrezplacno = true;

            if (obrCas_24ur.checked) dto.ObratovalniCas24Ur = true;
            if (obrCas_trenutnoOdprto.checked) dto.ObratovalniCasTrenutnoOdprto = true;

            if (vrsta_avto.checked) dto.VrstaVozilaAvto = true;
            if (vrsta_koloMotorSkiro.checked) dto.VrstaVozilaKoloMotorSkiro = true;
            if (vrsta_avtodom.checked) dto.VrstaVozilaAvtodom = true;
            if (vrsta_tovorno.checked) dto.VrstaVozilaTovornoVozilo = true;

            this.filterReq = new XMLHttpRequest();
            this.filterReq.timeout = 10000;
            this.filterReq.onload = () => {
                if (this.filterReq.responseText) {
                    const ids: number[] = JSON.parse(this.filterReq.responseText);
                    const filter: FilterSpecification = [
                        "all",
                        ["in", ["to-number", ["get", "id"]], ["literal", ids]]
                    ];

                    if (dto.ZasedenostProsto === true) {
                        filter.push([
                            "all",
                            [
                                "==",
                                ["get", "isOperational"],
                                "True"
                            ],
                            [
                                "==",
                                ["get", "isFree"],
                                "True"
                            ]
                        ]);
                    }

                    this._map.setFilter(this.chargingLayerId, filter);
                }
            };

            const url = `${this._options.napDcUrl}/naps.prometej.filter`;
            this.filterReq.ontimeout = () => { };
            this.filterReq.onerror = () => { };
            this.filterReq.onabort = () => { };
            this.filterReq.open('POST', url, true);
            this.filterReq.setRequestHeader('Content-Type', 'application/json');
            this.filterReq.send(JSON.stringify(dto));
        }, 300);
    }

    private polnilnicaReq: XMLHttpRequest;
    private getPolnilnice(fts: Feature[]): Promise<Feature<EnergySiteWebDTO>[]> {
        return new Promise<Feature<EnergySiteWebDTO>[]>((res, rej) => {
            if (this.polnilnicaReq) {
                this.polnilnicaReq.abort();
                this.polnilnicaReq = null;
            }

            this.polnilnicaReq = new XMLHttpRequest();
            this.polnilnicaReq.timeout = 10000;
            this.polnilnicaReq.onload = () => {
                if (this.polnilnicaReq.responseText) {
                    let response: any[] = JSON.parse(this.polnilnicaReq.responseText);
                    response = response.map(x => EnergySiteWebDTO.fromGeoJson(x));

					//let uprWeb = newFt.properties.UpravljalecWeb;
					//if(uprWeb.startsWith("http://")===false && uprWeb.startsWith("https://")===false)
					//	uprWeb= "https://" + uprWeb;
                    const newFts: Feature<EnergySiteWebDTO>[] = fts.map((x, i) => {
                        const newFt = new Feature<EnergySiteWebDTO>(response[i]);
                        newFt.properties.ContentName = 'ncup.charging';
                        newFt.geometry = Point.fromCoordinate([newFt.properties.Long, newFt.properties.Lat]);
                        (newFt.properties as any).isOperational = x.properties.isOperational === 'True';
                        (newFt.properties as any).isFree = x.properties.isFree === 'True';
                        (newFt.properties as any).hasAdHocPrice = !(x.properties.ElectricAdHocPrice === null || x.properties.ElectricAdHocPrice === undefined);
                        (newFt.properties as any).hasPerChargingPointPrice = (newFt.properties.PriceDefinitionType === "PerChargingPoint");
						(newFt.properties as any).UpravljalecWeb = (newFt.properties.UpravljalecWeb && newFt.properties.UpravljalecWeb.indexOf("https://")===-1 ? "https://"+newFt.properties.UpravljalecWeb : newFt.properties.UpravljalecWeb);
                        return newFt;
                    });

                    res(newFts);
                }
            };

            const url = `${this._options.napDcUrl}/naps.prometej.polnilnica.detail`;
            this.polnilnicaReq.ontimeout = () => rej('timeout');
            this.polnilnicaReq.onerror = (e) => rej(e);
            this.polnilnicaReq.onabort = () => rej('abort');
            this.polnilnicaReq.open('POST', url, true);
            this.polnilnicaReq.setRequestHeader('Content-Type', 'application/json');

            const ids = fts.map(x => Number.parseInt(x.properties.id));
            this.polnilnicaReq.send(JSON.stringify(ids));
        });
    }

    private getFilterWidth(): string {
        const width = this._map.getSize()[0];
        return width > this.responsiveBreakpoint ? this.filterWidth : `${width - 75}px`;
    }

    private getDetailsWidth(): string {
        const width = this._map.getSize()[0];
        return width > this.responsiveBreakpoint ? this.detailsWidth : `${width - 75}px`;
    }

    private getDaysArray(): string[] {
        return [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
        ];
    }

    private getTodayWorkingHours(hours: OpeningTimesWebDTO[]): OpeningTimesWebDTO {
        let today = new Date().getDay();
        if (today === 0)
            today = 7;

        const weekdays = this.getDaysArray();
        const todayOpen = hours.find(x => x.DayInWeek === weekdays[today - 1]);
        return todayOpen;
    }

    private _connectorsDict: { [id: string]: { icon: string, title: string } } = {};
    private _vehiclesDict: { [id: string]: { icon: string, title: string } } = {};
    private getConnectorData(type: ChargingInterfaceTypeWebAppEnum): { icon: string, title: string } {
        const data = this._connectorsDict[type];
        if (data)
            return data;
        return { icon: 'prikljucek_unknown_green', title: 'ncup.filter.connectors.other' };
    }

    onAdd(map: maplibregl.Map): HTMLElement {
        const container = document.createElement('div');

        const btnWrapper = document.createElement('div');
        btnWrapper.className = 'maplibregl-ctrl maplibregl-ctrl-group';
        btnWrapper.appendChild(this._button);

        container.appendChild(btnWrapper);
        container.appendChild(this._details);
        container.appendChild(this._filter);

        return container;
    }

    onRemove() {
        this._button.parentNode.removeChild(this._button);
        this._filter.parentNode.removeChild(this._filter);
        this._details.parentNode.removeChild(this._details);
    }

    getDefaultPosition?: () => maplibregl.ControlPosition = () => 'top-left';
}

export interface IPolnilniceFilterRequest {
    ZasedenostProsto?: boolean; // če je polnilnica prosta

    MocPolnjenjaMin: number; // minimalna moč polnjenja polnilnice (dobiš skupaj z MocPolnjenjaMax)
    MocPolnjenjaMax: number; // maksimalna moč polnjenja polnilnice (dobiš skupaj z MocPolnjenjaMin)

    Prikljucki3Polni?: boolean; // polnilnica ima 3-polni prikljucek
    Prikljucki5Polni?: boolean; // polnilnica ima 5-polni prikljucek
    PrikljuckiSchuko?: boolean; // polnilnica ima Schuko prikljucek
    PrikljuckiType1?: boolean; // polnilnica ima Type-1 prikljucek
    PrikljuckiType2?: boolean; // polnilnica ima Type-2 prikljucek
    PrikljuckiCHAdeMO?: boolean; // polnilnica ima CHAdeMO prikljucek
    PrikljuckiCCS1SAE?: boolean; // polnilnica ima CCS1SAE prikljucek
    PrikljuckiCCS2SAE?: boolean; // polnilnica ima CCS1SAE prikljucek
    PrikljuckiRoadster?: boolean; // polnilnica ima roadster prikljucek
    PrikljuckiSupercharge?: boolean; // polnilnica ima supercharge prikljucek

    NacinAktivacijeKartica?: boolean; // polnilnica ima način aktivacije s kartico
    NacinAktivacijeAplikacija?: boolean; // polnilnica ima način aktivacije z aplikacijo
    NacinAktivacijeSMS?: boolean; // polnilnica ima način aktivacije z SMS
    NacinAktivacijePlugAndCharge?: boolean; // polnilnica ima način aktivacije s plug & charge oz. unlimited

    CenaBrezplacno?: boolean; // polnilnica je brezplačna

    ObratovalniCas24Ur?: boolean; // polnilnica obratuje 24ur
    ObratovalniCasTrenutnoOdprto?: boolean; // polnilnica trenutno obratuje

    VrstaVozilaAvto?: boolean; // na polnilnice lahko polniš avto
    VrstaVozilaKoloMotorSkiro?: boolean; // na polnilnice lahko polniš kolo, motor, skiro
    VrstaVozilaAvtodom?: boolean; // na polnilnice lahko polniš avtodom
    VrstaVozilaTovornoVozilo?: boolean; // na polnilnice lahko polniš tovorno vozilo
};

export class EnergySiteWebDTO {
    // internal
    ContentName: string;


    Name: string;
    Street: string;
    Street1: string;
    HouseNumber: string;
    Zip: string;
    City: string;
    // LocationType: LocationEnum;
    RefuelPoints: EnergyRefuelPointWebDTO[];
    WorkingHours: OpeningTimesWebDTO[];
    Authentications: AuthenticationAndIdentificationEnum[];
    PaymentTypes: string[];
    Lat?: number;
    Long?: number;
    ElectricAdHocPrice: number;
    PriceDefinitionType: string;
    UpravljalecName: string;
    UpravljalecTel: string;
    UpravljalecMail: string;
    UpravljalecWeb: string;

    public static fromGeoJson(geojson: any): EnergySiteWebDTO {
        const obj = new EnergySiteWebDTO();
        const keys = Object.keys(geojson);
        for (let i = 0; i < keys.length; i++)
            obj[keys[i]] = geojson[keys[i]];
        obj.WorkingHours = obj.WorkingHours.map(x => OpeningTimesWebDTO.fromGeojson(x));
        return obj;
    }
}

export interface EnergyRefuelPointWebDTO {
    StationName: string;
    StationIndex: number;
    RefuelPointName: string;
    RefuelPointIndex: number;
    InUse: boolean;
    Operational: boolean;
    ParkingFree: boolean;
    Voltage: number;
    ChargingPower: number;
    MaximumCurrent: number;
    Connectors: ConnectorWebDTO[];
    UsageTypes: ChargingPointUsageTypeEnum[];
}

export interface ConnectorWebDTO {
    ChargingInterface: ChargingInterfaceTypeWebAppEnum;
    MaxPowerAtSocket: number;
}

export enum ChargingPointUsageTypeEnum
{
    electricalDevices,
    electricBikeOrMotorcycle,
    electricVehicle,
    lorryPowerConsumption,
    motorhomeOrCaravanSupply,
    other
}

export enum ChargingInterfaceTypeWebAppEnum {
    chademo,
    cee3,
    cee5,
    domestic,
    iec62196T1,
    iec62196T1COMBO,
    iec62196T2,
    iec62196T2COMBO,
    teslaR,
    teslaS,
    other
}

export enum AuthenticationAndIdentificationEnum {
    activeRFIDChip,
    apps,
    calypso,
    cashPayment,
    creditCard,
    debitCard,
    mifareClassic,
    mifareDesfire,
    nfc,
    overTheAir,
    phoneDialog,
    phoneSMS,
    pinpad,
    plc,
    prepaidCard,
    rfid,
    website,
    unlimitedAccess,
    noAccess
}

export class OpenPeriodWebDTO {
    OpenFrom: number;
    OpenTill: number;

    constructor(openFrom: number, openTill: number) {
        this.OpenFrom = openFrom;
        this.OpenTill = openTill;
    }

    public stringify(): string {
        const openFromH = Math.floor(this.OpenFrom / 60);
        let openFromMin = (this.OpenFrom % 60).toString();
        if (openFromMin.length === 1)
            openFromMin = '0' + openFromMin;

        let openTill = this.OpenTill === 0 ? 1439 : this.OpenTill;
        const openTillH = Math.floor(openTill / 60);
        let openTillMin = (openTill % 60).toString();
        if (openTillMin.length === 1)
            openTillMin = '0' + openTillMin;

        return `${openFromH}:${openFromMin} - ${openTillH}:${openTillMin}`;
    }
}

export class OpeningTimesWebDTO {
    // DayInWeek: DayInWeekEnum;
    DayInWeek: string;
    OpenPeriods: OpenPeriodWebDTO[];

    public isCurrentlyOpen(): boolean {
        const now = new Date();
        const mins = now.getHours() * 60 + now.getMinutes();
        for (let i = 0; i < this.OpenPeriods.length; i++) {
            const p = this.OpenPeriods[i];
            if ((p.OpenFrom <= mins && p.OpenTill >= mins) || (p.OpenFrom === 0 && p.OpenTill === 0))
                return true;
        }
        return false;
    }

    public static fromGeojson(geojson: any): OpeningTimesWebDTO {
        const obj = new OpeningTimesWebDTO();
        obj.DayInWeek = geojson.DayInWeek;
        obj.OpenPeriods = geojson.OpenPeriods.map((p: any) => new OpenPeriodWebDTO(p.OpenFrom, p.OpenTill));
        return obj;
    }
}
