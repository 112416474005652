export const PopupCarouselElementId = 'popupCarousel';
export const PopupCarouselTemplate = (id: number) => `
    <div class="carousel carousel-rm2-popup slide" id="${PopupCarouselElementId}-${id}" data-bs-ride="carousel" data-ride="carousel" data-bs-interval="false" data-interval="false">
        <div class="carousel-inner">
            {{#features}}
                <div style="padding-bottom: 0 !important;" {{#index}}class="popup-carousel-item carousel-item"{{/index}}{{^index}}class="popup-carousel-item carousel-item active"{{/index}}>
                </div>
            {{/features}}
        </div>
        
        <div class="d-flex justify-content-between mt-2">
            <li class="carousel-control-prev" data-bs-target="#${PopupCarouselElementId}-${id}" data-target="#${PopupCarouselElementId}-${id}" role="button" type="button" data-bs-slide="prev" data-slide="prev">
                <span class="carousel-control-prev-icon"></span>
            </li>

            <ol class="carousel-indicators d-flex align-items-center">
                {{#features}}
                    <li type="button" role="button" data-bs-target="#${PopupCarouselElementId}-${id}" data-target="#${PopupCarouselElementId}-${id}" data-bs-slide-to="{{index}}" data-slide-to="{{index}}" {{^index}}class="active"{{/index}}></li>
                {{/features}}
            </ol>

            <li class="carousel-control-next" data-bs-target="#${PopupCarouselElementId}-${id}" data-target="#${PopupCarouselElementId}-${id}" role="button" type="button" data-bs-slide="next" data-slide="next">
                <span class="carousel-control-next-icon"></span>
            </li>
        </div>
    </div>
`;
