export const SearchResultsTemplate = `
    <div class="list-group border-top">
        {{#results}}
            <a id="result-{{index}}" class="list-group-item list-group-item-action border-0" style="cursor: pointer">
                <div class="d-flex align-items-center">
                    <img alt="loc" src="assets/icons/marker_muted.svg" class="me-3" width="32px" height="32px">
                    <div class="flex-grow-1">
                        <div class="fs-18px fw-bold">{{properties.Title}}</div>
                        <div class="promet-si-routing-search-result-description">{{properties.Description}}</div>

                        <hr class="m-0">
                    </div>
                </div>
            </a>
        {{/results}}
    </div>
`;
