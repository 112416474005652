export class Utils {

    public static routeColor(selected: boolean) { return selected ? '#0F3C8F' : '#BBBDBF'; }
    public static routeOrangeColor(selected: boolean) { return selected ? '#F49E00' : '#BB9F8D'; }
    public static routeRedColor(selected: boolean) { return selected ? '#E2001A' : '#A57F7E'; }

    public static formatDistance(distance: number): string {
        if (distance < 1000)
            return `${Math.round(distance)} m`;
        else if (distance < 100000)
            return `${Math.round((distance / 1000) * 100) / 100} km`;
        else
            return `${Math.round(distance / 1000)} km`;
    }

    public static formatDuration(seconds: number): string {
        if (seconds < 60)
            return `${Math.round(seconds)}s`;
        else if (seconds < 3600)
            return `${Math.round(seconds / 60)}min`;
        else {
            const h = Math.floor(seconds / 3600);
            const min = Math.round((seconds - (h * 3600)) / 60);
            return `${h}h ${min}min`;
        }
    }
}