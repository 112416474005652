import { IPanOptions, PanOptions } from '../model/RM2CameraChangeOptions';
import { Projection } from '..';

export type PopupAnchor = 'left' | 'top' | 'right' | 'bottom';

export class PopupOptions implements IPopupOptions {
    projection?: Projection;
    panOnOpen?: boolean;
    panOnPositionChange?: boolean;
    closesOnClick?: boolean;
    hasCloseButton?: boolean;
    addContainer?: boolean;
    anchor?: PopupAnchor;
    offset?: [number, number];
    maxWidth?: string[];
    showMarkerOnMobile?: boolean;
    cameraOptions?: IPanOptions;

    constructor(opts?: IPopupOptions) {
        this.panOnOpen = true;
        this.panOnPositionChange = true;
        this.closesOnClick = true;
        this.hasCloseButton = true;
        this.addContainer = true;
        this.anchor = 'bottom';
        this.offset = [0, -14];
        this.maxWidth = ['320px'];
        this.showMarkerOnMobile = document.documentElement.clientWidth <= 575;
        this.cameraOptions = new PanOptions(opts ? opts.cameraOptions : null);

        if (opts) {
            if (opts.projection != undefined)
                this.projection = opts.projection;
            
            if (opts.panOnOpen != undefined)
                this.panOnOpen = opts.panOnOpen;
            
            if (opts.panOnPositionChange != undefined)
                this.panOnPositionChange = opts.panOnPositionChange;
            
            if (opts.closesOnClick != undefined)
                this.closesOnClick = opts.closesOnClick;
            
            if (opts.hasCloseButton != undefined)
                this.hasCloseButton = opts.hasCloseButton;
            
            if (opts.addContainer != undefined)
                this.addContainer = opts.addContainer;
            
            if (opts.anchor != undefined)
                this.anchor = opts.anchor;
            
            if (opts.offset != undefined)
                this.offset = opts.offset;
            
            if (opts.maxWidth != undefined)
                this.maxWidth = opts.maxWidth.slice();
            
            if (opts.cameraOptions != undefined)
                this.cameraOptions = opts.cameraOptions;
        }
    }
}

export interface IPopupOptions {
    projection?: Projection;
    panOnOpen?: boolean;
    panOnPositionChange?: boolean;
    closesOnClick?: boolean;
    hasCloseButton?: boolean;
    addContainer?: boolean;
    anchor?: PopupAnchor;
    offset?: [number, number];
    maxWidth?: string[];
    showMarkerOnMobile?: boolean;
    cameraOptions?: IPanOptions;
}

export class CarouselPopupOptions extends PopupOptions implements ICarouselPopupOptions {
    selectedItem?: number;

    constructor(opts?: ICarouselPopupOptions) {
        super(opts);
        this.selectedItem = 0;

        if (opts) {
            if (opts.selectedItem != undefined)
                this.selectedItem = opts.selectedItem;
        }
    }
}

export interface ICarouselPopupOptions extends IPopupOptions {
    selectedItem?: number;
}
