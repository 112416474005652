// import * as maplibregl from 'maplibre-gl';
// import { LayerSpecification, SourceSpecification } from 'maplibre-gl';
import { LayerSpecification, SourceSpecification } from '@maplibre/maplibre-gl-style-spec';

import { LayerMetadata, ILayerMetadata } from './style-metadata/RM2LayerMetadata';

export class Layer {
    id: string;
    type: 'fill' | 'line' | 'symbol' | 'circle' | 'fill-extrusion' | 'raster' | 'background' | 'heatmap' | 'hillshade';

    metadata?: ILayerMetadata;
    ref?: string;

    source?: string;
    'source-layer'?: string;

    minzoom?: number;
    maxzoom?: number;

    interactive?: boolean;

    filter?: any[];
    layout?: LayerSpecification['layout'];
    paint?: LayerSpecification['paint'];

    constructor(layer: LayerSpecification) {
        // TODO
        Object.keys(layer).forEach(k => this[k] = layer[k]);
        this.metadata = new LayerMetadata(layer.metadata || {});
    }
}

// interface BackgroundLayer extends Layer {
//     type: 'background';
//     layout?: maplibregl.BackgroundLayout;
//     paint?: maplibregl.BackgroundPaint;
// }

// interface CircleLayer extends Layer {
//     type: 'circle';
//     layout?: maplibregl.CircleLayout;
//     paint?: maplibregl.CirclePaint;
// }

// interface FillExtrusionLayer extends Layer {
//     type: 'fill-extrusion';
//     layout?: maplibregl.FillExtrusionLayout;
//     paint?: maplibregl.FillExtrusionPaint;
// }

// interface FillLayer extends Layer {
//     type: 'fill';
//     layout?: maplibregl.FillLayout;
//     paint?: maplibregl.FillPaint;
// }

// interface HeatmapLayer extends Layer {
//     type: 'heatmap';
//     layout?: maplibregl.HeatmapLayout;
//     paint?: maplibregl.HeatmapPaint;
// }

// interface HillshadeLayer extends Layer {
//     type: 'hillshade';
//     layout?: maplibregl.HillshadeLayout;
//     paint?: maplibregl.HillshadePaint;
// }

// interface LineLayer extends Layer {
//     type: 'line';
//     layout?: maplibregl.LineLayout;
//     paint?: maplibregl.LinePaint;
// }

// interface RasterLayer extends Layer {
//     type: 'raster';
//     layout?: maplibregl.RasterLayout;
//     paint?: maplibregl.RasterPaint;
// }

// interface SymbolLayer extends Layer {
//     type: 'symbol';
//     layout?: maplibregl.SymbolLayout;
//     paint?: maplibregl.SymbolPaint;
// }

// export type AnyLayer =
//     | BackgroundLayer
//     | CircleLayer
//     | FillExtrusionLayer
//     | FillLayer
//     | HeatmapLayer
//     | HillshadeLayer
//     | LineLayer
//     | RasterLayer
//     | SymbolLayer;

// import { Projection } from './RM2Projection';
// import { FeatureCollection } from './RM2Feature';
// import { Source, TileSource, VectorSource } from '../source/RM2Source';
// import { Coordinate } from '..';
// import { IRM2Event, RM2Event, MapEventArgs } from '../model/RM2Event';
// // import { EventEmitterFactory } from '../../../../core/event-emitter';
// import { Subject, Subscription } from 'rxjs';

// /**
//  * Layer object.
//  */
// export abstract class Layer {
//     protected _baseLayers: any;
//     protected _title: string;          // TOCHECK ali ni title v ol.Layer
//     protected _icon: string;
//     public get sid(): string { return this._sid; }
//     protected _sid: string;
//     public get restyleInterval() { return this._restyleInterval; }
//     protected _restyleInterval: number;
//     public get lockMode() { return this._lockMode; }
//     protected _lockMode: string[];
//     protected _parent: GroupLayer;
//     protected _visible: boolean;
//     protected _uiVisible: boolean;
//     protected _uiMode: string;
//     protected _type: string;
//     protected _includeInIdentify: boolean;
//     protected _forBasemaps: string[];
//     protected _visibleRefreshButton: boolean;
//     protected _listViewDefaultExpand: boolean;

//     public get onRestyle(): IRM2Event<MapEventArgs> { return this._onRestyle.expose(); }
//     protected readonly _onRestyle = new RM2Event<MapEventArgs>();

//     /**
//      * Creates an instance of Layer.
//      */
//     public constructor(title: string, icon: string, sid: string, lockMode: string, visible: boolean, restyleInterval?: number, baseLayer?: any, type?: string, includeInIdentify?: boolean, uiVisible?: boolean, uiMode?: string, forBasemaps?: string[]) {
//         this._title = title;
//         this._icon = icon;
//         this._sid = sid;
//         this._lockMode = lockMode !== undefined ? lockMode.split('|') : [];
//         this._restyleInterval = restyleInterval;
//         this._baseLayers = baseLayer;
//         this.visible = visible;
//         this._uiVisible = uiVisible !== false;
//         this._uiMode = uiMode;
//         this._type = type;
//         this._includeInIdentify = includeInIdentify !== false;
//         this._forBasemaps = forBasemaps;
//     }

//     public get baseLayers(): any[] { return this._baseLayers; }
//     public set baseLayers(layers: any[]) { this._baseLayers = layers; }

//     /**
//      * Returns title of this layer.
//      */
//     public get title(): string { return this._title; }

//     /**
//      * Returns icon of this layer.
//      */
//     public get icon(): string { return this._icon; }

//     /**
//      * Returns parent GroupLayer of this layer.
//      */
//     public get parent(): GroupLayer { return this._parent; }

//     /**
//      * Sets parent GroupLayer to this layer.
//      */
//     public set parent(parent: GroupLayer) { this._parent = parent; }

//     /**
//      * Sets the type of layer (e.g. basemaps)
//      */
//     public set type(type: string) { this._type = type; }

//     /**
//      * Returns the type of layer (e.g. basemaps)
//      */
//     public get type(): string { return this._type; }

//     /**
//      * Sets whether the layer gets detected on map click
//      */
//     public set includeInIdentify(include: boolean) { this._includeInIdentify = include; }

//     /**
//      * Returns whether the layer gets detected on map click
//      */
//     public get includeInIdentify(): boolean { return this._includeInIdentify; }

//     /**
//      * Sets whether the layer can only appear on certain basemaps
//      */
//     public set forBasemaps(basemapIds: string[]) { this._forBasemaps = basemapIds; }

//     /**
//      * Sets whether the layer can only appear on certain basemaps
//      */
//     public get forBasemaps(): string[] { return this._forBasemaps; }

//     public get visibleRefreshButton(): boolean { return this._visibleRefreshButton; }
//     public set visibleRefreshButton(value: boolean) { this._visibleRefreshButton = value; }

//     public get listViewDefaultExpand(): boolean { return this._listViewDefaultExpand; }
//     public set listViewDefaultExpand(value: boolean) { this._listViewDefaultExpand = value; }

//     private _visibleChanged = new Subject<boolean>();
//     public get visibleChanged(): Subject<boolean> { return this._visibleChanged; }

//     /**
//      * Returns bool whether the layer is visible.
//      */
//     public get visible(): boolean { return this._visible; }

//     /**
//      * Sets layer visibility.
//      */
//     public set visible(visible: boolean) {
//         if (this._visible !== visible) {
//             this._visible = visible;
//             const observers = this._visibleChanged.observers;
//             if (observers && observers.length > 0)
//                 this._visibleChanged.next(visible);
//             // EventEmitterFactory.getCurrent().emit('layer:visibilityChanged', this);
//         }
//     }

//     /**
//      * Whether the group is visible in the legend UI
//      */
//     public get uiVisible(): boolean { return this._uiVisible; }

//     /**
//      * How to display the contents in the legend UI
//      */
//     public get uiMode(): string { return this._uiMode; }

//     /**
//      * Returns bool whether the layer can be visible.
//      */
//     public get canVisible(): boolean { return this._lockMode.indexOf('visible') === -1; }

//     restyle() {
//         if (this.baseLayers) {
//             let changed = false;
//             for (let i = 0; i < this.baseLayers.length; i++)
//                 changed = this.baseLayers[i].changed();

//             if (changed === true)
//                 this._onRestyle.trigger({ layer: this, map: undefined, coordinate: undefined, nativeEvent: undefined });
//         }
//     }

//     setVisible(visible: boolean) {
//         this.visible = visible;
//     }
// }

// /**
//  * GroupLayer object.
//  */
// export class GroupLayer extends Layer {
//     private _sequenceMode: string;
//     private _layers: Layer[];
//     private _defaultFocused?: string;
//     private _uiGroupExpandable: boolean;
//     private _uiGroupAlwaysOpen: boolean;
//     private _uiGroupHeaderVisible: boolean;
//     private _zIndex?: number;

//     /**
//      * Creates an instance of GroupLayer.
//      */
//     public constructor(options: GroupLayerOptions) {
//         super(options.title, options.icon, options.sid, options.lockMode, options.visible, options.restyleInterval, options.baseLayers, options.type, options.includeInIdentify, options.uiVisible, options.uiMode, options.forBasemaps);

//         this._sequenceMode = options.sequenceMode || 'group';
//         this._layers = options.layers || [];
//         this._defaultFocused = options.defaultFocused;
//         this._uiGroupExpandable = options.uiGroupExpandable;
//         this._uiGroupAlwaysOpen = options.uiGroupAlwaysOpen;
//         this._uiGroupHeaderVisible = options.uiGroupHeaderVisible;
//         this._zIndex = options.zIndex;

//         let hasVisible = false;
//         for (let i = 0; i < this._layers.length; i++) {
//             if (this._sequenceMode === 'choice' && hasVisible)
//                 this._layers[i].setVisible(false);

//             hasVisible = hasVisible || this._layers[i].visible;
//             this._layers[i].parent = this;
//         }
//     }

//     /**
//      * Returns sequenceMode of the layergroup.
//      */
//     public get sequenceMode(): string {
//         return this._sequenceMode;
//     }

//     /**
//      * Returns layer array of the layergroup.
//      */
//     public get layers(): Layer[] {
//         return this._layers ? this._layers : [];
//     }

//     /**
//      * Returns the default layer that will be focused in this group layer
//      */
//     public get defaultFocused(): string {
//         return this._defaultFocused;
//     }

//     /**
//      * Whether the legend UI of the group is expandable
//      */
//     public get uiGroupExpandable(): boolean {
//         return this._uiGroupExpandable;
//     }

//     /**
//      * Whether the legend UI of the group is always in the open position
//      */
//     public get uiGroupAlwaysOpen(): boolean {
//         return this._uiGroupAlwaysOpen;
//     }

//     /**
//      * Whether the header of the group should be visible
//      */
//     public get uiGroupHeaderVisible(): boolean {
//         return this._uiGroupHeaderVisible;
//     }

//     /**
//      * The z-index of the group
//      */
//     public get zIndex(): number {
//         return this._zIndex;
//     }

//     resetZIndex() {
//         this._zIndex = undefined;
//     }

//     public filterLayers(predicate: (layer: Layer) => boolean) : Layer[] {
//         var filtered = [];
//         if (predicate(this) == true)
//             filtered.push(this);
        
//         if (this.layers) {
//             for (var i = 0; i < this.layers.length; i++) {
//                 var l = this.layers[i];
//                 if (predicate(l) == true)
//                     filtered.push(l);
                
//                 if (l instanceof GroupLayer)
//                     filtered = filtered.concat(l.filterLayers(predicate));
//             }
//         }

//         return filtered;
//     }
// }

// /**
//  * SourceLayer object.
//  */
// export abstract class SourceLayer extends Layer {
//     // protected _styler: Styler;

//     /**
//      * Creates an instance of SourceLayer.
//      */
//     public constructor(options: SourceLayerOptions) {
//         super(options.title, options.icon, options.sid, options.lockMode, options.visible, options.restyleInterval, options.baseLayers, options.type, options.includeInIdentify, options.uiVisible, options.uiMode, options.forBasemaps);
//         // this._styler = options.styler;
//     }
//     /**
//      * Returns a styler associated with this layer.
//      */
//     // public get styler(): Styler {
//     //     return this._styler;
//     // }
//     /**
//      * Returns a source associated with this layer.
//      */
//     public abstract get source(): Source;
// }

// /**
//  * VectorLayer object.
//  */
// export class VectorLayer extends SourceLayer {

//     listViewTitleTemplate: string;
//     listViewDescriptionTemplate: string;
//     listViewEnableOpenButton: boolean;
//     infoWindowTitleTemplate?: string;
//     infoWindowDescriptionTemplate?: string;
//     infoWindowDescriptionRefreshInterval?: number;
//     infoWindowMaxWidth?: string;
//     infoWindowEnableOpenButton?: boolean;

//     protected _source: VectorSource;

//     /**
//      * Creates an instance of VectorLayer.
//      */
//     public constructor(options: SourceLayerOptions) {
//         super(options);
//         // this._styler = this._styler || Styler.default;
//         // this._styler = this._styler; // TODO: nek default style kot zgoraj

//         this.listViewTitleTemplate = options.listViewTitleTemplate || `
//           {{#Title}}
//             {{#translateKey}}$.Title{{/translateKey}}
//           {{/Title}}

//           {{^Title}}
//             {{#title}}
//               {{#translateKey}}$.title{{/translateKey}}
//             {{/title}}
//           {{/Title}}
//         `;

//         this.listViewDescriptionTemplate = options.listViewDescriptionTemplate || `
//           {{#Description}}
//             {{#translateKey}}$.Description{{/translateKey}}
//           {{/Description}}

//           {{^Description}}
//             {{#description}}
//               {{#translateKey}}$.description{{/translateKey}}
//             {{/description}}
//           {{/Description}}
//         `;

//         this.listViewEnableOpenButton = options.listViewEnableOpenButton === true ? true : false

//         this.infoWindowTitleTemplate = options.infoWindowTitleTemplate || `
//             <div class="d-flex-inline align-items-center w-100">
//                 <!--<img class="rm2-popup-content-img mr-1 my-0" src="{{icon}}" alt="{{title}}">-->
//                 <span class="mb-0 font-weight-bold">{{title}}</span>
//             </div>
//             <hr class="mt-0 my-2" style="height: 1px">
//         `;

//         this.infoWindowDescriptionTemplate = options.infoWindowDescriptionTemplate || `
//             <p class="mb-0">
//                 {{description}}
//             </p>
//         `;

//         this.infoWindowDescriptionRefreshInterval = options.infoWindowDescriptionRefreshInterval;
//         this.infoWindowMaxWidth = options.infoWindowMaxWidth || '240px';
//         this.infoWindowEnableOpenButton = options.infoWindowEnableOpenButton === true ? true : this.listViewEnableOpenButton;
//     }

//     /**
//      * Returns a source associated with this layer.
//      */
//     public get source(): VectorSource {
//         return this._source;
//     }

//     /**
//      * Returns collection of features - members of this layer.
//      */
//     public get features(): FeatureCollection {
//         if (this._source == null)
//             return new FeatureCollection();
//         return this._source.getFeatures();
//     }

//     /**
//      * Returns geographic projection of this layer.
//      */
//     public get projection(): Projection {
//         return this._source.projection;
//     }

//     // /**
//     //  * Executes query over features of this layer for given querystring and sends QueryServiceResult object to given response function.
//     //  *
//     //  * Response is QueryServiceResult object: { service: this, status: x.status, items: x.items }.
//     //  *
//     //  * Feaure is selected if any of its string attributes matches to the given querystring.
//     //  */
//     // public query(queryString: string, response: (results: QueryServiceResult) => void, options?: QueryServiceOptions) {
//     //     if (this._source) {
//     //         this._source.query(queryString, x => {
//     //             response({ service: this, status: x.status, items: x.items });
//     //         }, options);
//     //     }
//     // }

//     // /**
//     //  * Executes location query over features of this layer for given coordinate and sends LocationServiceResult object to given response function.
//     //  *
//     //  * Response is LocationServiceResult object: { service: this, status: x.status, items: x.items }.
//     //  */
//     // public locate(coordinate: Coordinate, response: (results: LocationServiceResult) => void, options?: LocationServiceOptions) {
//     //     this._source.locate(coordinate, x => {
//     //         response({ service: this, status: x.status, items: x.items });
//     //     }, options);
//     // }
// }

// /**
//  * RasterLayer object.
//  */
// export class RasterLayer extends SourceLayer {
//     protected _source: TileSource;

//     /**
//      * Creates an instance of RasterLayer.
//      */
//     public constructor(options: SourceLayerOptions) {
//         super(options);
//     }

//     /**
//      * Returns a source associated with this layer.
//      */
//     public get source(): TileSource {
//         return this._source;
//     }
// }

// /**
//  * LayerOptions interface.
//  */
// export interface LayerOptions {
//     title: string;
//     icon: string;
//     sid: string;
//     baseLayers?: any[];
//     visible?: boolean;
//     opacity?: number;
//     lockMode?: string;
//     minZoom?: number;        // TOCHECK units
//     maxZoom?: number;        // TOCHECK units
//     extent?: number[];       // [minx, miny, maxx, maxy] TOCHECK units
//     restyleInterval?: number;
//     type?: string;
//     includeInIdentify?: boolean;
//     uiVisible?: boolean;
//     uiMode?: string;
//     forBasemaps?: string[];
// }

// /**
//  * GroupLayerOptions interface.
//  *
//  * sequenceMode supports values "group" and "choice"
//  */
// export interface GroupLayerOptions extends LayerOptions {
//     sequenceMode?: string;
//     layers?: Layer[];
//     defaultFocused?: string;
//     uiGroupExpandable?: boolean;
//     uiGroupAlwaysOpen?: boolean;
//     uiGroupHeaderVisible?: boolean;
//     zIndex?: number;
// }

// /**
//  * SourceLayerOptions interface.
//  */
// export interface SourceLayerOptions extends LayerOptions {
//     source: Source;
//     listViewTitleTemplate?: string;
//     listViewDescriptionTemplate?: string;
//     listViewEnableOpenButton?: boolean;
//     infoWindowTitleTemplate?: string;
//     infoWindowDescriptionTemplate?: string;
//     infoWindowDescriptionRefreshInterval?: number;
//     infoWindowMaxWidth?: string;
//     infoWindowEnableOpenButton?: boolean;
// }
