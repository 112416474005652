import { Projection } from '../model/RM2Projection';

export class Zoom {
    public static fromMbZoom(mbZoom: number): number { return mbZoom; }
    public static fromOlZoom(olZoom: number): number { return olZoom + 5.5; }
    public static toMbZoom(mbZoom: number): number { return mbZoom; }
    public static toOlZoom(rmZoom: number): number { return rmZoom - 5.5; }
}

export class CameraChangeOptions implements ICameraChangeOptions {
    projection?: Projection;
    animate?: boolean;
    duration?: number;
    padding?: number | PaddingOptions;

    constructor(opts?: ICameraChangeOptions) {
        this.projection = opts && opts.projection != undefined ? opts.projection : undefined;
        this.animate = opts && opts.animate != undefined ? opts.animate : true;
        this.duration = opts && opts.duration != undefined ? opts.duration : 500;
        this.padding = opts && opts.padding != undefined ? opts.padding : 50;
    }
}

export interface ICameraChangeOptions {
    projection?: Projection;
    animate?: boolean;
    duration?: number;
    padding?: number | PaddingOptions;
};

export class PanOptions extends CameraChangeOptions {
    zoom?: number;
    bearing?: number;
    pitch?: number;
    relCenterX?: number;
    relCenterY?: number;
    minResolution?: number;

    constructor(opts?: IPanOptions) {
        super(opts);
        this.zoom = opts && opts.zoom != undefined ? opts.zoom : undefined;
        this.bearing = opts && opts.bearing != undefined ? opts.bearing : undefined;
        this.pitch = opts && opts.pitch != undefined ? opts.pitch : undefined;
        this.relCenterX = opts && opts.relCenterX != undefined ? opts.relCenterX : 0.5;
        this.relCenterY = opts && opts.relCenterY != undefined ? opts.relCenterY : 0.5;
        this.minResolution = opts && opts.minResolution != undefined ? opts.minResolution : 1;
    }
}

export interface IPanOptions extends ICameraChangeOptions {
    zoom?: number;
    bearing?: number;
    pitch?: number;
    relCenterX?: number;
    relCenterY?: number;
    minResolution?: number;
}

export class FitOptions extends CameraChangeOptions {
    maxZoom?: number;
    bearing?: number;
    pitch?: number;

    constructor(opts?: IFitOptions) {
        super(opts);
        this.maxZoom = opts && opts.maxZoom != undefined ? opts.maxZoom : 16;
        this.bearing = opts && opts.bearing != undefined ? opts.bearing : 0;
        this.pitch = opts && opts.pitch != undefined ? opts.pitch : 0;
    }
}

export interface IFitOptions extends ICameraChangeOptions {
    maxZoom?: number;
    padding?: number | PaddingOptions;
    bearing?: number;
    pitch?: number;
}

export interface PaddingOptions {
    top: number;
    bottom: number;
    left: number;
    right: number;
}
