import * as turf_helpers from '@turf/helpers';
import * as turf_clone from '@turf/clone';
import * as turf_envelope from '@turf/envelope';
import * as turf_centroid from '@turf/centroid';
import * as turf_flatten from '@turf/flatten';
import * as turf_distance from '@turf/distance';

import proj4 from 'proj4';
import { reproject } from 'reproject';
import { parse, stringify } from 'wkt';
import { Projection } from './RM2Projection';

// wrappers around JSTS classes

export type CoordinateLike = [number, number];

/**
 * Coordinate class. Extends jsts.geom.Coordinate class.
 */
export class Coordinate {

    x: number;
    y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    /**
     * Returns coordinate from the first two ordinates in the given numeric array.
     */
    public static fromOrdinates(ordinates: CoordinateLike): Coordinate {
        return new Coordinate(ordinates[0], ordinates[1]);
    }
    /**
     * Returns an array or two ordinates (x and y) from the given Coordinate object.
     */
    public static toOrdinates(coordinate: Coordinate): CoordinateLike {
        return [coordinate.x, coordinate.y];
    }
    /**
     * Returns an array of Coordinate objects from the given numeric array (takes two ordintes for each Coordinate).
     */
    public static sequenceFromOrdinates(ordinates: number[]): Coordinate[] {
        const coordinates = new Array<Coordinate>();
        for (let i = 0; i < ordinates.length; i += 2)
            coordinates.push(new Coordinate(ordinates[i], ordinates[i + 1]));
        
        return coordinates;
    }
    /**
     * Returns a coordinate, transformed from source projection to target projection.
     */
    public static transform(coordinate: Coordinate, sourceProjection: Projection, targetProjection: Projection): Coordinate {
        if (sourceProjection === targetProjection)
            return new Coordinate(coordinate.x, coordinate.y);
        
        return this.fromOrdinates(proj4(sourceProjection.crsCode, targetProjection.crsCode, this.toOrdinates(coordinate)));
    }
}

/**
 * Envelope class. Represents rectangular geographic area defined with minX, minY, maxX, mayY borders.
 */
export class Envelope {

    minx: number;
    miny: number;
    maxx: number;
    maxy: number;

    constructor(minx: number, miny: number, maxx: number, maxy: number) {
        this.minx = minx;
        this.miny = miny;
        this.maxx = maxx;
        this.maxy = maxy;
    }

    /**
     * Returns new Envelope object from given array of 4 numeric values.
     */
    public static fromNumArray(extent: number[]): Envelope {
        return new Envelope(extent[0], extent[1], extent[2], extent[3]);
    }
    /**
     * Returns numeric array of 4 ordinates (minX, minY, maxX, maxY).
     */
    public static toNumArray(envelope: Envelope) {
        return [envelope.minx, envelope.miny, envelope.maxx, envelope.maxy];
    }
    /**
     * Returns new envelope, transformed from source projection to target projection.
     */
    public static transform(envelope: Envelope, sourceProjection: Projection, targetProjection: Projection): Envelope {
        if (sourceProjection === targetProjection)
            return new Envelope(envelope.minx, envelope.miny, envelope.maxx, envelope.maxy);
        
        const min = proj4(sourceProjection.crsCode, targetProjection.crsCode, [envelope.minx, envelope.miny]);
        const max = proj4(sourceProjection.crsCode, targetProjection.crsCode, [envelope.maxx, envelope.maxy]);
        return this.fromNumArray(min.concat(max));
    }
    /**
     * Returns new envelope of given point coordinate.
     */
    public static fromCoordinate(coordinate: Coordinate) {
        return new Envelope(coordinate.x, coordinate.y, coordinate.x, coordinate.y);
    }
}

/**
 * String value that represents type of geometry.
 */
export type GeometryType = 'point' | 'lineString' | 'polygon' | 'multiPoint' | 'multiLineString' | 'multiPolygon' | 'geometryCollection';

/**
 * Represents geometry object.
 */
export abstract class Geometry implements turf_helpers.Geometry  {
    type: string;
    bbox?: [number, number, number, number] | [number, number, number, number, number, number];
    coordinates: turf_helpers.Position | turf_helpers.Position[] | turf_helpers.Position[][] | turf_helpers.Position[][][];

    public getCoordinate(): Coordinate {
        const type = this.type.toLowerCase();
        if (this.type === 'polygon' || this.type === 'multipolygon') {
            const centroid = this.getCentroid();
            if (centroid && centroid.coordinates && centroid.coordinates.length >= 2) {
                return new Coordinate(centroid.coordinates[0], centroid.coordinates[1])
            }
        }
        else if (this.type === 'linestring' || this.type === 'multilinestring' || this.type === 'multipoint') { // za multipoint - morda bi bilo bolj prav da je zgoraj - centroid. promet.si obmocja pluzenja mapbox vrne kot multipoint (ZAKAJ?), zato sem jih dal sem.
            const flatten = this.flattenCoords();
            if (flatten && flatten.length >= 2) {
                return new Coordinate(Math.floor(flatten.length / 2), Math.floor(flatten.length / 2) + 1)
            }
        }
        else {
            const flatten = this.flattenCoords();
            if (flatten.length >= 2) {
                return new Coordinate(flatten[0], flatten[1]);
            }
        }
        return null;
    }

    public getCoordinates(): Coordinate[] {
        const flatten = this.flattenCoords();
        if (flatten.length % 2 !== 0)
            return null;

        const coords: Coordinate[] = [];
        for (let i = 0; i < flatten.length; i += 2)
            coords.push(new Coordinate(flatten[i], flatten[i + 1]));
        
        return coords;
    }

    public getEnvelopeInternal(): Envelope {
        const envelope = turf_envelope.default(this);
        const flatten = turf_flatten.default(envelope);
        return Envelope.fromNumArray(flatten.features[0].bbox);
    }

    public distance(geometry: Geometry): number {
        const c1 = this.getCentroid();
        const c2 = geometry.getCentroid();

        const p1 = turf_helpers.point(c1.coordinates);
        const p2 = turf_helpers.point(c2.coordinates);
        return turf_distance.default(p1, p2);
    }

    public getCentroid(): Point {
        const centroid = turf_centroid.default(this);
        const coords = centroid.geometry.coordinates;
        return Point.fromCoordinate([coords[0], coords[1]]);
    }

    private flattenCoords(): number[] {
        return this.coordinates.flat(3);
    }

    /**
     * Returns new Geometry object from given WKT string.
     */
    public static fromWkt(wktString: string): Geometry {
        const json = parse(wktString);
        return this.fromGeoJson(json);
        // return this._wktReader.read(wkt) as Geometry;
    }

    /**
     * Returns WKT string from given Geometry object.
     */
    public static toWkt(geometry: Geometry): string {
        const wktString = stringify(geometry);
        console.log(wktString);
        return wktString;
        // return this._wktWriter.write(geometry);
    }

    /**
     * Clones the Geometry object
     */
    public clone(): Geometry {
        return Geometry.fromGeoJson(turf_clone.default(this));
    }

    /**
     * Returns new Geometry object from given geoJSON string.
     */
    public static fromGeoJson(geoJson: string | Object): Geometry {
        let json: Geometry;
        if (typeof geoJson == 'string')
            json = JSON.parse(geoJson) as Geometry;
        else
            json = geoJson as Geometry;

            
        const type = json.type.toLowerCase();
        if (type === 'point')
            return new Point(json.coordinates as CoordinateLike);
        // else if (type === "LineSegment")
        //     return new LineSegment();
        else if (type === 'linestring')
            return new LineString(json.coordinates as CoordinateLike[]);
        else if (type === 'multilinestring')
            return new MultiLineString(json.coordinates as CoordinateLike[][]);
        else if (type === 'polygon')
            return new Polygon(json.coordinates as CoordinateLike[][]);
        else if (type === 'multipoint')
            return new MultiPoint (json.coordinates as CoordinateLike[]);
        else if (type === 'multipolygon')
            return new MultiPolygon (json.coordinates as CoordinateLike[][][]);
        else
            throw new Error(`Not supported geo type: ${type}`); // 'geometryCollection';
        
        throw new Error('NotImplemented');
    }

    /**
     * Returns geoJSON string from given Geometry object.
     */
    public static toGeoJson(geometry: Geometry): Object {
        return geometry;
    }

    // todo Optimization! Implement transform on jsts geometry
    /**
     * Returns new Geometry object, transformed from source projection to target projection.
     */
    public static transform(geometry: Geometry, sourceProjection: Projection, targetProjection: Projection): Geometry {
        if (sourceProjection === targetProjection)
            return turf_clone.default(geometry);
        
        // HACK: nekaj narobe s knjiznico v primeru polygona
        if (geometry.type.toLowerCase() == 'polygon')
            (geometry as any).shell.points.coordinates = [];

        return Geometry.fromGeoJson(reproject(Geometry.toGeoJson(geometry), proj4.defs(sourceProjection.crsCode), proj4.defs(targetProjection.crsCode)));
    }
}
/**
 * Represents point geometry object.
 */
export class Point extends Geometry implements turf_helpers.Point {
    type: "Point";
    coordinates: turf_helpers.Position;
    
    constructor(coordinates: Coordinate | CoordinateLike) {
        super();

        this.type = "Point";
        if (coordinates instanceof Coordinate)
            this.coordinates = [coordinates.x, coordinates.y];
        else
            this.coordinates = coordinates;
    }

    public static fromCoordinate(coordinate: Coordinate | CoordinateLike): Point {
        if (Array.isArray(coordinate))
            coordinate = Coordinate.fromOrdinates(coordinate);
        
        return new Point(coordinate);
    }
}

/**
 * Represents MultiPoint geometry object
 */
export class MultiPoint extends Geometry implements turf_helpers.MultiPoint {
    type: "MultiPoint";
    coordinates: turf_helpers.Position[];
    
    constructor(coordinates: Coordinate[] | CoordinateLike[]) {
        super();

        this.type = "MultiPoint";
        const positions: turf_helpers.Position[] = [];
        for (let i = 0; i < coordinates.length; i++) {
            const coord = coordinates[i];
            if (coord instanceof Coordinate)
                positions.push([coord.x, coord.y]);
            else
                positions.push(coord);
        }

        this.coordinates = positions;
    }
}
/**
 * Represents linestring geometry object.
 */
export class LineSegment extends Geometry {
    public static fromCoordinates(coordinates: [Coordinate, Coordinate] | [CoordinateLike, CoordinateLike]): LineString {
        if (Array.isArray(coordinates) && Array.isArray(coordinates[0]))
            coordinates = (coordinates as [CoordinateLike, CoordinateLike]).map(coord => Coordinate.fromOrdinates(coord)) as [Coordinate, Coordinate];
        
        return new (jsts.geom as any).LineSegment(coordinates[0], coordinates[1]);
    }
}
/**
 * Represents linestring geometry object.
 */
export class LineString extends Geometry implements turf_helpers.LineString {
    type: "LineString";
    coordinates: turf_helpers.Position[];

    constructor(coordinates: Coordinate[] | CoordinateLike[]) {
        super();

        this.type = "LineString";
        const positions: turf_helpers.Position[] = [];
        for (let i = 0; i < coordinates.length; i++) {
            const coord = coordinates[i];
            if (coord instanceof Coordinate)
                positions.push([coord.x, coord.y]);
            else
                positions.push(coord);
        }

        this.coordinates = positions;
    }
}
/**
 * Represents multilinestring geometry object.
 */
export class MultiLineString extends Geometry implements turf_helpers.MultiLineString {
    type: "MultiLineString";
    coordinates: turf_helpers.Position[][];

    constructor(coordinates: Coordinate[][] | CoordinateLike[][]) {
        super();

        this.type = "MultiLineString";
        const positions: turf_helpers.Position[][] = [];
        for (let i = 0; i < coordinates.length; i++) {
            const ls = coordinates[i];
            const lsCoords: turf_helpers.Position[] = [];
            for (let j = 0; j < ls.length; j++) {
                const coord = ls[j];
                if (coord instanceof Coordinate)
                    lsCoords.push([coord.x, coord.y]);
                else
                    lsCoords.push(coord);
            }

            positions.push(lsCoords);
        }

        this.coordinates = positions;
    }
}
/**
 * Represents polygon geometry object.
 */
export class Polygon extends Geometry implements turf_helpers.Polygon {
    type: "Polygon";
    coordinates: turf_helpers.Position[][];

    constructor(coordinates: Coordinate[][] | CoordinateLike[][]) {
        super();

        this.type = "Polygon";
        const positions: turf_helpers.Position[][] = [];
        for (let i = 0; i < coordinates.length; i++) {
            const ls = coordinates[i];
            const lsCoords: turf_helpers.Position[] = [];
            for (let j = 0; j < ls.length; j++) {
                const coord = ls[j];
                if (coord instanceof Coordinate)
                    lsCoords.push([coord.x, coord.y]);
                else
                    lsCoords.push(coord);
            }

            positions.push(lsCoords);
        }

        this.coordinates = positions;
    }
}

/**
 * Represents MultiPolygon geometry object
 */
export class MultiPolygon extends Geometry implements turf_helpers.MultiPolygon {
    type: "MultiPolygon";
    coordinates: turf_helpers.Position[][][];

    constructor(coordinates: Coordinate[][][] | CoordinateLike[][][]) {
        super();

        this.type = "MultiPolygon";

        const positions: turf_helpers.Position[][][] = [];
        for (let k = 0; k < coordinates.length; k ++) {
            const polygonPaths: turf_helpers.Position[][] = [];
            const polygon = coordinates[k];
            for (let i = 0; i < polygon.length; i++) {
                const ls = polygon[i];
                const lsCoords: turf_helpers.Position[] = [];
                for (let j = 0; j < ls.length; j++) {
                    const coord = ls[j];
                    if (coord instanceof Coordinate)
                        lsCoords.push([coord.x, coord.y]);
                    else
                        lsCoords.push(coord);
                }
    
                polygonPaths.push(lsCoords);
            }

            positions.push(polygonPaths);
        }

        this.coordinates = positions;
    }
}