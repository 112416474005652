import { CoordinateLike } from '../../model/RM2Geometry';
import { RouteHighlightOptions, IRouteHighlightOptions } from '../../services/RM2RoutingService';

export class MapPickerControlOptions extends RouteHighlightOptions implements IRouteHighlightOptions {
    constructor(opts?: IMapPickerControlOptions) {
        super(opts);
    }
}

export interface IMapPickerControlOptions extends IRouteHighlightOptions {
}

export interface ILocationSelectData {
    coord: CoordinateLike;
    mouseButton: number;
    index?: number;
}
