export const RoutingHeaderTemplate = `
    <div class="bg-primary pb-1 promet-si-routing-header">
        <div class="d-flex justify-content-end">
            <div id="hide-button" class="d-flex align-items-center justify-content-center rounded-circle bg-red cursor-pointer icon-32">

                <img alt="close" src="assets/icons/close_white.svg" width="20px" height="20px"> <!-- close -->
            </div>
        </div>
        <div style="margin-top: 13px;">
            {{#locations}}
                <div class="row promet-si-routing-item">
                    <!-- icon -->
                    <div class="col-1 d-flex align-items-center justify-content-center">
                        <div class="position-relative d-flex justify-content-center h-100">
                            <div class="d-flex align-items-center w-100">
                                <img src="{{icon}}" alt="lokacija" style="width: 32px !important; height: 32px !important;">
                            </div>

                            <!-- med lokacijami -->
                            {{#canSwapWithNext}}
                                <div class="position-absolute mt-1 d-flex align-items-center w-100" style="top: 50%; height: 100%">
                                    <img alt="between" src="assets/icons/routing_between.svg" width="32px" height="32px">
                                </div>
                            {{/canSwapWithNext}}
                        </div>
                    </div>

                    <!-- input -->
                    <div class="col-10 d-flex align-items-center">
                        <div class="input-group w-100 m-0">
                            <input id="input-{{id}}" name="{{id}}" class="form-control border-right-0 promet-si-routing-item-input" data-i18n="[placeholder]rmap.rm.routing.search-placeholder">

                            <!-- my location -->
                            <div class="input-group-append bg-white promet-si-routing-item-input-my-loc">
                                <div id="my-loc-{{id}}" class="d-flex align-items-center justify-content-center h-100 px-2">
                                    <img alt="myloc" src="assets/icons/crosshair_dblue.svg" width="20px" height="20px">
                                </div>
                            </div>

                            <!-- clear input -->
                            <div class="input-group-append bg-white promet-si-routing-item-input-clear">
                                <div id="remove-{{id}}" class="d-flex align-items-center justify-content-center h-100 px-2">
                                    <img alt="clear" src="assets/icons/close_dblue.svg" width="20px" height="20px">
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- swap locations -->
                    {{#canSwapWithNext}}
                        <div class="col-1 d-flex position-relative flex-row align-items-center">
                            <button id="swap-{{id}}" name="{{id}}" class="btn btn-link btn-no-underline p-0 text-white position-absolute mt-2 d-flex align-items-center" style="top: 50%; height: 100%">
                                <img alt="swap" src="assets/icons/routing_swap.svg" width="20px" height="20px">
                            </button>
                        </div>
                    {{/canSwapWithNext}}
                </div>
            {{/locations}}

            <!-- dodaj novo lokacijo -->
            <div class="row promet-si-routing-add-item">
                <div class="col-1"></div>
                <div class="col-11">
                    <div id="add-location-button" class="text-white d-flex align-items-center" style="cursor: pointer;">
                        <img alt="add" src="assets/icons/routing_add.svg" width="32px" height="32px">
                        <span class="ms-2 fs-16px text-lowercase" data-i18n="rmap.rm.routing.add-via-point"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
`;
