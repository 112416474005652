import * as maplibregl from 'maplibre-gl';

import { Map } from '../../map/RM2Map';
import { MapEventArgs, RM2Event } from '../../model/RM2Event';
import { Popup } from '../../popup/RM2Popup';
import { IMapPickerControlOptions, MapPickerControlOptions, ILocationSelectData } from './RM2MapPickerControlOptions';
import { CoordinateLike, Point } from '../../model/RM2Geometry';
import { Route, RoutingService, RouteFeatureCollection } from '../../services/RM2RoutingService';
import { ServiceType } from '../../services/RM2Service';
import { LocalizationService } from '../../services/RM2LocalizationService';

export class MapPickerControl implements maplibregl.IControl {
    protected _map: Map;

    private _options: IMapPickerControlOptions;
    private _routingService: RoutingService;
    private _localizationService: LocalizationService;

    public enabled: boolean = true;
    public constructRoutes: boolean = true;
    public drawRoutes: boolean = true;

    public get onProcessingStatusChange() { return this._onProcessingStatusChange.expose(); }
    protected readonly _onProcessingStatusChange = new RM2Event<boolean>();

    public get onLocationSelected() { return this._onLocationSelected.expose(); }
    protected readonly _onLocationSelected = new RM2Event<ILocationSelectData>();

    public get onRouteConstructed() { return this._onRouteConstructed.expose(); }
    protected readonly _onRouteConstructed = new RM2Event<Route>();

    private popupClick: Popup;
    private popupRemovePoint: Popup;
    private route: Route<RouteFeatureCollection> = { points: [], route: null };

    constructor(map: Map, opts?: IMapPickerControlOptions) {
        this._map = map;
        this._options = new MapPickerControlOptions(opts);

        this._routingService = this._map.getService(ServiceType.Routing) as RoutingService;
        this._localizationService = this._map.getService(ServiceType.Localization) as LocalizationService;

        map.onClick.subscribe(this.onMapClick);
    }

    onAdd(map: maplibregl.Map): HTMLElement {
        // const wrapper = document.createElement('div');
        // wrapper.className = 'maplibregl-ctrl maplibregl-ctrl-group';
        // wrapper.appendChild(this._button);

        return document.createElement('div');
    }

    onRemove() {
        // TODO: hide popup?
        this._map = undefined;
    }

    getDefaultPosition(): maplibregl.ControlPosition {
        return 'top-left';
    }

    hide() {
        if (this.popupClick)
            this.popupClick.close();
        
        if (this.popupRemovePoint)
            this.popupRemovePoint.close();
    }

    inject(route: Route<RouteFeatureCollection>) {
        this.route = route;
    }

    clear() {
        if (this.route) {
            this.route.points = [];
            this.route.route = null;
        }
    }

    private onMapClick = async (e: MapEventArgs) => {
        if (e.nativeEvent instanceof MouseEvent) {
            if (this.enabled === true) {
                if (e.nativeEvent.button === 0) { // LMB
                    const found = e.features.find(ft => ft.geometry.type.toLowerCase() === 'point');
                    if (found) {
                        let index: number;
                        const point = this.route.points.find((point, i) => {
                            if (i > 0 && i < this.route.points.length - 1 && Point.fromCoordinate(point).distance(found.geometry) < 0.001) {
                                index = i;
                                return true;
                            }
                            return false;
                        });

                        if (point) {
                            this.hide();

                            const content = document.createElement('button');
                            content.type = 'button';
                            content.className = 'btn btn-danger';
                            this._localizationService.localizeElement(content, 'general.remove');
                            content.onclick = () => this.removeLocation(index);

                            this.popupRemovePoint = this._map.openPopup(found.geometry.getCoordinate(), content, { addContainer: false, hasCloseButton: false, anchor: 'top' });
                        }
                    }
                    else
                        this._onLocationSelected.trigger({ coord: e.coordinate, mouseButton: 1 });
                }
                else if (e.nativeEvent.button === 2) { // RMB
                    const group = document.createElement('div');
                    group.className = 'list-group shadow';
        
                    const aStart = document.createElement('a');
                    aStart.type = 'button';
                    aStart.className = 'list-group-item list-group-item-action';
                    this._localizationService.localizeElement(aStart, 'rmap.rm.map-picker.set-as-start');
                    aStart.onclick = () => this.addLocation(e.coordinate, 0, 2);
                    group.appendChild(aStart);
        
                    const aEnd = document.createElement('a');
                    aEnd.type = 'button';
                    aEnd.className = 'list-group-item list-group-item-action';
                    this._localizationService.localizeElement(aEnd, this.route.points.length > 1 ? 'rmap.rm.map-picker.set-as-new-destination' : 'rmap.rm.map-picker.set-as-destination');
                    aEnd.onclick = () => this.addLocation(e.coordinate, this.route.points.length === 0 ? 1 : this.route.points.length, 2);
                    group.appendChild(aEnd);
        
                    this.hide();
                    this.popupClick = this._map.openPopup(e.coordinate, group, {
                        addContainer: false,
                        hasCloseButton: false,
                        panOnOpen: false
                        // cameraOptions: { relCenterX: this.getRelCenterX() }
                    });
                }
            }
        }
    };

    private addLocation(coord: CoordinateLike, index: number, mouseButton: number) {
        this.hide();
        this._onLocationSelected.trigger({ coord: coord, mouseButton: mouseButton, index: index });
        if (this.constructRoutes) {
            if (index > this.route.points.length - 1)
                this.route.points.push(coord);
            else {
                if (this.route.points.length > 1)
                    this.route.points.splice(index, 1, coord);
                else
                    this.route.points.splice(index, 0, coord);
            }

            this.setRoute();
        }
    }

    private removeLocation(index: number) {
        this.hide();
        if (this.constructRoutes) {
            if (index > 0 && index < this.route.points.length)
                this.route.points.splice(index, 1);
        
            this.setRoute();
        }
    }

    private async setRoute() {
        if (this.route.points.length > 1) {
            this.setLoadingStatus(true);
            const route = await this._routingService.routev1(this.route.points.slice());
            this.setLoadingStatus(false);
            this._onRouteConstructed.trigger(route);
            if (route)
                this.route = route;
            else
                this.clear();
        }
        
        this.refreshRoute();
    }

    private refreshRoute() {
        if (this.drawRoutes === true) {
            if (this.route && this.route.points.length > 0)
                this._map.drawRoute(this.route, this._options);
            else
                this._map.clearRoute();
        }
    }

    private setLoadingStatus(loading: boolean) {
        this._onProcessingStatusChange.trigger(loading);
    }
}
