export const RMBClickTemplate = `
    <ul class="list-group">
        <li id="promet-si-rmb-info" data-id="promet-si-rmb-info" class="list-group-item list-group-item-action promet-si-rmb-item p-1" data-i18n="promet-si.rmb.what-is-here"></li>
        <li id="promet-si-rmb-start" data-id="promet-si-rmb-start" class="list-group-item list-group-item-action promet-si-rmb-item p-1" data-i18n="promet-si.rmb.route-start"></li>
        <li id="promet-si-rmb-via" data-id="promet-si-rmb-via" class="list-group-item list-group-item-action promet-si-rmb-item p-1" data-i18n="promet-si.rmb.route-via"></li>
        <li id="promet-si-rmb-destination" data-id="promet-si-rmb-destination" class="list-group-item list-group-item-action promet-si-rmb-item p-1" data-i18n="promet-si.rmb.route-destination"></li>
    </ul>
`;

export const RMBClickTemplateInfo = `
    <div class="p-2">
        <div class="fw-bold pe-3">{{properties.Title}}</div>
        <div>{{properties.Description}}</div>
        <div>
            <span data-i18n="promet-si.rmb.gps"></span>
            <span>{{properties.x}}, {{properties.y}}</span>
        </div>
        {{#properties.Ad}}
            <div>
                <span data-i18n="promet-si.rmb.ad-near"></span><span>{{#properties.Ad.dist}} ({{properties.Ad.dist}} m){{/properties.Ad.dist}}: </span>
                <span id="promet-si-geocoding-ad" data-id="promet-si-geocoding-ad" class="text-primary" style="cursor: pointer; text-decoration: underline;">{{properties.Ad.title}}, {{properties.Ad.descr}}</span>
            </div>
        {{/properties.Ad}}
        {{^properties.Ad}}
            <div data-i18n="promet-si.rmb.ad-near-none"></div>
        {{/properties.Ad}}

        {{#properties.Cesta}}
            <div>
                <span data-i18n="promet-si.rmb.cesta-near"></span><span>{{#properties.Cesta.dist}} ({{properties.Cesta.dist}} m){{/properties.Cesta.dist}}: </span>
                <span id="promet-si-geocoding-cesta" data-id="promet-si-geocoding-cesta" class="text-primary" style="cursor: pointer; text-decoration: underline;">{{properties.Cesta.descr}}</span>
            </div>
            <div>
                <span data-i18n="promet-si.rmb.odsek-stacionaza"></span>
                {{#properties.Cesta.odsek}}
                    <span>{{properties.Cesta.odsek}}, </span>
                {{/properties.Cesta.odsek}}
                {{#properties.Cesta.ds_at}}
                    <span>{{properties.Cesta.ds_at}}m</span>
                {{/properties.Cesta.ds_at}}
            </div>
        {{/properties.Cesta}}
        {{^properties.Cesta}}
            <div data-i18n="promet-si.rmb.cesta-near-none"></div>
        {{/properties.Cesta}}
    </div>
`;

export const RMBClickTemplateAd = `
    <div class="p-2">
        <div class="fw-bold pe-3">{{title}}</div>
        <div>{{descr}}</div>
    </div>
`;

export const RMBClickTemplateCesta = `
<div class="p-2">
    <div class="pe-3">
        <span>{{descr}}, </span>
        <span>{{odsek}}</span>
    </div>
</div>
`;
