import * as maplibregl from 'maplibre-gl';

import { ServiceType } from '../../services/RM2Service';
import { LocalizationService } from '../../services/RM2LocalizationService';
import { Map, PersistedVisibility } from '../../map/RM2Map';
import { LayerGroup, ILayerGroup } from '../../model/style-metadata/RM2LayerGroup';
import { Layer } from '../..';

export class LegendOptions {
    groups: LegendGroupOptions[];

    public getGroupData(id: string): LegendGroupOptions {
        return this.groups.find(e => e.sid == id.substring(id.length - e.sid.length));
    }
}

export class LegendGroupOptions {
    sid: string;
    layers: LegendLayerOptions[];
}

export class LegendLayerOptions {
    title: string;
    color: string;
}

export class LegendControl implements maplibregl.IControl {
    private _map: Map;

    private _button: HTMLButtonElement;
    private _panel: HTMLDivElement;

    private _localizationService: LocalizationService;

    private hoveringCheckbox: boolean = false;
    private layerCheckboxes: HTMLInputElement[] = [];
    private getLayerCheckboxId(layerId: string): string { return `rm2-legend-layer-checkbox-${layerId}`; }
    private getGroupCheckboxId(layerId: string): string { return `rm2-legend-group-checkbox-${layerId}`; }

    constructor(map: Map, opts: LegendOptions) {
        this._map = map;

        this._localizationService = this._map.getService(ServiceType.Localization) as LocalizationService;

        this._button = this.createButton();
        this._panel = this.createPanel();

        this._map.onLayerVisibilityChanged.subscribe((l: Layer) => this.onLayerVisibilityChanged(l));
        this._map.onGroupVisibilityChanged.subscribe((l: LayerGroup) => this.onGroupVisibilityChanged(l));
    }

    onAdd(map: maplibregl.Map): HTMLElement {
        const wrapper = document.createElement('div');
        wrapper.className = 'maplibregl-ctrl maplibregl-ctrl-group';
        wrapper.appendChild(this._button);
        
        return wrapper;
    }

    onRemove() {
        this._button.parentNode.removeChild(this._button);
        if (this._panel.parentNode)
            this._panel.parentNode.removeChild(this._panel);
        
        this._map = undefined;
    }

    getDefaultPosition():maplibregl.ControlPosition {
        return 'top-right';
    }

    public open() {
        this.layerCheckboxes = [];
        const content = this.createLegend();
        this._panel.appendChild(content);

        this._button.parentNode.parentNode.appendChild(this._panel);

        this.resizePanel();
        window.addEventListener('resize', this.resizePanel)
    }

    public close() {
        if (this._panel) {
            while (this._panel.firstChild)
                this._panel.removeChild(this._panel.firstChild);
            
            if (this._panel.parentNode)
                this._panel.parentNode.removeChild(this._panel);
        }
        
        window.removeEventListener('resize', this.resizePanel);
    }

    private createButton(): HTMLButtonElement {
        const button = document.createElement('button');
        button.type = 'button'; // da se page ne refresha
        button.appendChild(document.createTextNode('≡'));
        button.onclick = () => this.open();

        return button;
    }

    private createPanel(): HTMLDivElement {
        const panel = document.createElement('div');
        panel.className = 'rm2-legend-panel rm2-map-controls-panel rounded p-2';

        // const closer = document.createElement('div');
        // closer.className = 'p-2 rm2-legend-panel-closer';
        // const i = document.createElement('i');
        // i.className = 'fa fa-times';
        // closer.appendChild(i);
        // closer.onclick = () => this.close();
        // panel.appendChild(closer);

        return panel;
    }

    // TODO: check, če se je višina sploh spremenila? base class za vse kontrole, ki se morajo spremeniti ob resizu windowa?
    private resizePanel = () => {
        const height = this._map.getSize()[1];
        this._panel.style.maxHeight = `CALC(${height}px - 1em)`;
    };

    private createLegend(groups?: LayerGroup[]): HTMLDivElement {
        if (groups == undefined) {
            var metadata = this._map.metadata;
            if (metadata && metadata.groups)
                groups = metadata.groups.filter(g => g.id && g.uiVisible !== false);
        }

        const content = document.createElement('div');
        groups.forEach(g => {
            const gid = g.id;
            const elementalLayersInGroup = this._map.getLayers(l => l.metadata && l.metadata.gid === gid && l.metadata.uiVisible !== false);

            switch (g.sequenceMode) {
                case 'choice': // radio
                    // if (layersInGroup.length > 0) {
                    //     const listGroup = document.createElement('div');
                    //     listGroup.className = 'list-group';
                    //     layersInGroup.forEach(c => {
                    //         const listItem = document.createElement('a');
                    //         listItem.type = 'button';
                    //         listItem.className = 'list-group-item list-group-item-action d-flex w-100 p-2 align-items-center';
                    //         const listItemTitle = document.createElement('span');
                    //         // if (c.metadata.title)
                    //         //     this._localizationService.localizeElement(listItemTitle, c.metadata.title);
                    //         listItemTitle.innerText = c.id;
                    //         listItem.appendChild(listItemTitle);
                    //         const listItemImg = document.createElement('img');
                    //         listItemImg.className = 'rm2-legend-layer-icon-big ml-auto';
                    //         if (c.metadata.icon)
                    //             listItemImg.src = c.metadata.icon;
                    //         listItem.appendChild(listItemImg);
                            
                    //         // listItem.onclick = () => this._rMap.raiseInGroupLayer(l.sid);

                    //         listGroup.appendChild(listItem);
                    //     });

                    //     content.appendChild(listGroup);
                    // }

                    // if (g.children && g.children.length > 0) {
                    //     if (g.uiGroupExpandable !== false || g.uiGroupAlwaysOpen === true)
                    //         content.appendChild(this.createLegend(g.children));
                    // }

                    const listGroup = document.createElement('div');
                    listGroup.className = 'list-group';
                    if (g.children && g.children.length > 0) {
                        g.children.forEach(c => {
                            const listItem = document.createElement('a');
                            listItem.type = 'button';
                            listItem.className = 'list-group-item list-group-item-action d-flex w-100 p-2 align-items-center';
                            const listItemTitle = document.createElement('span');
                            if (c.title)
                                this._localizationService.localizeElement(listItemTitle, c.title);
                            listItem.appendChild(listItemTitle);
                            const listItemImg = document.createElement('img');
                            listItemImg.className = 'rm2-legend-layer-icon-big ml-auto';
                            if (c.icon)
                                listItemImg.src = c.icon;
                            listItem.appendChild(listItemImg);
                            
                            listItem.onclick = () => {
                                const filtered = g.children.filter(child => child.id !== c.id);
                                filtered.forEach(other => this._map.setGroupVisibility(other.id, false));
                                this._map.setGroupVisibility(c.id, true);

                                const persist: PersistedVisibility[] = filtered.map(x => {
                                    return {
                                        type: 'group',
                                        id: x.id,
                                        visible: false
                                    };
                                });

                                persist.push({
                                    type: 'group',
                                    id: c.id,
                                    visible: true
                                });

                                this._map.persistVisibility(persist);
                            };

                            listGroup.appendChild(listItem);
                        });
                    
                        content.appendChild(listGroup);
                    }

                    break;
                case 'checkbox':
                default:
                    const container = document.createElement('div');
                    // if (g.parent)
                    //     container.classList.add('mt-1', 'card');

                    if (g.uiGroupExpandable !== false) {
                        if (elementalLayersInGroup.length <= 1) {
                            const formGroup = this.getCheckboxHeader(g);
                            container.classList.add('rm2-legend-transparent-border-bg', 'ml-2');
                            formGroup.classList.add('rm2-legend-transparent-border-bg', 'card-header', 'py-2');
                            container.appendChild(formGroup);
                        }
                        else {
                            const controls = 'rm2-legend-collapse-' + g.id;
                            const groupContent = document.createElement('div');

                            groupContent.className = 'collapse card-body p-0';
                            groupContent.id = controls;

                            if (g.uiGroupHeaderVisible !== false) {
                                groupContent.classList.add('p-2');
                                const groupCollapse = document.createElement('a');
                                groupCollapse.className = 'card-header rm2-legend-pointer p-1 text-black w-100 d-inline-flex justify-content-start align-items-center';

                                const formGroup = this.getCheckboxHeader(g, false);
                                groupCollapse.appendChild(formGroup);

                                if (g.uiGroupAlwaysOpen !== true) {
                                    const collapse = document.createElement('div');
                                    collapse.className = 'flex-grow-1';
                                    const collapseIcon = document.createElement('i');
                                    collapseIcon.className = 'fa fa-angle-down float-right pr-2';
                                    collapse.appendChild(collapseIcon);
                                    groupCollapse.appendChild(collapse);

                                    groupCollapse.onclick = () => {
                                        if (this.hoveringCheckbox === false) {
                                            groupContent.classList.toggle('show');
                                            collapseIcon.classList.toggle('fa-angle-down');
                                            collapseIcon.classList.toggle('fa-angle-up');
                                        }
                                    };
                                }
                                else
                                    groupContent.classList.add('show');

                                container.appendChild(groupCollapse);
                            }
                            else
                                groupContent.classList.add('show');

                            elementalLayersInGroup.forEach(l => {
                                const childHeader = this.getCheckboxHeader(l);
                                groupContent.appendChild(childHeader);
                            });
                            container.appendChild(groupContent);
                        }

                        content.appendChild(container);
                    }
                    else {
                        // container.className = 'w-100 d-inline-flex justify-content-start align-items-center';
                        container.classList.add('rm2-legend-transparent-border-bg', 'ml-2');
                        const formGroup = this.getCheckboxHeader(g);
                        formGroup.classList.add('rm2-legend-transparent-border-bg', 'card-header', 'py-2');
                        container.appendChild(formGroup);

                        content.appendChild(container);
                    }
                    break;
            }
        });

        return content;
    }

    private getCheckboxHeader(el: LayerGroup | Layer, flexGrowLabel: boolean = true): HTMLDivElement {
        const formGroup = document.createElement('div');
        formGroup.className = 'rm2-legend-pointer custom-control custom-checkbox';
        const checkbox = document.createElement('input');
        const formId = el instanceof LayerGroup ? this.getGroupCheckboxId(el.id) : this.getLayerCheckboxId(el.id);
        checkbox.type = 'checkbox';
        checkbox.className = 'rm2-legend-pointer custom-control-input';
        checkbox.id = formId;
        if (el instanceof LayerGroup)
            checkbox.checked = el.visible !== false;
        else
            checkbox.checked = el.metadata == null || el.metadata.visible !== false;
        checkbox.onmouseenter = () => this.hoveringCheckbox = true;
        checkbox.onmouseleave = () => this.hoveringCheckbox = false;
        checkbox.onchange = (e) => {
            const vis = (e.target as HTMLInputElement).checked;
            const persist: PersistedVisibility = {
                type: 'group',
                id: el.id,
                visible: vis
            };

            if (el instanceof LayerGroup)
                this._map.setGroupVisibility(el.id, vis);
            else {
                persist.type = 'layer';
                this._map.setLayerVisibility(el.id, vis);
            }

            this._map.persistVisibility([persist]);
        }
        this.layerCheckboxes.push(checkbox);

        formGroup.appendChild(checkbox);
        const label = document.createElement('label');
        label.className = 'rm2-legend-pointer custom-control-label';
        label.htmlFor = formId;

        if (flexGrowLabel === true)
            label.classList.add('flex-grow-1');

        let iconSrc: string;
        if (el instanceof LayerGroup)
            iconSrc = el.icon;
        else {
            if (el.metadata.icon)
                iconSrc = el.metadata.icon;
        }
        
        if (iconSrc) {
            const icon = document.createElement('img');
            icon.className = 'rm2-legend-layer-icon mr-2';
            icon.src = iconSrc;
            label.appendChild(icon);
        }

        const title = document.createElement('span');
        this._localizationService.localizeElement(title, el instanceof LayerGroup ? el.title : el.metadata.title);
        label.appendChild(title);
        formGroup.appendChild(label);

        return formGroup;
    }

    private onGroupVisibilityChanged(group: LayerGroup) {
        const id = this.getGroupCheckboxId(group.id);
        const found = this.layerCheckboxes.find(checkbox => checkbox.id === id);
        if (found)
            found.checked = group.visible !== false;
    }

    private onLayerVisibilityChanged(layer: Layer) {
        const id = this.getLayerCheckboxId(layer.id);
        const found = this.layerCheckboxes.find(checkbox => checkbox.id === id);
        if (found)
            found.checked = layer.metadata.visible;
    }
}
