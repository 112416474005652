import Mustache from 'mustache';

export * from './rm2/css';

import { Map, Feature, IPolygonOptions, Geometry, Projection, IGeolocationOptions, MapEventArgs, Popup, FeatureCollection, Layer, Route } from './rm2';
import { Subject, ReplaySubject, Observable, BehaviorSubject } from 'rxjs';
import { Coordinate } from './rm2';
import { ILineOptions } from './rm2/';
import { Polygon, CoordinateLike, Point } from './rm2/model/RM2Geometry';
import { IFeatureHighlightOptions, IMarkerOptions, ICircleOptions } from './rm2/highlights/RM2HighlightOptions';
import { FitOptions, ICameraChangeOptions } from './rm2/model/RM2CameraChangeOptions';
import { RM2ViewData } from './rm2/model/RM2ViewData';
import { MapTheme } from './rm2/model/RM2Theme';
import { Position } from 'geojson';
import { RM2GeolocationPosition } from './rm2/geolocation/RM2Geolocation';
import { v4 } from 'uuid';
import { IRouteHighlightOptions, RouteHighlightOptions, RouteLegacy, RouteFeatureCollection, RouteInstructionsFeatureCollection } from './rm2/services/RM2RoutingService';
import { MapControlType } from './rm2/map-controls/RM2MapControls';
import { IPopupOptions } from './rm2/popup/RM2PopupOptions';
import { ServiceType } from './rm2/services/RM2Service';
import { LocalizationService } from './rm2/services/RM2LocalizationService';
import { RM2MapCompareState } from './rm2/map/RM2Map';

export class MapContainer {
  public static readonly ATTRIBUTE_STYLE_URL = "style-url";
  private _lastPopup: Popup;

  constructor(elementId: string, mapId?: string, initMap?: boolean) {
    this.ElementId = elementId;
    this.Element = document.getElementById(elementId);
    if (!this.Element)
      throw new Error(`Invalid elementId ${elementId}`);

    this._extendElement();
    this.reconfigure(initMap, mapId);
  }

  public ElementId: string;
  public Element: HTMLElement;
  private get ElementAny() { return this.Element as any; }
  private get styleUrl(): string { return this.Element.getAttribute(MapContainer.ATTRIBUTE_STYLE_URL); }

  public get map(): Map { return this._map; }
  private _map: Map;
  private _onLayerReloadedSubs: Array<any> = [];

  public get evt_mapInitialized(): Observable<void> { return this._evt_mapInitialized; }
  private _evt_mapInitialized = new ReplaySubject<void>(1);

  public get evt_mapClick(): Observable<MapEventArgs> { return this._evt_mapClick; }
  private _evt_mapClick = new Subject<MapEventArgs>();

  public get evt_mapLongClick(): Observable<MapEventArgs> { return this._evt_mapLongClick; }
  private _evt_mapLongClick = new Subject<MapEventArgs>();

  public get evt_mapDblClick(): Observable<MapEventArgs> { return this._evt_mapDblClick; }
  private _evt_mapDblClick = new Subject<MapEventArgs>();

  public get evt_mapMouseMove(): Observable<MapEventArgs> { return this._evt_mapMouseMove; }
  private _evt_mapMouseMove = new Subject<MapEventArgs>();

  public get evt_layerRestyle(): Observable<MapEventArgs> { return this._evt_layerRestyle; }
  private _evt_layerRestyle = new Subject<MapEventArgs>();

  public get evt_mapUserAction(): Observable<MapEventArgs> { return this._evt_mapUserAction; }
  private _evt_mapUserAction = new Subject<MapEventArgs>();

  public get evt_mapLoad(): Observable<MapEventArgs> { return this._evt_mapLoad; }
  private _evt_mapLoad = new ReplaySubject<MapEventArgs>(1);

  public get evt_layerReloaded(): Observable<MapEventArgs> { return this._evt_layerReloaded; }
  private _evt_layerReloaded = new Subject<MapEventArgs>();

  public get evt_viewChange(): Observable<RM2ViewData> { return this._evt_viewChange; }
  private _evt_viewChange = new Subject<RM2ViewData>();

  public get evt_routeConstructed(): Observable<Route> { return this._evt_routeConstructed; }
  private _evt_routeConstructed = new Subject<Route>();

  public get evt_popupOpened(): Observable<Popup> { return this._evt_popupOpened; }
  private _evt_popupOpened = new Subject<Popup>();

  public static get(mapId: string): MapContainer {
    const rmaps = (window as any).rmaps;
    if (Array.isArray(rmaps)) {
      const found = rmaps.find(x => x.id === mapId);
      if (found)
        return found.map;
    }
    return null;
  }

  public static initDARSPlugiMap(mapContainerId: string, lang: string): MapContainer {
    const el = document.querySelector(`#${mapContainerId}`);
    if (el) {
      //el.setAttribute(this.ATTRIBUTE_STYLE_URL, `https://promet-dars.geoprostor.net/ext/plugi/map-styles/vector-hybrid.${lang}.mb-style.json`);
      el.setAttribute(this.ATTRIBUTE_STYLE_URL, `https://www.promet.si/ext/plugi/map-styles/vector-hybrid.${lang}.mb-style.json`);

      return new MapContainer(mapContainerId);
    }

    throw new Error(`Invalid map target for DARS plugi: ${mapContainerId}`)
  }

  private _extendElement() {
    // Tega ne delamo - vrnemo samo MapContainer objekt. Po potrebi se lahko doda

    // if (this.ElementAny.Rm2Bootstraper) {
    //   throw new Error("Element is already in use by Rm2. Dispose existing first!");
    // }
    // this.ElementAny.Rm2Bootstraper = this;
  }

  public reconfigure = (initMap?: boolean, mapId?: string) => {
    this.dispose();
    this._reconfigure(initMap, mapId);
  }

  private _reconfigure = async (initMap?: boolean, mapId?: string) => {
    if (initMap !== false)
      this.initMap(mapId);
  }

  public initMap = (mapId?: string) => {
    if (!mapId)
      mapId = v4();

    this._map = new Map(this.styleUrl, this.Element, { id: mapId });

    const metadata = this._map.metadata;
    this.map.onClick.subscribe(this._mapOnClick);
    this.map.onLongClick.subscribe(this._mapOnLongClick);
    this.map.onDblClick.subscribe(this._mapOnDblClick);
    this.map.onMouseMove.subscribe(this._mapOnMouseMove);
    this.map.onRestyleLayer.subscribe(this._mapOnRestyleLayer);
    this.map.onUserAction.subscribe(this._mapOnUserAction);
    this.map.onViewChange.subscribe(this._mapOnViewChange);
    this.map.onLoad.subscribe(this._mapOnLoad)
    this.map.onRouteConstructed.subscribe(this._mapRouteConstructed);
    this.map.onPopupOpened.subscribe(this._popupOpened);

    const maps = (window as any).rmaps;
    const obj = { id: mapId, map: this };
    if (Array.isArray(maps))
      maps.push(obj);
    else
      (window as any).rmaps = [obj];

    // const layers = this._map.getLayers(x => x instanceof VectorLayer);
    // for (const key in layers) {
    //   if (layers.hasOwnProperty(key)) {
    //     const layer = layers[key] as VectorLayer;

    //     const handlerFactory = (l: VectorLayer) => () => {
    //       this._layerOnReloaded({ map: this.map, layer: l });
    //     };

    //     if (layer.source) {
    //       const handler = handlerFactory(layer);
    //       layer.source.onReloadLayer.subscribe(handler);
    //       this._onLayerReloadedSubs.push({ source: layer.source, handler: handler });
    //     }
    //   }
    // }

    this.map.setTarget(this.Element);
    this._onMapInitialized();
  }

  public dispose = () => {
    if (this.map) {
      this.map.setTarget(undefined);

      this.map.onClick.unsubscribe(this._mapOnClick);
      this.map.onLongClick.unsubscribe(this._mapOnLongClick);
      this.map.onDblClick.unsubscribe(this._mapOnDblClick);
      this.map.onMouseMove.unsubscribe(this._mapOnMouseMove);
      this.map.onRestyleLayer.unsubscribe(this._mapOnRestyleLayer);
      this.map.onUserAction.unsubscribe(this._mapOnUserAction);
      this.map.onViewChange.unsubscribe(this._mapOnViewChange);
      this.map.onRouteConstructed.unsubscribe(this._mapRouteConstructed);
      this.map.onPopupOpened.unsubscribe(this._popupOpened);

      for (const key in this._onLayerReloadedSubs) {
        if (this._onLayerReloadedSubs.hasOwnProperty(key)) {
          const sh = this._onLayerReloadedSubs[key];
          if (sh && sh.source && sh.handler) {
            sh.source.onReloadLayer.unsubscribe(sh.handler);
          }
        }
      }
      this._onLayerReloadedSubs = [];
    }
  }

  public zoom = (zoom?: number, animate?: boolean) => {
    const view = this.map.getView();
    const newZoom = view.zoom + zoom;
    this.map.pan(view.center, {
      zoom: newZoom,
      animate: animate
    });
  }

  public getLayers = (condition: (x: Layer) => boolean = () => true): Layer[] => {
    if (this.map) {
      const layers = this.map.getLayers(x => x instanceof Layer && condition(x) === true);
      return layers;
    }
    return null;
  }

  public setVisibility = (sids?: string[], visible?: boolean) => {
    if (sids) {
      sids.forEach(sid => {
        if (visible == null)
          this.map.setLayerVisibility(sid, !this.map.getLayerVisibility(sid));
        else
          this.map.setLayerVisibility(sid, visible);
      });
    }
  }

  public drawRouteFromGeoJson = (route: any, points: CoordinateLike[], options?: IRouteHighlightOptions) => {
    const fc = RouteFeatureCollection.fromGeoJson(route);
    fc.properties.Instructions = RouteInstructionsFeatureCollection.fromGeoJson(fc.properties.Instructions);
    for (let i = 0; i < fc.features.length; i++)
      fc.features[i].properties.includeInPopup = false;
    
    this.drawRoute({
      points: points,
      route: fc
    }, options)
  };

  public drawRoute = (route: Route, options?: IRouteHighlightOptions) => {
    this.map.drawRoute(route, options);
  };

  public drawRouteLegacy = (route: RouteLegacy, options?: IRouteHighlightOptions) => {
    this.map.drawRouteLegacy(route, options);
  };

  public clearRoute = () => {
    this.map.clearRoute();
  };

  // public drawMarker = (gjGeometry?: string, options?: IMarkerOptions) => {
  //   const geometry = Geometry.fromGeoJson(gjGeometry);
  //   const projection = Projection.create(options.projection as any);
  //   options.projection = projection;

  //   if (geometry && projection)
  //     this.map.drawMarker(geometry, options);
  // }

  public drawMarker = (coords: Coordinate | CoordinateLike, options?: IMarkerOptions) => {
    let geometry: Point = undefined;
    if (Array.isArray(coords as any)) {
      geometry = Point.fromCoordinate(coords);
    }
    if (coords instanceof String || typeof  coords === 'string') {
      geometry = Point.fromGeoJson(coords as any as string) as Point;
    }
    else {
      geometry = coords as any as Point;
    }

    const projection = Projection.create(options.projection as any || 'EPSG:4326');
    options.projection = projection;

    if (geometry && projection)
      this.map.drawMarker(geometry, options);
  }

  public clearMarker = () => {
    this.map.clearMarkers();
  }

  public drawCircle = (gjGeometry?: string, options?: ICircleOptions) => {
    const geometry = Geometry.fromGeoJson(gjGeometry);
    const projection = Projection.create(options.projection as any);
    options.projection = projection;

    if (geometry && projection)
      this.map.drawCircle(geometry, options);
  }

  public clearCircle = () => {
    this.map.clearCircles();
  }

  public drawLine = (gjGeometry: string, options?: ILineOptions) => {
    const geometry = Geometry.fromGeoJson(gjGeometry);
    const projection = Projection.create(options.projection as any);
    options.projection = projection;

    if (geometry && projection)
      this.map.drawLine(geometry, options);
  };

  public clearLine = () => {
    this.map.clearLines();
  }

  public drawPolygon = (coords: number[][][], options?: IPolygonOptions) => {
    const polygonCoords = [coords[0].map(coord => Coordinate.fromOrdinates(coord as CoordinateLike))];
    const geometry = new Polygon(polygonCoords);
    this.map.drawPolygon(geometry, options);
  }

  public clearPolygon = () => {
    this.map.clearPolygons();
  }

  public addFeature = (ft: any, opts?: IFeatureHighlightOptions): any => {
    // opts.projection = Projection.create('EPSG:4326');
    return this.map.addFeature(Feature.fromGeoJson(ft), opts);
  };

  public addFeatures = (ft: any, opts?: IFeatureHighlightOptions): any => {
    // opts.projection = Projection.create('EPSG:4326');
    return this.map.addFeatures(FeatureCollection.fromGeoJson(ft), opts);
  };

  public removeFeature = (id: string) => {
    if (id)
      this.map.removeFeature(id);
  };

  public clearFeatures = () => {
    this.map.clearFeatures();
  };

  public getAllFeatures = (): any => {
    return this.map.getAllFeatures().toGeoJson();
  }

  public fitToFeatures = (padding: number) => {
    this.map.fitCameraToFeatures(this.map.getAllFeatures(), new FitOptions({ padding: padding }));
  }

  public fitToFeatureCollection = (fc: FeatureCollection<any, any>, options: FitOptions) => {
    const ffc = FeatureCollection.fromGeoJson(fc);
    this.map.fitCameraToFeatures(ffc, options);
  }

  public fitToSources = (sourceIds: string[], options: FitOptions) => {
    this.map.fitCameraToSources(sourceIds, options);
  }

  public fitToExtent = (bbox: Coordinate[] | CoordinateLike[], options: FitOptions) => {
    this.map.fit(bbox, options);
  }

  public pan = (coordinate: number[], zoom?: number, projection?: string, animate?: boolean, relCenterX?: number, relCenterY?: number, bearing?: number, pitch?: number) => {
    const _projection = Projection.create(projection || 'EPSG:4326');
    this.map.pan(Coordinate.fromOrdinates(coordinate as CoordinateLike), {
      zoom: zoom,
      projection: _projection,
      animate: animate,
      relCenterX: relCenterX,
      relCenterY: relCenterY,
      bearing: bearing,
      pitch: pitch
    });
  }

  public tilt = (degrees: number) => {
    this.map.tilt(degrees);
  }

  public setLoadingStatus = (status: boolean) => {
    const loadingId = 'rmap2-loading';
    const container = this.map.getTarget();
    const found = container.querySelector('#' + loadingId);
    if (status) {
      if (found)
        return;
      
      const loadingContainer = document.createElement('div');
      loadingContainer.className = 'w-100 h-100 d-flex align-items-center justify-content-center';
      loadingContainer.id = loadingId;
      loadingContainer.style.backgroundColor = '#00000099';
      loadingContainer.style.zIndex = '2';
      loadingContainer.style.position = 'absolute';

      const spinner = document.createElement('div');
      spinner.className = 'spinner-border text-light';
      spinner.setAttribute('role', 'status');
      const spinnerContent = document.createElement('span');
      spinnerContent.className = 'sr-only';
      spinnerContent.innerText = 'Loading...';
      spinner.appendChild(spinnerContent);

      loadingContainer.appendChild(spinner);
      container.appendChild(loadingContainer);
    }
    else {
      if (found)
        found.parentElement.removeChild(found);
    }
  };

  public showGeolocation = (position: RM2GeolocationPosition, options: IGeolocationOptions) => {
    this.map.showGeolocation(position, options);
  }

  public clearGeolocation = () => {
    this.map.clearGeolocation();
  }

  public getControl = (control: MapControlType) => {
    return this.map.getControl(control);
  };

  public setMapTheme = (theme: string) => {
    this.map.setMapTheme(theme as MapTheme);
  };

  public setSourceData = (id: string, data: any) => {
    const fc = FeatureCollection.fromGeoJson(data);
    this.map.setSourceData(id, fc);
  };

  public openPopupForFeature(ft: any, layer: string, options?: IPopupOptions): Popup {
    const ftJson = Feature.fromGeoJson(ft);
    const l = this._map.getLayer(layer);
    if (l) {
      let zoom = null;
      // const src = (typeof l.source == 'string' ? this._map.getSource(l.source) : l.source) as maplibregl.GeoJSONSource;

      const content = this.getPopupContent(ftJson, l);
      this._map.closeAllPopups();
      const p = this._map.openPopupForFeature(ftJson, l, content.content, options);
      this._lastPopup = p;
      this._lastPopup.onClose.subscribe(() => clearInterval(content.interval));
      return p;
    }
    return null;
  }

  // public refresh(sids?: string[]) {
  //   let layers = this.map.getLayers(x => true);
  //   if (sids != null && sids !== undefined) {
  //     const fsids = sids.filter(sid => sid != null && sid !== undefined && sid !== "");
  //     layers = this.map.getLayers(x => fsids.indexOf(x.sid) >= 0);
  //   }
  //   layers.forEach(layer => {
  //     if (layer instanceof SourceLayer) {
  //       if (layer.source) {
  //         if (layer.source instanceof VectorSource) {
  //           layer.source.reload(undefined, error => {
  //             if (console) {
  //               console.error(`Failed reloading layer, error: ${error}`);
  //             }
  //           });
  //         }
  //         else {
  //           layer.source.reload();
  //         }
  //       }
  //     }
  //   });
  // }

  public transformEpsg3912ToEpsg4326(x: number, y: number): CoordinateLike {
    const coord = Coordinate.fromOrdinates([x, y]);
    const transformed = Coordinate.transform(coord, Projection.create('EPSG:3912'), Projection.create('EPSG:4326'));
    return [transformed.x, transformed.y];
  }

  public setLanguage(lang: string) {
    this._map.setLanguage(lang);
  }

  // Dodano za atob, pa smo ugotovili da ni uporabno
  // public fullscreenEnter () {
  //   this.map.fullscreenEnter();
  // }
  // public fullscreenExit () {
  //   this.map.fullscreenExit();
  // }
  // public fullscreenGet (): boolean {
  //   return this.map.fullscreenGet();
  // }

  private _onMapInitialized() {
    this._evt_mapInitialized.next(undefined);
  }

  private _mapOnClick = (eventArgs: MapEventArgs) => {
    // ista koda se ponovi v rmap2.service.ts
    const metadata = this._map.metadata;
    const showPopup = metadata == null || metadata.enablePopup !== false;
    if (showPopup && eventArgs.nativeEvent instanceof MouseEvent && eventArgs.nativeEvent.button == 0) { // LMB
      const fts: Feature<any>[] = [];
      const ftsLayers = [];

      // filtriraj
      for (let i = 0; i < eventArgs.features.length; i++) {
        const ft = eventArgs.features[i];
        const ftLayer = eventArgs.featureLayers[i];
        if (ft.properties.includeInIdentify !== false && ft.properties.includeInPopup !== false) {
          let id = ft && ft.properties && ft.properties.Id;

          // odstranimo duplikate - vcasih je multilinestring kot vec featurjev
          if (id && fts.some(xft => xft && xft.properties && xft.properties.Id === id)) {//ft && ft.properties && ft.properties.id !== undefined) {
            continue;
          }
          else {
            fts.push(ft);
            ftsLayers.push(ftLayer);
          }
        }
      }

      if (fts.length > 0) {
        const clusterPointCountKey = 'point_count';
        // če so v kliku samo clusterji
        if (fts.find(ft => ft.hasProperty(clusterPointCountKey) === false) == undefined) {
          const cluster = fts[0];
          const clusterCoord = cluster.geometry.getCoordinate();
          const currView = this._map.getView();

          setTimeout(() => {
            this.map.pan(clusterCoord, {
              zoom: currView.zoom + 1,
              animate: true
            });
          });
        }
        else {
          // filtriraj ven clusterje
          for (let i = 0; i < fts.length; i++) {
            const ft = fts[i];
            if (ft.hasProperty(clusterPointCountKey)) {
              fts.splice(i, 1);
              ftsLayers.splice(i, 1);
              i--;
            }
          }

          let firstCoordinate = fts[0].geometry.getCoordinate() as Coordinate;
          if (fts[0].geometry.type !== 'Point') {
            firstCoordinate = new Coordinate(eventArgs.coordinate[0], eventArgs.coordinate[1]);
          }
          const intervals = [];
          this._lastPopup = eventArgs.map.openPopupForFeatures([firstCoordinate.x, firstCoordinate.y], fts, (ft: Feature, i: number): HTMLElement => {
            const l = ftsLayers[i];
            const container = this.getPopupContent(ft, l);
            intervals.push(container.interval);
            return container.content;
          }, { maxWidth: ftsLayers.map(l => l.metadata.infoWindowMaxWidth) });
  
          this._lastPopup.onClose.subscribe(() => intervals.forEach(interval => clearInterval(interval)));
        }
      }
      else
        this._evt_mapClick.next(eventArgs);
    }
    else
      this._evt_mapClick.next(eventArgs);
  };

  private _mapOnLongClick = (eventArgs: MapEventArgs) => {
    this._evt_mapLongClick.next(eventArgs);
  };

  private _mapOnDblClick = (eventArgs: MapEventArgs) => {
    this._evt_mapDblClick.next(eventArgs);
  };

  private _mapOnMouseMove = (eventArgs: MapEventArgs) => {
    this._evt_mapMouseMove.next(eventArgs);
  };

  private _mapOnRestyleLayer = (eventArgs: MapEventArgs) => {
    this._evt_layerRestyle.next(eventArgs)
  };

  private _mapOnUserAction = (eventArgs: MapEventArgs) => {
    this._evt_mapUserAction.next(eventArgs);
  };

  private _mapOnViewChange = (eventArgs: RM2ViewData) => {
    this._evt_viewChange.next(eventArgs);
  };

  private _mapRouteConstructed = (eventArgs: Route) => {
    this._evt_routeConstructed.next(eventArgs);
  };

  private _popupOpened = (eventArgs: Popup) => {
    this._evt_popupOpened.next(eventArgs);
  };

  private _mapOnLoad = (eventArgs: MapEventArgs) => {
    this._evt_mapLoad.next(eventArgs);
  };

  private _layerOnReloaded = (eventArgs: MapEventArgs) => {
    this._evt_layerReloaded.next(eventArgs);
  };

  public get view(): RM2ViewData {
    return this.map.getView();
  }

  public set view(value: RM2ViewData) {
    this.map.setView(value);
  }

  private getPopupContent(ft: Feature, l: Layer): { interval: any, content: HTMLElement } {
    const container = document.createElement('div');
    let interval: any;

	if(ft && ft.properties && ft.properties.UpravljalecWeb)
	{
		
	}	
    // if (ft && ft.properties && ft.properties.contentName == "ncup.charging")
    // {
    //     (ft.properties as any).hasAdHocPrice = !(ft.properties.adHocPrice === null || ft.properties.adHocPrice === undefined);
    //     (ft.properties as any).isOpen = (ft.properties.isOpen && !(ft.properties.isOpen==="False"));
    // }
    
    ft.properties.r_eq_str = function () {
      return function (text, render) : string {
        //{r_eq_str}isOpen;False;<span class='font-weight-bold' data-i18n='ncup.chargingStations.open.open'></span>
        if (text != null && text !== undefined && typeof text == 'string' && text.length > 1) {
          const splt = text.split(';', 3);
          const key = splt[0];
          const expectedVal = splt[1];
          const output = splt[2]
          const pval = key in ft.properties ? ft.properties[key] : undefined;
  
          if (expectedVal === pval) {
            const r = render(output);
            return r;
          }
        }
        return undefined;
      };
    };
    ft.properties.r_neq_str = function () {
      return function (text, render) : string {
        //{r_eq_str}isOpen;False;<span class='font-weight-bold' data-i18n='ncup.chargingStations.open.open'></span>
        if (text != null && text !== undefined && typeof text == 'string' && text.length > 1) {
          const splt = text.split(';', 3);
          const key = splt[0];
          const expectedVal = splt[1];
          const output = splt[2]
          const pval = key in ft.properties ? ft.properties[key] : undefined;
  
          if (expectedVal !== pval) {
            const r = render(output);
            return r;
          }
        }
        return undefined;
      };
    };

    if (l.metadata.infoWindowTitleTemplate) {
      const title = document.createElement('div');
      title.innerHTML = Mustache.render(l.metadata.infoWindowTitleTemplate, ft.properties);

      // close btn
      const closeBtn = document.createElement('div');
      closeBtn.className = 'ml-auto bg-red text-white rm2-popup-close d-sm-none';
      closeBtn.setAttribute('id', 'promet-si-popup-close-btn');
      const closeIcon = document.createElement('img');
      // closeIcon.src = 'assets/res/icons/placeholder.svg';
      closeIcon.style.pointerEvents = 'none';
      closeBtn.appendChild(closeIcon);
      closeBtn.onclick = () => this._map.closeAllPopups();
      title.children[0].appendChild(closeBtn);

      container.appendChild(title);
    }

    if (l.metadata.infoWindowDescriptionTemplate) {
      const createDescription = (template: string, view: any): HTMLDivElement => {
        const dscr = document.createElement('div');
        let tmplt = template;
        if (template.includes('{{dt}}')) {
          const dt = new Date().getTime();
          tmplt = template.replace(/{{dt}}/g, dt.toString());
        }
        
        dscr.innerHTML = Mustache.render(tmplt, view);
        return dscr;
      };

      let description = createDescription(l.metadata.infoWindowDescriptionTemplate, ft.properties);
      container.appendChild(description);

      const service = this._map.getService(ServiceType.Localization) as LocalizationService;
      if (service)
        service.refresh(container);
      else
        throw new Error('Please include the localization service to localize popups.');

      if (l.metadata.infoWindowDescriptionRefreshInterval > 0) {
        interval = setInterval(() => {
          var newDescription = createDescription(l.metadata.infoWindowDescriptionTemplate, ft.properties);
          container.replaceChild(newDescription, description);
          description = newDescription;
        }, l.metadata.infoWindowDescriptionRefreshInterval);
      }
    }

    return {
      interval: interval,
      content: container
    };
  }

  public _compare (element: HTMLElement) {
    const compareStyleUrl = element.getAttribute("style-url");
    const compareMap = new Map(compareStyleUrl, element);
    const opts = {sliderPosition: 0} as RM2MapCompareState; 

    this.map.compareWith(compareMap);
    return compareMap;
  }
}

export interface ILayerSettings {
  layers?: ISingleLayerSettings[];
}

export interface ISingleLayerSettings {
  sid: string;
  visible?: boolean;
}

export interface IPopupInfo {
  html: HTMLElement;
  coord: CoordinateLike;
  interval?: any;
}
