import { CoordinateLike, Feature } from "../..";
import { Query_v3ResponseFeatureProperties } from "../../services/RM2NominatimService";

export class PrometSiControlOptions implements IPrometSiControlOptions {
    hasDetailButton?: boolean;
    hasLegend?: boolean;
    openLegend?: boolean;

    weatherDataUrl: string;
    weatherImgUrl: string;
    weatherFrameInterval?: number;
    weatherLastFrameUpdateInterval?: number;

    constructor(opts?: IPrometSiControlOptions) {
        this.hasDetailButton = opts && opts.hasDetailButton === true;
        this.hasLegend = opts == undefined || opts.hasLegend !== false;
        this.openLegend = opts && opts.openLegend === true;

        this.weatherDataUrl = opts && opts.weatherDataUrl ? opts.weatherDataUrl : 'https://promet-dars.geoprostor.net/dc/arso.radar.list';
        this.weatherImgUrl = opts && opts.weatherImgUrl ? opts.weatherImgUrl : 'https://promet-dars.geoprostor.net/dc/arso.radar.img';
        this.weatherFrameInterval = opts && opts.weatherFrameInterval ? opts.weatherFrameInterval : 500;
        this.weatherLastFrameUpdateInterval = opts && opts.weatherLastFrameUpdateInterval ? opts.weatherLastFrameUpdateInterval : 5 * 60 * 1000;
    }
}

export interface IPrometSiControlOptions {
    hasDetailButton?: boolean;
    hasLegend?: boolean;
    openLegend?: boolean;

    weatherDataUrl: string;
    weatherImgUrl: string;
    weatherFrameInterval?: number;
    weatherLastFrameUpdateInterval?: number;
}

export class PrometSiRoutingLocation {
    id: string;
    coords: CoordinateLike; // coordse shranjujemo posebej, ker so coordsi v data snappirani na naslov/cesto
    data: Feature<Query_v3ResponseFeatureProperties>;
    icon: string;
    element: HTMLInputElement;

    canSwapWithNext: boolean;

    constructor(id: string, canSwapWithNext: boolean = true) {
        this.id = id;
        this.canSwapWithNext = canSwapWithNext;
    }
}

export enum PrometSiSearchResultsType {
    Normal = 0,
    Routing = 1
}
