export enum MapControlType {
    Legend = 'legend',
    FullScreen = 'fullscreen',
    Zoom = 'zoom',
    Basemaps = 'basemaps',
    Attribution = 'attribution',
    Geolocation = 'geolocation',
    Routing = 'routing',
    MapPicker = 'map-picker',
    PrometSi = 'promet-si',
    NAP = 'nap'
}

export interface ControlData {
    type: MapControlType;
    options: any;
    enabled?: boolean;
    position?: 'top-right' | 'bottom-right' | 'bottom-left' | 'top-left';
}
